import { CLOSE_MODAL, OPEN_MODAL } from '../constants';

export const closeModal = (name) => ({
  type: CLOSE_MODAL,
  payload: name,
});

export const openModal = (name, params = {}) => ({
  type: OPEN_MODAL,
  payload: {
    name,
    params,
  },
});
