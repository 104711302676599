import React, { useContext } from 'react';

import {
  ExtraLayoutType,
  ModalsType,
  PreloadDataType,
  RoutesMap,
  TenantType,
} from '@portals/types';

interface ContextType {
  routes: RoutesMap;
  modals: ModalsType;
  preloadData: PreloadDataType;
  tenantType: TenantType;
  extraLayout: ExtraLayoutType;
}

const AppConfigContext = React.createContext<Partial<ContextType>>(null);

const AppConfigProvider = ({ children, value }) => {
  return (
    <AppConfigContext.Provider value={value}>
      {children}
    </AppConfigContext.Provider>
  );
};

const useAppConfig = () => {
  const context = useContext(AppConfigContext);
  if (context === undefined) {
    throw new Error('useAppConfig must be used within a AppConfigProvider');
  }

  return context;
};

export { AppConfigProvider, useAppConfig, AppConfigContext };
