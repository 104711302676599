import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalHeader } from 'reactstrap';

import { ModalProps } from '@portals/framework';
import { useErrorByActionName, useIsPending } from '@portals/redux';
import { clearError } from '@portals/redux/actions/errors';
import { createUser } from '@portals/redux/actions/users';
import AutoFormik, { FieldTypeEnum } from '@portals/ui/AutoFormik';

const FIELDS = [
  { name: 'name', title: 'Name', required: true },
  { name: 'email', title: 'Email', type: FieldTypeEnum.Email, required: true },
];

const AddUser: FC<ModalProps> = ({ closeMe }) => {
  const dispatch = useDispatch();
  const isePending = useIsPending('createUser');
  const error = useErrorByActionName('createUser');

  const handleSubmit = (data) => {
    dispatch(createUser(data, closeMe));
  };

  const handleClose = () => {
    dispatch(clearError('createUser'));
    closeMe();
  };

  return (
    <Modal isOpen={true} toggle={handleClose} centered>
      <ModalHeader toggle={handleClose}>Add a new user</ModalHeader>

      <AutoFormik
        fields={FIELDS}
        handleSubmit={handleSubmit}
        modal={handleClose}
        serverError={error}
        inProgress={isePending}
        submitTitle="Create"
      />
    </Modal>
  );
};

export default AddUser;
