import { set } from 'lodash/fp';

import {
  DELETE_DATA,
  REPLACE_DATA,
  SIGNED_OUT,
  UPDATE_DATA,
  UPDATE_NESTED,
  DELETE_ALL_DATA,
  SET_VALUE,
  DELETE_DATA_BY_ACCESSOR,
} from '../constants';

const initialState = {};

const reducer = (state = initialState, action) => {
  const { type, data = {}, id } = action.payload || {};

  switch (action.type) {
    case UPDATE_DATA:
      return { ...state, [type]: { ...state[type], ...data } };

    case REPLACE_DATA:
      return { ...state, [type]: data };

    case DELETE_DATA:
      const deletedData = { ...state[type] };
      delete deletedData[data];
      return { ...state, [type]: deletedData };

    case DELETE_DATA_BY_ACCESSOR:
      const items = { ...state[type] };
      const updatedItems = data.accessor(items);

      return { ...state, [type]: updatedItems };

    case DELETE_ALL_DATA:
      return { ...state, [action.payload]: {} };

    case SIGNED_OUT:
      return initialState;

    case UPDATE_NESTED:
      return set(action.payload.keys, action.payload.value, state);

    case SET_VALUE:
      return {
        ...state,
        [type]: { ...state[type], [id]: action.payload.value },
      };

    default:
      return state;
  }
};

export default reducer;
