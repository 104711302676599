import { API } from '@portals/redux';
import {
  updateData,
  updateNormalizedData,
  deleteAllData,
  replaceNormalizedData,
  deleteData,
  updateAccess,
} from '@portals/redux/actions/data';
import { SPACE_SCHEMA } from '@portals/redux/schemas';

export const getSpaces = (extraAction = null) => ({
  type: API,
  payload: {
    url: 'ui/organization/spaces',
    success: [
      replaceNormalizedData('spaces'),
      updateAccess('spaces', 'space_'),
      extraAction,
    ],
    normalize: [SPACE_SCHEMA], // We get an array of spaces
    name: 'getSpaces',
  },
});

export const updateSpaceData = (space, data, extraAction = null) => ({
  type: API,
  payload: {
    method: 'PUT',
    url: `ui/organization/spaces/${space.id}`,
    data: data,
    normalize: SPACE_SCHEMA,
    success: [
      updateNormalizedData('spaces'),
      updateAccess('spaces', 'space_'),
      extraAction,
    ],
    toastr: 'Update space',
    name: `updateSpace${space.id}`,
  },
});

export const createSpace = (parent_id, data, extraAction) => ({
  type: API,
  payload: {
    url: 'ui/organization/spaces',
    data: { ...data, parent_id },
    normalize: SPACE_SCHEMA,
    success: extraAction
      ? [
          updateNormalizedData('spaces'),
          updateAccess('spaces', 'space_'),
          extraAction,
        ]
      : [updateData('spaces'), updateAccess('spaces', 'space_')],
  },
});

export const deleteSpace = (spaceId, extraAction) => ({
  type: API,
  payload: {
    url: `ui/organization/spaces/${spaceId}`,
    method: 'DELETE',
    toastr: 'Delete space',
    success: [
      getSpaces,
      deleteAllData('devices'),
      deleteData('access', `space_${spaceId}`),
      extraAction,
    ],
    name: `deleteSpace_${spaceId}`,
  },
});

export const moveSpace = (spaceId, targetId, extraAction) => ({
  type: API,
  payload: {
    method: 'PUT',
    url: `ui/organization/spaces/${spaceId}`,
    data: { parent_id: targetId },
    normalize: SPACE_SCHEMA,
    success: [getSpaces(), extraAction],
    toastr: 'Move space',
  },
});
