import { ColorInput } from '@mantine/core';
import { isString } from 'lodash/fp';
import React, { FC } from 'react';

import { FieldRendererProps } from '../types';

const ColorField: FC<FieldRendererProps> = ({
  error,
  field,
  value,
  handleBlur,
  setFieldValue,
  readOnly,
  disabled,
  required,
}) => {
  const { name, title, placeholder, inputProps } = field;
  const { startAdornment, endAdornment } = inputProps || {};

  return (
    <ColorInput
      error={error}
      icon={startAdornment}
      rightSection={endAdornment}
      label={title}
      name={name}
      required={required}
      placeholder={placeholder || (isString(title) ? title : '')}
      value={value}
      disabled={disabled || readOnly}
      onChange={(value) => setFieldValue(field.name, value)}
      onBlur={handleBlur}
      withinPortal={false}
      {...(inputProps || {})}
    />
  );
};

export default ColorField;
