import { Text, Tooltip } from '@mantine/core';
import { useKBar } from 'kbar';
import React from 'react';
import { Nav, Navbar as ReactstrapNavbar } from 'reactstrap';
import styled from 'styled-components';

import { useAppConfig } from '@portals/framework/context';

import { ReactComponent as Search } from '../../../assets/img/icons/search.svg';
import { HubspotConversationsTrigger } from './HubspotConversationsTrigger';
import Notifications from './Notifications';
import ProductUpdates from './ProductUpdates';
import { UserSettings } from './UserSettings';

export const Navbar = () => {
  const { extraLayout = {} } = useAppConfig();
  const { query } = useKBar();
  const { navbarLayout, options = {} } = extraLayout;

  return (
    <NavbarContainer color="white" light expand>
      <Nav navbar>
        {navbarLayout ? navbarLayout() : null}

        {options.isMobile ? null : (
          <Tooltip label={<Text size="xs">Search</Text>}>
            <SearchIcon onClick={query.toggle} />
          </Tooltip>
        )}

        <HubspotConversationsTrigger />

        <ProductUpdates />

        <Notifications />

        <UserSettings />
      </Nav>
    </NavbarContainer>
  );
};

const NavbarContainer = styled(ReactstrapNavbar)`
  border-bottom: 2px solid #eceff1;
  padding: 10px 12px;

  ${({ isMobile }) =>
    isMobile &&
    `
    position: sticky;
    top: 0;
  `}

  .navbar-nav {
    align-items: center;
  }
`;

const SearchIcon = styled(Search)`
  margin-right: 16px;
  cursor: pointer;
`;
