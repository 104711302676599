import { MenuStylesParams, SelectStylesNames } from '@mantine/core';

import { ComponentStyleOverrideFn } from './common-types';

export const selectStyle: ComponentStyleOverrideFn<
  SelectStylesNames,
  MenuStylesParams
> = (theme) => ({
  root: {
    width: '100%',
  },
  hovered: {
    backgroundColor: theme.colors.gray[1],
  },
  label: {
    marginBottom: theme.spacing.xs,
    color: theme.colors.gray[7],
    fontWeight: 400,
  },
  input: {
    padding: `0px ${theme.spacing.md}px`,
    borderColor: theme.fn.rgba(theme.colors.gray[4], 0.75),

    '&::placeholder': {
      color: theme.colors.gray[4],
      fontWeight: 400,
      fontSize: theme.fontSizes.sm,
    },
  },
  dropdown: {
    padding: 4,
    transform: 'translateY(5px)',
    borderRadius: theme.radius.sm,
  },
  item: {
    width: '100%',
    minHeight: 33,
    display: 'flex',
    alignItems: 'center',
    padding: `0px ${theme.spacing.md}px`,
    fontWeight: 400,
    fontSize: theme.fontSizes.sm,
  },
  selected: {
    color: theme.colors.blue_accent[4],
    background: 'none',

    '&:hover': {
      backgroundColor: theme.colors.gray[1],
    },
  },
});
