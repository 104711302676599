import { BreadcrumbsStylesNames } from '@mantine/core';

import { ComponentStyleOverrideFn } from './common-types';

export const breadcrumbsStyle: ComponentStyleOverrideFn<
  BreadcrumbsStylesNames,
  any
> = (theme) => {
  return {
    root: {
      alignItems: 'center',

      a: {
        color: theme.colors.blue_gray[5],
      },
    },
    breadcrumb: {
      fontSize: theme.fontSizes.sm,
      fontWeight: 500,
    },
  };
};
