import React, { FC } from 'react';

import Authenticated from '@portals/framework/authenticated';
import { useAppConfig } from '@portals/framework/context';
import { WithData } from '@portals/ui';

import Onboarded from '../components/Onboarded';

const Onboarding: FC<any> = ({ children }) => {
  const { preloadData, extraLayout } = useAppConfig();
  const { dashboardDataLayout: DataLayout } = extraLayout;

  const content = ({ isFetched }) => (
    <WithData types={preloadData} isFetched={isFetched}>
      <Onboarded>{children}</Onboarded>
    </WithData>
  );

  return (
    <Authenticated>
      {DataLayout ? (
        <DataLayout>{({ isFetched }) => content({ isFetched })}</DataLayout>
      ) : (
        content({ isFetched: true })
      )}
    </Authenticated>
  );
};

export default Onboarding;
