import React, { FC } from 'react';
import { Helmet, HelmetProps } from 'react-helmet';

import { useCSP } from '../hooks/csp';

type MetaProps = {
  title: HelmetProps['title'];
  links?: HelmetProps['link'];
  meta?: HelmetProps['meta'];
};

const Meta: FC<MetaProps> = ({ title, links, meta = [] }) => {
  const cspContent = useCSP();

  return (
    <Helmet
      defer={false}
      title={title}
      script={[
        {
          type: 'module',
          async: false,
          src: 'https://embed.launchnotes.io/latest/dist/esm/launchnotes-embed.js',
        },
      ]}
    >
      <meta
        key="CSP"
        httpEquiv="Content-Security-Policy"
        content={cspContent}
      />

      {meta.map((props, index) => (
        <meta {...props} key={index} />
      ))}

      {links ? (
        links.map((props, index) => <link key={index} {...props} />)
      ) : (
        <link href="favicon.ico" rel="icon" sizes="192x192" />
      )}
    </Helmet>
  );
};

export default Meta;
