import { join } from 'lodash/fp';
import { useMemo } from 'react';

import { WS_URL } from '@portals/utils';

const IS_DEV = process.env.NX_ENV !== 'production';

// While in dev, server's running on diff ports - 'self' doesn't include it
const DEFAULT_SRC = IS_DEV
  ? `'self' localhost:* *.xyte.io *.syncpro.io *.amazonaws.com *.hubspot.com`
  : `'self' *.xyte.io *.syncpro.io *.amazonaws.com *.hubspot.com`;

// Native
const BLOB_SRC = 'blob:';
const DATA_SRC = 'data:';
const WS_SRC = WS_URL;
const UNSAFE_INLINE_SRC = `'unsafe-inline'`;
const ALL_SRC = `*`;

// 3-rd party
const GOOGLE_SRC = '*.googleapis.com *.gstatic.com *.google.com';
const AMAZON_SRC = '*.amazonaws.com';
const CHARGIFY_SRC = '*.chargify.com';
const SENTRY_SRC = '*.sentry.io';
const AMPLITUDE_SRC = '*.amplitude.com';
const LAUNCH_NOTES_SRC = '*.launchnotes.io *.launchnotes.com';
const STRIPE_SRC = '*.stripe.com';

/*
 * Dictionary:
 * [directive]: 'default-src', 'img-src', 'font-src', ...
 * [source]: 'self', *.url.com, blob:, ...
 *
 * Not all existing directives are assigned here. Whichever directives are un-assigned, are using
 * the 'default-src' directive source.
 * */
const CSP_DIRECTIVES = [
  {
    // Default directive, used for directives that were not assigned here
    directive: 'default-src',
    sources: [DEFAULT_SRC],
  },
  {
    // Specifies which sources are allowed to load images. Using "ALL_SRC" bc some of the used
    // images are integration-service based. For example: Slack's logo, Salesforce's logo
    directive: 'img-src',
    sources: [DEFAULT_SRC, ALL_SRC, DATA_SRC],
  },
  {
    // Specifies which sources are allowed to load css.
    // - Google is loading its various CSS-sheets for its Maps & Places elements.
    // - 'unsafe-inline' is telling that it's safe to load inline <style>-based css in header
    directive: 'style-src',
    sources: [DEFAULT_SRC, UNSAFE_INLINE_SRC],
  },
  {
    // Specifies which sources are allowed to load fonts.
    // - Google is loading main app's font: Nunito
    directive: 'font-src',
    sources: [DEFAULT_SRC, GOOGLE_SRC],
  },
  {
    // Specifies which sources are allowed to load scripts.
    // - Amazon is used for uploading files to S3, required this source to work properly
    // - Google is used for displaying maps
    directive: 'script-src',
    sources: [DEFAULT_SRC, AMAZON_SRC, GOOGLE_SRC],
  },
  {
    // Specifies which URLs can be loaded using script interfaces (<a>, fetch(), WebSocket...)
    // - Amazon is used for accessing S3 files
    // - Sentry is used for errors reporting
    directive: 'connect-src',
    sources: [
      DEFAULT_SRC,
      WS_SRC,
      AMAZON_SRC,
      SENTRY_SRC,
      GOOGLE_SRC,
      AMPLITUDE_SRC,
      LAUNCH_NOTES_SRC,
    ],
  },
  {
    // Specifies valid sources for JavaScript <script> elements, but
    // not inline script event handlers like onclick
    directive: 'script-src-elem',
    sources: [
      DEFAULT_SRC,
      GOOGLE_SRC,
      LAUNCH_NOTES_SRC,
      STRIPE_SRC,
    ],
  },
  {
    // Specifies valid sources for stylesheets <style> elements and <link> elements with
    // rel="stylesheet".
    directive: 'style-src-elem',
    sources: [DEFAULT_SRC, UNSAFE_INLINE_SRC, GOOGLE_SRC],
  },
  {
    // Specifies valid sources for nested browsing contexts loading using elements such as
    // <frame> and <iframe>
    // - Chargify opens an iframe for payment information
    directive: 'frame-src',
    sources: [DEFAULT_SRC, CHARGIFY_SRC, GOOGLE_SRC, STRIPE_SRC],
  },
  {
    // Specifies valid sources for Worker, SharedWorker, or ServiceWorker scripts.
    directive: 'worker-src',
    sources: [DEFAULT_SRC, BLOB_SRC],
  },
];

const getCSP = () =>
  CSP_DIRECTIVES.reduce((acc, curr) => {
    const { directive, sources } = curr;

    return `${acc}\n${directive} ${join(' ', sources)};`;
  }, '');

export const useCSP = () => useMemo(() => getCSP(), []);
