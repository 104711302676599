import { getOr, isUndefined } from 'lodash/fp';

import { PartnerConfigType } from '@portals/types';

export const portalName = (config: PartnerConfigType) =>
  isUndefined(process.env.NX_BRAND_NAME)
    ? getOr('', 'name', config)
    : process.env.NX_BRAND_NAME;

export const portalWindowTitle = (config: PartnerConfigType) =>
  config?.window_title || `${portalName(config)} - Simply Connected`;

export const portalFavicon = (
  config: PartnerConfigType,
  defaultPath: string = 'favicon.ico'
) => config?.favicon || defaultPath;
