import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { switchTenant } from '@portals/redux/actions/auth';
import { setRoute } from '@portals/redux/actions/routing';

const AutoTenantSwitcher = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const id = useMemo(
    () => new URLSearchParams(location.search).get('org'),
    [location.search]
  );

  useEffect(() => {
    if (!id) return;

    dispatch(setRoute(location.pathname, true));
    dispatch(switchTenant({ id }, 'organization'));
  }, [dispatch, id, location.pathname]);

  return null;
};

export default AutoTenantSwitcher;
