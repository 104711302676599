import * as Sentry from '@sentry/react';
import { flatten } from 'lodash/fp';
import { normalize as normalizeLib } from 'normalizr';

import { errorsToString } from '@portals/utils';

import { getHeaders, signedOut } from '../actions/auth';
import { clearError, setError } from '../actions/errors';
import { toastrError, toastrSuccess } from '../actions/toastr';
import { startNetwork, endNetwork } from '../actions/ui';
import { API } from '../constants';

const BASE_URL = process.env.NX_SERVER_URL;

const buildUrl = (uri) => BASE_URL + uri;

const handleArrayOfSuccess = (array, dispatch, data) => {
  flatten(array)
    .filter((item) => item)
    .forEach((item) => {
      const action = typeof item === 'function' ? item(data) : item;

      dispatch(action);
    });
};

const api =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (action.type !== API) {
      return next(action);
    }

    const {
      url, // URL to send to
      success, // Action(s) to perform on success
      name, // Name for internal tracking (spinners)
      preProcess, // Run func on received data before normalization
      postProcess, // Run func on received data after normalization
      normalize, // Schema for normalization
      error, // Action to perform on error
      data, // Data for post/patch/etc
      method, // Method (GET/POST/etc)
      headers, // Additional headers to pass
      withError, // Should the resulting error be saved in state,
      toastr, // Automatically display toastr on success and error
    } = action.payload;

    const state = getState();

    const handleSuccess = (data) => {
      try {
        if (preProcess) {
          data = preProcess(data);
        }

        if (normalize) {
          data = normalizeLib(data, normalize);
        }

        if (postProcess) {
          data = postProcess(data);
        }

        if (toastr) {
          dispatch(toastrSuccess(`${toastr} successful`));
        }

        if (success) {
          handleArrayOfSuccess(
            Array.isArray(success) ? success : [success],
            dispatch,
            data
          );
        }
      } catch (err) {
        console.warn('Error during network success processing', err);
      }

      dispatch(endNetwork(name));
    };

    const dispatchErrorHandler = (data) => {
      if (withError && name) {
        dispatch(setError(name, data));
      }

      if (error) {
        if (typeof error === 'function') {
          dispatch(error(data));
        } else {
          dispatch(error);
        }
      }

      if (toastr || withError) {
        const title = toastr ? `${toastr} error` : `Error (${name || 'API'})`;
        let msg = data;

        msg = data.errors ? errorsToString(data.errors) : msg;
        msg = data.error ? data.error : msg;

        dispatch(toastrError(title, msg.toString()));
      }

      dispatch(endNetwork(name));
    };

    const handleError = (err) => {
      let msg;

      // Notify Sentry
      Sentry.withScope((scope) => {
        const { status, redirected, statusText, url } = err;
        scope.setExtra('action', action);
        scope.setExtra('error', { status, redirected, statusText, url });
        scope.setExtra('url', url);
        Sentry.captureMessage('Network error');
      });

      // Did we get a Response object?
      if (err instanceof Response) {
        msg = err.statusText;
      } else {
        msg = err;
      }

      // On auth errors, we signout user assuming something naughty is happening
      if (err.status === 401) {
        console.error('Authentication error, signing out');
        dispatch(signedOut());
      } else {
        if (typeof err === 'object' && err.message === 'Failed to fetch') {
          console.error('Network communication error: ', err);
          console.error('Action', action);

          dispatch(toastrError('Network error', 'Cannot access server'));
        }
        console.warn('Error from API: ', msg);
      }

      if (error || withError || toastr) {
        if (err instanceof Response) {
          err
            .json()
            .then(dispatchErrorHandler)
            .catch(() => dispatchErrorHandler(err.statusText));
        } else {
          dispatchErrorHandler(msg);
        }
      } else {
        dispatch(endNetwork(name));
      }
    };

    // Set default headers and options
    const options: Omit<RequestInit, 'headers'> & { headers: Headers } = {
      headers: new Headers({ 'content-type': 'application/json' }),
      cache: 'no-store',
    };

    if (headers) {
      Object.keys(headers).forEach((header) =>
        options.headers.append(header, headers[header])
      );
    }

    if (method) {
      options.method = method;
    }

    // Turn into POST (or other) request if data was passed
    if (data) {
      options.method = options.method || 'post';
      options.body = JSON.stringify(data);
    }

    // Add access token if user signed in
    if (state.ui.auth) {
      const headers = getHeaders(state.ui.auth);

      Object.keys(headers).forEach((header) =>
        options.headers.append(header, headers[header])
      );
    }

    // Make sure to clear error register
    if (withError && name) {
      dispatch(clearError(name));
    }

    dispatch(startNetwork(name));

    fetch(buildUrl(url), options)
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then(handleSuccess)
      .catch(handleError);
  };

export default api;
