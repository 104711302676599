import { Box, Menu, MenuProps } from '@mantine/core';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { Row, UseExpandedRowProps, UseRowSelectRowProps } from 'react-table';

import { RowMenuContentRendererType, RowMenuRefType } from '@portals/types';

import RowMenuToggle from './RowMenuToggle';

interface RowMenuProps<TData extends object> {
  isHovered: boolean;
  children: RowMenuContentRendererType<TData>;
  menuProps?: Omit<MenuProps, 'children'>;
  row: Row<TData> & UseExpandedRowProps<TData> & UseRowSelectRowProps<TData>;
}

function RowMenu<TData extends object>({
  isHovered,
  menuProps = {},
  children,
  row,
}: RowMenuProps<TData>) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const rowMenuRef = useRef<RowMenuRefType>();

  useImperativeHandle(
    rowMenuRef,
    () => ({
      onClose: () => setIsMenuOpen(false),
      onOpen: () => setIsMenuOpen(true),
    }),
    []
  );

  if (!isHovered && !isMenuOpen) return null;

  return (
    <Box
      sx={{
        position: 'sticky',
        right: 0,
        width: 0,
      }}
    >
      <Menu
        control={
          <div>
            <RowMenuToggle isOpen={isMenuOpen} />
          </div>
        }
        onOpen={() => setIsMenuOpen(true)}
        onClose={() => setIsMenuOpen(false)}
        closeOnItemClick={false}
        styles={{
          body: {
            overflow: 'visible !important',
            padding: 5,
          },
        }}
        {...menuProps}
      >
        {children({ menuRef: rowMenuRef?.current, row }) || (
          <Menu.Item
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
            disabled
          >
            No available actions
          </Menu.Item>
        )}
      </Menu>
    </Box>
  );
}

export default RowMenu;
