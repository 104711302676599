import React, { useContext, useState } from 'react';

import { VerticalScrollBar, ScrollStateType } from '@portals/ui';

const ScrollStateContext = React.createContext<ScrollStateType>({
  top: 0,
  left: 0,
  clientWidth: 0,
  clientHeight: 0,
  scrollWidth: 0,
  scrollHeight: 0,
  scrollLeft: 0,
  scrollTop: 0,
});

const ScrollStateProvider = ({ children }) => {
  const [scrollState, setScrollState] = useState<ScrollStateType>({
    top: 0,
    left: 0,
    clientWidth: 0,
    clientHeight: 0,
    scrollWidth: 0,
    scrollHeight: 0,
    scrollLeft: 0,
    scrollTop: 0,
  });

  return (
    <VerticalScrollBar onScrollFrame={setScrollState}>
      <ScrollStateContext.Provider value={scrollState}>
        {children}
      </ScrollStateContext.Provider>
    </VerticalScrollBar>
  );
};

const useScrollState = () => {
  const context = useContext(ScrollStateContext);
  if (context === undefined) {
    throw new Error('useScrollContext must be used within a ScrollContext');
  }

  return context;
};

export { ScrollStateProvider, useScrollState, ScrollStateContext };
