import { Box } from '@mantine/core';
import React from 'react';
import ChevronDown from 'react-feather/dist/icons/chevron-down';

const RowExpandToggle = ({ expanded }) => (
  <Box
    style={{ width: '40px' }}
    sx={{
      svg: {
        transition: 'transform 0.15s ease-in-out',
        transformOrigin: 'center',
        ...(expanded
          ? {
              transform: 'rotate(180deg)',
            }
          : {}),
      },
    }}
  >
    <ChevronDown size={15} />
  </Box>
);

export default RowExpandToggle;
