import { Avatar, Badge, Box, createStyles, Text } from '@mantine/core';
import React, { FC } from 'react';

interface UserAvatar {
  isActive?: boolean;
  logoSrc: string;
}

export const UserAvatar: FC<UserAvatar> = ({ isActive = false, logoSrc }) => (
  <Avatar
    className="tenant-menu-avatar"
    radius="md"
    p={3}
    styles={(theme) => ({
      root: {
        width: 44,
        height: 44,
        background: 'transparent',
        transition: 'border 0.1s ease-in-out',
        border: `2px solid ${
          isActive ? theme.colors.blue_accent[4] : 'transparent'
        }`,
      },
      image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      },
    })}
    src={logoSrc || 'logo_full_dark.svg'}
  />
);

interface UserName {
  name: string;
  isLab?: boolean;
  partner?: string;
}

export const UserName: FC<UserName> = ({ name, isLab = false, partner }) => {
  const { cx, classes } = useNameStyles({ isLab, partner });

  return (
    <Box className={cx('tenant-user-menu', classes.container)}>
      <Text
        size="md"
        weight={700}
        title={name}
        sx={(theme) => ({
          color: theme.colors.blue_gray[8],
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        })}
      >
        {name}
      </Text>

      {isLab ? (
        <Badge
          size="xs"
          radius="sm"
          variant="filled"
          sx={{
            flexShrink: 0,
          }}
        >
          LAB
        </Badge>
      ) : null}
    </Box>
  );
};

export const useNameStyles = createStyles(
  (theme, { partner, isLab }: { partner?: string; isLab?: boolean }) => ({
    container: {
      whiteSpace: 'nowrap',
      transition: 'color 0.1s ease-in-out',
      position: 'relative',
      display: 'grid',
      gridTemplateColumns: isLab ? 'auto max-content' : '1fr',
      gridColumnGap: theme.spacing.xs,
      justifyContent: 'flex-start',
      alignItems: 'center',

      ...(partner
        ? {
            transform: 'translateY(-9px)',

            ':after': {
              content: `"by ${partner}"`,
              position: 'absolute',
              left: 0,
              width: '100%',
              transform: 'translateY(100%)',
              fontSize: theme.fontSizes.xs,
              color: theme.colors.blue_gray[4],
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }
        : {}),
    },
  })
);
