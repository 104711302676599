import {
  Box,
  createStyles,
  Divider,
  Paper,
  Stack,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { get, getOr, size, values } from 'lodash/fp';
import React, { forwardRef, useMemo, useState } from 'react';

import { useUserIsAdmin } from '@portals/entities';
import { usePortalConfig } from '@portals/framework';
import { CloudChange, CloudPlus, Flag } from '@portals/icons/linear';
import {
  useAuth,
  useConfig,
  useFeatures,
  useOpenModal,
  useOrganizationConfig,
  useUsers,
} from '@portals/redux';
import { ComponentRendererType, TenantType } from '@portals/types';

import { UserAvatar, UserName } from './common';
import { TenantSwitch } from './TenantSwitch';

interface TenantMenuContentProps {
  logoSrc: string;
  label: string;
  onClose: () => void;
}

const TenantMenuContent = forwardRef<HTMLDivElement, TenantMenuContentProps>(
  ({ logoSrc, label, onClose }, ref) => {
    const { classes } = useStyles();
    const [isTenantSwitchActive, setIsTenantSwitchActive] = useState(false);

    const users = useUsers();
    const { id, tenant } = useAuth();
    const isAdmin = useUserIsAdmin(id);
    const openModal = useOpenModal();

    const membersCount = useMemo(
      () => values(users).filter((user) => !user.is_group && user.local).length,
      [users]
    );
    const controlledTenants = size(
      getOr([], [id, 'controlled', tenant.type], users)
    );

    const items = useMemo(() => {
      const itemsList: Array<{
        Icon: ComponentRendererType;
        label: string;
        onClick: () => void;
        shouldClose?: boolean;
      }> = [];

      if (isAdmin) {
        itemsList.push({
          Icon: Flag,
          label: 'Feature Flags',
          onClick: () => openModal('FeatureFlags'),
        });
      }

      if (users && controlledTenants > 1) {
        itemsList.push({
          Icon: CloudChange,
          label: 'Switch Tenant',
          onClick: () => setIsTenantSwitchActive(true),
          shouldClose: false,
        });
      }

      if (isAdmin && tenant.type === TenantType.Partner) {
        itemsList.push({
          Icon: CloudPlus,
          label: 'Create New Tenant',
          onClick: () => openModal('CreatePartner'),
        });
      }

      return itemsList;
    }, [controlledTenants, isAdmin, openModal, tenant.type, users]);

    if (isTenantSwitchActive)
      return (
        <Paper
          ref={ref}
          sx={{
            width: '100%',
          }}
        >
          <TenantSwitch
            toggleTenantSwitch={() => setIsTenantSwitchActive(false)}
            toggleTenantMenu={onClose}
          />
        </Paper>
      );

    return (
      <Paper
        ref={ref}
        py="md"
        px="xs"
        sx={{
          width: '100%',
        }}
      >
        <Box mb="md" className={classes.header}>
          <UserAvatar logoSrc={logoSrc} isActive />

          <Stack spacing={0}>
            <UserName name={label} />
            <Text
              size="xs"
              weight={400}
              sx={(theme) => ({ color: theme.colors.blue_gray[3] })}
            >
              {`${membersCount} ${membersCount > 1 ? 'Members' : 'Member'}`}
            </Text>
          </Stack>
        </Box>

        <Divider />

        <Stack mt="md" spacing="xs" px={0}>
          {items.map(({ Icon, label, onClick, shouldClose = true }) => (
            <UnstyledButton
              key={label}
              onClick={() => {
                onClick();

                if (shouldClose) {
                  onClose();
                }
              }}
              className={classes.menuItemWrapper}
            >
              <Box className={classes.menuItem}>
                <Icon />

                <Text size="sm">{label}</Text>
              </Box>
            </UnstyledButton>
          ))}
        </Stack>
      </Paper>
    );
  }
);

const useStyles = createStyles((theme) => ({
  header: {
    display: 'grid',
    gridTemplateColumns: '44px 1fr',
    gridColumnGap: theme.spacing.md,
    alignItems: 'center',
    height: 44,
  },
  menuItemWrapper: {
    height: 44,
    padding: `0 ${theme.spacing.md}px`,
    transition: 'background 0.1s ease-in-out',

    '&:hover': {
      backgroundColor: theme.colors.gray[0],
    },
  },
  menuItem: {
    display: 'grid',
    gridTemplateColumns: '24px 1fr',
    gridColumnGap: theme.spacing.md,
    color: theme.colors.blue_gray[6],
    alignItems: 'center',
  },
}));

export const OrganizationMenuContent = forwardRef<
  HTMLDivElement,
  { onClose: () => void }
>(({ onClose }, ref) => {
  const features = useFeatures();
  const organization = useOrganizationConfig();

  return (
    <TenantMenuContent
      ref={ref}
      onClose={onClose}
      label={organization.name}
      logoSrc={features.logo}
    />
  );
});

export const PartnerMenuContent = forwardRef<
  HTMLDivElement,
  { onClose: () => void }
>(({ onClose }, ref) => {
  const { data } = usePortalConfig();
  const config = useConfig();

  return (
    <TenantMenuContent
      ref={ref}
      onClose={onClose}
      label={get(['partner', 'display_name'], config)}
      logoSrc={data.logo}
    />
  );
});
