import { Checkbox } from '@mantine/core';
import React, { FC, MutableRefObject } from 'react';
import { TableToggleCommonProps } from 'react-table';

const ColumnCheckbox: FC<TableToggleCommonProps & { id: string }> =
  React.forwardRef(
    (
      { indeterminate, id, ...rest },
      ref: MutableRefObject<TableToggleCommonProps>
    ) => {
      const defaultRef = React.useRef(null);
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        if (!resolvedRef.current) resolvedRef.current = {};

        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <Checkbox
          id={id}
          ref={resolvedRef}
          indeterminate={indeterminate}
          {...rest}
        />
      );
    }
  );

export default ColumnCheckbox;
