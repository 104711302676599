import classNames from 'classnames';
import React, { FC } from 'react';
import ShoppingCart from 'react-feather/dist/icons/shopping-cart';
import { Card, CardBody, Media } from 'reactstrap';

import { ProductType } from '@portals/types';

type ProductCardProps = ProductType;

const ProductCard: FC<ProductCardProps> = ({
  owner,
  title,
  img,
  disabled,
  comingSoon,
}) => {
  const cardClass = classNames('flex-fill ribbon-parent', {
    selectable: !disabled,
    'cursor-not-allowed': disabled,
  });

  return (
    <Card className={cardClass}>
      {comingSoon && <div className="ribbon">Coming soon</div>}

      <CardBody className="py-3">
        <Media>
          <div className="d-inline-block mt-2 mr-3 mb-2">
            {img ? (
              <img
                src={img}
                width="64"
                height="64"
                className="rounded-circle"
                alt={title}
              />
            ) : (
              <ShoppingCart className="feather-lg text-primary" />
            )}
          </div>
          <Media body>
            <h3 className="mb-2 mt-3">{title}</h3>
            <div className="mb-0">
              By
              {owner}
            </div>
          </Media>
        </Media>
      </CardBody>
    </Card>
  );
};

export default ProductCard;
