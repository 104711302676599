export const INCIDENT_TYPE = [
  { id: 1, title: 'Critical', color: 'red' },
  { id: 2, title: 'High', color: 'orange' },
  { id: 3, title: 'Moderate', color: 'blue' },
  { id: 4, title: 'Low', color: 'indigo' },
  { id: 5, title: 'Planning', color: 'cyan' },
];

export const PRIORITY_OPTIONS = {
  1: 'Critical',
  2: 'High',
  3: 'Moderate',
  4: 'Low',
  5: 'Planning',
};

export const PRIORITY_COLORS = {
  1: 'red',
  2: 'orange',
  3: 'blue',
  4: 'indigo',
  5: 'cyan',
};

export const LICENSE_STATES = {
  available: 'Available',
  inuse: 'Inuse',
  used: 'Used',
  remove: 'Removing',
  error: 'Error',
  pending: 'Pending',
  removed: 'Removed',
};

export const LICENSE_TYPES = {
  1: 'Single Device',
  0: 'Open',
};

export const COLORS = {
  primary: '#47bac1',
  secondary: '#a180da',
  tertiary: '#5fc27e',
  success: '#5fc27e',
  info: '#5b7dff',
  warning: '#fcc100',
  danger: '#f44455',
};

export const LICENSE_STATE_COLORS = {
  unassigned: 'blue',
  assigned: 'blue_accent',
  unclaimed: 'blue_accent',
  pending: 'blue_accent',
  inuse: 'green',
  used: 'orange',
  remove: 'blue_accent',
  error: 'red',
};

export const COMMAND_STATUS_COLORS = {
  pending: 'indigo',
  done: 'green',
  failed: 'red',
  aborted: 'orange',
  in_progress: 'blue',
};

export const SUBSCRIPTION_STATE_COLORS = {
  active: 'blue',
  suspended: 'orange',
  canceled: 'red',
};

export const DEFAULT_MAP_CENTER = { lng: 2.154007, lat: 41.390205 };

export const CHART_COLORS = [
  '#47bac1',
  '#7bc9bb',
  '#a7d6bc',
  '#cde3c6',
  '#edf0d9',
  '#e5d29f',
  '#e9ad6f',
  '#f18054',
  '#f44455',
  '#fa7778',
];

export const ACCESS_LEVEL = { 1: 'View', 2: 'Edit', 3: 'Admin' };
export const LEVEL_TO_ACCESS = { None: 0, View: 1, Edit: 2, Admin: 3 };
export enum AccessLevelEnum {
  None,
  View,
  Edit,
  Admin,
  Disabled, // Can be used to disable features for everyone
}

export const PAYMENT_INTERVALS = {
  monthly: 'Monthly',
  yearly: 'Yearly',
};

export const PAYMENT_INTERVAL_TO_STRING = {
  monthly: 'month',
  yearly: 'year',
};

// System Issues
export const MONITORING_LICENSES_LOW = 1;
export const NO_DEVICE_MONITORING_LICENSES = 2;

// Websocket
export const WS_URL = process.env.NX_SERVER_URL
  ? process.env.NX_SERVER_URL.replace(/http|https/, 'ws') + 'cable'
  : '';
