import { Modal } from '@mantine/core';
import React from 'react';

import AutoFormik, { FieldType, FieldTypeEnum } from '@portals/ui/AutoFormik';

const FIELDS: Array<FieldType> = [
  { name: 'note', title: 'Note', type: FieldTypeEnum.Textarea },
];

const UpdateInvoiceNote = ({ data, inProgress, closeMe, serverError }) => {
  const { invoiceId, note, onSubmit } = data;

  return (
    <Modal opened={true} onClose={closeMe} title="Change note">
      <AutoFormik
        initialValues={{ note }}
        fields={FIELDS}
        submitTitle="Update note"
        serverError={serverError}
        inProgress={inProgress}
        handleSubmit={async (data) => {
          await onSubmit({ note: data.note, invoiceId });
          closeMe();
        }}
        modal={closeMe}
      />
    </Modal>
  );
};

export default UpdateInvoiceNote;
