import { compact, values } from 'lodash/fp';
import React, { useMemo } from 'react';
import { CSVLink } from 'react-csv';
import { ColumnInstance } from 'react-table';

import { TableInstanceType } from '@portals/types/Table';

import { ReactComponent as CsvIcon } from '../../../../assets/csv.svg';

interface ExportProps<TData extends object> {
  instance: TableInstanceType<TData>;
  name: string;
}

function Export<TData extends object>({ instance, name }: ExportProps<TData>) {
  const { flatHeaders, rows } = instance;

  const exportData = useMemo(() => {
    const headers = compact(
      flatHeaders.map(
        ({
          Header,
          canExport = true,
        }: ColumnInstance<any> & { canExport: boolean }) =>
          !canExport ? null : Header
      )
    );
    const dataRows = rows.map(({ values: rowValues }) => values(rowValues));

    return [headers, ...dataRows];
  }, [flatHeaders, rows]);

  return (
    <CSVLink filename={`${name || 'xyte'}.csv`} data={exportData}>
      <CsvIcon />
    </CSVLink>
  );
}

export default Export;
