import moment from 'moment';
import React, { FC } from 'react';
import styled from 'styled-components';

import { ChatMessageType } from '@portals/types';
import { getStyledThemeColor } from '@portals/utils';

const chatTimeParser = (time: string) => {
  const msgTime = new Date(time);
  const now = new Date();
  const prettyTime = moment(msgTime).format('hh:mm');
  const prettyDate = moment(msgTime).format('MM/DD/YYYY');

  if (
    now.getMonth() === msgTime.getMonth() &&
    now.getDate() === msgTime.getDate()
  ) {
    return `Today at ${prettyTime}`;
  } else if (
    now.getMonth() === msgTime.getMonth() &&
    now.getDate() === msgTime.getDate() + 1
  ) {
    return `Yesterday at ${prettyTime}`;
  } else {
    return `${prettyDate} ${prettyTime}`;
  }
};

interface MessageProps {
  message: ChatMessageType;
  chatOwner: string;
}

const Message: FC<MessageProps> = ({ message, chatOwner }) => {
  const { owner, user, text, created_at, owner_type } = message;

  return (
    <Wrapper isOwner={owner_type === chatOwner} className="message-wrapper">
      <div className="message-container">
        <div className="user">{`${user} (${owner})`}</div>

        <div className="message">{text}</div>

        <div className="timestamp-container">{chatTimeParser(created_at)}</div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ isOwner: boolean }>`
  display: grid;
  grid-auto-flow: row;
  width: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, max-content);

  .message-container {
    padding: 15px 25px;
    justify-self: ${({ isOwner }) => (isOwner ? 'end' : 'start')};
    color: ${({ isOwner, theme }) =>
      isOwner ? theme.color.white : theme.color.dark};
    background-color: ${({ isOwner, theme }) =>
      isOwner ? '#19806B' : theme.color.gray150};
    width: 60%;
    max-width: 450px;
    border-radius: 8px;
    position: relative;

    .user {
      font-weight: bold;
      margin-bottom: 10px;
    }

    .message {
      word-break: break-word;
    }

    .timestamp-container {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      transform: translateY(100%);
      color: ${getStyledThemeColor('gray500')} !important;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 10px 0;
    }
  }
`;

export default Message;
