// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = 'SIDEBAR_VISIBILITY_TOGGLE';

// Auth
export const SIGNED_IN = 'SIGNED_IN';
export const SIGNED_OUT = 'SIGNED_OUT';
export const SIGN_IN_FAILED = 'SIGN_IN_FAILED';
export const RESET_AUTH_ERROR = 'RESET_AUTH_ERROR';
export const SWITCH_TENANT = 'SWITCH_TENANT';
export const UPDATE_AUTH_TENANT_NAME = 'UPDATE_AUTH_TENANT_NAME';

// API
export const API = 'API';
export const DEVICE_API = 'DEVICE_API';

// UI
export const START_NETWORK = 'START_NETWORK';
export const END_NETWORK = 'END_NETWORK';
export const SET_PARTNER_CONFIG = 'SET_PARTNER_CONFIG';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

// Data
export const UPDATE_DATA = 'UPDATE_DATA';
export const DELETE_DATA = 'DELETE_DATA';
export const REPLACE_DATA = 'REPLACE_DATA';
export const UPDATE_NESTED = 'UPDATE_NESTED';
export const DELETE_ALL_DATA = 'DELETE_ALL_DATA';
export const SET_VALUE = 'SET_VALUE';
export const DELETE_DATA_BY_ACCESSOR = 'DELETE_DATA_BY_ACCESSOR';

// Modal
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

// Routing
export const SET_ROUTE = 'SET_ROUTE';
export const RELOAD_PAGE = 'RELOAD_PAGE';

// Errors
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';

// Store
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART';
export const SET_PRODUCT_QUANTITY = 'SET_PRODUCT_QUANTITY';
export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_BUYER_INFO_ID = 'SET_BUYER_INFO_ID';
export const CLEAR_CART = 'CLEAR_CART';
export const CHECK_SELECTED_CURRENCY = 'CHECK_SELECTED_CURRENCY';
export const CHECK_CART_ITEMS = 'CHECK_CART_ITEMS';
export const CHECK_SELECTED_ADDRESS = 'CHECK_SELECTED_ADDRESS';
export const CHECK_SELECTED_CREDIT_CARD = 'CHECK_SELECTED_CREDIT_CARD';
export const SET_CREDIT_CARD_ID = 'SET_CREDIT_CARD_ID';
export const SET_BILLING_ADDRESS_ID = 'SET_BILLING_ADDRESS_ID';
export const SET_SHIPPING_ADDRESS_ID = 'SET_SHIPPING_ADDRESS_ID';
export const SET_IS_SAME_ADDRESS = 'SET_IS_SAME_ADDRESS';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';

// Analytics
export const LOG_ANALYTICS_EVENT = 'LOG_ANALYTICS_EVENT';
