import { useMantineTheme } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useIsTabInFocus } from '@portals/framework';
import { Refresh2 } from '@portals/icons/linear';

import Message from './Message';

const CURRENT_VERSION =
  process.env.NX_GIT_LATEST_COMMIT_SHA || 'No version set';
const FOCUSED_CHECK_FREQUENCY = 60; // 1 minute
const NOTIFICATION_ID = 'version Monitor notification';

const checkDeployedVersion = (setLatestVersion) => {
  const handleError = (error) =>
    console.error(
      'Could not get the version of currently deployed code:',
      error.statusText
    );

  fetch(`${process.env.NX_BASE_URL ?? ''}/version.txt`)
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.text();
    })
    .then((data) => setLatestVersion(data.trim()))
    .catch(handleError);
};

const VersionMonitor =
  process.env.NX_ENV !== 'production'
    ? () => null
    : () => {
        const windowFocused = useIsTabInFocus();
        const [latestVersion, setLatestVersion] = useState(CURRENT_VERSION);
        const needsUpdate = CURRENT_VERSION !== latestVersion;
        const intervalHandle = useRef(null);
        const checkDeployedVersionWrapped = useCallback(
          () => !needsUpdate && checkDeployedVersion(setLatestVersion),
          [setLatestVersion, needsUpdate]
        );
        const { colors } = useMantineTheme();

        // Don't check version too often when focus is returned to the window
        useEffect(() => {
          if (!needsUpdate && windowFocused) {
            checkDeployedVersionWrapped();
          }
        }, [windowFocused, needsUpdate, checkDeployedVersionWrapped]);

        const stopPeriodicCheck = useCallback(() => {
          if (intervalHandle.current) {
            clearInterval(intervalHandle.current);
            intervalHandle.current = null;
          }
        }, [intervalHandle]);

        const startPeriodicCheck = useCallback(() => {
          if (!intervalHandle.current) {
            intervalHandle.current = setInterval(
              checkDeployedVersionWrapped,
              FOCUSED_CHECK_FREQUENCY * 1000
            );
          }
        }, [checkDeployedVersionWrapped, intervalHandle]);

        useEffect(() => {
          // If already needs update or window is not focused, cancel the interval
          if (needsUpdate || !windowFocused) {
            stopPeriodicCheck();
          }

          // If no need to update and window is focused, start periodically checking version
          if (!needsUpdate && windowFocused) {
            startPeriodicCheck();
          }
        }, [stopPeriodicCheck, startPeriodicCheck, needsUpdate, windowFocused]);

        useEffect(() => {
          if (needsUpdate) {
            showNotification({
              id: NOTIFICATION_ID,
              title: 'New Version Available',
              message: <Message />,
              icon: <Refresh2 style={{ color: colors.blue_accent[7] }} />,
              autoClose: false,
              disallowClose: true,
              styles: (theme) => ({
                icon: {
                  position: 'absolute',
                },
                title: {
                  paddingLeft: 45,
                },
                body: {
                  marginRight: 0,
                  '.notification-content': {
                    paddingLeft: 45,
                    marginBottom: theme.spacing.xl,
                  },
                },
              }),
            });

            console.warn(
              'A new version has been released, please click here to reload'
            );
            console.log(
              `Current version: ${CURRENT_VERSION}, latest version: ${latestVersion}`
            );
          }
        }, [needsUpdate, latestVersion, colors]);

        return null;
      };

export default VersionMonitor;
