import { FormikErrors } from 'formik';
import React, { ChangeEvent, FC } from 'react';

import { AutoFormikProps, FieldType, FieldTypeEnum } from '../types';
import CheckboxField from './CheckboxField';
import ColorField from './ColorField';
import CustomField from './CustomField';
import DatetimeField from './DatetimeField';
import FileField from './FileField';
import ImageField from './ImageField';
import JsonField from './JsonField';
import { MultiSelectField } from './MultiSelectField';
import NumberField from './NumberField';
import PasswordField from './PasswordField';
import { SelectField } from './SelectField';
import TextareaField from './TextareaField';
import TextField from './TextField';
import VirtualizedSelectField from './VirtualizedSelectField';

type FieldProps = AutoFormikProps['inputProps'] &
  FieldType['inputProps'] & {
    field: FieldType;
    disabled: boolean;
    readOnly: boolean;
    value: any;
    handleChange: (event: ChangeEvent<any>) => void;
    setFieldValue: (name: string, value: any) => void;
    error?:
      | FormikErrors<any>
      | Array<FormikErrors<any>>
      | string
      | Array<string>;
    handleBlur: (event: FocusEvent) => void;
  };

const Field: FC<FieldProps> = (props) => {
  let Component;

  switch (props.field.type) {
    case FieldTypeEnum.Custom:
      Component = CustomField;
      break;

    case FieldTypeEnum.Checkbox:
      Component = CheckboxField;
      break;

    case FieldTypeEnum.File:
      Component = FileField;
      break;

    case FieldTypeEnum.VirtualizedSelect:
      Component = VirtualizedSelectField;
      break;

    case FieldTypeEnum.ImageSelector:
      Component = ImageField;
      break;

    case FieldTypeEnum.Select:
      Component = SelectField;
      break;

    case FieldTypeEnum.Multiselect:
      Component = MultiSelectField;
      break;

    case FieldTypeEnum.Datetime:
      Component = DatetimeField;
      break;

    case FieldTypeEnum.JSON:
      Component = JsonField;
      break;

    case FieldTypeEnum.Number:
      Component = NumberField;
      break;

    case FieldTypeEnum.Color:
      Component = ColorField;
      break;

    case FieldTypeEnum.Password:
      Component = PasswordField;
      break;

    case FieldTypeEnum.Textarea:
      Component = TextareaField;
      break;

    case FieldTypeEnum.Text:
    default:
      Component = TextField;
  }

  return <Component {...props} />;
};

export default Field;
