import { Button, Group, TextInput, Title } from '@mantine/core';
import { useInterval } from '@mantine/hooks';
import { isEmpty } from 'lodash/fp';
import React, { useState, useMemo, useRef, useEffect, FC } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import styled from 'styled-components';

import { TickSimple, CloseX, Send2 } from '@portals/icons/linear';
import { ChatMessageType, TenantType } from '@portals/types';
import { getStyledThemeColor } from '@portals/utils';

import { VerticalScrollBar } from '../CustomScrollbar';
import Message from './Message';

const HALF_MINUTE = 30000;

interface ChatProps {
  messages: ChatMessageType[];
  pollingFn: () => void;
  seen: boolean;
  updateSeen: (seen: boolean) => void;
  chatOwner: string;
  disabled?: boolean;
  sendMessage: (message: string) => Promise<void>;
}

const Chat: FC<ChatProps> = ({
  pollingFn,
  messages,
  seen,
  updateSeen,
  chatOwner,
  disabled = false,
  sendMessage,
}) => {
  const pollingInterval = useInterval(pollingFn, HALF_MINUTE);
  const scrollRef = useRef<Scrollbars>();

  const [msgText, setMsgText] = useState('');
  const orderedMessages = useMemo(
    () =>
      messages.sort(
        (a, b) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      ),
    [messages]
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await sendMessage(msgText);
      setMsgText('');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(
    function handlePolling() {
      pollingInterval.start();

      return () => {
        pollingInterval.stop();
      };
    },
    [pollingInterval]
  );

  useEffect(
    function autoScrollToNewestMessage() {
      if (scrollRef.current && !isEmpty(orderedMessages)) {
        scrollRef.current.scrollToBottom();
      }
    },
    [orderedMessages]
  );

  return (
    <div>
      <Group position="apart" mb="lg">
        <Title order={3} sx={{ fontWeight: 600 }}>
          Chat
        </Title>
        <Button
          disabled={disabled}
          variant="outline"
          color={seen ? 'red' : 'primary'}
          onClick={() => updateSeen(!seen)}
          leftIcon={
            seen ? (
              <CloseX width={18} height={18} />
            ) : (
              <TickSimple width={18} height={18} />
            )
          }
        >
          {`Mark as ${seen ? 'NOT' : ''} seen by us`}
        </Button>
      </Group>
      <ChatWrapper>
        {!isEmpty(orderedMessages) ? (
          <VerticalScrollBar innerRef={scrollRef}>
            <ChatMessages className="chat-messages-container">
              {orderedMessages.map((message) => (
                <Message
                  key={message.id}
                  message={message}
                  chatOwner={chatOwner}
                />
              ))}
            </ChatMessages>
          </VerticalScrollBar>
        ) : (
          <div className="h-100 w-100 d-flex align-items-center justify-content-center font-size-lg text-muted">
            {`Here you can send additional information and speak with ${
              chatOwner === TenantType.Organization
                ? 'the manufacturer'
                : 'the client'
            }`}
          </div>
        )}
      </ChatWrapper>
      <InputContainer>
        <form onSubmit={handleSubmit}>
          <TextInput
            disabled={disabled}
            placeholder="Type your message..."
            value={msgText}
            onChange={(e) => setMsgText(e.target.value)}
            styles={{
              root: {
                width: '100% !important',
                maxWidth: '100% !important',
                flex: 1,
              },
              wrapper: {
                input: {
                  padding: '10px 15px !important',
                  height: 55,
                  minHeight: 55,
                },
              },
            }}
            rightSectionWidth={120}
            rightSection={
              <Button
                variant="outline"
                disabled={!msgText || disabled}
                rightIcon={<Send2 width={15} height={15} />}
                type="submit"
              >
                Send
              </Button>
            }
          />
        </form>
      </InputContainer>
    </div>
  );
};

const ChatWrapper = styled.div`
  height: 500px;
  border: 1px solid ${getStyledThemeColor('gray300')};
`;

const ChatMessages = styled.div`
  padding: 25px 20px;
  display: grid;
  grid-template-rows: max-content;
  grid-template-columns: 1fr;
  grid-row-gap: 40px;
`;

const InputContainer = styled.div`
  padding: 25px 20px;
  border: 1px solid ${getStyledThemeColor('gray300')};
  border-top: none;
  position: relative;
  display: grid;
  width: 100%;

  .input-group {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-rows: 45px;
    border: 1px solid ${getStyledThemeColor('gray300')};
    border-radius: 4px;

    input {
      width: 100%;
      height: 100%;
      border: none;
      box-shadow: none !important;

      &::placeholder {
        color: ${getStyledThemeColor('gray500')};
      }
    }

    .input-group-append {
      width: 100%;

      .btn {
        background-color: transparent;
        height: 45px;
        border: none;
        color: ${getStyledThemeColor('green')} !important;
        font-weight: bold;

        &:hover,
        &:active {
          color: ${getStyledThemeColor('green')};
        }
      }
    }
  }
`;

export default Chat;
