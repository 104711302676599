import { get, isNumber } from 'lodash/fp';

import THEME from '../../../ui/src/lib/ThemeProvider/theme';

/*
 * Util method for getting RGBA string from HEX string.
 * hex - color hex string
 * opacity - opacity from 0 to 100
 * hexToRgba("#00000", 50) -> 'rgba(0, 0, 0, 0.5)'
 * */
export const hexToRgba = (hex: string, opacity: number) => {
  if (typeof hex !== 'string') {
    return hex;
  }

  hex = hex.replace('#', '');

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity / 100})`;
};

export const getStyledThemeColor =
  <ColorKey extends keyof typeof THEME['color']>(
    path: ColorKey | string | Array<ColorKey | string>
  ) =>
  ({ theme }: { theme: typeof THEME }) =>
    get(path, theme.color);

export const getStyledThemeLayout =
  <LayoutKey extends keyof typeof THEME['layout']>(
    path: LayoutKey | string | Array<LayoutKey | string>
  ) =>
  ({ theme }: { theme: typeof THEME }) =>
    get(path, theme.layout);

export const loadFonts = (font: { url: string; family: string }) => {
  const head = document.head || document.getElementsByTagName('head')[0],
    style = document.createElement('style');

  head.appendChild(style);

  const css = `
          ${font.url ? `@import url('${font.url}');` : ''}

          body, input, select, textarea, button {
            font-family: ${font.family};
          }
        `;

  style.appendChild(document.createTextNode(css));
};

/*
 * Util method for getting CSS properties based on their type.
 * If a number is passed - it adds 'px' at the end. If string - leaves as is.
 * `width={50}` => `width: 50px`
 * `width="50%"` => `width: 50%`
 * */
export const getStyledStringOrNumberValue =
  (valueKey: string) =>
  (props: Record<string, any>): string => {
    const value = get(valueKey, props);

    if (isNumber(value)) return `${value}px`;

    return value;
  };

const getShadePercent = (varNum) => {
  const accent = varNum.toString();
  switch (accent) {
    case '0':
      return 1;
    case '50':
      return 0.9;
    case '100':
      return 0.75;
    case '200':
      return 0.6;
    case '300':
      return 0.3;
    case '350':
      return 0.3;
    case '400':
      return 0;
    case '500':
      return -0.25;
    case '600':
      return -0.45;
    case '700':
      return -0.55;
    case '800':
      return -0.75;
    case '900':
      return -0.9;
    default:
      return 1;
  }
};

export const shadeColor = (argColor, varNum) => {
  let color = argColor;
  if (color.toUpperCase() === '#FFF') {
    color = '#ffffff';
  }

  const percent = getShadePercent(varNum);
  const f = parseInt(color.slice(1), 16),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = f >> 16,
    G = (f >> 8) & 0x00ff,
    B = f & 0x0000ff;

  return `#${(
    0x1000000 +
    (Math.round((t - R) * p) + R) * 0x10000 +
    (Math.round((t - G) * p) + G) * 0x100 +
    (Math.round((t - B) * p) + B)
  )
    .toString(16)
    .slice(1)}`;
};

export const hexToPalette = ({ hex, isDark, prefix }) =>
  getColors(
    hex,
    [0, 100, 200, 300, 400, 500, 600, 700, 800, 900],
    isDark,
    prefix || ''
  );

export const getColors = (hex, arr, dark, prefix) => {
  const result = {};

  for (let i = 0; i < arr.length; i++) {
    result[`${prefix}${arr[i]}`] =
      arr[i] === 400
        ? hex
        : shadeColor(hex, dark ? arr[arr.length - 1 - i] : arr[i]);
  }

  return result;
};
