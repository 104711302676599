import { Box } from '@mantine/core';
import React from 'react';

import { New } from '@portals/icons/linear';

import LaunchNotes from '../../../external/LaunchNotes';

const ProductUpdates = () => {
  return (
    <LaunchNotes>
      {({ isActive, setIsActive }) => (
        <Box
          mr="md"
          sx={(theme) => ({
            color: isActive
              ? theme.colors.blue_accent[4]
              : theme.colors.blue_gray[5],
            cursor: 'pointer',
          })}
          onClick={() => setIsActive(true)}
          id="launch-notes-toggle"
        >
          <New />
        </Box>
      )}
    </LaunchNotes>
  );
};

export default ProductUpdates;
