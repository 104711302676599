import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';

import start from '@portals/framework';
import { getCurrentUser } from '@portals/redux/actions/users';
import { TenantType } from '@portals/types';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { getConfig } from '../../organizations/src/app/redux/actions/config';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { getSpaces } from '../../organizations/src/app/redux/actions/spaces';
import FieldOpsMeta from './app/components/FieldOpsMeta';
import GlobalStyle from './app/components/GlobalStyle';
import NavbarLayout from './app/components/NavbarLayout';
import { routes } from './app/routes';

const preloadData = {
  immediate: {
    spaces: getSpaces,
    config: getConfig,
    users: getCurrentUser,
  },
  delayed: {},
};

start(routes, {}, preloadData, TenantType.Organization, {
  globalLayout: (config) => (
    <>
      <FieldOpsMeta config={config} />
      <GlobalStyle />
    </>
  ),
  navbarLayout: () => <NavbarLayout />,
  options: {
    isMobile: true,
  },
});
