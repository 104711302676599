import { Group, Stack, Text, Title } from '@mantine/core';
import React, { FC } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { Spinner } from 'reactstrap';
import * as Yup from 'yup';

import { useAppConfig } from '@portals/framework/context';
import { useIsPending, useWelcomeDetails } from '@portals/redux';
import { getUserDetails, setUserDetails } from '@portals/redux/actions/auth';
import AutoFormik, { FieldTypeEnum } from '@portals/ui/AutoFormik';

import { FormWrapper } from './common';

const FIELDS = [
  {
    name: 'email',
    title: 'Email',
    type: FieldTypeEnum.Email,
    disabled: true,
  },
  {
    name: 'name',
    title: 'Your name',
    type: FieldTypeEnum.String,
    required: true,
  },
  {
    name: 'password',
    title: 'New Password',
    type: FieldTypeEnum.Password,
    required: true,
    placeholder: 'Hard to guess password',
    validations: Yup.string().min(6),
  },
  {
    name: 'confirm',
    title: 'Confirm Password',
    type: FieldTypeEnum.Password,
    required: true,
    placeholder: 'The same password again',
    validations: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match'
    ),
  },
];

type WelcomeConnectedProps = {
  error?: string;
};

const Welcome: FC<WelcomeConnectedProps> = ({ error }) => {
  const { tenantType } = useAppConfig();
  const { token } = useParams<{ token: string }>();
  const dispatch = useDispatch();
  const inProgress = useIsPending('setUserDetails');
  const welcomeDetails = useWelcomeDetails();

  const handleSubmit = ({ password, name }) =>
    dispatch(
      setUserDetails({
        token,
        password,
        name,
        tenant: tenantType,
      })
    );

  useEffectOnce(() => {
    dispatch(getUserDetails(token, tenantType));
  });

  if (!welcomeDetails && error) {
    return <h3 className="m-auto">{error}</h3>;
  }

  if (!welcomeDetails) {
    return <Spinner className="m-auto" />;
  }

  return (
    <FormWrapper id="welcome">
      <Stack
        sx={(theme) => ({
          '.mantine-TextInput-root, .mantine-PasswordInput-root': {
            marginBottom: theme.spacing.sm,
          },
        })}
      >
        <Group position="apart" mb="lg" align="baseline">
          <Title order={1}>Welcome to {welcomeDetails.tenant_name}</Title>

          <Text color="dimmed" size="sm" align="center">
            <Group spacing={6}>
              <Link to="/auth/sign-in">Sign-In</Link>
            </Group>
          </Text>
        </Group>

        <AutoFormik
          fields={FIELDS}
          initialValues={{
            name: welcomeDetails.name,
            email: welcomeDetails.email,
          }}
          inProgress={inProgress}
          handleSubmit={handleSubmit}
          buttonsProps={{
            size: 'sm',
            style: {
              width: '100%',
              marginTop: '10px',
            },
          }}
          submitTitle="Join"
        />

        {error && <p className="text-danger text-center">{error}</p>}
      </Stack>
    </FormWrapper>
  );
};

const mapStateToProps = (state) => ({
  error: state.errors['getUserDetails'] || state.errors['setUserDetails'],
});

export default connect<WelcomeConnectedProps>(mapStateToProps)(Welcome);
