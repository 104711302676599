import {
  NotificationStylesNames,
  NotificationStylesParams,
} from '@mantine/core';

import { ComponentStyleOverrideFn } from './common-types';

export const notificationStyle: ComponentStyleOverrideFn<
  NotificationStylesNames,
  NotificationStylesParams
> = (theme) => ({
  root: {
    width: 420,
    padding: `${theme.spacing.xl}px !important`,
    background: theme.fn.rgba(theme.white, 0.4),
    boxShadow: `0 4px 10px ${theme.fn.rgba(theme.black, 0.1)}`,
    backdropFilter: 'blur(100px)',
    borderRadius: theme.radius.lg,
  },
  title: {
    fontSize: theme.fontSizes.md,
    fontWeight: 700,
    color: theme.colors.blue_gray[8],
    marginBottom: theme.spacing.xs,
  },
  icon: {
    display: 'flex',
    alignSelf: 'self-start',
    backgroundColor: `${theme.fn.rgba(theme.white, 0.4)} !important`,
  },
});
