import {
  createStyles,
  Group,
  Image,
  NumberInput,
  NumberInputProps,
  Text,
} from '@mantine/core';
import React, { FC } from 'react';

import {
  CURRENCIES,
  CURRENCIES_FLAGS,
  CurrencyCode,
} from '@portals/framework/constants';
import { getCurrencySign } from '@portals/utils';

import { useConvertedCurrencyInput } from '../hooks/currency-input';

interface CurrencyRowProps {
  value: NumberInputProps['value'];
  onChange: NumberInputProps['onChange'];
  currencyCode: CurrencyCode;
  isDefaultCurrency: boolean;
}

export const CurrencyRow: FC<CurrencyRowProps> = ({
  value,
  onChange,
  currencyCode,
  isDefaultCurrency,
}) => {
  const { classes } = useStyles();
  const currencySign = getCurrencySign(currencyCode);
  const [adjustedValue, onChangeHandler] = useConvertedCurrencyInput(
    currencyCode,
    value,
    onChange
  );

  return (
    <Group noWrap spacing="xs" className={classes.container}>
      <Image
        width={30}
        height={22}
        radius="sm"
        src={CURRENCIES_FLAGS[currencyCode]}
      />
      <Text size="sm">
        <span>{CURRENCIES[currencyCode]}</span>
        {isDefaultCurrency && (
          <Text component="span" className={classes.defaultText}>
            &nbsp;(default)
          </Text>
        )}
      </Text>

      <Group noWrap className={classes.inputWrapper}>
        <Text size="sm" className={classes.currencySign}>
          {currencySign}
        </Text>
        <NumberInput
          hideControls
          size="xs"
          precision={2}
          value={adjustedValue}
          onChange={onChangeHandler}
          classNames={{ input: classes.input }}
        />
      </Group>
    </Group>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    paddingBlock: theme.spacing.lg,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },
  defaultText: {
    color: theme.colors.blue_gray[3],
  },
  currencySign: {
    color: theme.colors.gray[6],
  },
  inputWrapper: {
    marginLeft: 'auto',
  },
  input: {
    width: 100,
    fontSize: theme.fontSizes.sm,
  },
}));
