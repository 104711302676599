const timers = {};

const debounceMiddleware = () => (next) => (action) => {
  const { debounce } = action.meta || {};

  // We either use explicitly provided name or just debounce all actions of this type
  const { name = action.type } = action.payload || {};

  if (!debounce) {
    return next(action);
  }

  // If already pending, remove
  if (timers[name]) {
    clearTimeout(timers[name]);
  }

  // Set timeout to pass the action
  timers[name] = setTimeout(() => {
    delete timers[name];
    next(action);
  }, debounce);
};

export default debounceMiddleware;
