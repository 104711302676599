import { setNestedValue, updateNormalizedData } from '../actions/data';
import { API } from '../constants';
import { USER_SCHEMA } from '../schemas';

export const getUser = (id) => ({
  type: API,
  payload: {
    url: `ui/users/${id}`,
    success: updateNormalizedData('users'),
    normalize: USER_SCHEMA,
    name: `getUser_${id}`,
  },
});

export const getCurrentUser = () => getUser('self');

export const getUsers = (ids = null) => ({
  type: API,
  payload: {
    url: `ui/users${ids ? '?ids=' + encodeURI(ids) : ''}`,
    success: updateNormalizedData('users'),
    normalize: [USER_SCHEMA],
    name: 'getUsers',
  },
});

export const createUser = (
  data,
  extraActions,
  toastrMessage = 'User created'
) => ({
  type: API,
  payload: {
    url: 'ui/users',
    data,
    success: [updateNormalizedData('users'), extraActions],
    normalize: USER_SCHEMA,
    name: 'createUser',
    withError: true,
    toastr: toastrMessage,
  },
});

export const resendInvite = (userId, extraActions?: Array<any>) => ({
  type: API,
  payload: {
    method: 'post',
    url: `ui/users/${userId}/resend_invite`,
    success: [updateNormalizedData('users'), extraActions],
    normalize: USER_SCHEMA,
    name: `resendInvite_${userId}`,
    withError: true,
    toastr: 'Resent invite',
  },
});

export const updateUserSettings = (
  id,
  setting,
  value,
  toastr = 'Update settings'
) => [
  setNestedValue(['users', id, 'settings', setting], value),
  {
    type: API,
    payload: {
      method: 'PUT',
      url: `ui/users/${id}`,
      data: { setting, value },
      toastr,
      name: 'updateUser',
    },
    meta: {
      debounce: 1000,
    },
  },
];
