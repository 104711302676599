import { createStyles, Group, GroupProps } from '@mantine/core';
import classNames from 'classnames';
import React, { FC } from 'react';

interface ModalFooterProps extends GroupProps {}

const useStyles = createStyles((theme) => ({
  container: {
    paddingTop: theme.spacing.md,
  },
}));

const ModalFooter: FC<ModalFooterProps> = ({
  children,
  className,
  ...groupProps
}) => {
  const { classes } = useStyles();

  return (
    <Group className={classNames(classes.container, className)} {...groupProps}>
      {children}
    </Group>
  );
};

export default ModalFooter;
