import { AnimateSharedLayout } from 'framer-motion';
import { isFunction, startsWith } from 'lodash/fp';
import React, { FC, Suspense, useMemo } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import { CanViewFn, usePermissionAccess } from '@portals/framework';
import { useAppConfig } from '@portals/framework/context';
import { useFeatures } from '@portals/redux';
import type {
  ModalsType,
  RoutesType,
  RouteType,
  StateType,
} from '@portals/types';
import { ErrorBoundary, Loader, ScrollToTop } from '@portals/ui';

import Modals from '../components/Modals';
import AuthLayout from '../layouts/Auth';
import DashboardLayout from '../layouts/Dashboard';
import OnboardingLayout from '../layouts/Onboarding';
import ShopLayout from '../layouts/Shop';
import Page404 from '../pages/auth/Page404';
import Referral from '../pages/auth/Referral';
import { authRoutes } from './auth';
import AutoTenantSwitcher from './AutoTenantSwitcher';

const getRouteComponent = (
  route: RouteType,
  features: StateType['data']['config']['server']['features'],
  canView: CanViewFn
) => {
  const { canAccessRoute, id, path, component: Component } = route;

  if (canAccessRoute && !canAccessRoute({ features, canView })) {
    return;
  }

  const adjustedPath = isFunction(path) ? path(features) : path;

  return (
    <Route
      key={id}
      path={adjustedPath}
      exact
      render={(props) => (
        <Suspense fallback={<Loader />}>
          <Component {...props} />
        </Suspense>
      )}
    />
  );
};

const childRoutes = (
  routes: RoutesType,
  features: StateType['data']['config']['server']['features'],
  canView: CanViewFn
) => {
  const result = [];

  routes.forEach((route) => {
    const { canAccessRoute, children, path } = route;

    if (canAccessRoute && !canAccessRoute({ features, canView })) {
      return;
    }

    // Route item with children
    if (children) {
      children.forEach((childRoute) => {
        result.push(getRouteComponent(childRoute, features, canView));
      });
    }

    if (path) {
      result.push(getRouteComponent(route, features, canView));
    }
  });

  return result.filter(Boolean);
};

type RoutesProps = {
  modals: ModalsType;
};

enum LayoutTypeEnum {
  Auth = 'Auth',
  Shop = 'Shop',
  Dashboard = 'Dashboard',
  Onboarding = 'Onboarding',
}

const AdjustedRoutes = () => {
  const location = useLocation();
  const { extraLayout, routes } = useAppConfig();
  const features = useFeatures();
  const { canView } = usePermissionAccess();

  const [auth, dashboard, shop = null, onboarding = null] = useMemo(() => {
    const appRoutes = [
      childRoutes(routes.auth || authRoutes, features, canView),
    ];

    const dashboardRoutes = [];

    routes.dashboard.forEach((dashboardRoute) => {
      if (
        dashboardRoute.canAccessRoute &&
        !dashboardRoute.canAccessRoute({ features, canView })
      ) {
        return;
      }

      dashboardRoutes.push(
        childRoutes(dashboardRoute.childRoutes, features, canView)
      );
    });

    appRoutes.push([dashboardRoutes]);

    if (routes.shop) {
      appRoutes.push(childRoutes(routes.shop, features, canView));
    } else {
      // TODO: Refactor to const { auth, dashboard, ... } = useMemo();
      appRoutes.push(null);
    }

    if (routes.onboarding) {
      appRoutes.push(childRoutes(routes.onboarding, features, canView));
    }

    return appRoutes;
  }, [
    canView,
    features,
    routes.auth,
    routes.dashboard,
    routes.onboarding,
    routes.shop,
  ]);

  const layoutType = useMemo(() => {
    if (startsWith('/auth', location.pathname)) {
      return LayoutTypeEnum.Auth;
    } else if (startsWith('/shop', location.pathname)) {
      return LayoutTypeEnum.Shop;
    } else if (startsWith('/onboarding', location.pathname)) {
      return LayoutTypeEnum.Onboarding;
    } else {
      return LayoutTypeEnum.Dashboard;
    }
  }, [location.pathname]);

  const adjustedRoutes = useMemo(() => {
    let Layout;
    let adjustedRoutes;

    if (layoutType === LayoutTypeEnum.Auth) {
      Layout = extraLayout?.authLayout || AuthLayout;
      adjustedRoutes = auth;
    } else if (layoutType === LayoutTypeEnum.Shop) {
      Layout = extraLayout?.shopLayout || ShopLayout;
      adjustedRoutes = shop;
    } else if (layoutType === LayoutTypeEnum.Onboarding) {
      Layout = extraLayout?.onboardingLayout || OnboardingLayout;
      adjustedRoutes = onboarding;
    } else {
      Layout = extraLayout?.dashboardLayout || DashboardLayout;
      adjustedRoutes = dashboard;
    }

    return (
      <Layout>
        <ErrorBoundary>
          <Switch>
            {adjustedRoutes}

            <Route render={() => <Page404 />} />
          </Switch>
        </ErrorBoundary>
      </Layout>
    );
  }, [auth, dashboard, onboarding, extraLayout, layoutType, shop]);

  return (
    <ScrollToTop>
      <AnimateSharedLayout>{adjustedRoutes}</AnimateSharedLayout>
    </ScrollToTop>
  );
};

const Routes: FC<RoutesProps> = ({ modals }) => (
  <>
    <AutoTenantSwitcher />

    <Referral />

    <AdjustedRoutes />

    <Modals modals={modals} />
  </>
);

export default Routes;
