import { Select } from '@mantine/core';
import { isArray, isObject, keys, map, toString } from 'lodash/fp';
import React, { FC, useMemo } from 'react';

import { FieldRendererProps } from '../types';

export const SelectField: FC<FieldRendererProps> = ({
  error,
  field,
  value,
  handleBlur,
  clearable,
  disabled,
  setFieldValue,
  readOnly,
  required,
}) => {
  const { name, title, options, placeholder, inputProps = {} } = field;
  const { startAdornment, endAdornment, ...restInputProps } = inputProps || {};

  const adjustedOptions = useMemo(() => {
    if (isArray(options)) {
      return map(toString, options);
    } else if (isObject(options)) {
      return keys(options).map((optionKey) => ({
        label: options[optionKey],
        value: optionKey,
      }));
    }
  }, [options]);

  return (
    <Select
      error={error}
      withinPortal={false}
      data={adjustedOptions}
      required={required}
      readOnly={readOnly}
      clearable={clearable}
      onChange={(value) => setFieldValue(field.name, value)}
      disabled={disabled || readOnly}
      placeholder={placeholder || title}
      label={title}
      id={name}
      value={value}
      onBlur={handleBlur}
      icon={startAdornment}
      rightSection={endAdornment}
      dropdownPosition="flip"
      searchable
      {...restInputProps}
    />
  );
};
