import { filter, flow, get, getOr, values, omitBy } from 'lodash/fp';
import { createSelector, Selector } from 'reselect';

import { getAuth } from '@portals/redux/selectors/ui';
import {
  DeviceModelType,
  OrganizationIncidentType,
  OrganizationFileType,
  PartnerFileType,
  ReferralCodeType,
  RuleType,
  StateType,
  SubscriptionType,
  UserPermissions,
  UserType,
} from '@portals/types';

export const getData = (state: StateType): StateType['data'] => state.data;

/* CONFIG */
export const getConfig: Selector<StateType, StateType['data']['config']> =
  createSelector(getData, get('config'));

export const getUserConfig: Selector<
  StateType,
  StateType['data']['config']['user']
> = createSelector(getConfig, get('user'));

export const getServerConfig: Selector<
  StateType,
  StateType['data']['config']['server']
> = createSelector(getConfig, get('server'));

export const getOrganizationConfig: Selector<
  StateType,
  StateType['data']['config']['organization']
> = createSelector(getConfig, get('organization'));

export const getOrganizationType: Selector<
  StateType,
  StateType['data']['config']['organization']['type']
> = createSelector(getOrganizationConfig, get('type'));

/* USERS */
export const getUser: (userId: string) => Selector<StateType, UserType> = (
  userId: string
) => createSelector(getUsers, get<StateType['data']['users'], string>(userId));

export const getUsers: Selector<StateType, StateType['data']['users']> =
  createSelector(getData, get('users'));

export const getCurrentUser: Selector<StateType, UserType | undefined> =
  createSelector([getUsers, getAuth], (users, auth) => get([auth?.id], users));

export const getCurrentUserPermissions: Selector<
  StateType,
  UserPermissions | undefined
> = createSelector([getCurrentUser], get('permissions'));

export const getAuthorizedUsers: Selector<
  StateType,
  StateType['data']['authorized_users']
> = createSelector(getData, get('authorized_users'));

/* FEATURES */
export const getFeatures: Selector<
  StateType,
  StateType['data']['config']['server']['features']
> = createSelector(getServerConfig, getOr({}, 'features'));

/* DEVICES */
export const getDeviceModels: Selector<
  StateType,
  StateType['data']['device_models']
> = createSelector(getData, get('device_models'));

export const getDeviceModel: (
  deviceId: string
) => Selector<StateType, DeviceModelType> = (deviceId: string) =>
  createSelector(
    getDeviceModels,
    get<StateType['data']['device_models'], string>(deviceId)
  );

export const getDevices: Selector<StateType, StateType['data']['devices']> =
  createSelector(getData, get('devices'));

/* SPACES */
export const getSpaces: Selector<StateType, StateType['data']['spaces']> =
  createSelector(getData, get('spaces'));

/* SUBSCRIPTIONS */
export const getSubscriptions: Selector<
  StateType,
  StateType['data']['subscriptions']
> = createSelector(getData, get('subscriptions'));

export const getSubscription: (
  subscriptionId: string
) => Selector<StateType, SubscriptionType> = (subscriptionId: string) =>
  createSelector(
    getSubscriptions,
    get<StateType['data']['subscriptions'], string>(subscriptionId)
  );

/* LICENSES */
export const getLicenses: Selector<StateType, StateType['data']['licenses']> =
  createSelector(getData, get('licenses'));

/* FILES */
export const getFiles: Selector<StateType, StateType['data']['file_infos']> =
  createSelector(getData, get('file_infos'));

export const getOrgFiles: Selector<
  StateType,
  Record<string, OrganizationFileType>
> = createSelector(getData, (data) => {
  const filesInfo = get('file_infos', data) as Record<
    string,
    OrganizationFileType
  >;

  return omitBy<OrganizationFileType>(({ partner }) => partner, filesInfo);
});

export const getFileById: (
  fileId: string
) => Selector<StateType, OrganizationFileType | PartnerFileType | undefined> = (
  fileId: string
) =>
  createSelector(
    getFiles,
    get<StateType['data']['file_infos'], string>(fileId)
  );

/* PRODUCTS */
export const getProducts: Selector<StateType, StateType['data']['products']> =
  createSelector(getData, get('products'));

export const getPartnerPackages: Selector<
  StateType,
  StateType['data']['partner_packages']
> = createSelector(getData, get('partner_packages'));

export const getShortCode: Selector<
  StateType,
  StateType['data']['config']['partner']['shortcode']
> = createSelector(getConfig, get('partner.shortcode'));

/* STATS */
export const getStats: Selector<
  StateType,
  StateType['data']['stats'] | Record<string, never>
> = createSelector(getData, getOr({}, 'stats'));

/* INCIDENTS */
export const getIncidents: Selector<StateType, StateType['data']['incidents']> =
  createSelector(getData, get('incidents'));

export const getIncidentsByDeviceId: (
  deviceId: string
) => Selector<StateType, OrganizationIncidentType[]> = (deviceId: string) =>
  createSelector(getIncidents, (incidents) =>
    flow([
      values,
      filter(
        (incident: OrganizationIncidentType) => incident.device_id === deviceId
      ),
    ])(incidents)
  );

/* REFERRAL CODES */
export const getReferralCodes: Selector<
  StateType,
  Array<ReferralCodeType>
> = createSelector(getData, flow([get('referral_codes'), values]));

/* ACCESS */

export const getAccess: Selector<
  StateType,
  StateType['data']['access'] | Record<string, never>
> = createSelector(getData, getOr({}, 'access'));

/* INTEGRATIONS */

export const getIntegrations: Selector<
  StateType,
  StateType['data']['integrations'] | Record<string, never>
> = createSelector(getData, getOr({}, 'integrations'));

/* WELCOME DETAILS */
export const getWelcomeDetails: Selector<
  StateType,
  StateType['data']['welcome_details'] | undefined
> = createSelector(getData, get('welcome_details'));

/* ORGANIZATIONS */
export const getOrganizations: Selector<
  StateType,
  StateType['data']['organizations'] | Record<string, never>
> = createSelector(getData, get('organizations'));

export const getStateDumps: Selector<
  StateType,
  StateType['data']['state_dumps']
> = createSelector(getData, get('state_dumps'));

/* TELEMETRIES */
export const getTelemetries: Selector<
  StateType,
  StateType['data']['telemetries']
> = createSelector(getData, get('telemetries'));

/* RULES */
export const getRules: Selector<StateType, StateType['data']['rules']> =
  createSelector(getData, get('rules'));

export const getRuleById: (
  ruleId: string
) => Selector<StateType, RuleType | undefined> = (ruleId: string) =>
  createSelector(getRules, get<StateType['data']['rules'], string>(ruleId));
