import { getOr, size } from 'lodash/fp';

import { useUserIsAdmin } from '@portals/entities';
import { useAuth, useUsers } from '@portals/redux';
import { TenantType } from '@portals/types';

export const useShouldDisplayTenantMenu = () => {
  const users = useUsers();
  const { id, tenant } = useAuth();
  const isAdmin = useUserIsAdmin(id);
  const controlledTenants = size(
    getOr([], [id, 'controlled', tenant.type], users)
  );

  const hasFeatureFlags = isAdmin;
  const hasMultipleTenants = users && controlledTenants > 1;
  const canCreateTenant = tenant.type === TenantType.Partner && isAdmin;

  return hasFeatureFlags || hasMultipleTenants || canCreateTenant;
};
