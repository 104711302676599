import UsersIcon from 'react-feather/dist/icons/users';

import { RouteType } from '@portals/types';

import ChangePassword from '../pages/auth/ChangePassword';
import Page404 from '../pages/auth/Page404';
import Page500 from '../pages/auth/Page500';
import ResetPassword from '../pages/auth/ResetPassword';
import SignIn from '../pages/auth/SignIn';
import SignUp from '../pages/auth/SignUp';
import Welcome from '../pages/auth/Welcome';

export const authRoutes: RouteType[] = [
  {
    path: '/auth',
    id: 'Auth',
    icon: UsersIcon,
    badgeColor: 'secondary',
    badgeText: 'Special',
    children: [
      {
        path: ['/auth', '/auth/sign-in'],
        id: 'Sign In',
        component: SignIn,
      },
      {
        path: '/auth/sign-up',
        id: 'Sign Up',
        component: SignUp,
      },
      {
        path: '/auth/change-password/:token',
        id: 'Change Password',
        component: ChangePassword,
      },
      {
        path: '/auth/welcome/:token',
        id: 'Welcome',
        component: Welcome,
      },

      {
        path: '/auth/reset-password',
        id: 'Reset Password',
        component: ResetPassword,
      },
      {
        path: '/auth/404',
        id: '404 Page',
        component: Page404,
      },
      {
        path: '/auth/500',
        id: '500 Page',
        component: Page500,
      },
    ],
  },
];
