import * as Sentry from '@sentry/react';
import React from 'react';
import { useQueryClient } from 'react-query';
import { Redirect, useLocation } from 'react-router-dom';

import { useConnection as useWebsocketConnection } from '@portals/framework';
import { useAuth } from '@portals/redux';

const AuthRedirect = () => {
  const { pathname, search } = useLocation();

  // Save prev path and search so we can redirect back after login
  if (pathname) {
    localStorage.setItem('afterAuth', pathname + search);
  }

  return <Redirect to="/auth/sign-in" />;
};

const Authenticated = ({ children }) => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const { disconnect } = useWebsocketConnection();

  if (auth) {
    Sentry.configureScope((scope) => scope.setUser({ id: auth.uid }));
    return children;
  } else {
    queryClient.clear();
    disconnect();
    return <AuthRedirect />;
  }
};

export default Authenticated;
