import { InputWrapper } from '@mantine/core';
import classNames from 'classnames';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import 'cropperjs/dist/cropper.css';
import { ImageInput } from '@portals/framework';
import { useOpenModal } from '@portals/redux';
import { getStyledThemeColor } from '@portals/utils';

import { ReactComponent as TrashIcon } from '../../../assets/trash.svg';
import { AutoFormikProps, FieldType, SetFieldValueType } from '../types';

export type ImageSelectorFieldValue = {
  id: string;
  url: string;
  thumbnail: string;
};

type ImageSelectorFieldProps = {
  error?: string;
  required?: boolean;
  horizontal: AutoFormikProps['inputProps']['horizontal'];
  setFieldValue: SetFieldValueType<string>;
  field: FieldType & {
    height?: number | string;
    width?: number | string;
    croppingAspectRatio?: number;
    showSkipCropButton?: boolean;
    cropping?: boolean;
  };
  value: ImageSelectorFieldValue | string;
  className?: string;
  label?: string;
};

// After moving to S3 from Cloudinary, need to support deprecated images structure
// Should be removed after a migration is run on all images
const getImageUrl = (image?: string | ImageSelectorFieldValue) =>
  (image as ImageSelectorFieldValue)?.url || (image as string) || '';

const ImageField: FC<ImageSelectorFieldProps> = ({
  field,
  value,
  setFieldValue,
  label = 'Upload image',
  error,
  required,
  className,
}) => {
  const image = getImageUrl(value);
  const openModal = useOpenModal();

  const { inputProps = {} } = field;

  const onChange = (imageList) => {
    const image = imageList[0];

    openModal('UploadImage', {
      image,
      aspectRatio: field.croppingAspectRatio,
      onUpload: (imgUrl: string) => setFieldValue(field.name, imgUrl),
      onError: (error) => console.error(error),
    });
  };

  return (
    <InputWrapper
      label={field.title}
      error={error}
      required={required}
      {...inputProps}
    >
      {image ? (
        <Container
          imageSrc={image}
          className={classNames('image-container', className)}
        >
          <div
            className="uploaded-image"
            style={{ width: field.width, height: field.height }}
          />

          <div
            className="remove-image"
            onClick={() => setFieldValue(field.name, '')}
          >
            <TrashIcon />
          </div>
        </Container>
      ) : (
        <ImageInput
          label={label}
          onChange={onChange}
          className={classNames(className)}
        />
      )}
    </InputWrapper>
  );
};

const Container = styled.div<{ imageSrc?: string }>`
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(221, 226, 226, 0.25);
  width: 100%;
  height: 216px;
  box-sizing: border-box;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${getStyledThemeColor('gray200')};
  position: relative;
  border: 1px solid ${getStyledThemeColor('gray300')};
  padding: 10px;

  .upload-asset {
    pointer-events: none;
  }

  .uploaded-image {
    width: 100%;
    height: 100%;

    ${({ imageSrc }) => css`
      background-image: url(${imageSrc});
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    `}
  }

  &.is-empty {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: ${getStyledThemeColor('gray100')};
    border: 1px dashed ${getStyledThemeColor('gray300')};

    .label {
      margin-top: 7px;
      font-size: 12px;
      color: #78909c;
    }

    &:hover {
      transition: border-color 0.15s ease-in-out;
      border-color: ${getStyledThemeColor('primary')};
    }
  }

  &.is-dragging {
    border-color: ${getStyledThemeColor('primary')};
  }

  .remove-image {
    position: absolute;
    bottom: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    background-color: ${getStyledThemeColor('white')};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    border: 1px solid ${getStyledThemeColor('gray150')};

    svg {
      path {
        transition: stroke 0.15s ease-in-out;
      }
    }

    &:hover {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
      background-color: ${getStyledThemeColor('danger')};
      color: ${getStyledThemeColor('white')};
      border-color: transparent;

      svg {
        path {
          stroke: ${getStyledThemeColor('white')};
        }
      }
    }
  }
`;

export default ImageField;
