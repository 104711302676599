import { ActionIcon, Badge, Divider, Group, Text } from '@mantine/core';
import { isEmpty, size } from 'lodash/fp';
import React from 'react';
import X from 'react-feather/dist/icons/x';

import { SmartTableProps } from '@portals/types';

import { useTableInstance } from '../../context';
import ColumnsSettings from '../ColumnsSettings';
import Export from './Export';
import GlobalFilter from './GlobalFilter';

type HeaderProps<TData extends object> = Pick<
  SmartTableProps<TData>,
  | 'name'
  | 'noExport'
  | 'noColumnsSelection'
  | 'additionalActions'
  | 'selectedItemsActions'
  | 'withGlobalSearch'
  | 'isCompact'
>;

function Header<TData extends object>({
  additionalActions,
  selectedItemsActions,
  name,
  noExport = false,
  noColumnsSelection = false,
  withGlobalSearch = false,
  isCompact = false,
}: HeaderProps<TData>) {
  const instance = useTableInstance<TData>();
  const { allColumns, toggleHideColumn } = instance;

  if (isCompact && (additionalActions || selectedItemsActions))
    return (
      <Group position="apart" sx={{ height: 35 }}>
        {selectedItemsActions ? (
          <Group>
            {isEmpty(instance.state.selectedRowIds) ? null : (
              <>
                <Badge
                  size="lg"
                  rightSection={
                    <ActionIcon
                      color="blue_accent"
                      sx={{ minWidth: 'unset', width: 'auto' }}
                      onClick={() => instance.toggleAllRowsSelected(false)}
                    >
                      <X size={14} />
                    </ActionIcon>
                  }
                  sx={{
                    textTransform: 'none',
                    lineHeight: '14px',
                  }}
                >
                  <Text size="xs" weight={400}>
                    {size(instance.state.selectedRowIds)} Selected
                  </Text>
                </Badge>

                <Group>
                  <Divider sx={{ height: '21px' }} orientation="vertical" />
                </Group>

                <Group spacing="xs">{selectedItemsActions}</Group>
              </>
            )}
          </Group>
        ) : null}

        {additionalActions ? (
          <Group position="right" align="center">
            {additionalActions()}
          </Group>
        ) : null}
      </Group>
    );

  return (
    <Group position="apart" align="center" sx={{ height: 35 }}>
      <Group>
        {withGlobalSearch ? <GlobalFilter /> : null}

        {isEmpty(instance.state.selectedRowIds) ? null : (
          <>
            <Badge
              size="lg"
              rightSection={
                <ActionIcon
                  color="blue_accent"
                  sx={{ minWidth: 'unset', width: 'auto' }}
                  onClick={() => instance.toggleAllRowsSelected(false)}
                >
                  <X size={14} />
                </ActionIcon>
              }
              sx={{
                textTransform: 'none',
                lineHeight: '14px',
              }}
            >
              <Text size="xs" weight={400}>
                {size(instance.state.selectedRowIds)} Selected
              </Text>
            </Badge>

            <Group>
              <Divider sx={{ height: '21px' }} orientation="vertical" />
            </Group>

            <Group spacing="xs">{selectedItemsActions}</Group>
          </>
        )}
      </Group>

      <Group spacing="md" align="center">
        {additionalActions ? additionalActions() : null}

        {noExport && noColumnsSelection ? null : (
          <Group>
            <Divider sx={{ height: '21px' }} orientation="vertical" />
          </Group>
        )}

        {noExport ? null : (
          <ActionIcon>
            <Export instance={instance} name={name} />
          </ActionIcon>
        )}

        {noColumnsSelection ? null : (
          <ColumnsSettings
            columns={allColumns}
            onColumnToggle={toggleHideColumn}
          />
        )}
      </Group>
    </Group>
  );
}

export default Header;
