import {
  Box,
  createStyles,
  Divider,
  Group,
  Menu,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import {
  compact,
  first,
  join,
  last,
  map,
  size,
  upperCase,
  words,
} from 'lodash/fp';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { useHubspotConversations } from '@portals/framework';
import { useAppConfig } from '@portals/framework/context';
import { getAuth, useActionDispatch } from '@portals/redux';
import { signOut } from '@portals/redux/actions/auth';
import { openModal } from '@portals/redux/actions/modal';
import { TenantType } from '@portals/types';
import { getStyledThemeColor } from '@portals/utils';

import { ReactComponent as HelpIcon } from '../../../assets/img/icons/help.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/img/icons/logout.svg';
import { ReactComponent as PasswordIcon } from '../../../assets/img/icons/password.svg';
import { ReactComponent as PrivacyIcon } from '../../../assets/img/icons/privacy.svg';
import { ReactComponent as TermsIcon } from '../../../assets/img/icons/terms.svg';

export const UserSettings = () => {
  const signOutAction = useActionDispatch(signOut);
  const openModalAction = useActionDispatch(openModal);
  const { classes } = useStyles();
  const { extraLayout = {} } = useAppConfig();
  const hubspotConversations = useHubspotConversations();

  const { name, uid: email, tenant } = useSelector(getAuth) || {};
  const isPartner = tenant.type === TenantType.Partner;

  const settingsItems = useMemo(
    () =>
      compact([
        {
          Icon: HelpIcon,
          label: 'Help',
          href: isPartner ? 'https://dev.xyte.io/' : 'https://docs.xyte.io/',
        },
        {
          Icon: PasswordIcon,
          label: 'Change Password',
          onClick: () => openModalAction('ChangePassword'),
        },
        {
          Icon: TermsIcon,
          label: 'Terms of service',
          href: 'https://xyte.io/terms-of-service',
        },
        {
          Icon: PrivacyIcon,
          label: 'Privacy',
          href: 'https://xyte.io/privacy-policy',
        },
        {
          Icon: LogoutIcon,
          label: 'Sign out',
          onClick: () => {
            hubspotConversations.removeWidget();
            signOutAction();
          },
        },
      ]),
    [hubspotConversations, isPartner, openModalAction, signOutAction]
  );

  const nameAbbreviation = useMemo(() => {
    const nameWords = words(name);

    if (size(nameWords) === 1) {
      return upperCase(first(nameWords[0]));
    }

    return join(
      '',
      map((word) => upperCase(first(word)), [first(nameWords), last(nameWords)])
    );
  }, [name]);

  return (
    <Menu
      ml="md"
      control={
        <UserWrapper>
          <Tooltip
            label={<Text size="xs">User settings</Text>}
            disabled={extraLayout?.options?.isMobile}
          >
            <User className="user-name">{nameAbbreviation}</User>
          </Tooltip>
        </UserWrapper>
      }
      styles={(theme) => ({
        body: {
          width: 300,
          padding: `${theme.spacing.md}px ${theme.spacing.xs}px`,
        },
        itemInner: {
          padding: `0px ${theme.spacing.md}px`,
          justifyContent: 'start',
        },
      })}
    >
      <Stack spacing="xs">
        <Box className={classes.header}>
          <HeaderUser>{nameAbbreviation}</HeaderUser>
          <div className="user-data">
            <UserName>{name}</UserName>
            <Email className="user-email">{email}</Email>
          </div>
        </Box>

        <Group px="xs" grow>
          <Divider
            sx={(theme) => ({ borderColor: theme.colors.blue_gray[3] })}
          />
        </Group>

        {settingsItems.map(({ Icon, label, href, onClick }) => (
          <Menu.Item
            icon={<Icon />}
            {...(href
              ? {
                  onClick: () => {
                    window.open(href, '_blank');
                  },
                }
              : { onClick })}
            key={label}
          >
            {label}
          </Menu.Item>
        ))}
      </Stack>
    </Menu>
  );
};

const useStyles = createStyles((theme) => ({
  header: {
    display: 'grid',
    gridTemplateColumns: '44px 1fr',
    gridColumnGap: theme.spacing.md,
    alignItems: 'center',
    height: 44,
    padding: `0px ${theme.spacing.sm}px`,

    '.user-data': {
      display: 'grid',
    },
  },
}));

const UserWrapper = styled.div`
  border-left: 1px solid ${getStyledThemeColor('gray400')};
  padding-left: 10px;
  height: 100%;

  .nav-link {
    padding: 0 !important;
    display: flex;
    height: 100%;
    align-items: flex-end !important;
    justify-content: space-between;
  }
`;

const User = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #263238;
  color: ${getStyledThemeColor('white')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
  padding-top: 2px;
  border: 2px solid ${getStyledThemeColor('white')};
`;

const HeaderUser = styled(User)`
  height: 44px;
  width: 44px;
  margin-left: 0;
`;

const Email = styled.div`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.0015em;
  color: #90a4ae;
  transition: color 0.15s ease-in-out;
  position: relative;
  text-transform: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const UserName = styled.div`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.0015em;
  color: #263238;
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
