import { schema } from 'normalizr';

export const SPACE_SCHEMA = new schema.Entity(
  'spaces',
  {},
  {
    // Convert path into array of integers instead of string ("4.1.3" => [4,1,3])
    processStrategy: (space) => {
      const path =
        space.path === '' ? [] : space.path.split('.').map((i) => parseInt(i));
      return { ...space, path };
    },
  }
);

export const USER_SCHEMA = new schema.Entity('users');

export const DEVICE_SCHEMA = new schema.Entity('devices');

export const INCIDENT_SCHEMA = new schema.Entity('incidents');

export const DEVICE_MODELS_SCHEMA = new schema.Entity('device_models');

export const TICKET_SCHEMA = new schema.Entity('tickets');

export const STATE_DUMP_SCHEMA = new schema.Entity('state_dumps');

export const LICENSE_SCHEMA = new schema.Entity('licenses', {
  device: DEVICE_SCHEMA,
});

export const PARTNER_LICENSE_SCHEMA = new schema.Entity('licenses');

export const INTEGRATIONS_SCHEMA = new schema.Entity(
  'integrations',
  {},
  {
    idAttribute: 'name',
  }
);

export const ORGANIZATIONS_SCHEMA = new schema.Entity('organizations');

export const FILE_INFOS_SCHEMA = new schema.Entity('file_infos');

export const RULES_SCHEMA = new schema.Entity('rules');

export const COMMANDS_SCHEMA = new schema.Entity('commands');

export const REFERRAL_CODES_SCHEMA = new schema.Entity(
  'referral_codes',
  {},
  {
    idAttribute: 'referral_code',
  }
);

export const AUTHORIZED_USERS_SCHEMA = new schema.Entity('authorized_users');

export const TICKET_DEVICE_SCHEMA = new schema.Entity(
  'ticket_device',
  {},
  {
    idAttribute: 'ticket_id',
  }
);

export const PRODUCTS_SCHEMA = new schema.Entity('products');

export const PARTNER_PACKAGES_SCHEMA = new schema.Entity('partner_packages');

export const BILLING_INFO_SCHEMA = new schema.Entity('billing_info');

export const SUBSCRIPTION_SCHEMA = new schema.Entity('subscriptions');

export const SYSTEM_ISSUES_SCHEMA = new schema.Entity('system_issues');
