import { Box, Stack } from '@mantine/core';
import { filter, isEmpty, keys, size } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useUpdateEffect } from 'react-use';

import { SmartTableProps } from '@portals/types/Table';

import {
  NoDataState,
  NoFiltersResultsState,
  NoSearchResultsState,
} from '../components/data-states';
import Footer from '../components/Footer';
import Header from '../components/Header';
import TableScrollWrapper from '../components/TableScrollWrapper';
import TBody from '../components/TBody';
import THead from '../components/THead';
import { useTableInstance } from '../context';

function SmartTable<TData extends object>({
  onSelected,
  name,
  noExport = false,
  noDataIndication,
  noSort = false,
  noFilters,
  noColumnsSelection = false,
  noHeader = false,
  isCompact = false,
  additionalActions,
  selectedItemsActions,
  expandRow,
  rowMenu,
  menuProps = {},
}: Omit<SmartTableProps<TData>, 'keyField' | 'data' | 'columns'>) {
  const instance = useTableInstance();
  const { data, rows, state, page } = instance;

  useUpdateEffect(() => {
    if (!onSelected) return;

    const toggledIndexes = filter(
      (index) => instance.state.selectedRowIds[index],
      keys(instance.state.selectedRowIds)
    );

    onSelected(toggledIndexes);
  }, [instance.state.selectedRowIds]);

  const content = useMemo(() => {
    if (isEmpty(data)) {
      return (
        <>
          <TableScrollWrapper isCompact={isCompact}>
            <div />
            <div />
          </TableScrollWrapper>

          <NoDataState {...noDataIndication} />
        </>
      );
    }

    if (isEmpty(rows) && !!state.globalFilter) {
      return (
        <>
          <TableScrollWrapper isCompact={isCompact}>
            <THead<TData>
              noSort={noSort}
              noFilters={noFilters}
              isCompact={isCompact}
            />

            <div />
          </TableScrollWrapper>

          <NoSearchResultsState />
        </>
      );
    }

    if (isEmpty(rows)) {
      return (
        <>
          <TableScrollWrapper isCompact={isCompact}>
            <THead<TData>
              noSort={noSort}
              noFilters={noFilters}
              isCompact={isCompact}
            />

            <div />
          </TableScrollWrapper>

          <NoFiltersResultsState />
        </>
      );
    }

    return (
      <>
        <TableScrollWrapper isCompact={isCompact}>
          <THead<TData>
            noSort={noSort}
            noFilters={noFilters}
            isCompact={isCompact}
          />

          <TBody<TData>
            expandRow={expandRow}
            rowMenu={rowMenu}
            isCompact={isCompact}
            data={page}
            menuProps={menuProps}
          />
        </TableScrollWrapper>

        <Footer isCompact={isCompact} totalCount={size(rows)} />
      </>
    );
  }, [
    data,
    rows,
    state,
    isCompact,
    noSort,
    noFilters,
    expandRow,
    rowMenu,
    page,
    noDataIndication,
    menuProps,
  ]);

  return (
    <Stack
      className="smart-table-container"
      spacing={0}
      sx={{
        height: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateRows: noHeader ? '1fr' : 'max-content 1fr',
        position: 'relative',
      }}
    >
      {noHeader ? null : (
        <Box className="header-container" mb="md">
          <Header<TData>
            name={name}
            noExport={noExport}
            noColumnsSelection={noColumnsSelection}
            additionalActions={additionalActions}
            selectedItemsActions={selectedItemsActions}
            withGlobalSearch
            isCompact={isCompact}
          />
        </Box>
      )}

      {content}
    </Stack>
  );
}

export default SmartTable;
