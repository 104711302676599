import { Box, createStyles } from '@mantine/core';
import React, { FC, useCallback, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

interface TableScrollWrapperProps {
  isCompact?: boolean;
}

const TableScrollWrapper: FC<TableScrollWrapperProps> = ({
  children,
  isCompact,
}) => {
  const { cx, classes } = useStyles(isCompact);
  const [isScrolled, setIsScrolled] = useState(false);

  const renderView = useCallback(
    (props) => (
      <Box
        {...props}
        className={cx('table-scroll-container', classes.container, {
          'with-shadow': isScrolled,
        })}
      />
    ),
    [classes.container, cx, isScrolled]
  );

  const onScrollFrame = useCallback(
    (scrollState) => {
      if (scrollState.scrollLeft > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    },
    [setIsScrolled]
  );

  return (
    <Scrollbars
      onScrollFrame={onScrollFrame}
      key="table-scroll-wrapper"
      renderView={renderView}
    >
      {children}
    </Scrollbars>
  );
};

const useStyles = createStyles((theme, isCompact: boolean) => ({
  container: {
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    border: isCompact ? 'none' : `1px solid ${theme.colors.gray[2]}`,
    borderBottom: 'none',
    borderRadius: isCompact ? 0 : theme.radius.md,
    backgroundColor: theme.white,
    padding: isCompact ? '0' : `0px ${theme.spacing.md}px`,

    '&.with-shadow': {
      '.sticky': {
        boxShadow: `8px 0px 9px rgba(0, 0, 0, 0.07)`,
      },
    },
  },
}));

export default TableScrollWrapper;
