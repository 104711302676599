import { InputWrapper } from '@mantine/core';
import React from 'react';
import { FC } from 'react';

import { FieldRendererProps } from '@portals/ui/AutoFormik';

const CustomField: FC<FieldRendererProps> = (props) => {
  const { field, error, required } = props;
  const Component = field.component;

  return (
    <InputWrapper label={props.title} error={error} required={required}>
      <Component {...props} />
    </InputWrapper>
  );
};

export default CustomField;
