import { Box, createStyles, Group, MenuProps } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { map } from 'lodash/fp';
import React, { useMemo } from 'react';

import { ExpandRowType, RowType, SmartTableProps } from '@portals/types/Table';
import { ROW_HEIGHT } from '@portals/ui/Table';

import ExpandRow from './ExpandRow';
import RowMenu from './RowMenu';
import TCell from './TCell';

interface TRowProps<TData extends object> {
  row: RowType<TData>;
  expandRow: ExpandRowType<TData>;
  rowMenu?: SmartTableProps['rowMenu'];
  isCompact?: boolean;
  menuProps?: Omit<MenuProps, 'children'>;
}

function TRow<TData extends object>({
  row,
  expandRow,
  rowMenu,
  isCompact = false,
  menuProps = {},
}: TRowProps<TData>) {
  const { cx, classes } = useStyles({
    isSelected: row.isSelected,
    isExpanded: row.isExpanded,
    isCompact,
  });
  const { hovered, ref } = useHover();

  const rowProps = row.getRowProps();
  const hasExpandRow = useMemo(() => {
    if (!expandRow) return false;
    else if (expandRow.isEnabled) return expandRow.isEnabled(row);

    return true;
  }, [expandRow, row]);

  const cells = useMemo(
    () =>
      map(
        (cell) => <TCell key={cell.getCellProps().key} cell={cell} />,
        row?.cells
      ),
    [row?.cells]
  );

  return (
    <Box
      {...rowProps}
      className={cx('trow-wrapper', classes.rowWrapper, {
        expanded: hasExpandRow && row.isExpanded,
      })}
    >
      <Group
        noWrap
        ref={ref}
        spacing={0}
        className={cx('trow', classes.row)}
        title=""
        {...(hasExpandRow ? row?.getToggleRowExpandedProps() : {})}
      >
        {cells}

        {rowMenu ? (
          <RowMenu<TData> isHovered={hovered} row={row} menuProps={menuProps}>
            {rowMenu}
          </RowMenu>
        ) : null}
      </Group>

      {hasExpandRow ? <ExpandRow expandRow={expandRow} row={row} /> : null}
    </Box>
  );
}

const useStyles = createStyles<
  'rowWrapper' | 'row',
  { isExpanded: boolean; isSelected: boolean; isCompact: boolean }
>((theme, { isExpanded, isSelected, isCompact }) => ({
  rowWrapper: isExpanded
    ? {
        display: 'grid !important',
        gridTemplateRows: `${ROW_HEIGHT}px max-content`,
      }
    : {
        '&:not(:last-of-type)': {
          borderBottom: isCompact
            ? 'none'
            : `1px solid ${theme.colors.gray[3]}`,
        },
      },
  row: {
    width: '100%',
    height: ROW_HEIGHT,
    position: 'relative',
    transition: 'background-color 0.15s ease-in-out',

    ...(isSelected
      ? {
          backgroundColor: theme.fn.rgba(theme.colors.blue[0], 0.3),
        }
      : {
          backgroundColor: theme.white,

          '&:hover': {
            backgroundColor: theme.fn.rgba(theme.colors.gray[0], 0.5),
          },
        }),
  },
}));

export default TRow;
