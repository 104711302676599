import { Store } from 'redux';

import { CurrencyCode } from '@portals/framework/constants';

import {
  PackageType,
  DeviceType,
  OrganizationIncidentType,
  PartnerConfigType,
  SpaceType,
  TelemetryType,
  IntegrationType,
  FeaturesType,
  DataOrganizationType,
  NotificationType,
  UserType,
  SystemIssuesType,
  DeviceModelType,
  TicketType,
  LicenseType,
  StatsType,
  ModelProductType,
  UuidType,
  ReferralCodeType,
  RuleType,
  DashboardLayout,
  NotificationsType,
  PortalConfigType,
  PeriodEnum,
  TenantType,
  SubscriptionType,
  AccessType,
  IntegrationsExtraZoomType,
  IntegrationExtraQSysType,
  IntegrationExtraBrightSignType,
  StateDumpType,
  ConfigPartnerType,
  ConfigOrganizationType,
  OrganizationFileType,
  PartnerFileType,
  PaymentMethodEnum,
} from './common';

export interface SidebarType {
  isOpen: boolean;
  isSticky: boolean;
}

export enum AuthorizedUserEnum {
  User = 'user',
  Group = 'group',
}

export interface AuthorizedUserType {
  name: string;
  type: AuthorizedUserEnum;
  organization: string;
  email?: string;
  notes: string;
  id: string;
}

export interface CommandType {
  id: string;
  status: string;
  name: string;
  params: any;
  message: any;
  device_id: string;
  created_at: string;
}

export type DataType = Record<string, unknown> & {
  access: AccessType;
  authorized_users: Array<AuthorizedUserType>;
  cartPreview: Record<string, any>; // ORGANIZATION ONLY
  commands: Record<string, CommandType>;
  config: ConfigType;
  counters: Record<string, number>;
  dev?: {
    auth?: {
      access_key?: string;
      hub_url?: string;
    };
  };
  file_infos: Record<string, OrganizationFileType | PartnerFileType>;
  devices: Record<string, DeviceType>;
  incidents: Record<string, OrganizationIncidentType>;
  integrations: {
    email?: IntegrationType;
    cisco_teams?: IntegrationType;
    zoom?: IntegrationType<IntegrationsExtraZoomType>;
    bright_sign?: IntegrationType<IntegrationExtraBrightSignType>;
    q_sys?: IntegrationType<IntegrationExtraQSysType>;
  };
  licenses: Record<string, LicenseType>;
  subscriptions: Record<string, SubscriptionType>;
  organizations: Record<string, DataOrganizationType>;
  partner_packages: Record<string, ModelProductType>;
  products: Record<string, ModelProductType>;
  portal_config?: PortalConfigType; // PARTNER ONLY
  referral_codes: Record<string, ReferralCodeType>;
  rules: Record<string, RuleType>;
  spaces: Record<string, SpaceType>;
  stats: StatsType;
  device_models: Record<string, DeviceModelType>;
  system_issues: Record<string, SystemIssuesType>;
  telemetries: Record<string, TelemetryType>;
  tickets: Record<string, TicketType>;
  users: Record<string, UserType>;
  welcome_details: WelcomeDetailsType;
  state_dumps?: Record<string, StateDumpType>;
};

export interface ConfigType {
  [TenantType.Organization]?: ConfigOrganizationType;
  [TenantType.Partner]?: ConfigPartnerType;
  server: {
    features?: FeaturesType | Record<string, any>;
    notifications?: NotificationsType;
  };
  user: {
    dashboard: DashboardLayout;
  };
}

export interface WelcomeDetailsType {
  name: string;
  email: string;
  tenant_name: string;
}

export interface StoreStateType {
  currency: CurrencyCode;
  cart: Record<string, Record<PeriodEnum, number>>;
  creditCardId: null | string;
  billingAddressId: null | string;
  shippingAddressId: null | string;
  isSameAddress: boolean;
  paymentMethod: PaymentMethodEnum;
}

export interface StripeInfoSetupIntentType {
  client_secret: string;
}

export interface AuthType {
  client: string;
  token: string;
  uid: string;
  expiry: string;
  change?: boolean;
  global_admin?: boolean;
  org_admin?: boolean;
  confirmed_at: string;
  partner_admin?: boolean;
  id: string;
  access: Array<UuidType>;
  name: string;
  email: string;
  tenant: {
    type: TenantType;
    name: string;
    display_name: string;
    id: string;
  };
}

export interface UIType {
  auth: AuthType | null;
  notifications: Array<NotificationType>;
  network?: Record<string, boolean>;
  authError?: string;
  partnerConfig?: PartnerConfigType;
}

export interface ToastrType {
  toastrs: Array<Record<string, any>>;
  confirm: any;
}

export interface ShopType {
  selectedPackage: PackageType | null;
}

export interface StateType {
  sidebar: SidebarType;
  layout: { isBoxed: boolean };
  ui: UIType;
  data: DataType;
  toastr: ToastrType;
  modals: Record<string, any>;
  errors: Record<string, string>;
  store: StoreStateType;
}

export type StoreType = Store<StateType>;

export interface AddProductToCartPayload {
  productId: string;
  period: PeriodEnum;
  quantity: number;
}
