import React from 'react';
import ReactDOM from 'react-dom';

import {
  ExtraLayoutType,
  ModalsType,
  PreloadDataType,
  RoutesMap,
  TenantType,
} from '@portals/types';
import { checkMobileRedirect, initSentry } from '@portals/utils';

import App from './App';
import ErrorBoundary from './Error';
import modals from './modals';
import './external/google_maps';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const start = (
  routes: RoutesMap,
  customModals: ModalsType = {},
  preloadData: PreloadDataType,
  tenantType: TenantType,
  extraLayout?: ExtraLayoutType
) => {
  initSentry();
  checkMobileRedirect(tenantType);

  const params = new URLSearchParams(document.location.search);
  const existingAuth = params.get('auth');

  if (existingAuth) {
    try {
      // Parsing first to catch errors
      const auth = JSON.parse(atob(existingAuth));

      localStorage.setItem('auth', JSON.stringify(auth));

      window.location.assign('/');
    } catch (err) {
      console.error(err);
    }
  }

  if (process.env.NX_GIT_LATEST_COMMIT_SHA) {
    console.log(
      `Current portal version: ${process.env.NX_GIT_LATEST_COMMIT_SHA}`
    );
  }

  ReactDOM.render(
    <ErrorBoundary>
      <App
        routes={routes}
        modals={{ ...modals, ...customModals }}
        preloadData={preloadData}
        tenantType={tenantType}
        extraLayout={extraLayout}
      />
    </ErrorBoundary>,
    document.getElementById('root')
  );
};

export default start;
