import { ButtonStylesNames, ButtonStylesParams } from '@mantine/core';

import { ComponentStyleOverrideFn } from './common-types';

const commonButtonStyle = {
  label: {
    fontWeight: 400,
  },
};

export const buttonsStyle: ComponentStyleOverrideFn<
  ButtonStylesNames,
  ButtonStylesParams
> = (theme, params) => {
  const commonStyles = {
    fontWeight: 400,
  };

  if (params.size === 'md') {
    return {
      root: {
        ...commonStyles,
        height: 40,
      },
      label: {
        fontSize: theme.fontSizes.sm,
        ...commonButtonStyle,
      },
    };
  } else if (params.size === 'sm') {
    return {
      root: {
        ...commonStyles,
        height: 34,
      },
      label: {
        fontSize: theme.fontSizes.sm,
        ...commonButtonStyle,
      },
    };
  } else if (params.size === 'xs') {
    return {
      root: {
        ...commonStyles,
        height: 30,
        ...commonButtonStyle,
      },
    };
  }

  return commonButtonStyle;
};
