import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader } from 'reactstrap';

import { createGroup } from '@portals/redux/actions/groups';
import AutoFormik from '@portals/ui/AutoFormik';

type TableProps = {
  closeMe: () => void;
  createGroup: (name: string, extraActions?) => void;
  adding: boolean;
};

const FIELDS = [{ name: 'name', title: 'Group Name', required: true }];

const CreateGroup: FC<TableProps> = ({ closeMe, createGroup, adding }) => (
  <Modal isOpen={true} toggle={closeMe} centered>
    <ModalHeader toggle={closeMe}>Add Access</ModalHeader>

    <AutoFormik
      fields={FIELDS}
      handleSubmit={({ name }) => createGroup(name, closeMe)}
      modal={closeMe}
      inProgress={adding}
      submitTitle="Create"
    />
  </Modal>
);

const mapStateToProps = (state) => ({
  users: state.data.users,
  adding: state.ui.network['createGroup'],
});

export default connect(mapStateToProps, { createGroup })(CreateGroup);
