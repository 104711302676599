import { default as Form, IdSchema } from '@rjsf/core';
import metaSchema04 from 'ajv/lib/refs/json-schema-draft-04.json';
import metaSchema06 from 'ajv/lib/refs/json-schema-draft-06.json';
import React, { useCallback, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { Button } from 'reactstrap';
import styled from 'styled-components';

import { PendingOverlay } from '@portals/ui';

import { MessageTypeEnum } from '../types';
import ArrayFieldTemplate from './theme/ArrayFieldTemplate';
import CustomCheckbox from './theme/CustomCheckbox';
import ObjectFieldTemplate from './theme/ObjectFieldTemplate';

const WIDGETS = {
  CheckboxWidget: CustomCheckbox,
};

const UI_SCHEMA = {
  'ui:ObjectFieldTemplate': ObjectFieldTemplate,
  'ui:ArrayFieldTemplate': ArrayFieldTemplate,
};

interface FormConfig {
  schema: IdSchema;
  formData: string;
}

export function IframeJsonSchema() {
  const [formConfig, setFormConfig] = useState<FormConfig>({
    schema: null,
    formData: null,
  });

  const onSubmit = useCallback((formData) => {
    window.parent.postMessage(
      {
        type: MessageTypeEnum.SubmitFormData,
        formData,
      },
      '*'
    );
  }, []);

  const onMessage = useCallback((e) => {
    const { type } = e.data;

    switch (type) {
      case MessageTypeEnum.InitFormConfig: {
        const { schema, formData } = e.data;

        setFormConfig({ schema, formData });
      }
    }
  }, []);

  useEffectOnce(function registerMessagesListener() {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  });

  return (
    <Container>
      {!formConfig.schema ? (
        <StyledPendingOverlay isVisible={true} />
      ) : (
        <Form
          schema={formConfig.schema}
          formData={formConfig.formData}
          widgets={WIDGETS}
          uiSchema={UI_SCHEMA}
          additionalMetaSchemas={[metaSchema06, metaSchema04]}
          ArrayFieldTemplate={ArrayFieldTemplate}
          onSubmit={({ formData }) => onSubmit(formData)}
        >
          <div className="footer">
            <Button type="submit" color="primary" size="lg">
              Save Changes
            </Button>
          </div>
        </Form>
      )}

      <div className="placeholder" />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  background-color: white;
  padding: 20px 25px;
  display: grid;
  grid-template-rows: 1fr 20px;
  height: 100%;

  form {
    background-color: white;
    height: 100%;

    .panel {
      border: none;
      margin-bottom: 20px;

      .panel-title {
        border: none;
      }
    }

    > .form-group {
      border: 1px solid #e9ecef;
      margin-bottom: 0;

      .property-wrapper {
        padding: 10px 20px 0 0;

        #root__title {
          font-size: 20px;
          margin-bottom: 15px;
          border-bottom: 1px solid #e9ecef;
          padding-bottom: 10px;
          font-weight: bold;
        }

        label.control-label {
          font-size: 15px;
          color: #354052 !important;
          font-weight: bold;
        }

        .field-description {
          color: #6c757d;
        }
      }
    }

    > .footer {
      background-color: #f8f9fa;
      display: flex;
      align-items: center;
      height: 60px;
      padding-right: 20px;
      border: 1px solid #e9ecef;
      border-top: none;
      justify-content: flex-end;
    }
  }

  .placeholder {
    width: 100%;
    height: 20px;
  }
`;

const StyledPendingOverlay = styled(PendingOverlay)`
  width: 100%;
  height: 100%;
`;

export default IframeJsonSchema;
