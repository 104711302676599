import { getOr } from 'lodash/fp';
import { useSelector } from 'react-redux';

import { useAppConfig } from '@portals/framework/context';
import { getUser } from '@portals/redux';
import { TenantType, UuidType } from '@portals/types';

export enum UserPermissionFlagTypes {
  USER_PERMISSION_CREATE_PARTNER = 'create_partners',
}

export const useUserHasPermission = (
  userId: UuidType,
  flagKey: UserPermissionFlagTypes
) => {
  const user = useSelector(getUser(userId));

  return getOr(false, ['shared_settings', 'permissions', flagKey], user);
};

export const useUserIsAdmin = (userId: UuidType) => {
  const user = useSelector(getUser(userId));

  return !!user?.global_admin;
};

export const useUserCanManage = (userId: UuidType) => {
  const { tenantType } = useAppConfig();
  const user = useSelector(getUser(userId));
  const isLocalAdmin =
    tenantType === TenantType.Organization
      ? user.org_admin
      : user.partner_admin;
  return !!(user.global_admin || isLocalAdmin);
};
