import { Box, Button, createStyles, Divider, Group } from '@mantine/core';
import { isEmpty, map, size, take } from 'lodash/fp';
import React, { FC, useMemo } from 'react';

import { OverflowGroup } from '@portals/core';
import { Danger, InfoCircle, Refresh, Trash } from '@portals/icons/linear';
import { useIsPending, useOpenModal } from '@portals/redux';
import {
  DeviceModelType,
  DeviceType,
  FeaturesType,
  SupportedCommandType,
} from '@portals/types';
import { ModalButton } from '@portals/ui';

import MoreCommands from './MoreCommands';

const allowTickets = (
  model: DeviceModelType,
  features?: FeaturesType | Record<string, never>
) => {
  if (features?.tickets === false) {
    return false;
  }

  return !!model && model.allow_tickets;
};

interface CommandButtonsProps {
  device: DeviceType;
  model: DeviceModelType;
  features?: FeaturesType | Record<string, never>;
  updateDeviceData: () => void;
  management: boolean;
  getCommandParams: (command: SupportedCommandType) => any;
  supportedCommands: Array<SupportedCommandType>;
  refreshIndicator: string | Array<string>;
}

const MAX_NUM_OF_COMMANDS = 5;

const CommandButtons: FC<CommandButtonsProps> = ({
  device,
  model,
  features,
  updateDeviceData,
  management,
  getCommandParams,
  supportedCommands = [],
  refreshIndicator,
}) => {
  const openModal = useOpenModal();
  const { classes } = useStyles();

  const isPending = useIsPending(refreshIndicator);
  const withTickets = allowTickets(model, features);

  const commands = isEmpty(supportedCommands) ? null : (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridAutoColumns: 'max-content',
        gridAutoFlow: 'column',
        columnGap: theme.spacing.xs,
        overflow: 'hidden',
        width: '100%',
        maxWidth: 'fit-content',
      })}
    >
      {map(
        (command) => (
          <ModalButton
            key={command.id}
            modalName="RunCommand"
            variant="outline"
            label={command.friendly_name}
            data={getCommandParams(command)}
          />
        ),
        take<SupportedCommandType>(MAX_NUM_OF_COMMANDS, supportedCommands)
      )}
    </Box>
  );

  return (
    <Box py="sm" px="md" className={classes.container}>
      <Box className={classes.commandsWrapper}>
        <OverflowGroup
          isAlwaysRenderOverflow
          overflowRenderer={({ hiddenIndexes, amount }) => (
            <MoreCommands
              key={amount}
              maxNumOfCommands={MAX_NUM_OF_COMMANDS}
              hiddenIndexes={hiddenIndexes}
              supportedCommands={supportedCommands}
              onOpenCommandModal={(command) =>
                openModal('RunCommand', getCommandParams(command))
              }
            />
          )}
        >
          {commands}
        </OverflowGroup>
      </Box>

      <Group noWrap position="right" spacing="xs">
        {management && (
          <>
            <ModalButton
              variant="default"
              modalName="ReportIncident"
              label="Report Incident"
              leftIcon={<Danger />}
              data={device}
            />

            {withTickets && (
              <ModalButton
                variant="default"
                modalName="CreateTicket"
                label="Open Ticket"
                leftIcon={<InfoCircle />}
                data={device}
              />
            )}
          </>
        )}

        <Button
          onClick={updateDeviceData}
          variant="outline"
          loading={isPending}
          size="xs"
          sx={{
            svg: {
              width: 16,
              height: 16,
            },
          }}
          leftIcon={<Refresh />}
        >
          Refresh
        </Button>

        {management ? (
          <ModalButton
            variant="outline"
            modalName="DeleteDevice"
            label="Delete"
            color="red"
            leftIcon={<Trash />}
            data={device}
          />
        ) : null}
      </Group>
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
    columnGap: theme.spacing.md,
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
  },
  commandsWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    columnGap: theme.spacing.xs,
    alignItems: 'center',
  },
}));

export default CommandButtons;
