import { Checkbox, Stack, Button } from '@mantine/core';
import { every, keys, set, some } from 'lodash/fp';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { useFeatures } from '@portals/redux';

enum CheckboxStatus {
  Checked = 'checked',
  Empty = 'empty',
  Indeterminate = 'indeterminate',
}

type FeatureList = Array<{ id: string; label: string }>;

export interface FeatureFlagsModalProps {
  closeMe: () => void;
  featuresToDisplay: FeatureList;
  allFeatures: Record<string, any>;
  onSubmit: (updatedFeatures: ReturnType<typeof useFeatures>) => void;
}

const FeatureFlagsModal: FC<FeatureFlagsModalProps> = ({
  closeMe,
  onSubmit,
  allFeatures,
  featuresToDisplay,
}) => {
  // We need this because the indeterminate attr of the "Select all" checkbox
  // coming from the change event is not updated for some reason.
  // So we're using this ref to keep track of it's correct status.
  const isIndeterminate = useRef(false);

  // Using a useState and not useRef because during the first run
  // of the effect that updates the "Select all" checkbox the ref is not ready yet.
  const [selectAllCheckboxRef, setSelectAllCheckboxRef] =
    useState<HTMLInputElement | null>(null);

  const [updatedFeatures, setUpdatedFeatures] = useState(
    mapRelevantFeatures(allFeatures, featuresToDisplay)
  );

  const updateFeature = (name: string) =>
    setUpdatedFeatures((currFeatures) =>
      set(name, !currFeatures[name], currFeatures)
    );

  const onSubmitHandler = () => {
    onSubmit(updatedFeatures);
    closeMe();
  };

  const onChangeSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    let finalChecked;

    if (!isIndeterminate.current) finalChecked = checked;
    else finalChecked = false;

    const newUpdatedFeatures = keys(updatedFeatures).reduce(
      (acc, featureName) => {
        acc[featureName] = finalChecked;
        return acc;
      },
      {}
    );

    setUpdatedFeatures(newUpdatedFeatures);
  };

  useEffect(
    function updateSelectAllCheckboxStatus() {
      if (selectAllCheckboxRef === null) {
        return;
      }

      const checkboxStatus = calcSelectAllCheckboxStatus(updatedFeatures);

      if (checkboxStatus === CheckboxStatus.Checked) {
        selectAllCheckboxRef.checked = true;
        selectAllCheckboxRef.indeterminate = false;
      } else if (checkboxStatus === CheckboxStatus.Indeterminate) {
        selectAllCheckboxRef.checked = false;
        selectAllCheckboxRef.indeterminate = true;
      } else {
        selectAllCheckboxRef.checked = false;
        selectAllCheckboxRef.indeterminate = false;
      }

      isIndeterminate.current = selectAllCheckboxRef.indeterminate;
    },
    [selectAllCheckboxRef, updatedFeatures]
  );

  return (
    <Modal isOpen={true} toggle={closeMe} centered>
      <ModalHeader toggle={closeMe}>Feature Flags</ModalHeader>

      <ModalBody className="m-3">
        <Stack>
          <Checkbox
            ref={(el: HTMLInputElement) => setSelectAllCheckboxRef(el)}
            id="toggle_all"
            label="Select / Deselect all"
            checked={every(Boolean, updatedFeatures)}
            onChange={onChangeSelectAll}
          />

          {featuresToDisplay.map(({ id, label }) => (
            <Checkbox
              key={id}
              id={`toggle_${id}`}
              label={label}
              checked={updatedFeatures[id]}
              onChange={() => updateFeature(id)}
            />
          ))}
        </Stack>
      </ModalBody>

      <ModalFooter>
        <Button variant="white" onClick={closeMe}>
          Cancel
        </Button>

        <Button color="primary" onClick={onSubmitHandler}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

function mapRelevantFeatures(
  allFeatures: ReturnType<typeof useFeatures>,
  featuresToDisplay: FeatureList
): ReturnType<typeof useFeatures> {
  return featuresToDisplay.reduce((acc, feature) => {
    acc[feature.id] = allFeatures[feature.id];
    return acc;
  }, {});
}

function calcSelectAllCheckboxStatus(features: ReturnType<typeof useFeatures>) {
  if (every(Boolean, features)) {
    return CheckboxStatus.Checked;
  } else if (some(Boolean, features)) {
    return CheckboxStatus.Indeterminate;
  } else {
    return CheckboxStatus.Empty;
  }
}

export default FeatureFlagsModal;
