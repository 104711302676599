import { Button, Grid, Group, Modal, Stack } from '@mantine/core';
import React, { PropsWithChildren, useState } from 'react';
import { FilterProps, UseFiltersColumnProps } from 'react-table';

import DateInputGroup from './DateInputGroup';
import DateRangePresets from './DateRangePresets';
import RangeCalendar from './RangeCalendar';
import { DateRangeType } from './types';

interface SelectFilterProps<TData extends object> {
  closeMe: () => void;
  data: {
    column: FilterProps<TData>['column'] & UseFiltersColumnProps<TData>;
    options: Record<string, string>;
  };
}

function SelectFilter<TData extends object>({
  closeMe,
  data,
}: PropsWithChildren<SelectFilterProps<TData>>) {
  const { column } = data;

  const [localDateRange, setLocalDateRange] = useState<DateRangeType>([
    column?.filterValue?.gte || new Date(),
    column?.filterValue?.lte || new Date(),
  ]);

  const onClear = () => {
    column.setFilter(null);
    closeMe();
  };

  const onSubmit = () => {
    column.setFilter({ gte: localDateRange[0], lte: localDateRange[1] });
    closeMe();
  };

  return (
    <Modal
      opened={true}
      onClose={closeMe}
      closeOnEscape
      size="lg"
      padding={0}
      title=""
      withCloseButton={false}
      styles={{
        modal: {
          width: 820,
        },
        body: {
          height: 420,
        },
      }}
    >
      <Grid gutter={0} sx={{ height: '100%' }}>
        <Grid.Col span={3}>
          <DateRangePresets onChange={setLocalDateRange} />
        </Grid.Col>

        <Grid.Col span={9}>
          <Stack
            align="center"
            justify="space-between"
            spacing={0}
            sx={{ height: '100%' }}
          >
            <RangeCalendar
              value={localDateRange}
              onChange={setLocalDateRange}
            />

            <Group
              position="apart"
              px="xl"
              sx={(theme) => ({
                width: '100%',
                borderTop: `1px solid ${theme.colors.gray[3]}`,
                height: 72,
                flexShrink: 0,
              })}
            >
              <DateInputGroup
                dateRange={localDateRange}
                setDateRange={setLocalDateRange}
              />

              <Group spacing="md">
                <Button
                  size="md"
                  variant="default"
                  color="blue_gray"
                  onClick={onClear}
                >
                  Clear Filters
                </Button>

                <Button size="md" disabled={false} onClick={onSubmit}>
                  Apply
                </Button>
              </Group>
            </Group>
          </Stack>
        </Grid.Col>
      </Grid>
    </Modal>
  );
}

export default SelectFilter;
