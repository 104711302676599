import { Button, Group, Modal, Stack, Text } from '@mantine/core';
import React from 'react';

const ConfirmationModal = ({ data, closeMe }) => {
  const {
    onApprove,
    onCancel,
    description,
    title = 'Please confirm',
    confirmationLabel = 'Confirm',
    cancelLabel = 'Cancel',
  } = data;

  const onToggle = () => {
    onCancel();
    closeMe();
  };

  return (
    <Modal
      opened={true}
      onClose={onToggle}
      title={title}
      padding={32}
      styles={(theme) => ({
        modal: {
          borderRadius: theme.radius.md,
          width: 560,
        },
      })}
    >
      <Stack>
        <Text size="sm">{description}</Text>

        <Group position="right" pt={32}>
          <Button
            variant="outline"
            color="gray"
            onClick={onToggle}
            px="xl"
            sx={(theme) => ({
              color: theme.colors.blue_gray[8],
            })}
          >
            {cancelLabel}
          </Button>

          <Button color="red" onClick={onApprove} px="xl">
            {confirmationLabel}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default ConfirmationModal;
