import { Box, createStyles } from '@mantine/core';
import { GeoJsonObject } from 'geojson';
import React, { FC } from 'react';

import { setRoute } from '@portals/redux/actions/routing';

interface OrganizationIncidentMarkerProps {
  item: GeoJsonObject;
  color: string;
}

export const OrganizationIncidentMarker: FC<
  OrganizationIncidentMarkerProps
> = ({ item, color }) => {
  const { classes } = useStyles(color);

  return (
    <Box
      className={classes.incidentMarkerContainer}
      onClick={() => item.properties.link && setRoute(item.properties.link)}
    >
      1
    </Box>
  );
};

const useStyles = createStyles((theme, color: string) => ({
  incidentMarkerContainer: {
    cursor: 'pointer',
    color: theme.white,
    borderRadius: '50%',
    border: `1px solid ${theme.white}`,
    padding: theme.spacing.xs,
    textAlign: 'center',
    width: 30,
    height: 30,
    backgroundColor: theme.colors[color][4],

    '&:hover': {
      borderColor: theme.colors.gray[4],
    },
  },
}));
