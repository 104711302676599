import { first, isEmpty } from 'lodash/fp';
import { useQuery, useQueryClient } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';

import { QueryOptions } from '@portals/types';

import { fetchApiRequest, useRequestOptions } from '../utils/common';

export function useApiQuery<T>(
  url: string,
  key: string | string[],
  queryOptions: QueryOptions<T> = {}
): UseQueryResult<T> {
  const { url: adjustedUrl, options } = useRequestOptions({
    url,
  });

  return useQuery(
    key,
    () => fetchApiRequest(adjustedUrl, options),
    queryOptions
  );
}

type CacheOptions<T> = {
  cacheKey: string | Array<string>;
  getEntityData: (data: any) => T;
  isEnabled: boolean;
};

type UseApiQueryWithCache<T> = {
  url: string;
  key: string | string[];
  queryOptions?: QueryOptions<T>;
  cacheOptions: CacheOptions<T>;
};

/*
 * Can be used for getting entity data from cache [if exists] before fetching via API.
 * The hooks has same signature as `useApiQuery`, with additional `cacheOptions` argument:
 *
 * {
 * - cacheKey: string | Array<string> - key(s) under which the cached data might be stored
 * - getEntityData: (data: any) => T - function to get entity data from the API response. Might be a
 *  paginated list, or a simple entities list.
 * - isEnabled: boolean - if true, will use cache data [if exists] before fetching via API. If
 *  false, will fetch it.
 * }
 *
 * Example:
 * - 1. Go to '/incidents' page -> we fetch paginated data of all incidents for the table.
 * - 2. Click on a specific incident -> the app takes us to '/incident/{id}'.
 * - 3. We first check whether this specific incident exists under [EntityEnum.Incidents,
 *      DataTypeEnum.List] cache key. If it does -> we set its data in cache, thus skipping the need
 *      to fetch this specific incident.
 * - 4. If incident doesn't exist in cache, we simply fetch it via API.
 *  */
export function useApiQueryWithCache<T>({
  url,
  key,
  queryOptions = {},
  cacheOptions,
}: UseApiQueryWithCache<T>): UseQueryResult<T> {
  const queryClient = useQueryClient();

  if (cacheOptions.isEnabled) {
    const cache = queryClient.getQueryCache();
    const entityCaches = cache.findAll(cacheOptions.cacheKey);

    if (!isEmpty(entityCaches)) {
      const entityCache = first(entityCaches);
      const data = entityCache?.state.data;
      const entityData = cacheOptions.getEntityData(data);

      if (entityData) {
        queryClient.setQueryData(key, entityData);
      }
    }
  }

  return useApiQuery(url, key, queryOptions);
}
