import { Box, createStyles, Group, Text } from '@mantine/core';
import { GeoJsonObject } from 'geojson';
import React, { FC } from 'react';

import { Danger } from '@portals/icons/bold';

interface PartnerIncidentMarkerProps {
  item: GeoJsonObject;
  color: string;
}

export const PartnerIncidentMarker: FC<PartnerIncidentMarkerProps> = ({
  item,
  color,
}) => {
  const { classes, theme } = useStyles();

  return (
    <Group
      className={classes.container}
      noWrap
      align="center"
      px="sm"
      spacing="xs"
    >
      <Box
        sx={{
          color: theme.colors[color][4],
        }}
      >
        <Danger />
      </Box>

      <Text size="xs" className={classes.incidentTitle}>
        {item.properties.title}
      </Text>
    </Group>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    height: 46,
    boxShadow: `0 13px 36px ${theme.fn.rgba(theme.colors.blue_gray[2], 0.2)}`,
    backgroundColor: theme.white,
    width: 'fit-content',
    borderRadius: 58,
    color: theme.colors.gray[7],
  },
  incidentTitle: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 100,
  },
}));
