import { getOr } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import ShoppingCart from 'react-feather/dist/icons/shopping-cart';
import { connect } from 'react-redux';
import { Button, Badge } from 'reactstrap';

import { setRoute } from '@portals/redux/actions/routing';

const countReducer = (acc: number, count: number) => acc + count;

const ShoppingCartIndicator = ({ type, cart, setRoute }) => {
  const counter = useMemo(
    () => Object.values(cart).reduce(countReducer, 0),
    [cart]
  );

  if (counter === 0) {
    return null;
  }

  return (
    <div className="cart">
      <Button
        outline
        color="secondary"
        onClick={() => setRoute(`/carts/${type}`)}
      >
        <ShoppingCart size={30} />
        <Badge color="secondary">{counter}</Badge>
      </Button>
    </div>
  );
};

ShoppingCartIndicator.propTypes = {
  type: PropTypes.string.isRequired,
};

const mapStateToProps = (state: object, ownProps) => ({
  cart: getOr({}, ['carts', ownProps.type], state),
});

export default connect(mapStateToProps, { setRoute })(ShoppingCartIndicator);
