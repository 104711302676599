import { MantineProviderProps } from '@mantine/core';

import { modalDefaultProps } from './modal';
import { stepperDefaultProps } from './stepper';

const DEFAULT_PROPS: MantineProviderProps['defaultProps'] = {
  Modal: modalDefaultProps,
  Stepper: stepperDefaultProps,
};

export default DEFAULT_PROPS;
