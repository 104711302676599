import { createStyles, Group } from '@mantine/core';
import { map } from 'lodash/fp';
import React, { useCallback } from 'react';

import { ColumnType } from '@portals/types';
import { COMPACT_ROW_HEIGHT, ROW_HEIGHT } from '@portals/ui/Table';

import { useTableInstance } from '../context';
import TH from './TH';

interface THeadProps {
  noFilters?: boolean;
  noSort?: boolean;
  isCompact?: boolean;
}

function THead<TData extends object>({
  noFilters = false,
  noSort = false,
  isCompact = false,
}: THeadProps) {
  const { classes, cx } = useStyles({ isCompact });
  const instance = useTableInstance<TData>();
  const { headerGroups } = instance;

  const headerCellRenderer = useCallback(
    (column: ColumnType<TData> & { isAction: boolean }) => (
      <TH
        column={column}
        key={column.id}
        noFilters={noFilters}
        noSort={noSort}
      />
    ),
    [noSort, noFilters]
  );

  return (
    <Group className={cx('thead', classes.container)}>
      {map(
        (headerGroup) => (
          <Group
            {...headerGroup.getHeaderGroupProps()}
            className="thead-cell"
            spacing={0}
            sx={{
              height: '100%',
            }}
          >
            {headerGroup.headers.map(headerCellRenderer)}
          </Group>
        ),
        headerGroups
      )}
    </Group>
  );
}

const useStyles = createStyles<'container', { isCompact: boolean }>(
  (theme, { isCompact }) => ({
    container: {
      width: '100%',
      position: 'sticky',
      top: 0,
      zIndex: 5,
      height: isCompact ? COMPACT_ROW_HEIGHT : ROW_HEIGHT,
      borderBottom: isCompact ? 'none' : `1px solid ${theme.colors.gray[3]}`,
      backgroundColor: isCompact ? theme.colors.gray[0] : theme.white,
    },
  })
);

export default THead;
