import { API } from '@portals/redux';
import { updateNormalizedData } from '@portals/redux/actions/data';
import { getUsers } from '@portals/redux/actions/users';
import { USER_SCHEMA } from '@portals/redux/schemas';

export const createGroup = (name: string, extraActions?) => ({
  type: API,
  payload: {
    url: 'ui/groups',
    data: { name },
    success: [getUsers(), extraActions],
    normalize: USER_SCHEMA,
    name: 'createGroup',
    withError: true,
    toastr: 'Group created',
  },
});

export const addGroupMember = (
  groupId: string,
  memberId: string,
  admin: boolean,
  extraActions?
) => ({
  type: API,
  payload: {
    url: `ui/groups/${groupId}/group_memberships`,
    data: { id: memberId, admin },
    success: [updateNormalizedData('users'), extraActions],
    normalize: USER_SCHEMA,
    name: 'addGroupMember',
    withError: true,
    toastr: 'Member added',
  },
});

export const removeGroupMember = (
  groupId: string,
  memberId: string,
  extraActions?
) => ({
  type: API,
  payload: {
    url: `ui/groups/${groupId}/group_memberships/${memberId}`,
    method: 'delete',
    success: [updateNormalizedData('users'), extraActions],
    normalize: USER_SCHEMA,
    name: 'removeGroupMember',
    withError: true,
    toastr: 'Member removed',
  },
});
