import { Box } from '@mantine/core';
import { isFunction } from 'lodash/fp';
import React, { useMemo } from 'react';

import { EXPANDED_ROW_HEIGHT, ExpandRowType, RowType } from '@portals/ui/Table';

interface ExpandRowProps<TData extends object> {
  expandRow: ExpandRowType<TData>;
  row: RowType<TData>;
}

function ExpandRow<TData extends object>({
  expandRow,
  row,
}: ExpandRowProps<TData>) {
  const isActive = expandRow && expandRow?.renderer && row.isExpanded;

  const height = useMemo(() => {
    if (!isActive) return 0;

    if (!expandRow.height) return EXPANDED_ROW_HEIGHT;
    else if (isFunction(expandRow.height)) return expandRow.height(row);
    return expandRow.height;
  }, [expandRow, isActive, row]);

  if (!isActive) return null;

  return (
    <Box
      pb="sm"
      className="expand-row-wrapper"
      sx={{
        height,
      }}
    >
      {expandRow.renderer(row?.original, row)}
    </Box>
  );
}

export default ExpandRow;
