import { getOr } from 'lodash/fp';
import React, { FC, useMemo } from 'react';
import { connect } from 'react-redux';
import { Badge } from 'reactstrap';

import { setRoute } from '@portals/redux/actions/routing';

const countReducer = (acc: number, count: number) => acc + count;

type TableProps = {
  cart: Map<string, string>;
};

const ShoppingCartCounter: FC<TableProps> = ({ cart }) => {
  const counter = useMemo(
    () => Object.values(cart).reduce(countReducer, 0),
    [cart]
  );

  if (counter === 0) {
    return null;
  }

  return (
    <Badge color="info" size={18} className="sidebar-badge">
      {counter}
    </Badge>
  );
};

const mapStateToProps = (state: Map<string, string>, ownProps) => ({
  cart: getOr({}, ['carts', ownProps.type], state),
});

export default connect(mapStateToProps, { setRoute })(ShoppingCartCounter);
