import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { fetchApiRequest, useApiQuery, useRequestOptions } from '@portals/api';
import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { TenantGroup, UserPermissions } from '@portals/types';

const QUERY_KEY = 'tenant-groups';

export function useTenantGroups() {
  return useApiQuery<TenantGroup[]>('ui/groups', QUERY_KEY);
}

export function useUpdateTenantGroupPermissions(groupId: string) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: `ui/groups/${groupId}/permissions`,
    method: 'PATCH',
  });

  return useMutation({
    mutationFn: (permissions: UserPermissions): Promise<TenantGroup> => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify(permissions),
      });
    },
    onSuccess: async () => {
      dispatch(toastrSuccess('Group permissions update successful'));
      await queryClient.invalidateQueries(QUERY_KEY);
    },
    onError: () => dispatch(toastrError('Group permissions update failed')),
  });
}
