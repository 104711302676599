import { API } from '@portals/redux';
import { replaceNormalizedData } from '@portals/redux/actions/data';
import { SYSTEM_ISSUES_SCHEMA } from '@portals/redux/schemas';
import { TenantType } from '@portals/types';

export const getSystemIssues = (
  tenantType: TenantType.Organization | TenantType.Partner
) => ({
  type: API,
  payload: {
    url: `ui/${tenantType}/system_issues`,
    success: replaceNormalizedData('system_issues'),
    normalize: [SYSTEM_ISSUES_SCHEMA],
    name: 'getSystemIssues',
  },
});
