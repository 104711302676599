import React, { FC } from 'react';
import Info from 'react-feather/dist/icons/info';
import { UncontrolledTooltip } from 'reactstrap';

const ChecksumAdornment: FC<{ docsUrl: string }> = ({ docsUrl }) => (
  <>
    <Info id="checksum-info" width="15" height="15" />
    <UncontrolledTooltip
      placement="bottom"
      target="checksum-info"
      autohide={false}
      delay={{ show: 0, hide: 500 }}
    >
      A hash used for data integrity. Check our{' '}
      <a href={docsUrl} target="_blank" rel="noopener noreferrer">
        docs
      </a>{' '}
      for more information.
    </UncontrolledTooltip>
  </>
);

const SignatureAdornment: FC<{ docsUrl: string }> = ({ docsUrl }) => (
  <>
    <Info id="signature-info" width="15" height="15" />
    <UncontrolledTooltip
      placement="bottom"
      target="signature-info"
      autohide={false}
      delay={{ show: 0, hide: 500 }}
    >
      A hash used for data authenticity. Check our{' '}
      <a href={docsUrl} target="_blank" rel="noopener noreferrer">
        docs
      </a>{' '}
      for more information.
    </UncontrolledTooltip>
  </>
);

export { ChecksumAdornment, SignatureAdornment };
