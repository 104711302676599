import { useEffect, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';

import type {
  CredentialResponse,
  GoogleType,
  GsiButtonConfiguration,
} from '../types/google-auth';

const CLIENT_ID = process.env.NX_GOOGLE_CLIENT_ID;

declare const window: Window & {
  google: GoogleType;
};

export const useGoogleSSOButton = (
  onSuccess: (response: CredentialResponse) => void,
  btnConfig?: Partial<GsiButtonConfiguration>
) => {
  const btnRef = useRef<HTMLDivElement>();
  const pollInterval = useRef<ReturnType<typeof setInterval>>();
  const [isGoogleAvailable, setIsGoogleAvailable] = useState(false);

  useEffectOnce(function checkGoogleAuthAvailability() {
    if (window.google) {
      setIsGoogleAvailable(true);
      return;
    }

    pollInterval.current = setInterval(() => {
      if (window.google) {
        setIsGoogleAvailable(true);

        clearInterval(pollInterval.current);
      }
    }, 100);
  });

  useEffect(
    function initializeGoogleAuth() {
      if (isGoogleAvailable) {
        window.google.accounts.id.initialize({
          client_id: CLIENT_ID,
          callback: onSuccess,
        });

        window.google.accounts.id.renderButton(btnRef.current, {
          type: 'standard',
          size: 'large',
          width: '400px',
          text: 'signin_with',
          ...(btnConfig || {}),
        });
      }
    },
    [btnConfig, isGoogleAvailable, onSuccess]
  );

  return btnRef;
};
