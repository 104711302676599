import React, { FC, useCallback, useRef } from 'react';
import { useEffectOnce } from 'react-use';
import styled from 'styled-components';

import { MessageTypeEnum } from './types';

interface JsonSchemaForm {
  schema: Record<string, any>;
  data: Record<string, any>;
  onSubmit: (formData: Record<string, any>) => void;
  deviceId: string;
}

const JSON_SCHEMA_APP_URL = process.env.NX_JSON_SCHEMA_APP_URL;

const JsonSchemaForm: FC<JsonSchemaForm> = ({
  schema,
  data = {},
  onSubmit,
}) => {
  const iframeRef = useRef(null);

  const onMessage = useCallback(
    (event) => {
      if (event.origin !== JSON_SCHEMA_APP_URL) return;

      const { type } = event.data;

      switch (type) {
        case MessageTypeEnum.SubmitFormData: {
          const { formData } = event.data;

          onSubmit(formData);
        }
      }
    },
    [onSubmit]
  );

  useEffectOnce(function subscribeToIframeMessages() {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  });

  const sendInitialFormConfig = useCallback(() => {
    if (!iframeRef.current) return;

    iframeRef.current.contentWindow.postMessage(
      {
        type: MessageTypeEnum.InitFormConfig,
        schema,
        formData: data,
      },
      JSON_SCHEMA_APP_URL
    );
  }, [data, schema]);

  const onRef = useCallback(
    (ref) => {
      if (!ref) return;

      iframeRef.current = ref;

      iframeRef.current.onload = () => {
        sendInitialFormConfig();
      };
    },
    [sendInitialFormConfig]
  );

  return (
    <Container>
      <Iframe ref={onRef} src={JSON_SCHEMA_APP_URL} />
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  overflow: hidden;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

export default JsonSchemaForm;
