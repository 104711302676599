import { ActionIcon, Box, createStyles } from '@mantine/core';
import { get } from 'lodash/fp';
import React, { FC } from 'react';

import { usePortalConfig } from '@portals/framework';
import { ArrowRight1 } from '@portals/icons/linear';
import {
  useConfig,
  useFeatures,
  useIsSidebarOpen,
  useOrganizationConfig,
} from '@portals/redux';

import { UserAvatar, UserName } from './common';

interface TenantUserProps {
  isActive: boolean;
  isDisabled?: boolean;
  logoSrc: string;
  label: string;
  isLab?: boolean;
  partner?: string;
}

const TenantUser: FC<TenantUserProps> = ({
  isActive,
  logoSrc,
  label,
  isLab = false,
  partner,
  isDisabled,
}) => {
  const { classes, cx } = useStyles(isActive);
  const isSidebarOpen = useIsSidebarOpen();

  return (
    <Box
      px="md"
      className={cx(classes.container, { [classes.hoverable]: !isDisabled })}
    >
      <UserAvatar logoSrc={logoSrc} isActive={isActive} />

      {isSidebarOpen ? (
        <>
          <UserName name={label} isLab={isLab} partner={partner} />

          {isDisabled ? null : (
            <ActionIcon className={cx(classes.toggle, 'tenant-menu-toggle')}>
              <ArrowRight1 />
            </ActionIcon>
          )}
        </>
      ) : null}
    </Box>
  );
};

const useStyles = createStyles((theme, isActive: boolean) => ({
  container: {
    height: '100%',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '44px 1fr 18px',
    gridColumnGap: theme.spacing.md,
  },
  hoverable: {
    '&:hover': {
      '.tenant-menu-avatar': {
        borderColor: theme.colors.blue_accent[4],
      },

      '.tenant-menu-toggle, .tenant-user-menu': {
        color: theme.colors.blue_accent[4],
      },
    },
  },
  toggle: {
    transition: 'color 0.1s ease-in-out',
    color: isActive ? theme.colors.blue_accent[4] : theme.colors.blue_gray[2],
    svg: {
      width: 18,
      height: 18,
    },
  },
}));

export const OrganizationUser: FC<
  Pick<TenantUserProps, 'isActive' | 'isDisabled'>
> = ({ isActive, isDisabled }) => {
  const features = useFeatures();
  const organization = useOrganizationConfig();

  return (
    <TenantUser
      isDisabled={isDisabled}
      isActive={isActive}
      logoSrc={features?.logo}
      label={organization.name}
      isLab={organization?.lab}
      partner={organization.partner_display_name}
    />
  );
};

export const PartnerUser: FC<
  Pick<TenantUserProps, 'isActive' | 'isDisabled'>
> = ({ isActive, isDisabled }) => {
  const { data } = usePortalConfig();
  const config = useConfig();

  return (
    <TenantUser
      isDisabled={isDisabled}
      isActive={isActive}
      logoSrc={data?.logo}
      label={get(['partner', 'display_name'], config)}
    />
  );
};
