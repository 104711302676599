import { Box, Indicator, Text, Tooltip, useMantineTheme } from '@mantine/core';
import React from 'react';

import { MessageQuestion } from '@portals/icons/linear';

import { useHubspotConversations } from '../../../external/HubspotConversationsProvider';

export const HubspotConversationsTrigger = () => {
  const { isEnabled, toggleWidget, isOpen, unreadMessagesCount } =
    useHubspotConversations();
  const theme = useMantineTheme();

  const iconColor = isOpen
    ? theme.other.primaryColor
    : theme.colors.blue_gray[6];

  const tooltipText = isOpen ? 'Hide Chat' : 'Chat with us';

  if (!isEnabled) {
    return null;
  }

  return (
    <Box mr="md" sx={{ position: 'relative', cursor: 'pointer' }}>
      <Indicator
        color="red_accent"
        disabled={unreadMessagesCount === 0}
        withBorder
        offset={2}
        size={12}
      >
        <Tooltip label={<Text size="xs">{tooltipText}</Text>}>
          <MessageQuestion color={iconColor} onClick={toggleWidget} />
        </Tooltip>
      </Indicator>
    </Box>
  );
};
