import { MenuStylesNames, MenuStylesParams } from '@mantine/core';

import { ComponentStyleOverrideFn } from './common-types';

export const menuStyles: ComponentStyleOverrideFn<
  MenuStylesNames,
  MenuStylesParams
> = (theme) => ({
  body: {
    padding: '4px',
    gap: 0,
  },
  item: {
    height: 40,
    padding: 0,
    color: theme.colors.blue_gray[9],
    fontWeight: 400,
    borderRadius: theme.radius.sm,
  },
  itemBody: {
    padding: `0px ${theme.spacing.md}px`,
    fontSize: theme.fontSizes.xs,
  },
  itemHovered: {
    backgroundColor: theme.colors.gray[1],
  },
});
