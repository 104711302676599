import { createStyles, Modal, ModalProps, Stack, Text } from '@mantine/core';
import { MantineTheme } from '@mantine/styles';
import { defaults } from 'lodash/fp';
import React, { FC } from 'react';

interface ModalCenteredMediaLayoutProps extends ModalProps {
  media: React.ReactNode;
  mediaSize?: number | string;
  description?: React.ReactNode;
  footer?: React.ReactNode;
}

export const ModalCenteredMediaLayout: FC<ModalCenteredMediaLayoutProps> = ({
  children,
  title,
  media,
  mediaSize = 96,
  description,
  footer,
  styles,
  ...modalProps
}) => {
  const { classes, theme } = useStyles(mediaSize);
  const mergeModalStyles = getMergedModalStyles(theme, modalStyles, styles);

  return (
    <Modal padding={0} styles={mergeModalStyles} {...modalProps}>
      <Stack spacing="md">
        <div className={classes.media}>{media}</div>
        {title ? <Text className={classes.title}>{title}</Text> : null}
        {description ? (
          <Text className={classes.description} size="sm">
            {description}
          </Text>
        ) : null}

        {children}

        {footer ? <footer className={classes.footer}>{footer}</footer> : null}
      </Stack>
    </Modal>
  );
};

const modalStyles: ModalProps['styles'] = () => ({
  header: {
    height: 0,
    margin: 0,
    padding: '28px 32px 0',
  },
  body: {
    padding: '0 32px 32px',
  },
});

const useStyles = createStyles((theme, mediaSize?: number | string) => ({
  media: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 4,

    '> *': {
      width: mediaSize,
      height: mediaSize,
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 400,
    textAlign: 'center',
  },
  description: {
    textAlign: 'center',
  },
  footer: {
    marginTop: theme.spacing.md,
  },
}));

function getMergedModalStyles(
  theme: MantineTheme,
  defaultStyles: ModalProps['styles'],
  overrideStyles: ModalProps['styles']
): ModalProps['styles'] {
  const finalDefaults =
    typeof defaultStyles === 'function' ? defaultStyles(theme) : defaultStyles;

  const finalOverrides =
    typeof overrideStyles === 'function'
      ? overrideStyles(theme)
      : overrideStyles;

  return defaults(finalDefaults, finalOverrides);
}
