import { Text, Tooltip } from '@mantine/core';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { castArray, first, isFunction } from 'lodash/fp';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { usePermissionAccess } from '@portals/framework';
import { useAppConfig } from '@portals/framework/context';
import { useTranslation } from '@portals/i18n';
import { useFeatures } from '@portals/redux';
import { CategoryRouteItem } from '@portals/types';
import { getStyledThemeColor } from '@portals/utils';

import { SidebarItem } from './SidebarItem';

interface SidebarSectionProps {
  headerId: CategoryRouteItem['headerId'];
  childRoutes: CategoryRouteItem['childRoutes'];
  isSidebarOpen: boolean;
}

export const SidebarSection: FC<SidebarSectionProps> = ({
  headerId,
  childRoutes,
  isSidebarOpen,
}) => {
  const t = useTranslation();
  const features = useFeatures();
  const { canView } = usePermissionAccess();
  const { tenantType } = useAppConfig();

  // check that there is at least one child route that is visible and accessible
  const shouldRenderSection = childRoutes.some(
    ({ canAccessRoute, isVisibleInSidebar }) => {
      const haveAccess =
        !canAccessRoute || canAccessRoute({ features, canView });
      const isVisible = !isVisibleInSidebar || isVisibleInSidebar(features);

      return haveAccess && isVisible;
    }
  );

  if (!shouldRenderSection) {
    return null;
  }

  return (
    <Container isSidebarOpen={isSidebarOpen}>
      {headerId ? (
        <motion.div layout="position">
          <div className={classNames('sidebar-header', tenantType)}>
            {t(headerId)}
          </div>
          <div className="sidebar-divider"></div>
        </motion.div>
      ) : null}

      {childRoutes.map((childRoute) => {
        const { canAccessRoute, isVisibleInSidebar } = childRoute;

        if (
          (canAccessRoute && !canAccessRoute({ features, canView })) ||
          (isVisibleInSidebar && !isVisibleInSidebar(features))
        ) {
          return null;
        }

        return (
          <Tooltip
            key={childRoute.id}
            sx={{ display: 'block' }}
            label={<Text size="xs">{t(childRoute.id)}</Text>}
            disabled={isSidebarOpen}
            position="right"
            placement="center"
            gutter={-5}
          >
            <SidebarItem
              {...childRoute}
              to={first(
                castArray(
                  isFunction(childRoute.path)
                    ? childRoute.path(features)
                    : childRoute.path
                )
              )}
              isSidebarOpen={isSidebarOpen}
            />
          </Tooltip>
        );
      })}
    </Container>
  );
};

const Container = styled.div<{ isSidebarOpen: boolean }>`
  .sidebar-header {
    padding: 15px 32px;
    letter-spacing: 0.04em;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
    transition: opacity 0.15s ease-in-out;
    text-transform: none;

    display: ${({ isSidebarOpen }) => (isSidebarOpen ? 'list-item' : 'none')};

    &.partner,
    &.organization {
      color: ${getStyledThemeColor('gray550')};
    }
  }

  ${({ isSidebarOpen }) =>
    !isSidebarOpen &&
    css`
      .sidebar-divider {
        border-bottom: 1px solid #eceff1;
        margin: 28px 0 28px 16px;
        width: 44px;
      }
    `};
`;
