import history from '@portals/browser-history';

import { RELOAD_PAGE, SET_ROUTE } from '../constants';

const routing = () => (next) => (action) => {
  if (action.type === SET_ROUTE) {
    const { route, replace, search = '' } = action.payload;

    if (
      window.location.pathname !== route ||
      window.location.search !== search
    ) {
      const method = replace ? history.replace : history.push;
      method(route);
    }
  } else if (action.type === RELOAD_PAGE) {
    window.location.reload();
  } else {
    return next(action);
  }
};

export default routing;
