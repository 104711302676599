import { compact } from 'lodash/fp';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { UserType } from '@portals/types';

type TableProps = {
  user: UserType;
};

const GroupMember: FC<TableProps> = ({ user }) => {
  const { name, organization, partner, email } = user;

  return (
    <Link to={`/settings/users/${user.id}`}>
      {compact([organization, partner, name]).join(' / ')}({email})
    </Link>
  );
};

export default GroupMember;
