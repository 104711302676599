import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import isMobile from 'is-mobile';
import { getOr, set } from 'lodash/fp';
import { RefObject, useEffect, useState } from 'react';

import { TenantType } from '@portals/types';

export const fallbackIfUndefined = (value, fallback) =>
  typeof value === 'undefined' ? fallback : value;

export const PRIORITY_NAMES = {
  1: 'Critical',
  2: 'High',
  3: 'Moderate',
  4: 'Low',
  5: 'Planning',
};

type OutsideClickEvent = MouseEvent | TouchEvent;

// Triggers a handler when user clicks / touches outside of the element (ref)
export const useOnClickOutside = (
  ref: RefObject<HTMLElement>,
  handler: (event: OutsideClickEvent) => any,
  isActive: boolean
) => {
  useEffect(() => {
    if (isActive) {
      const listener = (event: OutsideClickEvent) => {
        if (!ref.current || ref.current.contains(event.target as HTMLElement)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }
  }, [ref, handler, isActive]);
};

export const useDebouncedValue = <TValue>(value: TValue, delay: number) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
};

export const suppressPropagation =
  (cb: any = null) =>
  (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (cb) cb(e);
  };

const mobileHost = (base) => {
  // Due to SSL limitations, we don't support m.[partner].on-xyte.com
  if (base.endsWith('.on-xyte.com')) {
    return 'app.xyte.io';
  }

  return base;
};

export const getIsMobileLocation = () => {
  const currentLocation = window.location;

  return currentLocation.host.startsWith('m.');
};

export const getIsFieldOps = () => {
  const currentLocation = window.location;

  return currentLocation.host.includes('field-ops.xyte.io');
};

export const checkMobileRedirect = (tenantType: TenantType) => {
  if (process.env.NODE_ENV === 'development') return;

  const currentLocation = window.location;
  const isOrganization = tenantType === TenantType.Organization;
  const isMobileLocation = getIsMobileLocation();
  const isFieldOps = getIsFieldOps();

  if (isOrganization && !isFieldOps && isMobile() && !isMobileLocation) {
    window.location.assign(`https://m.${mobileHost(currentLocation.host)}`);
  }
};

export const initSentry = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
    if (process.env.NX_SENTRY) {
      Sentry.init({
        dsn: process.env.NX_SENTRY,
        autoSessionTracking: true,
        normalizeDepth: 10,
        integrations: [new Integrations.BrowserTracing()],
        maxBreadcrumbs: 100,
        // Filter out large data sets
        beforeBreadcrumb: (breadcrumb, _) => {
          const firstArg = getOr('', ['data', 'arguments', 0], breadcrumb);

          if (firstArg.startsWith('ACTION:')) {
            return set(
              'data.arguments.1.payload.data',
              '*removed*',
              breadcrumb
            );
          }
          return breadcrumb;
        },
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 0.01,
      });
    }
  }
};
