import { Formik } from 'formik';
import { flatten } from 'lodash/fp';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import * as Yup from 'yup';

import { Loader } from '@portals/ui';

import formBuilder from './formBuilder';
import { AutoFormikProps, FieldType, FieldTypeEnum } from './types';

const buildSchema = (fields: Array<FieldType>) =>
  Yup.object().shape(
    fields.reduce((obj, field) => {
      let type;

      if (field.validations) {
        type = field.validations;
      } else {
        switch (field.type) {
          // TODO: Support more types
          case FieldTypeEnum.Number:
            type = Yup.number();
            break;

          case FieldTypeEnum.Email:
            type = Yup.string().email('email address is not valid');
            break;

          default:
            type = Yup.mixed();
        }
      }

      if (field.required) {
        type = type.required('Required');
      }

      return { ...obj, [field.name]: type };
    }, {})
  );

const firstNonNull = (array: Array<any>) =>
  array.find((i) => i !== null && typeof i !== 'undefined');

const buildInitialValues = (
  fields: Array<FieldType>,
  initialValues: Record<string, any> = {}
) =>
  fields.reduce(
    (obj, field) => ({
      ...obj,
      [field.name]: firstNonNull([
        initialValues[field.name],
        field.default,
        '',
      ]),
    }),
    {}
  );

const AutoFormik: FC<AutoFormikProps> = (props) => {
  const { fields, initialValues, serverError, handleSubmit } = props;

  const dataFields = useMemo(
    () =>
      flatten(
        fields.reduce(
          (list, field) => [
            ...list,
            field.type === FieldTypeEnum.Group ? field.children : field,
          ],
          []
        )
      ),
    [fields]
  );

  const [schema, setSchema] = useState(buildSchema(dataFields));
  const [initial, setInitial] = useState(
    buildInitialValues(dataFields, initialValues)
  );

  useUpdateEffect(
    function setSchemaBasedOnDataFields() {
      setSchema(buildSchema(dataFields));
    },
    [dataFields]
  );

  useUpdateEffect(
    function setInitialValues() {
      setInitial(buildInitialValues(dataFields, initialValues));
    },
    [dataFields, initialValues]
  );

  const onSubmit = useCallback(
    (newValues: Record<string, any>) =>
      handleSubmit({ ...initialValues, ...newValues }),
    [handleSubmit, initialValues]
  );

  return !schema ? (
    <Loader />
  ) : (
    <Formik
      innerRef={props.innerRef}
      enableReinitialize={true}
      initialValues={initial}
      initialErrors={serverError && { server: serverError }}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {formBuilder(props)}
    </Formik>
  );
};

export default AutoFormik;
