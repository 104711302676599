import React, { FC } from 'react';
import styled from 'styled-components';

interface SidebarToggleButtonProps {
  isOpened: boolean;
  toggleSidebar: () => void;
}

export const SidebarToggleButton: FC<SidebarToggleButtonProps> = ({
  isOpened,
  toggleSidebar,
}) => (
  <Container onClick={toggleSidebar} id="sidebar-arrow" isOpened={isOpened}>
    <img src="sidebar-arrow.svg" alt="" />
  </Container>
);

const Container = styled.div<{ isOpened: boolean }>`
  cursor: pointer;

  img {
    transition: transform 0.15s ease-in-out;

    transform: ${({ isOpened }) =>
      isOpened ? 'rotate(0deg)' : 'rotate(180deg)'};
  }
`;
