import { Text, Tooltip } from '@mantine/core';
import React, { ComponentType, FC } from 'react';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
} from 'reactstrap';
import styled, { css } from 'styled-components';

import { getStyledThemeColor } from '@portals/utils';

type NavbarDropdownProps = {
  children: any;
  count: number;
  header?: string;
  footer?: string;
  icon: ComponentType<any>;
};

export const NavbarDropdown: FC<NavbarDropdownProps> = ({
  children,
  count,
  header,
  footer,
  icon: Icon,
}) => (
  <UncontrolledDropdownContainer nav inNavbar count={count}>
    <DropdownToggle nav className="nav-icon dropdown-toggle">
      <div className="position-relative notifications-number">
        <Tooltip label={<Text size="xs">Notifications</Text>}>
          <Icon className="align-middle" />
        </Tooltip>
      </div>
    </DropdownToggle>
    <DropdownMenu right className="dropdown-menu-lg py-0">
      {header && (
        <div className="dropdown-menu-header position-relative">
          {count} {header}
        </div>
      )}
      <ListGroup>{children}</ListGroup>
      {footer && (
        <DropdownItem header className="dropdown-menu-footer">
          <span className="text-muted">{footer}</span>
        </DropdownItem>
      )}
    </DropdownMenu>
  </UncontrolledDropdownContainer>
);

type ControlledNavbarDropdownProps = NavbarDropdownProps & {
  isOpen: boolean;
  onToggle: () => void;
  showBadge: boolean;
};

export const ControlledNavbarDropdown: FC<ControlledNavbarDropdownProps> = ({
  children,
  count,
  showBadge,
  header,
  footer,
  icon: Icon,
  isOpen,
  onToggle,
}) => (
  <UncontrolledDropdown
    nav
    inNavbar
    className="mr-2"
    isOpen={isOpen}
    onToggle={onToggle}
  >
    <DropdownToggle nav className="nav-icon dropdown-toggle">
      <div className="position-relative">
        <Icon className="align-middle" size={18} />
        {showBadge ? (
          <span className="indicator bg-danger">{count}</span>
        ) : null}
      </div>
    </DropdownToggle>

    {isOpen ? (
      <DropdownMenu right className="dropdown-menu-lg py-0">
        {header && (
          <div className="dropdown-menu-header position-relative">
            {count} {header}
          </div>
        )}
        <ListGroup>{children}</ListGroup>
        {footer && (
          <DropdownItem header className="dropdown-menu-footer">
            <span className="text-muted">{footer}</span>
          </DropdownItem>
        )}
      </DropdownMenu>
    ) : null}
  </UncontrolledDropdown>
);

const UncontrolledDropdownContainer = styled(UncontrolledDropdown)<{
  count: number;
}>`
  .nav-icon {
    padding: 0 !important;

    svg,
    .feather {
      width: 24px;
      height: 24px;
    }
  }

  .notifications-number {
    height: 100%;
    display: flex;
    position: relative;
    z-index: 2;

    ${({ count }) =>
      count > 0 &&
      css`
        &:before {
          content: '${count}';
          width: 22px;
          height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          font-size: 11px;
          font-weight: 600;
          color: ${getStyledThemeColor('white')};
          border: 2px solid ${getStyledThemeColor('white')};
          background-color: #ef5350;
          position: absolute;
          left: 10px;
          top: -5px;
          z-index: 1;
        }
      `}
`;
