import { TenantType } from '@portals/types';

import {
  END_NETWORK,
  START_NETWORK,
  SET_PARTNER_CONFIG,
  API,
  ADD_NOTIFICATION,
  HIDE_NOTIFICATION,
  REMOVE_NOTIFICATION,
  RESET_AUTH_ERROR,
} from '../constants';

export const startNetwork = (name) => ({
  type: START_NETWORK,
  payload: name,
  meta: { quiet: true },
});

export const endNetwork = (name) => ({
  type: END_NETWORK,
  payload: name,
  meta: { quiet: true },
});

export const setPartnerConfig = (data) => ({
  type: SET_PARTNER_CONFIG,
  payload: data,
});

export const getPartnerConfig = (tenantType) =>
  tenantType !== TenantType.Partner
    ? {
        type: API,
        payload: {
          url: 'ui/org_config',
          success: setPartnerConfig,
          error: setPartnerConfig({}),
          withError: true,
          name: 'getPartnerConfig',
        },
      }
    : setPartnerConfig({});

export const addNotification = (id, message, link = null) => ({
  type: ADD_NOTIFICATION,
  payload: { id, message, link, visible: true },
});

export const removeNotification = (id) => ({
  type: REMOVE_NOTIFICATION,
  payload: id,
});

export const hideNotification = (id) => ({
  type: HIDE_NOTIFICATION,
  payload: id,
});

export const resetAuthError = () => ({
  type: RESET_AUTH_ERROR,
});
