import { Box } from '@mantine/core';
import classnames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { join, keys } from 'lodash/fp';
import React, { FC, useEffect, useRef, useState } from 'react';
import ImageUploading, { ImageType } from 'react-images-uploading';
import { ExportInterface } from 'react-images-uploading/dist/typings';
import styled from 'styled-components';

import { getStyledThemeColor } from '@portals/utils';

import { ReactComponent as DropFile } from '../assets/img/icons/drop-file.svg';
import { ReactComponent as UploadIcon } from '../assets/img/icons/upload.svg';

interface ImageInputProps {
  label?: string;
  onChange: (imageList: Array<ImageType>) => void;
  acceptType?: Array<string>;
  className?: string;
}

const ERRORS_MAP = ({
  maxNumber,
  acceptType,
}: {
  maxNumber?: number;
  acceptType?: Array<string>;
}) => ({
  maxNumber: `Number of selected images exceed ${maxNumber}`,
  acceptType: `Your selected file type is not allowed (${join(
    ', ',
    acceptType
  )})`,
});

const ImageInput: FC<ImageInputProps> = ({
  label = 'Upload image',
  onChange,
  acceptType = ['jpg', 'jpeg', 'gif', 'png', 'svg'],
  className,
}) => {
  const [errors, setErrors] = useState(null);
  const errorTimeout = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    errorTimeout.current = setTimeout(() => {
      setErrors(null);
    }, 10000);

    return () => {
      if (errorTimeout.current) {
        clearTimeout(errorTimeout.current);
      }
    };
  }, []);

  const removeError = () => {
    setErrors(null);

    if (errorTimeout.current) {
      clearTimeout(errorTimeout.current);
    }
  };

  return (
    <>
      <AnimatePresence>
        {errors ? (
          <motion.div
            initial={{ opacity: 0, y: '-50%' }}
            animate={{ opacity: 1, y: '0%' }}
            exit={{ opacity: 0, y: '-50%' }}
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              zIndex: 2,
            }}
          >
            <Box
              sx={(theme) => ({
                backgroundColor: theme.colors.red_accent[4],
                color: theme.white,
                width: '100%',
                padding: '15px 0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              })}
              onClick={removeError}
            >
              {keys(errors).map(
                (error) =>
                  ERRORS_MAP({ maxNumber: 1, acceptType })[error] ||
                  'Failed to upload image, please try again'
              )}
            </Box>
          </motion.div>
        ) : null}
      </AnimatePresence>

      <ImageUploading
        value={[]}
        acceptType={acceptType}
        onChange={onChange}
        maxNumber={1}
        onError={(errors) => setErrors(errors)}
      >
        {({ onImageUpload, isDragging, dragProps }: ExportInterface) => (
          <Container
            {...dragProps}
            onClick={onImageUpload}
            className={classnames(className, {
              'is-empty': true,
              'is-dragging': isDragging,
            })}
          >
            <div className="upload-asset d-flex align-items-center justify-content-center flex-column">
              {isDragging ? <DropFile /> : <UploadIcon />}

              <div className="label">
                {isDragging ? 'Drop file here' : label}
              </div>
            </div>
          </Container>
        )}
      </ImageUploading>
    </>
  );
};

const Container = styled.div`
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(221, 226, 226, 0.25);
  width: 100%;
  height: 216px;
  box-sizing: border-box;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${getStyledThemeColor('gray200')};
  position: relative;
  border: 1px solid ${getStyledThemeColor('gray300')};

  .upload-asset {
    pointer-events: none;
  }

  .uploaded-image {
    max-width: 100%;
    max-height: 100%;
  }

  &.is-empty {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: ${getStyledThemeColor('gray100')};
    border: 1px dashed ${getStyledThemeColor('gray300')};

    .label {
      margin-top: 7px;
      font-size: 12px;
      color: #78909c;
    }

    &:hover {
      transition: all 0.15s ease-in-out;
      border-color: ${getStyledThemeColor('cyan')};
      background-color: rgba(38, 132, 255, 0.1);

      .label {
        color: ${getStyledThemeColor('cyan')};
      }

      svg {
        path {
          fill: ${getStyledThemeColor('cyan')};
        }
      }
    }
  }

  &.is-dragging {
    border-color: ${getStyledThemeColor('cyan')};
    background-color: rgba(38, 132, 255, 0.1);
  }

  .remove-image {
    position: absolute;
    bottom: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    background-color: ${getStyledThemeColor('white')};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    border: 1px solid ${getStyledThemeColor('gray150')};

    svg {
      path {
        transition: stroke 0.15s ease-in-out;
      }
    }

    &:hover {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
      background-color: ${getStyledThemeColor('danger')};
      color: ${getStyledThemeColor('white')};
      border-color: transparent;

      svg {
        path {
          stroke: ${getStyledThemeColor('white')};
        }
      }
    }
  }
`;

export default ImageInput;
