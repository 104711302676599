import classnames from 'classnames';
import { compact } from 'lodash/fp';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { usePermissionAccess } from '@portals/framework';
import { useFeatures, useSidebar } from '@portals/redux';
import { VerticalScrollBar } from '@portals/ui';
import { getStyledThemeColor, getStyledThemeLayout } from '@portals/utils';

import { useAppConfig } from '../../../context';
import { SidebarFooter } from './SidebarFooter';
import { SidebarSection } from './SidebarSection';
import TenantMenu from './TenantMenu';

export const Sidebar = () => {
  const sidebar = useSidebar();
  const { tenantType, routes, extraLayout } = useAppConfig();
  const features = useFeatures();
  const { canView } = usePermissionAccess();

  const sidebarSections = useMemo(() => {
    const dashboard = routes.dashboard;

    const sections = dashboard.map(
      ({ id, headerId, childRoutes, canAccessRoute }) => {
        if (canAccessRoute && !canAccessRoute({ features, canView })) {
          return undefined;
        }

        return (
          <SidebarSection
            key={id}
            headerId={headerId}
            childRoutes={childRoutes}
            isSidebarOpen={sidebar.isOpen}
          />
        );
      }
    );

    return compact(sections);
  }, [canView, features, routes.dashboard, sidebar.isOpen]);

  return (
    <Container isOpen={sidebar.isOpen}>
      <div className={classnames('sidebar-content', tenantType)}>
        {extraLayout?.sidebarLayout?.brandRenderer ? (
          extraLayout?.sidebarLayout?.brandRenderer()
        ) : (
          <TenantMenu />
        )}

        <VerticalScrollBar
          hideTracksWhenNotNeeded
          renderView={(props) => <div className="sidebar-nav" {...props} />}
        >
          {sidebarSections}
        </VerticalScrollBar>

        <SidebarFooter />
      </div>
    </Container>
  );
};

const Container = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 1;
  border-right: none;

  width: ${({ isOpen }) =>
    isOpen
      ? getStyledThemeLayout('sidebarWidthOpened')
      : getStyledThemeLayout('sidebarWidthClosed')};

  .sidebar-content {
    border-right: none;
    z-index: 100;
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr 56px;

    width: ${({ isOpen }) =>
      isOpen
        ? getStyledThemeLayout('sidebarWidthOpened')
        : getStyledThemeLayout('sidebarWidthClosed')};

    &.partner,
    &.organization {
      background: ${getStyledThemeColor('white')};
    }
  }

  .sidebar-nav {
    padding-left: 0;
    list-style: none;
    padding-bottom: 15px;
  }
`;
