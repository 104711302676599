import { get } from 'lodash/fp';
import React, { FC, ReactNode, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { Button } from 'reactstrap';

import { setRoute } from '@portals/redux/actions/routing';
import { ComponentRendererType, TabType } from '@portals/types';

type ButtonTabsProps = {
  tabs: Array<TabType>;
  children?: (selectedTab: TabType, currUrl: string) => ReactNode;
  urlParam: string;
  urlBase: string;
  titleWrap: ComponentRendererType<any>;
};

type ButtonsTabsConnectedActions = {
  setRoute: (path: string) => void;
};

const ButtonTabs: FC<
  RouteComponentProps & ButtonTabsProps & ButtonsTabsConnectedActions
> = ({ match, tabs, children, urlParam, urlBase, setRoute, titleWrap }) => {
  const defaultId = get([0, 'id'], tabs);
  const [stateSelectedId, setStateSelectedId] = useState(defaultId);
  const selectedId = urlParam
    ? match.params[urlParam] || defaultId
    : stateSelectedId;
  const selectedTab = tabs.find(({ id }) => id === selectedId);
  const SelectedComponent = selectedTab && selectedTab.Component;
  const currUrl = `${urlBase}/${selectedId}`;

  const setSelectedId = (id) =>
    urlParam ? setRoute(`${urlBase}/${id}`) : setStateSelectedId(id);

  return (
    <div>
      <div className="mb-3 pb-2 border-bottom">
        {tabs.map((tab) => (
          <Button
            key={tab.id}
            color={selectedId === tab.id ? 'primary' : 'none'}
            className="mr-1 mb-1"
            onClick={() => setSelectedId(tab.id)}
          >
            <div className={selectedId === tab.id ? '' : 'text-primary'}>
              {titleWrap ? React.createElement(titleWrap, tab) : tab.title}
            </div>
          </Button>
        ))}
      </div>

      {selectedTab &&
        (children ? (
          children(selectedTab, currUrl)
        ) : (
          <SelectedComponent id={selectedId} urlBase={currUrl} />
        ))}
    </div>
  );
};

const ConnectedButtonTabs = connect<null, ButtonsTabsConnectedActions>(null, {
  setRoute,
})(ButtonTabs);

export default withRouter(ConnectedButtonTabs);
