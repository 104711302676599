import classNames from 'classnames';
import { motion } from 'framer-motion';
import { isFunction } from 'lodash/fp';
import React, { FC, useMemo, useRef } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { Badge } from 'reactstrap';
import styled, { css } from 'styled-components';

import { useAppConfig } from '@portals/framework/context';
import { useTranslation } from '@portals/i18n';
import { useFeatures } from '@portals/redux';
import { ComponentRendererType, RouteType } from '@portals/types';
import { getStyledThemeColor } from '@portals/utils';

type SidebarItemProps = Omit<RouteType, 'component' | 'children'> & {
  to: string;
  isSidebarOpen: boolean;
};

interface LinkBodyProps {
  id?: string;
  href?: string;
  allPaths?: string | Array<string>;
  icon?: ComponentRendererType;
  badgeComponent?: ComponentRendererType;
  badgeColor?: string;
  badgeText?: string;
  label?: string;
  isSidebarOpen: boolean;
}

const LinkBody: FC<LinkBodyProps> = ({
  icon: Icon,
  label,
  badgeComponent,
  badgeColor,
  badgeText,
  isSidebarOpen,
}) => {
  return (
    <div className="link-body">
      {Icon ? <Icon size={18} className="align-middle" /> : null}

      {isSidebarOpen ? (
        <>
          <span>{label}</span>

          <div className="badges">
            {badgeComponent && React.createElement(badgeComponent)}

            {badgeColor && badgeText ? (
              <Badge color={badgeColor} size={18} className="sidebar-badge">
                {badgeText}
              </Badge>
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  );
};

export const SidebarItem: FC<SidebarItemProps> = ({
  path,
  id,
  badgeComponent,
  badgeColor,
  badgeText,
  icon,
  to,
  href,
  hasBorder,
  isSidebarOpen,
}) => {
  const features = useFeatures();
  const adjustedPath = isFunction(path) ? path(features) : path;

  const isMatch = useRouteMatch({ path: adjustedPath, exact: true });
  const t = useTranslation();
  const label = t(id);
  const containerRef = useRef(null);
  const { tenantType } = useAppConfig();

  const linkContent = useMemo(
    () => (
      <LinkBody
        icon={icon}
        label={label}
        badgeColor={badgeColor}
        badgeText={badgeText}
        badgeComponent={badgeComponent}
        isSidebarOpen={isSidebarOpen}
      />
    ),
    [isSidebarOpen, badgeColor, badgeComponent, badgeText, icon, label]
  );

  return (
    <Container
      layout="position"
      ref={containerRef}
      className={tenantType}
      isActive={!!isMatch}
      isSidebarOpen={isSidebarOpen}
    >
      {hasBorder && <div className="sidebar-separator"></div>}

      {href ? (
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className={classNames('sidebar-link', tenantType)}
        >
          {linkContent}
        </a>
      ) : (
        <NavLink to={to} className={classNames('sidebar-link', tenantType)}>
          {linkContent}
        </NavLink>
      )}
    </Container>
  );
};

const Container = styled(motion.div)<{
  isActive: boolean;
  isSidebarOpen: boolean;
}>`
  transition: padding 0.1s linear;
  margin-bottom: 5px;
  position: relative;

  padding: ${({ isSidebarOpen }) => (isSidebarOpen ? '0 16px' : '0')};

  .sidebar-link {
    font-size: 14px;
    font-weight: 600;
    align-items: center;
    width: 100%;
    padding: 10px 16px;
    border-radius: 8px;
    display: flex;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    .link-body {
      display: flex;
      align-items: center;
      gap: 14px;
      height: 24px;
    }

    svg {
      height: 24px;
      width: 24px;
    }

    .badges {
      position: absolute;
      right: 30px;
      top: 11px;
    }

    &:hover {
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.04);

      i,
      svg,
      path {
        stroke: ${getStyledThemeColor('white')};
      }
    }

    &:focus {
      outline: 0;
    }

    &.partner,
    &.organization {
      background: ${getStyledThemeColor('white')};
      color: ${getStyledThemeColor('gray750')};

      i,
      svg,
      path {
        stroke: ${getStyledThemeColor('gray750')};
      }

      &:hover {
        background: whitesmoke;

        i,
        svg,
        path {
          stroke: ${getStyledThemeColor('gray750')};
        }
      }
    }

    ${({ isSidebarOpen }) =>
      !isSidebarOpen &&
      css`
        height: 44px;
        width: 44px;
        margin: 5px 0 5px 16px;
        padding: 12px 10px 10px;

        .link-body {
          gap: 26px;
        }

        .badges {
          display: none;
        }
      `};
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      font-weight: 600;

      &.partner,
      &.organization {
        .sidebar-link,
        .sidebar-link:hover {
          background: ${theme.color.cyan};
          color: ${theme.color.white};

          i,
          svg,
          path {
            stroke: ${theme.color.white};
          }
        }
      }
    `};

  .sidebar-separator {
    border-top: 1px solid #eceff1;

    width: ${({ isSidebarOpen }) => (isSidebarOpen ? '220px' : '44px')};
    margin: ${({ isSidebarOpen }) => (isSidebarOpen ? '20px' : '20px 16px')};
`;
