import { useCallback, useMemo } from 'react';

import { useAppConfig } from '@portals/framework/context';
import { useCurrentUser } from '@portals/redux';
import { TenantType, UserPermissions } from '@portals/types';
import { AccessLevelEnum } from '@portals/utils';

export type CanViewFn = ReturnType<typeof usePermissionAccess>['canView'];
export type CanEditFn = ReturnType<typeof usePermissionAccess>['canEdit'];

export function usePermissionAccess() {
  const { tenantType } = useAppConfig();
  const { permissions, global_admin, org_admin, partner_admin } =
    useCurrentUser() || {};

  const isAdmin = useMemo(() => {
    if (global_admin) {
      return true;
    } else if (tenantType === TenantType.Partner) {
      return Boolean(partner_admin);
    } else if (tenantType === TenantType.Organization) {
      return Boolean(org_admin);
    } else {
      return false;
    }
  }, [global_admin, org_admin, partner_admin, tenantType]);

  const canView = useCallback(
    (permissionKey: keyof UserPermissions) => {
      if (isAdmin) return true;

      return !permissions
        ? false
        : permissions[permissionKey] >= AccessLevelEnum.View;
    },
    [isAdmin, permissions]
  );

  const canEdit = useCallback(
    (permissionKey: keyof UserPermissions) => {
      if (isAdmin) return true;

      return !permissions
        ? false
        : permissions[permissionKey] >= AccessLevelEnum.Edit;
    },
    [isAdmin, permissions]
  );

  return {
    canView,
    canEdit,
    isAdmin,
  };
}
