import { Group, Stack, Text, Title } from '@mantine/core';
import { get } from 'lodash/fp';
import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { createOrg } from '@portals/redux/actions/auth';
import { setRoute } from '@portals/redux/actions/routing';
import { PartnerConfigType, SetRoute, StateType } from '@portals/types';
import AutoFormik, { FieldTypeEnum } from '@portals/ui/AutoFormik';

import { FormWrapper } from './common';

const TermsAndPolicy = () => (
  <span>
    Agree to{' '}
    <a
      href="https://xyte.io/terms-of-service"
      target="_blank"
      rel="noopener noreferrer"
    >
      Terms of Use
    </a>{' '}
    and{' '}
    <a
      href="https://xyte.io/privacy-policy"
      target="_blank"
      rel="noopener noreferrer"
    >
      Privacy Policy
    </a>
  </span>
);

const ORGANIZATIONS_FIELDS = [
  {
    name: 'organization',
    title: 'Organization Name',
    required: true,
    placeholder: 'Name your organization',
  },
  {
    name: 'name',
    title: 'Name',
    required: true,
    placeholder: 'Your full name',
  },
  {
    name: 'email',
    title: 'Email',
    type: FieldTypeEnum.Email,
    required: true,
    placeholder: 'Company email address',
    inputProps: {
      type: 'email',
    },
  },
  {
    name: 'password',
    title: 'Password',
    type: FieldTypeEnum.Password,
    required: true,
    min: 6,
    placeholder: 'Hard to guess password',
    validations: Yup.string().min(6),
  },
  {
    name: 'terms',
    title: <TermsAndPolicy />,
    type: FieldTypeEnum.Checkbox,
    required: true,
  },
];

type SignUpConnectedProps = {
  error: string;
  creating: boolean;
  config: PartnerConfigType;
};

type SignUpConnectedActions = {
  createOrg: (
    organization: string,
    name: string,
    email: string,
    password: string,
    referral: string,
    afterAuthPath?: string
  ) => void;
  setRoute: SetRoute;
};

type SignUpProps = {
  onBack?: {
    to: string;
    callback: () => void;
  };
  isForceSignup?: boolean;
  afterAuthPath?: string;
};

const SignUp: FC<
  SignUpProps & SignUpConnectedProps & SignUpConnectedActions
> = ({
  config = {},
  error,
  creating,
  createOrg,
  setRoute,
  onBack,
  isForceSignup = false,
  afterAuthPath = '/',
}) => {
  const isReferral = !!localStorage.getItem('referral');

  const handleSubmit = (form) => {
    const referral = localStorage.getItem('referral') || '';

    const { name, organization, email, password } = form;

    createOrg(organization, name, email, password, referral, afterAuthPath);
  };

  useEffect(() => {
    if (!isReferral && !isForceSignup && config.signup !== true) {
      setRoute('/auth/sign-in');
    }
  }, [isReferral, isForceSignup, config.signup, setRoute]);

  return (
    <FormWrapper id="sign-up">
      <Stack
        className="sign-up-container"
        sx={(theme) => ({
          '.mantine-TextInput-root, .mantine-PasswordInput-root': {
            marginBottom: theme.spacing.sm,
          },
          '.mantine-Checkbox-root': {
            marginBottom: theme.spacing.xl,
          },
        })}
      >
        <Group position="apart" mb="lg" align="baseline">
          <Title order={1}>Sign-Up</Title>

          <Text color="dimmed" size="sm" align="center">
            <Group spacing={6}>
              Already have an account?
              <Link
                className="sign-up-link-sign-in"
                to={onBack ? onBack.to : '/auth/sign-in'}
                onClick={() => {
                  if (onBack) {
                    onBack.callback();
                  }
                }}
              >
                Sign in
              </Link>
            </Group>
          </Text>
        </Group>

        <AutoFormik
          fields={ORGANIZATIONS_FIELDS}
          buttonsProps={{
            size: 'sm',
            style: {
              width: '100%',
              marginTop: '10px',
            },
          }}
          handleSubmit={handleSubmit}
          serverError={error}
          inProgress={creating}
          submitTitle="Sign Up"
        />
      </Stack>
    </FormWrapper>
  );
};

const mapStateToProps = (state: StateType) => ({
  error: state.errors.createOrg,
  creating: get(['ui', 'network', 'createOrg'], state),
  config: state.ui.partnerConfig,
});

export default connect<SignUpConnectedProps, SignUpConnectedActions>(
  mapStateToProps,
  { createOrg, setRoute }
)(SignUp);
