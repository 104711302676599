import {
  Box,
  createStyles,
  NumberInput,
  NumberInputProps,
  TextInput,
  TextInputProps,
} from '@mantine/core';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { useOnClickOutside } from '@portals/utils';

interface EditableCellProps<Value = string> {
  value: Value;
  formattedValue?: string;
  onChange: (value: Value) => void;
}

export const EditableTextCell: FC<EditableCellProps & TextInputProps> = ({
  value,
  formattedValue,
  onChange,
  ...inputProps
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const { cx, classes } = useStyles();
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  useOnClickOutside(containerRef, () => setIsEdit(false), isEdit);

  useEffect(() => {
    if (isEdit && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEdit]);

  return (
    <Box
      className={cx(classes.container, { edit: isEdit })}
      onClick={() => setIsEdit(true)}
      ref={containerRef}
    >
      {isEdit ? (
        <TextInput
          ref={inputRef}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          radius={0}
          onBlur={() => setIsEdit(false)}
          styles={{
            root: {
              width: '100%',
              height: '100%',
            },
            wrapper: {
              height: '100%',
            },
            input: {
              width: '100%',
              height: '100%',
            },
          }}
          {...inputProps}
        />
      ) : (
        formattedValue || value
      )}
    </Box>
  );
};

export const EditableNumberCell: FC<
  EditableCellProps<number> & NumberInputProps
> = ({ value, formattedValue, onChange, ...inputProps }) => {
  const [isEdit, setIsEdit] = useState(false);
  const { cx, classes } = useStyles();
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  useOnClickOutside(containerRef, () => setIsEdit(false), isEdit);

  useUpdateEffect(() => {
    if (isEdit && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEdit]);

  return (
    <Box
      className={cx(classes.container, { edit: isEdit })}
      onClick={() => setIsEdit(true)}
      ref={containerRef}
    >
      {isEdit ? (
        <NumberInput
          ref={inputRef}
          radius={0}
          value={value}
          hideControls
          onChange={onChange}
          onBlur={() => {
            setIsEdit(false);
          }}
          styles={{
            root: {
              width: '100%',
              height: '100%',
            },
            wrapper: {
              height: '100%',
            },
            input: {
              width: '100%',
              height: '100%',
            },
          }}
          {...inputProps}
        />
      ) : (
        formattedValue || value
      )}
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    border: '1px solid transparent',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${theme.spacing.md}px`,

    '&:not(.edit)': {
      '&:hover': {
        borderColor: theme.colors.gray[5],
      },
    },

    '&.edit': {
      padding: 0,
    },
  },
}));
