import {
  Box,
  Divider,
  Group,
  MantineColor,
  MantineSize,
  Menu,
  Text,
} from '@mantine/core';
import { map } from 'lodash/fp';
import React, { FC } from 'react';

import { ComponentRendererType } from '@portals/types';

export interface RowMenuItem {
  id: string;
  label: string;
  Icon: ComponentRendererType;
  color?: MantineColor;
  onClick: () => void;
  withTopDivider?: boolean;
  withBottomDivider?: boolean;
  Wrapper?: FC;
}

interface RowMenuItemsProps {
  items: Array<RowMenuItem>;
  labelSize?: MantineSize;
}

const ICON_SIZE = 18;

const RowMenuItems: FC<RowMenuItemsProps> = ({ items, labelSize = 'xs' }) => {
  return (
    <>
      {map(
        ({
          id,
          Icon,
          color = 'dark',
          label,
          onClick,
          Wrapper = Box,
          withTopDivider,
          withBottomDivider,
        }) => (
          <Wrapper key={id} onClick={onClick}>
            {withTopDivider && <Divider />}

            <Menu.Item>
              <Group
                spacing="sm"
                sx={(theme) => ({ color: theme.colors[color][4] })}
              >
                <Icon width={ICON_SIZE} height={ICON_SIZE} />

                <Text size={labelSize}>{label}</Text>
              </Group>
            </Menu.Item>

            {withBottomDivider && <Divider />}
          </Wrapper>
        ),
        items
      )}
    </>
  );
};

export default RowMenuItems;
