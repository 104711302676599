import { MantineProvider } from '@mantine/core';
import React, { FC } from 'react';

import DEFAULT_PROPS from './default-props';
import STYLES from './styles-override';
import { THEME } from './theme';

export const ThemeProvider: FC = ({ children }) => {
  return (
    <MantineProvider
      emotionOptions={{ key: 'mantine', stylisPlugins: [] }}
      theme={THEME}
      styles={STYLES}
      defaultProps={DEFAULT_PROPS}
    >
      {children}
    </MantineProvider>
  );
};
