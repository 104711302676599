import { ActionIcon, Box, createStyles, Group, Text } from '@mantine/core';
import { noop } from 'lodash/fp';
import React from 'react';

import { ColumnType } from '@portals/types/Table';
import { suppressPropagation } from '@portals/utils';

import SortCaret from './Filters/SortCaret';

interface THProps<TData extends object> {
  column: ColumnType<TData> & { isAction: boolean };
  noFilters?: boolean;
  noSort?: boolean;
}

function TH<TData extends object>({
  column,
  noFilters = false,
  noSort = false,
}: THProps<TData>) {
  const { cx, classes } = useStyles();
  const { onClick: onSort = noop } = column?.getSortByToggleProps() || {};

  return (
    <Box
      key={column.id}
      className={cx('th', classes.container, {
        sticky: column.isSticky,
      })}
      {...column.getHeaderProps()}
    >
      <Group position="apart" pr="md" sx={{ height: '100%' }}>
        <Text size="sm" weight="bold" px="md">
          {column.Header ? column.render('Header') : null}
        </Text>

        {!column.isAction ? (
          <Group>
            {!noFilters && column.filter ? column.render('Filter') : null}

            {!noSort && column.canSort ? (
              <ActionIcon onClick={suppressPropagation(onSort)} size="xs">
                <SortCaret
                  direction={
                    column.isSorted
                      ? column.isSortedDesc
                        ? 'desc'
                        : 'asc'
                      : null
                  }
                />
              </ActionIcon>
            ) : null}
          </Group>
        ) : null}
      </Group>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    '&.sticky': {
      height: '100%',
      position: 'sticky',
      left: 0,
      backgroundColor: 'white',
      background: 'white',
      zIndex: 1,
      clipPath: 'inset(0px -15px 0px 0px)',
    },
  },
}));

export default TH;
