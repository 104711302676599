import {
  get,
  includes,
  map,
  omit,
  omitBy,
  set,
  setWith,
  unset,
} from 'lodash/fp';

import {
  AddProductToCartPayload,
  PaymentMethodEnum,
  StoreStateType,
} from '@portals/types';

import {
  ADD_PRODUCT_TO_CART,
  CHECK_CART_ITEMS,
  CHECK_SELECTED_ADDRESS,
  CHECK_SELECTED_CREDIT_CARD,
  CHECK_SELECTED_CURRENCY,
  CLEAR_CART,
  REMOVE_PRODUCT_FROM_CART,
  SET_BILLING_ADDRESS_ID,
  SET_BUYER_INFO_ID,
  SET_CREDIT_CARD_ID,
  SET_CURRENCY,
  SET_IS_SAME_ADDRESS,
  SET_PAYMENT_METHOD,
  SET_PRODUCT_QUANTITY,
  SET_SHIPPING_ADDRESS_ID,
  SWITCH_TENANT,
} from '../constants';

const initialState: StoreStateType = JSON.parse(
  localStorage.getItem('cart')
) || {
  currency: 'USD',
  cart: {},
  creditCardId: null,
  billingAddressId: null,
  shippingAddressId: null,
  isSameAddress: false,
  paymentMethod: PaymentMethodEnum.CreditCard,
};

const addProductToCart = (
  { productId, quantity, period }: AddProductToCartPayload,
  state: StoreStateType
): StoreStateType => {
  const existingProductInCart = get([productId, period], state.cart);

  if (existingProductInCart) {
    return set(
      ['cart', productId, period],
      existingProductInCart + quantity,
      state
    );
  }

  return setWith(Object, ['cart', productId, period], quantity, state);
};

const setProductQuantity = (
  { productId, quantity, period }: AddProductToCartPayload,
  state: StoreStateType
): StoreStateType => {
  const existingProductInCart = get([productId, period], state.cart);

  if (existingProductInCart) {
    if (quantity <= 0) {
      return unset(['cart', productId, period], state);
    }

    return set(['cart', productId, period], quantity, state);
  }

  return setWith(Object, ['cart', productId, period], quantity, state);
};

const removeProductFromCart = (
  { productId, period }: Omit<AddProductToCartPayload, 'quantity'>,
  state: StoreStateType
): StoreStateType => ({
  ...state,
  cart: omit([`${productId}.${period}`], state.cart),
});

const reducer = (state = initialState, action) => {
  let newState = state;

  switch (action.type) {
    case SET_CURRENCY:
      newState = set('currency', action.payload.currency, state);
      break;

    case SET_BUYER_INFO_ID:
      newState = set('buyerInfoId', action.payload.buyerInfoId, state);
      break;

    case ADD_PRODUCT_TO_CART:
      newState = addProductToCart(action.payload, state);
      break;

    case SET_PRODUCT_QUANTITY:
      newState = setProductQuantity(action.payload, state);
      break;

    case REMOVE_PRODUCT_FROM_CART:
      newState = removeProductFromCart(action.payload, state);
      break;

    case CHECK_SELECTED_CURRENCY:
      const { currencies, defaultCurrency } = action.payload;

      if (!includes(state.currency, currencies)) {
        newState = {
          ...newState,
          currency: defaultCurrency,
        };
      }
      break;

    case CHECK_CART_ITEMS:
      const { storeListings } = action.payload;
      const existingProductsIds = map('product.id', storeListings);

      newState = {
        ...newState,
        cart: omitBy(
          (_, productId) => !includes(productId, existingProductsIds),
          newState.cart
        ),
      };

      break;

    case CHECK_SELECTED_ADDRESS:
      const { addresses } = action.payload;
      const existingAddressesIds = map('id', addresses);

      const shippingIdExists = includes(
        newState.shippingAddressId,
        existingAddressesIds
      );

      const billingIdExists = includes(
        newState.billingAddressId,
        existingAddressesIds
      );

      newState = {
        ...newState,
        shippingAddressId: shippingIdExists ? newState.shippingAddressId : null,
        billingAddressId: billingIdExists ? newState.billingAddressId : null,
      };

      break;

    case CHECK_SELECTED_CREDIT_CARD:
      const { creditCards } = action.payload;
      const creditCardIds = map('id', creditCards);

      const creditCardIdExists = includes(newState.creditCardId, creditCardIds);

      newState = {
        ...newState,
        creditCardId: creditCardIdExists ? newState.creditCardId : null,
      };

      break;

    case CLEAR_CART:
      newState = {
        ...newState,
        isSameAddress: false,
        cart: {},
      };
      break;

    case SET_CREDIT_CARD_ID:
      newState = {
        ...newState,
        creditCardId: action.payload.id,
      };

      break;

    case SET_BILLING_ADDRESS_ID:
      newState = {
        ...newState,
        billingAddressId: action.payload.id,
      };

      break;

    case SET_SHIPPING_ADDRESS_ID:
      newState = {
        ...newState,
        shippingAddressId: action.payload.id,
        billingAddressId: newState.isSameAddress
          ? action.payload.id
          : newState.billingAddressId,
      };

      break;

    case SET_IS_SAME_ADDRESS:
      newState = {
        ...newState,
        isSameAddress: action.payload.isSameAddress,
        billingAddressId: action.payload.isSameAddress
          ? newState.shippingAddressId
          : newState.billingAddressId,
      };

      break;

    case SET_PAYMENT_METHOD:
      newState = set('paymentMethod', action.payload.paymentMethod, state);
      break;

    case SWITCH_TENANT:
      newState = {
        currency: 'USD',
        cart: {},
        creditCardId: null,
        billingAddressId: null,
        shippingAddressId: null,
        isSameAddress: false,
        paymentMethod: PaymentMethodEnum.CreditCard,
      };
      break;

    default:
      newState = state;
  }

  localStorage.setItem('cart', JSON.stringify(newState));

  return newState;
};

export default reducer;
