import * as DINERO_CURRENCIES from '@dinero.js/currencies';

const currencyCodes = Object.keys(DINERO_CURRENCIES) as CurrencyCode[];

// @ts-ignore
const currencyName = new Intl.DisplayNames(['en'], { type: 'currency' });

const CURRENCIES = currencyCodes.reduce((currencies, code) => {
  currencies[code] = currencyName.of(code) ?? 'Currency name not found';
  return currencies;
}, {} as Record<CurrencyCode, string>);

export { CURRENCIES };
export type CurrencyCode = keyof typeof DINERO_CURRENCIES;
