import { MenuProps } from '@mantine/core';
import { CSSProperties, ReactNode } from 'react';
import { UseQueryResult } from 'react-query';
import {
  Cell,
  DefaultSortTypes,
  Filters,
  HeaderGroup,
  IdType,
  Row,
  SortByFn,
  SortingRule,
  TableInstance,
  TableOptions as RTTableOptions,
  TableState as RTTableState,
  UseExpandedOptions,
  UseExpandedRowProps,
  UseExpandedState,
  UseFiltersColumnOptions,
  UseFiltersColumnProps,
  UseFiltersInstanceProps,
  UseFiltersOptions,
  UseFiltersState,
  UseGlobalFiltersColumnOptions,
  UseGlobalFiltersInstanceProps,
  UseGlobalFiltersOptions,
  UseGlobalFiltersState,
  UsePaginationInstanceProps,
  UsePaginationOptions,
  UsePaginationState,
  UseResizeColumnsColumnOptions,
  UseResizeColumnsColumnProps,
  UseRowSelectInstanceProps,
  UseRowSelectOptions,
  UseRowSelectRowProps,
  UseRowSelectState,
  UseSortByColumnOptions,
  UseSortByColumnProps,
  UseSortByOptions,
  UseSortByState,
  UseTableColumnOptions,
  UseTableColumnProps,
} from 'react-table';
import { CSSObject } from 'styled-components';

import { PaginationResponse } from './api';

export type TableState<TData extends object> = RTTableState<TData> &
  UsePaginationState<TData> &
  UseSortByState<TData> &
  UseExpandedState<TData> &
  UseFiltersState<TData> &
  UseGlobalFiltersState<TData> &
  UseRowSelectState<TData> & {
    expanded: UseExpandedState<TData>['expanded'] | Record<string, never>;
  };

export type TableOptions<TData extends object> = RTTableOptions<TData> &
  UsePaginationOptions<TData> &
  UseGlobalFiltersOptions<TData> &
  UseFiltersOptions<TData> &
  UseExpandedOptions<TData> &
  UseRowSelectOptions<TData> &
  UseSortByOptions<TData>;

export type TableInstanceType<TData extends object> = TableInstance<TData> &
  UsePaginationInstanceProps<TData> &
  UseGlobalFiltersColumnOptions<TData> &
  UseGlobalFiltersOptions<TData> &
  UseRowSelectInstanceProps<TData> &
  UseFiltersInstanceProps<TData> &
  UseGlobalFiltersInstanceProps<TData> & {
    state: Partial<TableState<TData>>;
  };

export type ColumnType<TData extends object> = HeaderGroup<TData> &
  UseTableColumnProps<TData> &
  UseSortByColumnProps<TData> &
  UseFiltersColumnProps<TData> &
  UseResizeColumnsColumnProps<TData> &
  UseTableColumnOptions<TData> &
  UseSortByColumnOptions<TData> &
  UseFiltersColumnOptions<TData> &
  UseResizeColumnsColumnOptions<TData> & {
    filterType?: TableFilterTypeEnum;
  } & { isSticky: boolean };

export enum TableFilterTypeEnum {
  Text = 'text',
  Number = 'number',
  Select = 'select',
  Date = 'date',
}

type TableFilterBaseType<TData = TableFilterTypeEnum> = {
  type: TData;
  placeholder?: string;
};

type TableFilterText = TableFilterBaseType<TableFilterTypeEnum.Text>;
type TableFilterNumber = TableFilterBaseType<TableFilterTypeEnum.Number>;
type TableDateFilter = TableFilterBaseType<TableFilterTypeEnum.Date>;
type TableSelectFilter = TableFilterBaseType<TableFilterTypeEnum.Select> & {
  options: Record<string, string>;
};

export type TableCell<TData extends object> = Cell<TData> & {
  column: { isSticky?: boolean; isEditable?: boolean };
};

export type TableFilterType =
  | TableFilterText
  | TableFilterNumber
  | TableDateFilter
  | TableSelectFilter;

export type TableColumnFormatter<TData extends object = any> = (
  cell: Cell<TData>,
  rowData: TData,
  row: Row<TData> & UseExpandedRowProps<TData>
) => ReactNode;

// @todo: loose this `any` when all usages are strictly typed
export type TableColumn<TData extends object = any> = {
  dataField: IdType<TData>;
  text: string;
  sort?: boolean;
  hidden?: boolean;
  filter?: TableFilterType;
  formatter?: TableColumnFormatter<TData>;
  headerFormatter?: (column: TableColumn<TData>) => ReactNode;
  rowStyle?: CSSObject;
  isAction?: boolean;
  sortValue?: SortByFn<TData> | DefaultSortTypes | string;
  minWidth?: number;
  maxWidth?: number;
  withTooltip?: boolean;
  withGlobalFilter?: boolean;
  isSticky?: boolean;
  isEditable?: boolean;
};

export enum ComparatorEnum {
  EQ = '=',
  NE = '!=',
  GT = '>',
  GE = '>=',
  LT = '<',
  LE = '<=',
}

export interface TableSortType<TData extends object = any> {
  id: IdType<TData>;
  desc?: boolean;
}

export type RowType<TData extends object> = Row<TData> &
  UseExpandedRowProps<TData> &
  UseRowSelectRowProps<TData>;

export interface ExpandRowType<TData extends object> {
  renderer: (rowData: TData, row: RowType<TData>) => JSX.Element;
  showExpandColumn?: boolean;
  expandHeaderColumnRenderer?: (header: any) => JSX.Element;
  expandColumnRenderer?: (params: { expanded: boolean }) => JSX.Element;
  height?: number | ((row: RowType<TData>) => number);
  isEnabled?: (row: RowType<TData>) => boolean;
}

export type NoDataIndicationType = {
  title: string;
  subtitle?: string;
  assetSrc?: string;
  actions?: ReactNode;
};

export type InfiniteTableProps<TData extends object> = {
  name: string;
  noExport?: boolean;
  noHeader?: boolean;
  noFilters?: boolean;
  isCompact?: boolean;
  expandRow?: ExpandRowType<TData>;
  noDataIndication?: NoDataIndicationType;
  additionalActions?: () => ReactNode;
  rowMenu?: RowMenuContentRendererType<TData>;
  selectedItemsActions?: ReactNode;
};

export type RowMenuRefType = {
  onClose: () => void;
  onOpen: () => void;
};

export type RowMenuContentRendererParams<TData extends object> = {
  menuRef: RowMenuRefType | undefined;
  row: Row<TData> & UseExpandedRowProps<TData> & UseRowSelectRowProps<TData>;
};

export type RowMenuContentRendererType<TData extends object> = (
  params: RowMenuContentRendererParams<TData>
) => ReactNode;

export type SmartTableProps<TData extends object = any> = {
  name: string;
  keyField: string;
  data: Array<TData>;
  columns: Array<TableColumn<TData>>;
  onSelected?: (rowIds: Array<string>) => void;
  rowStyle?: (params: TData) => CSSProperties;
  noDataIndication?: NoDataIndicationType;
  expandRow?: ExpandRowType<TData>;
  expanded?: TableState<TData>['expanded'];
  noExport?: boolean;
  noHeader?: boolean;
  noSort?: boolean;
  noColumnsSelection?: boolean;
  noFilters?: boolean;
  defaultSorted?: Array<TableSortType<TData>>;
  defaultFilters?: Filters<TData>;
  additionalActions?: () => ReactNode;
  selectedItemsActions?: ReactNode;
  rowMenu?: RowMenuContentRendererType<TData>;
  isUrlSyncEnabled?: boolean;
  withGlobalSearch?: boolean;
  isCompact?: boolean;
  menuProps?: Omit<MenuProps, 'children'>;
  pageSize?: number;
};

export type PaginatedDataHook<TData extends object> = (
  initialSearchParams: Partial<TableState<TData>>,
  columns: Array<TableColumn>,
  url?: string
) => UseQueryResult<PaginationResponse<TData>>;

export type DataHook<TData> = () => UseQueryResult<Array<TData>>;

export type UseInfiniteTable<TData extends object> = {
  defaultSortBy?: Array<SortingRule<TData>>;
  defaultFilters?: Filters<TData>;
  columns: Array<TableColumn<TData>>;
  dataHook: PaginatedDataHook<TData>;
  dataHookUrl?: string;
  mapDataFn?: (row: TData) => TData & Record<string, any>;
  expandRow?: ExpandRowType<TData>;
  rowStyle?: (params: any) => CSSProperties;
  isUrlSyncEnabled?: boolean;
  name: string;
  onSelected?: (rowIds: Array<string>) => void;
};

export type UseSmartTable<TData extends object> = {
  defaultSortBy?: UseSortByState<TData>['sortBy'];
  expanded?: TableState<TData>['expanded'];
  defaultFilters?: Filters<TData>;
  columns: Array<TableColumn<TData>>;
  data: Array<TData>;
  expandRow?: ExpandRowType<TData>;
  rowStyle?: (params: TData) => CSSProperties;
  onSelected?: (rowIds: Array<string>) => void;
  selected?: Array<string>;
  isUrlSyncEnabled?: boolean;
  noColumnsSelection?: boolean;
  pageSize?: number;
  name: string;
};

export type TableColumnSortValue<TData extends object> = (
  rowA: Row<TData>,
  rowB: Row<TData>
) => number;
