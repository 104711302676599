import { motion } from 'framer-motion';
import React, { FC, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { useAppConfig } from '@portals/framework/context';
import { getPartnerConfig } from '@portals/redux';
import { getPartnerConfig as getPartnerConfigAction } from '@portals/redux/actions/ui';
import { Loader } from '@portals/ui';

type ShopProps = {
  children: ReactNode;
  isPreview?: boolean;
  className?: string;
};

export const Shop: FC<ShopProps> = ({ children, className = '' }) => {
  const { tenantType, extraLayout } = useAppConfig();
  const config = useSelector(getPartnerConfig);
  const dispatch = useDispatch();

  useEffect(() => {
    if (config) return;

    dispatch(getPartnerConfigAction(tenantType));
  }, [config, dispatch, tenantType]);

  if (!config)
    return (
      <Container className={className}>
        <Loader />
      </Container>
    );

  return (
    <>
      {children}

      {extraLayout?.globalLayout ? extraLayout.globalLayout(config) : null}
    </>
  );
};

const Container = styled(motion.div)`
  display: flex;
  min-height: 100vh;
  width: 100%;
  position: relative;
  flex-direction: column;
`;

export default Shop;
