import React, { ReactNode } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import { Page404 } from '@portals/framework';

type ErrorBoundaryState = {
  error: null | Record<string, any>;
  errorInfo: null | {
    componentStack: string;
  };
};

type ErrorBoundaryProps = {
  children: ReactNode;
};

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps & RouteComponentProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps & RouteComponentProps) {
    super(props);

    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  componentDidUpdate(
    prevProps: Readonly<ErrorBoundaryProps & RouteComponentProps>,
    prevState: Readonly<ErrorBoundaryState & RouteComponentProps>
  ) {
    const isPathnameChanged =
      this.props.location.pathname !== prevProps.location.pathname;
    const isErrorVisible = !!this.state.error || !!this.state.errorInfo;

    if (isPathnameChanged && isErrorVisible) {
      this.removeError();
    }
  }

  removeError = () => this.setState({ error: null, errorInfo: null });

  render() {
    if (!this.state.errorInfo) {
      return this.props.children;
    }

    return (
      <Page404
        title={['Oops...', 'Something went wrong']}
        description={
          <div>
            Please try again or contact support at{' '}
            <a href="mailto:support@xyte.io">support@xyte.io</a>
          </div>
        }
      />
    );
  }
}

export default withRouter(ErrorBoundary);
