import {
  castArray,
  endsWith,
  find,
  findIndex,
  forEach,
  includes,
  isNil,
  isUndefined,
  map,
  startsWith,
  toString,
  isNumber,
  toNumber,
} from 'lodash/fp';
import { FilterValue, IdType } from 'react-table';

import {
  TableColumn,
  TableFilterTypeEnum,
  TableState,
} from '@portals/types/Table';

import { RESULTS_PER_PAGE } from '../constants';

// Adjust filter value for special array types - TableFilterTypeEnum.Date &
// TableFilterTypeEnum.Number
// Example:
// - { id: 'date', value: { gte: January 1st, lte: January 5th } } =>
//   [{ id: 'date[]', value: January 1st }, { id: 'date[]', value: January 5th }]
//
// - { id: 'foo', value: 1 } =>
//   { id: 'foo', value: 1 }
export function getFilterSearchParams(
  filter: { id: IdType<any>; value: FilterValue },
  column?: TableColumn
) {
  let gte, lte, params;

  switch (column?.filter?.type) {
    case TableFilterTypeEnum.Date:
      gte = filter.value?.gte;
      lte = filter.value?.lte;

      if (!gte && !lte) return null;

      params = [
        {
          id: `${filter.id}[]`,
          value: isNil(gte) ? '' : gte.toISOString(),
        },
        {
          id: `${filter.id}[]`,
          value: isNil(lte) ? '' : lte.toISOString(),
        },
      ];

      return params;

    case TableFilterTypeEnum.Number:
      gte = filter.value?.gte;
      lte = filter.value?.lte;

      if (!isNumber(gte) && !isNumber(lte)) return null;

      params = [
        {
          id: `${filter.id}[]`,
          value: isUndefined(gte) ? '' : gte,
        },
        {
          id: `${filter.id}[]`,
          value: isUndefined(lte) ? '' : lte,
        },
      ];

      return params;

    case TableFilterTypeEnum.Select:
      return map(
        (value) => ({
          id: `${filter.id}[]`,
          value,
        }),
        filter.value
      );

    case TableFilterTypeEnum.Text:
    default:
      return filter;
  }
}

// Checks if a given URL param key is an 'array' param
// Examples:
// `?foo[]=1` => true
// `?bar=1` => false
const getIsArrayParam = (urlParamKey: string) => endsWith('[]', urlParamKey);

// Returns the table filter key from a given URL param key
// Examples:
// `?foo[]` => `foo`
// `?bar` => `bar`
const getTableFilterValueFromUrlParam = (urlParamKey: string) => {
  if (getIsArrayParam(urlParamKey)) {
    return urlParamKey.slice(0, -2);
  }

  return urlParamKey;
};

// Return adjusted table filter value from a given URL param value & its column type
const getFieldValueFromUrlParamValue = (
  paramValue: string,
  fieldType?: TableFilterTypeEnum
) => {
  switch (fieldType) {
    case TableFilterTypeEnum.Date:
      return paramValue ? new Date(paramValue) : null;
    case TableFilterTypeEnum.Number:
      return Number(paramValue);
    case TableFilterTypeEnum.Select:
    default:
      return paramValue;
  }
};

// Helper util for turning a URL search param into a filter value
// Examples:
// - '?foo[]=1&foo[]=2' => { id: 'foo', value: { gte: 1, lte: 2 } }
// - '?bar=1' => { id: 'bar', value: 1 }
const pushFilterValueFromUrlParam = (
  urlParamKey: string,
  urlParamValue: string,
  filterValues: Array<{
    id: string;
    value: string | { gte: Date | number; lte?: Date | number } | Array<string>;
  }>,
  columns: Array<TableColumn>
) => {
  const fieldKey = getTableFilterValueFromUrlParam(urlParamKey);
  const fieldType = find({ dataField: fieldKey }, columns)?.filter?.type;
  const fieldValue = getFieldValueFromUrlParamValue(urlParamValue, fieldType);

  const isArrayParam = getIsArrayParam(urlParamKey);

  if (isArrayParam) {
    const filterIndex = findIndex({ id: fieldKey }, filterValues);

    if (
      fieldType === TableFilterTypeEnum.Date ||
      fieldType === TableFilterTypeEnum.Number
    ) {
      if (filterIndex === -1) {
        filterValues.push({
          id: fieldKey,
          value: {
            gte: fieldValue as Date | number,
          },
        });
      } else {
        // @ts-ignore
        filterValues[filterIndex].value.lte = fieldValue;
      }
    } else if (fieldType === TableFilterTypeEnum.Select) {
      if (filterIndex === -1) {
        filterValues.push({
          id: fieldKey,
          value: [fieldValue as string],
        });
      } else {
        (filterValues[filterIndex].value as Array<string>).push(
          fieldValue as string
        );
      }
    }
  } else {
    filterValues.push({
      id: fieldKey,
      value: fieldValue as string,
    });
  }
};

// Returns table's filter values from URL params
export const getTableStateFromSearchParams = <TData extends object>(
  urlSearch: string,
  columns: Array<TableColumn<TData>>
) => {
  let adjustedUrlSearchParams = urlSearch;

  if (startsWith('?', adjustedUrlSearchParams)) {
    adjustedUrlSearchParams = adjustedUrlSearchParams.slice(1);
  }

  const urlSearchParams = new URLSearchParams(adjustedUrlSearchParams);

  const initialTableState: Partial<
    Pick<TableState<TData>, 'sortBy' | 'filters' | 'pageIndex' | 'pageSize'>
  > = {
    pageIndex: 0,
    pageSize: RESULTS_PER_PAGE,
  };

  urlSearchParams.forEach((value, key) => {
    if (includes(key, ['sort_order', 'per_page'])) return;
    else if (key === 'sort_by') {
      initialTableState.sortBy = [
        {
          id: value,
          desc: urlSearchParams.get('sort_order') === 'desc',
        },
      ];
    } else if (key === 'page') {
      initialTableState.pageIndex = toNumber(urlSearchParams.get('page')) - 1;
    } else {
      initialTableState.filters ||= [];

      pushFilterValueFromUrlParam(
        key,
        value,
        initialTableState.filters,
        columns
      );
    }
  });

  return initialTableState;
};

// Builds a request URL from a table state
export const buildUrlFromTableState = <TData extends object>({
  url,
  columns,
  tableState,
}: {
  url: string;
  tableState: Partial<
    Pick<TableState<TData>, 'sortBy' | 'filters' | 'pageIndex' | 'pageSize'>
  >;
  columns?: Array<TableColumn<TData>>;
}) => {
  const requestUrl = new URL(url);

  const tableStateSearchParams = getSearchParamsFromTableState(
    tableState,
    columns
  );

  forEach(({ key, value }) => {
    requestUrl.searchParams.append(key, value);
  }, tableStateSearchParams);

  return requestUrl.toString();
};

// Returns URL search params for a given table state
// Columns are used to determine filter types - text, date, number...
export const getSearchParamsFromTableState = <TData extends object>(
  {
    filters,
    sortBy,
    pageIndex,
    pageSize,
  }: Partial<
    Pick<TableState<TData>, 'sortBy' | 'filters' | 'pageIndex' | 'pageSize'>
  >,
  columns?: Array<TableColumn>
) => {
  const searchParams: Array<{ key: string; value: string }> = [];

  // Column filters params
  forEach((filter) => {
    const column = find({ dataField: filter?.id }, columns);
    const filterSearchParams = castArray(getFilterSearchParams(filter, column));

    forEach((currFilter) => {
      if (currFilter) {
        searchParams.push({ key: currFilter.id, value: currFilter.value });
      }
    }, filterSearchParams);
  }, filters);

  // Columns sort by params
  forEach(({ id, desc }) => {
    searchParams.push({ key: 'sort_by', value: id });
    searchParams.push({ key: 'sort_order', value: desc ? 'desc' : 'asc' });
  }, sortBy);

  searchParams.push({ key: 'page', value: toString(pageIndex + 1) });
  searchParams.push({ key: 'per_page', value: toString(pageSize) });

  return searchParams;
};
