import { createGlobalStyle, css } from 'styled-components';

import { getStyledThemeColor } from '@portals/utils';

export default createGlobalStyle`
  html,
  body {
    overflow: hidden;
    height: 100vh;
  }

  body {
    transition: background-color 0.15s ease-in-out;
    background-color: ${getStyledThemeColor('white')};
  }
`;
