import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addLocale(en);

const timeAgoFormatter = new TimeAgo('en-US');

export const timeAgo = (time: number): string =>
  time ? (timeAgoFormatter.format(time) as string) : 'None';

export const prettyTime = (
  time: number | string | null | undefined
): string => {
  if (!time) {
    return 'Unknown';
  }

  const dateObject = new Date(time);

  return (
    dateObject.toLocaleDateString() + ' ' + dateObject.toLocaleTimeString()
  );
};

export const zeroPad = (num: number, places: number): string =>
  String(num).padStart(places, '0');

export const formatUTC = (offset: number): string => {
  const absOffset = Math.abs(offset);

  if (typeof offset === 'undefined') {
    return 'Unknown';
  }

  let str = `UTC ${offset >= 0 ? '+' : '-'}`;
  str += zeroPad(Math.floor(absOffset / 60), 2);
  str += ':';
  str += zeroPad(absOffset % 60, 2);

  return str;
};
