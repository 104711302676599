import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { getStyledThemeColor } from '@portals/utils';

type EmptyStateProps = {
  label: string;
  src?: string;
  children?: ReactNode;
};

const EmptyState: FC<EmptyStateProps> = ({
  label = 'No data',
  src = 'empty-state',
  children = null,
}) => (
  <Container className="empty-state-container">
    <img src={`${src}.svg`} alt="" className="mb-3" />

    <div>{label}</div>

    {children}
  </Container>
);

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: ${getStyledThemeColor('gray500')};
`;

export default EmptyState;
