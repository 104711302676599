import {
  Divider,
  Group,
  Select,
  Stack,
  TextInput,
  Checkbox,
} from '@mantine/core';
import React, { FC, useState } from 'react';
import Save from 'react-feather/dist/icons/save';
import XCircle from 'react-feather/dist/icons/x-circle';
import { Button } from 'reactstrap';

import { TenantType, UserType, UuidType } from '@portals/types';

type TableProps = {
  group: UserType;
  users: Record<UuidType, UserType>;
  tenantType: TenantType;
  onCancel: () => void;
  addGroupMember: (
    groupId: UuidType,
    memberId: UuidType,
    admin: boolean
  ) => void;
};

const AddGroupMember: FC<TableProps> = ({
  group,
  users,
  tenantType,
  onCancel,
  addGroupMember,
}) => {
  const [localUser, setLocalUser] = useState('');
  const [remoteUser, setRemoteUser] = useState('');
  const [admin, setAdmin] = useState(false);

  const handleAdd = () => {
    addGroupMember(group.id, localUser || remoteUser, admin);

    setLocalUser('');
    setRemoteUser('');
  };

  const options = Object.values(users)
    .filter(
      (user) =>
        user.local &&
        !user.is_group &&
        !Object.keys(group.users).some((member) => member === user.id)
    )
    .map((user) => ({
      label: `${user.name} (${user.email})`,
      value: user.id,
    }));

  return (
    <Stack>
      <Select
        data={options}
        value={localUser}
        placeholder={`Add user from this ${tenantType}`}
        onChange={(value) => {
          setLocalUser(value);
          setRemoteUser('');
        }}
      />

      {tenantType === TenantType.Organization && (
        <>
          <Divider label="OR" variant="dashed" labelPosition="center" />

          <TextInput
            type="text"
            id={`group_${group.id}_remote_user`}
            placeholder={`Add user from another ${tenantType}`}
            name="customSelect"
            value={remoteUser}
            onChange={(e) => {
              setRemoteUser(e.target.value);
              setLocalUser('');
            }}
          />
        </>
      )}

      <Checkbox
        className="mt-3"
        checked={admin}
        id={`group_${group.id}_admin`}
        label="Add as group admin"
        onChange={() => setAdmin(!admin)}
      />

      <Group position="right">
        <Button
          className="float-right ml-2 d-flex align-items-center"
          color="light"
          onClick={onCancel}
        >
          <XCircle size={17} className="mr-2" />
          Close
        </Button>
        <Button
          className="float-right d-flex align-items-center"
          color="primary"
          disabled={!localUser && !remoteUser}
          onClick={handleAdd}
        >
          <Save size={17} className="mr-2" />
          Save
        </Button>
      </Group>
    </Stack>
  );
};

export default AddGroupMember;
