import { uniq, compact } from 'lodash/fp';
import React, { useState, useMemo, FC } from 'react';
import { connect } from 'react-redux';
import { Row, Button, Col } from 'reactstrap';

import { setRoute } from '@portals/redux/actions/routing';
import { ProductType, SetRoute } from '@portals/types';

import ProductCard from './ProductCard';

type ProductsListProps = {
  products: Record<string, ProductType>;
};

type ProductsListConnectedActions = {
  setRoute: SetRoute;
};

const ProductsList: FC<ProductsListProps & ProductsListConnectedActions> = ({
  products,
  setRoute,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const categories = useMemo(
    () =>
      compact(uniq(Object.values(products).map((product) => product.category))),
    [products]
  );

  const visibleProducts = useMemo(() => {
    const productArray = Object.values(products);

    if (!selectedCategory) {
      return productArray;
    }

    return productArray.filter(
      (product) => product.category === selectedCategory
    );
  }, [products, selectedCategory]);

  return (
    <div>
      <div className="text-center mb-4">
        <Button
          className="btn-pill mr-2 ml-2"
          onClick={() => setSelectedCategory(null)}
          outline={selectedCategory !== null}
        >
          ALL
        </Button>

        {categories.map((category) => (
          <Button
            key={category}
            className="btn-pill m-2 text-uppercase"
            onClick={() => setSelectedCategory(category)}
            outline={selectedCategory !== category}
          >
            {category}
          </Button>
        ))}
      </div>

      <Row>
        {visibleProducts.map((product) => (
          <Col
            md="4"
            key={product.id}
            onClick={() =>
              !product.disabled && setRoute(`/integrations/${product.id}`)
            }
          >
            <ProductCard {...product} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default connect<null, ProductsListConnectedActions>(null, { setRoute })(
  ProductsList
);
