import { Box, createStyles, Stack } from '@mantine/core';
import { isEmpty, map, compact } from 'lodash/fp';
import React, { useMemo } from 'react';
import { UseQueryResult } from 'react-query';

import { PaginationResponse } from '@portals/types';
import { InfiniteTableProps } from '@portals/types/Table';
import { usePaginatedQueryContext, useTableInstance } from '@portals/ui/Table';

import {
  LoadingState,
  NoDataState,
  NoFiltersResultsState,
} from '../components/data-states';
import Footer from '../components/Footer';
import Header from '../components/Header';
import TableScrollWrapper from '../components/TableScrollWrapper';
import TBody from '../components/TBody';
import THead from '../components/THead';

function InfiniteTable<TData extends object>({
  name,
  noDataIndication,
  additionalActions,
  noExport = false,
  noHeader = false,
  noFilters = false,
  isCompact = false,
  rowMenu,
  expandRow,
  selectedItemsActions,
}: InfiniteTableProps<TData>) {
  const { classes } = useStyles({ noHeader });
  const instance = useTableInstance();
  const { data, state, rows } = instance;

  const query: UseQueryResult<PaginationResponse<TData>> =
    usePaginatedQueryContext();
  const totalCount = query?.data?.page_info?.total_count || 0;
  const isFetching = query.isFetching;

  const filterValues = useMemo(
    () => compact(map('filterValue', instance.allColumns)),
    [instance.allColumns]
  );

  const content = useMemo(() => {
    if (!state) return null;

    if (isEmpty(data) && (isEmpty(filterValues) || isCompact)) {
      return (
        <>
          <TableScrollWrapper isCompact={isCompact}>
            <div />
            <div />
          </TableScrollWrapper>

          {isFetching ? (
            <LoadingState />
          ) : (
            <NoDataState {...noDataIndication} />
          )}
        </>
      );
    }

    if (isEmpty(data)) {
      return (
        <>
          <TableScrollWrapper isCompact={isCompact}>
            <THead<TData> noFilters={noFilters} isCompact={isCompact} />

            <div />
          </TableScrollWrapper>

          {isFetching ? <LoadingState /> : <NoFiltersResultsState />}
        </>
      );
    }

    return (
      <>
        <TableScrollWrapper isCompact={isCompact}>
          <THead<TData> noFilters={noFilters} isCompact={isCompact} />

          <TBody<TData>
            data={rows}
            isCompact={isCompact}
            rowMenu={rowMenu}
            expandRow={expandRow}
          />
        </TableScrollWrapper>

        <Footer isCompact={isCompact} totalCount={totalCount} />

        {isFetching ? <LoadingState /> : null}
      </>
    );
  }, [
    data,
    state,
    filterValues,
    isCompact,
    noFilters,
    rows,
    rowMenu,
    expandRow,
    totalCount,
    isFetching,
    noDataIndication,
  ]);

  return (
    <Stack className={classes.container}>
      {noHeader ? null : (
        <Box className="header-container" mb="md">
          <Header<TData>
            name={name}
            noExport={noExport}
            additionalActions={additionalActions}
            isCompact={isCompact}
            selectedItemsActions={selectedItemsActions}
          />
        </Box>
      )}

      {content}
    </Stack>
  );
}

const useStyles = createStyles(
  (theme, { noHeader }: { noHeader: boolean }) => ({
    container: {
      height: '100%',
      width: '100%',
      display: 'grid',
      gridTemplateRows: noHeader ? '1fr' : 'max-content 1fr',
      position: 'relative',
      gap: 0,
    },
  })
);

export default InfiniteTable;
