import { ActionIcon, Group, Popover, Stack, Title } from '@mantine/core';
import { filter, includes, lowerCase } from 'lodash/fp';
import React, { FC, useMemo, useState } from 'react';

import { SearchInput } from '@portals/core';
import { CloseX, Setting2 } from '@portals/icons/linear';

import ColumnOption from './ColumnOption';

type ColumnsSettingsProps = {
  columns: Array<Record<string, any>>;
  onColumnToggle: (column: string) => void;
};

const ColumnsSettings: FC<ColumnsSettingsProps> = ({ columns }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredOptions = useMemo(
    () =>
      filter(
        ({ Header, isAction }) =>
          !isAction && includes(lowerCase(searchTerm), lowerCase(Header)),
        columns
      ),
    [columns, searchTerm]
  );

  return (
    <Popover
      opened={isOpen}
      position="left"
      placement="start"
      closeOnEscape
      onClose={() => setIsOpen(false)}
      spacing="xl"
      shadow="md"
      target={
        <ActionIcon
          onClick={() => setIsOpen(true)}
          styles={{
            root: {
              svg: {
                width: 18,
                height: 18,
              },
            },
          }}
          sx={(theme) =>
            isOpen
              ? {
                  svg: {
                    path: {
                      stroke: theme.colors.blue_accent[4],
                    },
                  },
                }
              : {}
          }
        >
          <Setting2 />
        </ActionIcon>
      }
    >
      <Stack
        sx={{
          width: 280,
        }}
      >
        <Group position="apart" mb="xs">
          <Title order={5} sx={{ fontWeight: 600 }}>
            Customize Columns
          </Title>

          <ActionIcon
            onClick={() => setIsOpen(false)}
            sx={{
              svg: {
                width: 16,
                height: 16,
              },
            }}
          >
            <CloseX />
          </ActionIcon>
        </Group>

        <SearchInput
          placeholder="Search column..."
          mb="sm"
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          onClear={() => setSearchTerm('')}
          sx={{
            width: '100%',
          }}
        />

        <Stack>
          {filteredOptions.map((column) => (
            <ColumnOption key={column.id} option={column} />
          ))}
        </Stack>
      </Stack>
    </Popover>
  );
};

export default ColumnsSettings;
