import { AspectRatio, Grid, Group, Image, Stack, Text } from '@mantine/core';
import React, { FC, ReactNode, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { PoweredByXyteIcon } from '@portals/framework';
import { useAppConfig } from '@portals/framework/context';
import { getPartnerConfig } from '@portals/redux/actions/ui';
import { ExtraLayoutType, PartnerConfigType } from '@portals/types';
import { Loader } from '@portals/ui';

type AuthConnectedProps = {
  config: PartnerConfigType;
};

type AuthConnectedActions = {
  getPartnerConfig: (tenantType: string) => void;
};

type AuthProps = {
  children: ReactNode;
  extraLayout?: ExtraLayoutType;
  isPreview?: boolean;
  className?: string;
};

export const Auth: FC<
  AuthProps & AuthConnectedProps & AuthConnectedActions
> = ({ config, children, getPartnerConfig, isPreview = false }) => {
  const { extraLayout = {}, tenantType } = useAppConfig();
  const { options = {} } = extraLayout;

  useEffect(() => {
    if (isPreview) return;

    getPartnerConfig(tenantType);
  }, [getPartnerConfig, isPreview, tenantType]);

  if (!config) return <Loader />;

  return (
    <>
      {extraLayout?.globalLayout && !isPreview
        ? extraLayout.globalLayout(config)
        : null}

      <Grid
        sx={(theme) => ({
          height: '100%',
          backgroundColor: theme.white,
          position: 'relative',
        })}
        m={0}
      >
        <Logo
          logo={config.logo || 'logo.png'}
          style={options.isMobile ? { left: 'unset', right: 36, top: 40 } : {}}
        />

        {options.isMobile ? null : (
          <Grid.Col
            lg={4}
            md={3}
            sx={(theme) => ({
              backgroundColor:
                config?.hero_gradient_primary || theme.colors.blue_accent[4],
            })}
          >
            <Stack
              align="center"
              justify="center"
              style={{
                height: '100%',
                position: 'relative',
                transform: 'translateY(-100px)',
              }}
            >
              <Group style={{ width: 305 }} position="center">
                <AspectRatio
                  ratio={1}
                  sx={(theme) => ({
                    maxWidth: 305,
                    width: '100%',

                    [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
                      maxWidth: 250,
                    },
                  })}
                  mb="md"
                >
                  <Image src={config.hero || 'logo_full.svg'} mb="lg" />
                </AspectRatio>

                <Text
                  align="center"
                  sx={(theme) => ({
                    color: config.tagline_color || theme.white,
                    fontWeight: 600,
                    fontSize: 25,
                    lineHeight: '34px',
                    letterSpacing: '0.01em',
                    padding: '0 10px',
                    wordBreak: 'break-word',
                  })}
                >
                  {config.tagline || 'Supercharging your HaaS business'}
                </Text>
              </Group>

              {config.powered_by_xyte_label !== false ? (
                <PoweredByXyteIcon
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    mixBlendMode: config.hero_gradient_primary
                      ? 'difference'
                      : 'unset',
                  }}
                />
              ) : null}
            </Stack>
          </Grid.Col>
        )}

        <Grid.Col lg={8} md={9}>
          <Group
            style={{ height: '100%', width: '100%' }}
            grow
            position="center"
          >
            <ContentWrapper>{children}</ContentWrapper>
          </Group>
        </Grid.Col>
      </Grid>
    </>
  );
};

const ContentWrapper = styled.div`
  max-width: 470px !important;
  width: 470px;
  padding: 0 35px;
`;

const Logo = styled.div<{ logo: string }>`
  position: absolute;
  top: 36px;
  left: 36px;
  width: 50px;
  height: 50px;
  background-image: url(${({ logo }) => logo});
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
`;

const mapStateToProps = (state) => ({
  config: state.ui.partnerConfig,
});

export default connect<AuthConnectedProps, AuthConnectedActions>(
  mapStateToProps,
  {
    getPartnerConfig,
  }
)(Auth);
