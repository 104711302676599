import { Box, createStyles } from '@mantine/core';
import { GeoJsonObject } from 'geojson';
import React, { FC } from 'react';

import { MAX_ZOOM } from './constants';

interface ClusterProps {
  item: GeoJsonObject;
  totalPoints: number;
  zoomToCluster: (cluster: GeoJsonObject) => void;
  zoom: number;
  color: string;
  lat: number;
  lng: number;
}

export const Cluster: FC<ClusterProps> = ({
  item,
  totalPoints,
  zoomToCluster,
  zoom,
  color,
  lat,
  lng,
}) => {
  const { classes, cx } = useStyles(color);

  const pointCount = item.properties.point_count;

  return (
    <Box
      onClick={() => zoom !== MAX_ZOOM && zoomToCluster(item)}
      className={cx(classes.clusterContainer, {
        [classes.maxZoom]: zoom === MAX_ZOOM,
      })}
      style={{
        width: `${10 + (pointCount / totalPoints) * 35}px`,
        height: `${10 + (pointCount / totalPoints) * 35}px`,
      }}
      //@ts-ignore
      lat={lat}
      lng={lng}
    >
      {pointCount}
    </Box>
  );
};

const useStyles = createStyles((theme, color: string) => ({
  clusterContainer: {
    cursor: 'pointer',
    color: theme.white,
    borderRadius: '50%',
    border: `1px solid ${theme.white}`,
    padding: theme.spacing.xs,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 30,
    minHeight: 30,
    backgroundColor: theme.colors[color][4],

    '&:hover': {
      borderColor: theme.colors.gray[1],
    },
  },
  maxZoom: {
    cursor: 'not-allowed',
  },
}));
