import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';

import { changePassword } from '@portals/redux/actions/auth';
import AutoFormik, { FieldType } from '@portals/ui/AutoFormik';

const FIELDS: Array<FieldType> = [
  {
    name: 'old_password',
    title: 'Old Password',
    type: 'password',
    required: true,
  },
  {
    name: 'new_password',
    title: 'New Password',
    type: 'password',
    required: true,
    validations: Yup.string().min(6),
  },
  {
    name: 'confirm',
    title: 'Confirm Password',
    type: 'password',
    required: true,
    validations: Yup.string().oneOf(
      [Yup.ref('new_password'), null],
      'Passwords must match'
    ),
  },
];

const ChangePassword = ({ closeMe, changePassword }) => {
  const handleSubmit = (data) => changePassword(data, closeMe);

  return (
    <Modal isOpen={true} toggle={closeMe} centered>
      <ModalHeader toggle={closeMe}>Change Password</ModalHeader>

      <AutoFormik
        fields={FIELDS}
        handleSubmit={handleSubmit}
        inProgress={false}
        modal={closeMe}
        submitTitle="Change"
      />
    </Modal>
  );
};

export default connect(null, { changePassword })(ChangePassword);
