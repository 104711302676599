import { Button, Group, Modal, TextInput } from '@mantine/core';
import React, { PropsWithChildren, useState } from 'react';
import { FilterProps, UseFiltersColumnProps } from 'react-table';

import { suppressPropagation } from '@portals/utils';

interface TextFilterProps<TData extends object> {
  closeMe: () => void;
  data: {
    column: FilterProps<TData>['column'] & UseFiltersColumnProps<TData>;
    placeholder?: string;
  };
}

function TextFilter<TData extends object>({
  closeMe,
  data,
}: PropsWithChildren<TextFilterProps<TData>>) {
  const { column, placeholder } = data;

  const [localValue, setLocalValue] = useState<string | ''>(
    column?.filterValue || ''
  );

  const onSubmit = () => {
    column.setFilter(localValue);
    closeMe();
  };

  const onClear = () => {
    column.setFilter(null);
    closeMe();
  };

  return (
    <Modal
      opened={true}
      onClose={closeMe}
      size="lg"
      padding={32}
      title={`Filter by '${data.column.Header}'`}
    >
      <form onSubmit={suppressPropagation(onSubmit)}>
        <Group align="center" sx={{ width: '100%' }} mt={30}>
          <TextInput
            data-autofocus
            name="value"
            placeholder={placeholder || (data.column.Header as string)}
            value={localValue}
            onChange={(event) => setLocalValue(event.target.value)}
            sx={{
              flex: 1,
            }}
          />
        </Group>

        <Group position="right" mt={50}>
          <Button
            variant="subtle"
            color="blue_gray"
            size="sm"
            onClick={closeMe}
          >
            Cancel
          </Button>

          <Button
            variant="outline"
            color="blue_gray"
            size="sm"
            onClick={onClear}
          >
            Clear Filters
          </Button>

          <Button size="sm" type="submit" disabled={!localValue}>
            Apply Filters
          </Button>
        </Group>
      </form>
    </Modal>
  );
}

export default TextFilter;
