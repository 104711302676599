import {
  RangeCalendar as MantineRangeCalendar,
  RangeCalendarProps,
} from '@mantine/dates';
import React from 'react';

const RangeCalendar = (props: RangeCalendarProps) => (
  <MantineRangeCalendar
    amountOfMonths={2}
    size="sm"
    styles={(theme) => ({
      calendarBase: {
        height: '100%',
        width: '100%',
        maxWidth: 'unset',
        gap: 0,

        '>div': {
          flex: 1,
          width: '100%',
          display: 'grid',
          padding: '15px 20px',
          gridTemplateRows: 'max-content 1fr',

          '&:first-of-type': {
            borderRight: `1px solid ${theme.colors.gray[3]}`,
          },
        },
      },
      calendarHeader: {
        width: '100%',
        marginBottom: 20,
      },
      month: {
        width: '100%',
      },
    })}
    {...props}
  />
);

export default RangeCalendar;
