import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat';

export enum LocaleEnum {
  en = 'en',
  de = 'de-DE',
  es = 'es-ES',
}

export type LocaleEnumType = LocaleEnum;

export type LocalesType = Record<LocaleEnumType, Record<string, string>>;

export type i18nConfigType = Record<
  string,
  PrimitiveType | FormatXMLElementFn<string, string>
>;
