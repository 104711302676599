import AddUser from './AddUser';
import ChangePassword from './ChangePassword';
import ConfirmationModal from './ConfirmationModal';
import CreateGroup from './CreateGroup';
import DeleteReferralCode from './DeleteReferralCode';
import FeatureFlagsModal from './FeatureFlagsModal';
import filtersModals from './filters';
import ManagedPermissions from './ManagedPermissions';
import ShowHash from './ShowHash';
import SwitchTenant from './SwitchTenant';
import UpdateInvoiceNote from './UpdateInvoiceNote';
import UploadImage from './UploadImage';

const MODALS = {
  ChangePassword,
  AddUser,
  ShowHash,
  CreateGroup,
  ConfirmationModal,
  DeleteReferralCode,
  FeatureFlagsModal,
  ManagedPermissions,
  UploadImage,
  UpdateInvoiceNote,
  SwitchTenant,
  ...filtersModals,
};

export type { FeatureFlagsModalProps } from './FeatureFlagsModal';

export default MODALS;
