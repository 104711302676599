import { replace } from 'lodash/fp';
import { useMutation } from 'react-query';

import { fetchApiRequest, getRequestOptions } from '@portals/api';
import { useAuth } from '@portals/redux';
import { AuthType } from '@portals/types';

interface FileUploadResponse {
  path: string;
  signedUrl: string;
}

const UPLOAD_URL = 'ui/file_upload';
const S3_PATH = '/uploads/';

const scrubFilename = (fileName: string) =>
  replace(/[^\w\d_\-.]+/gi, '', fileName);

const generateSignedUrl = async (auth: AuthType, file: File) => {
  const { url, options } = getRequestOptions(
    {
      url: UPLOAD_URL,
      method: 'POST',
      data: {
        objectName: file.name,
        contentType: file.type,
        path: S3_PATH,
      },
    },
    auth
  );

  const { signedUrl }: FileUploadResponse = await fetchApiRequest(url, options);

  return signedUrl;
};

const uploadFileToS3 = async (signedUrl: string, file: File) =>
  await fetchApiRequest(signedUrl, {
    method: 'PUT',
    body: file,
    headers: new Headers({
      'x-amz-acl': 'public-read',
      'content-type': file.type,
    }),
  });

export const useUploadFileToS3 = () => {
  const auth = useAuth();

  return useMutation({
    mutationFn: async ({
      originalFileName,
      blob,
    }: {
      originalFileName: string;
      blob: Blob;
    }): Promise<string> => {
      const fileName = scrubFilename(originalFileName);
      const file = new File([blob], fileName, {
        type: blob.type,
      });

      // Generate signed URL for S3 upload
      const signedUrl = await generateSignedUrl(auth, file);

      // Upload file using the newly generated URL
      await uploadFileToS3(signedUrl, file);

      return signedUrl.split('?').shift() as string;
    },
  });
};
