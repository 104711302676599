import { createStyles, Menu, Text } from '@mantine/core';
import React, { FC } from 'react';

import { usePortalProtocolAndDomain } from '@portals/framework';
import { Export2, More, UserSquare } from '@portals/icons/linear';
import { useOpenModal } from '@portals/redux';
import { CustomerWithControlledType } from '@portals/types';

interface CustomerMenuProps {
  customer: CustomerWithControlledType;
}

export const CustomerMenu: FC<CustomerMenuProps> = ({ customer }) => {
  const { classes } = useStyles();
  const { protocolAndDomain } = usePortalProtocolAndDomain();
  const openModal = useOpenModal();

  return (
    <Menu
      trigger="click"
      classNames={classes}
      control={<More className={classes.menuIcon} />}
    >
      <Menu.Item icon={<UserSquare width={16} height={16} />}>
        <Text
          onClick={() => openModal('CustomerView', { customer })}
          size="sm"
          className={classes.viewLink}
        >
          View Customer
        </Text>
      </Menu.Item>

      {customer.controlled && (
        <Menu.Item icon={<Export2 width={16} height={16} />}>
          <Text<'a'>
            component="a"
            href={`${protocolAndDomain}?org=${customer.id}`}
            target="_blank"
            size="sm"
            className={classes.viewLink}
          >
            Visit Portal
          </Text>
        </Menu.Item>
      )}
    </Menu>
  );
};

const useStyles = createStyles((theme) => ({
  item: {
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
  },
  itemBody: {
    padding: 0,
  },
  menuIcon: {
    color: theme.colors.gray[4],
    fill: theme.colors.gray[4],

    '&:hover': {
      fill: theme.black,
      color: theme.black,
    },
  },
  viewLink: {
    '&:hover': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
}));
