import { useLocalStorage } from '@mantine/hooks';
import { isEqual } from 'lodash/fp';
import { usePrevious, useUpdateEffect } from 'react-use';

import { UseSmartTable } from '@portals/types/Table';

import { useCreateTableInstance, useTableStateWithUrlSync } from '../hooks';

export function useSmartTable<TData extends object>({
  defaultSortBy = [],
  defaultFilters = [],
  columns = [],
  data = [],
  expandRow,
  onSelected,
  rowStyle,
  isUrlSyncEnabled = true,
  name,
  selected,
  expanded,
  pageSize,
}: UseSmartTable<TData>) {
  const [hiddenColumns, setHiddenColumns] = useLocalStorage({
    key: `${name}.hiddenColumns`,
    defaultValue: [],
  });

  const [searchParams, setSearchParams] = useTableStateWithUrlSync<TData>({
    sortBy: defaultSortBy,
    filters: defaultFilters,
    hiddenColumns,
    isUrlSyncEnabled,
    columns,
    expanded,
    pageSize,
  });

  const tableInstance = useCreateTableInstance<TData>(data, columns, {
    expandRow,
    rowStyle,
    searchParams,
    onSelected,
    selected,
    isServerFiltering: false,
  });
  const previousTableState = usePrevious(tableInstance.state);

  useUpdateEffect(() => {
    setSearchParams(tableInstance.state);
    setHiddenColumns(tableInstance.state.hiddenColumns);

    if (
      previousTableState?.filters &&
      (!isEqual(previousTableState?.filters, tableInstance.state.filters) ||
        !isEqual(
          previousTableState?.globalFilter,
          tableInstance.state.globalFilter
        ))
    ) {
      tableInstance.state.pageIndex = 0;
    }
  }, [tableInstance.state]);

  return tableInstance;
}
