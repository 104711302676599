import * as Sentry from '@sentry/react';
import { getOr, set } from 'lodash/fp';
import { applyMiddleware, compose, createStore, Store } from 'redux';

import apiMiddleware from './middleware/api';
import debounceMiddleware from './middleware/debounce';
import deviceApiMiddleware from './middleware/device_api';
import multiMiddleware from './middleware/multi';
import routingMiddleware from './middleware/routing';
import analyticsMiddleware from './middleware/analytics';
import rootReducer from './reducers/index';

const middleware = applyMiddleware(
  multiMiddleware,
  debounceMiddleware,
  routingMiddleware,
  apiMiddleware,
  deviceApiMiddleware,
  analyticsMiddleware
);

// Hide large data & value fields from Sentry
const clearLargeData = (action) => {
  if (typeof action.payload !== 'object') {
    return action;
  }

  return set(
    'payload.data',
    '*removed*',
    set('payload.value', '*removed*', action)
  );
};

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    store?: Store;
  }
}

const composeEnhancers =
  ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) =>
    getOr(true, 'meta.log', action) === false ? null : clearLargeData(action),
  stateTransformer: (state) =>
    set('data', null, set('ui.auth.token', '*hidden*', state)),
});

const store = createStore(
  rootReducer,
  composeEnhancers(middleware, sentryReduxEnhancer)
);

// In development give access to the store from the console
// if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
// TODO: We enable store access for now
window.store = store;
// }

export default store;
