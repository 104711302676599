import { motion, AnimatePresence } from 'framer-motion';
import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

import { ModelProductType, PeriodEnum } from '@portals/types';
import { formatCurrency, getStyledThemeColor } from '@portals/utils';

import checkmarkSrc from '../assets/checkmark.svg';
import { FADE_IN_OUT } from './animations';

type PackageColumnProps = Partial<ModelProductType> & {
  onSelect: () => void;
  zIndex?: number;
  isCheckout?: boolean;
  packageType?: string;
  popularityType?: string;
};

const PERIOD_LABEL = {
  [PeriodEnum.Yearly]: 'year',
  [PeriodEnum.Monthly]: 'month',
};

const PackageColumn: FC<PackageColumnProps> = ({
  image,
  detailed_description,
  payment_interval,
  name,
  badge,
  price,
  onSelect,
  color,
  zIndex = 1,
  isCheckout = false,
}) => (
  <Container
    className="package-column-container"
    whileHover={
      isCheckout
        ? {}
        : {
            y: -10,
            boxShadow:
              '0 16px 24px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.1)',
          }
    }
    initial={{
      boxShadow:
        '0 16px 24px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04)',
    }}
    layoutId={`column-wrapper-${name}`}
    transition={{ duration: 0.25 }}
    style={{ zIndex }}
    color={color}
  >
    <Column className="package-column">
      <ProductImage productSrc={image} />

      <Header>
        <ProductName className="product-name">{name}</ProductName>

        <Pricing>
          <div className="price">{`${formatCurrency(price / 100, 'USD')}`}</div>
          {payment_interval ? (
            <div className="month-label">
              /{` ${PERIOD_LABEL[payment_interval]}`}
            </div>
          ) : null}
        </Pricing>
      </Header>

      <AnimatePresence initial={false}>
        {isCheckout ? null : (
          <SelectWrapper
            className="select-wrapper btn"
            onClick={onSelect}
            {...FADE_IN_OUT}
          >
            Select Plan
          </SelectWrapper>
        )}
      </AnimatePresence>

      <MarkdownDetails>
        <ReactMarkdown>{detailed_description}</ReactMarkdown>
      </MarkdownDetails>
    </Column>

    <AnimatePresence initial={false}>
      {!isCheckout && badge ? (
        <PopularityType
          className="popularity-type"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {badge}
        </PopularityType>
      ) : null}
    </AnimatePresence>
  </Container>
);

const Container = styled(motion.div)<{ color: string }>`
  width: 450px;
  min-height: 740px;
  position: relative;
  z-index: 2;
  flex-shrink: 0;
  background-color: ${getStyledThemeColor('white')};
  border-radius: 12px;
  border: 1px solid ${getStyledThemeColor('gray200')};
  align-self: stretch;

  &:not(:last-of-type) {
    margin-right: 70px;
  }

  &:hover {
    .popularity-type {
      top: -50px;
      opacity: 1;
    }
  }

  .select-wrapper {
    border: 1px solid ${({ theme, color }) => color || theme.color.blue};
    color: ${({ theme, color }) => color || theme.color.blue};

    &:hover {
      background-color: ${({ theme, color }) => color || theme.color.blue};
      color: ${getStyledThemeColor('white')};
    }
  }

  ${({ theme, color }) => `
    .product-name {
      background-color: ${color || theme.color.blue};
      color: ${theme.color.white};
    }

    .popularity-type {
      background-color: ${color || theme.color.blue};
    }

    .select-wrapper {
      &:hover {
        background-color: ${color || theme.color.blue};
      }
    }

    &.selected {
      .package-column {
        border-color: ${color || theme.color.blue};
      }

      .select-wrapper {
        background-color: ${color || theme.color.blue};
      }
    }
  `}
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  z-index: 3;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${getStyledThemeColor('white')};
  padding: 25px 15px;
  border-radius: 12px;
`;

const PopularityType = styled(motion.div)`
  width: calc(100% - 10px);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${getStyledThemeColor('white')};
  font-size: 13px;
  letter-spacing: 0.2em;
  position: absolute;
  top: -40px;
  left: 5px;
  border-radius: 12px 12px 0 0;
  transition: all 0.15s ease-in-out;
  text-transform: uppercase;
  opacity: 0.75;
  z-index: -1;
`;

const Pricing = styled.div`
  font-size: 35px;
  color: ${getStyledThemeColor('dark')};
  flex: 1;
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;

  .month-label {
    padding-left: 2px;
    font-size: 15px;
    font-weight: 300;
    color: ${getStyledThemeColor('gray500')};
    transform: translateY(-7px) translateX(5px);
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 15px;
`;

const ProductImage = styled.div<{ productSrc: string }>`
  border-radius: 8px;
  height: 200px;
  width: 100%;
  flex-shrink: 0;
  background-image: url(${({ productSrc }) => productSrc});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 30px;
`;

const ProductName = styled.div`
  font-size: 27px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  width: calc(100% + 32px);
  padding: 10px;
  margin-bottom: 15px;
  word-break: break-word;
`;

export const MarkdownDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding: 0 15px;
  justify-content: space-between;

  h1 {
    font-size: 20px;
    color: ${getStyledThemeColor('dark')};
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  h2,
  h3,
  h4 {
    color: ${getStyledThemeColor('gray600')};
    font-weight: normal;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  h2 {
    font-size: 15px;
  }

  h3 {
    font-size: 14px;
  }

  h4 {
    font-size: 13px;
  }

  h5 {
    font-size: 13px;
    color: ${getStyledThemeColor('gray600')};
  }

  ul {
    transform: translateX(-20px);
  }

  li {
    font-size: 13px;
    color: ${getStyledThemeColor('gray600')};
    margin-bottom: 10px;
    position: relative;
    list-style: none;

    ul {
      margin-top: 10px;
    }

    &::after {
      content: '';
      width: 12px;
      height: 12px;
      position: absolute;
      left: 0;
      top: 0;
      background-image: url(${checkmarkSrc});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      transform: translateX(-20px) translateY(2px);
    }
  }
`;

const SelectWrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  flex-shrink: 0;
  padding: 10px 20px;
  margin-bottom: 30px;
  align-self: center;

  > div {
    display: flex;
    align-items: center;
    flex: 1;
    background-color: green;
    text-align: center;
  }
`;

export default PackageColumn;
