import { Box } from '@mantine/core';
import { map } from 'lodash/fp';
import React from 'react';
import { Row, UseExpandedRowProps, UseRowSelectRowProps } from 'react-table';

import { SmartTableProps } from '@portals/types/Table';

import { useTableInstance } from '../context';
import TRow from './TRow';

type TBodyProps<TData extends object> = Pick<
  SmartTableProps<TData>,
  'expandRow' | 'rowMenu' | 'isCompact' | 'menuProps'
> & { data: Array<Row<any>> };

function TBody<TData extends object>({
  expandRow,
  rowMenu,
  isCompact,
  menuProps = {},
  data,
}: TBodyProps<TData>) {
  const instance = useTableInstance();
  const { prepareRow, getTableBodyProps } = instance;

  const rows = map(
    (row: Row<any> & UseExpandedRowProps<any> & UseRowSelectRowProps<any>) => {
      if (!row) return null;

      prepareRow(row);

      return (
        <TRow<TData>
          key={row.id}
          row={row}
          expandRow={expandRow}
          rowMenu={rowMenu}
          isCompact={isCompact}
          menuProps={menuProps}
        />
      );
    },
    data
  );

  return (
    <Box
      {...getTableBodyProps()}
      className="tbody"
      sx={{
        height: '100%',
      }}
    >
      {rows}
    </Box>
  );
}

export default TBody;
