import {
  Button,
  Group,
  PasswordInput,
  Stack,
  TextInput,
  Title,
  Text,
} from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { useAppConfig } from '@portals/framework/context';
import { useIsPending } from '@portals/redux';
import { signIn } from '@portals/redux/actions/auth';
import { setRoute } from '@portals/redux/actions/routing';
import { StateType, TenantType } from '@portals/types';
import { FADE_IN_OUT, ToS } from '@portals/ui';
import { getIsFieldOps } from '@portals/utils';

import { FormWrapper } from './common';
import SSO from './SSO';
import { SignInProps } from './types';

type SignInConnectedProps = {
  auth?: StateType['ui']['auth'];
  authError: StateType['ui']['authError'];
  config: Partial<StateType['ui']['partnerConfig']>;
};

type SignInConnectedActions = {
  signIn: (email: string, password: string, tenantType: TenantType) => void;
  setRoute: (path: string, replace: boolean) => void;
};

export const SignIn: FC<
  SignInProps & SignInConnectedProps & SignInConnectedActions
> = ({
  config = {},
  auth,
  authError,
  signIn,
  setRoute,
  isForceSignup = false,
  isPreview = false,
  isForgotPasswordVisible = true,
  onSignUp,
  afterAuthPath,
}) => {
  const { tenantType, extraLayout = {} } = useAppConfig();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const withSignup = config.signup || false;

  const isSigningIn = useIsPending('signIn');

  const setRouteTimeout = useRef<ReturnType<typeof setTimeout>>(null);

  const handleInputChange = (handler) => (e) => {
    if (!isPreview) {
      handler(e.target.value);
    }
  };

  const handleSubmit = (e) => {
    if (!isPreview) {
      e.preventDefault();
      signIn(email, password, tenantType);
    }
  };

  useEffect(
    function redirectedAuthenticatedUser() {
      if (isPreview || !auth?.token) return;

      let path;

      if (afterAuthPath) {
        path = afterAuthPath;
      } else {
        path = localStorage.getItem('afterAuth') || '/';
        localStorage.removeItem('afterAuth');
      }

      setRouteTimeout.current = setTimeout(() => setRoute(path, true));

      return () => {
        if (setRouteTimeout.current) {
          clearTimeout(setRouteTimeout.current);
          setRouteTimeout.current = null;
        }
      };
    },
    [setRoute, isPreview, auth?.token, afterAuthPath]
  );

  useEffect(
    function redirectToChangePassword() {
      if (isPreview || !auth?.change || !!auth?.token) return;

      setRouteTimeout.current = setTimeout(() =>
        setRoute('/auth/change-password', true)
      );

      return () => {
        if (setRouteTimeout.current) {
          clearTimeout(setRouteTimeout.current);
          setRouteTimeout.current = null;
        }
      };
    },
    [auth?.change, auth?.token, setRoute, isPreview]
  );

  const isReferral = !!localStorage.getItem('referral');

  const isSignupVisible =
    isForceSignup ||
    isReferral ||
    (withSignup && tenantType === TenantType.Organization && !isPreview);

  const onSignUpWrapper = useCallback(
    (event) => {
      event.preventDefault();

      if (!isSignupVisible) return;
      else if (onSignUp) onSignUp();
      else setRoute('/auth/sign-up', true);
    },
    [isSignupVisible, onSignUp, setRoute]
  );

  return (
    <FormWrapper id="sign-in">
      <form onSubmit={handleSubmit} className="sign-in-container">
        <Stack>
          <Group position="apart" mb="lg" align="baseline">
            <Title order={1}>Sign-In</Title>

            {isSignupVisible && !isPreview ? (
              <Text color="dimmed" size="sm" align="center">
                <Group spacing={6}>
                  Don't have an account yet?
                  <Text variant="link" size="sm" onClick={onSignUpWrapper}>
                    Sign up
                  </Text>
                </Group>
              </Text>
            ) : null}
          </Group>

          <TextInput
            label="Email"
            disabled={isPreview}
            type="email"
            name="email"
            placeholder="Enter your email"
            value={email}
            onChange={handleInputChange(setEmail)}
            mb="md"
          />

          <PasswordInput
            label="Password"
            disabled={isPreview}
            name="password"
            placeholder="Enter your password"
            value={password}
            onChange={handleInputChange(setPassword)}
            mb="md"
          />

          <AnimatePresence>
            {authError && !isPreview ? (
              <motion.p
                {...FADE_IN_OUT}
                className="text-danger sign-in-auth-error"
              >
                {authError}
              </motion.p>
            ) : null}
          </AnimatePresence>

          {isForgotPasswordVisible ? (
            <Group position="right">
              <Link to="/auth/reset-password">Forgot password?</Link>
            </Group>
          ) : null}

          <Button
            loading={isSigningIn}
            disabled={isSigningIn || !email || !password}
            size="sm"
            color="blue_accent"
            type="submit"
            mt="md"
            mb="md"
          >
            Sign In
          </Button>

          {isPreview || getIsFieldOps() ? null : <SSO />}

          <ToS label={'clicking on "Sign In"'} />
        </Stack>
      </form>
    </FormWrapper>
  );
};

const mapStateToProps = (state: StateType) => ({
  auth: state.ui.auth,
  authError: state.ui.authError,
  config: state.ui.partnerConfig,
});

export default connect<SignInConnectedProps, SignInConnectedActions>(
  mapStateToProps,
  { signIn, setRoute }
)(SignIn);
