import { Box, Button, Group, Modal, NumberInput } from '@mantine/core';
import { isNumber } from 'lodash/fp';
import React, { PropsWithChildren, useState } from 'react';
import { FilterProps, UseFiltersColumnProps } from 'react-table';

import { suppressPropagation } from '@portals/utils';

interface NumberFilterProps<TData extends object> {
  closeMe: () => void;
  data: {
    column: FilterProps<TData>['column'] & UseFiltersColumnProps<TData>;
    placeholder?: [string, string];
  };
}

function NumberFilter<TData extends object>({
  closeMe,
  data,
}: PropsWithChildren<NumberFilterProps<TData>>) {
  const { column, placeholder = [] } = data;

  const [localRange, setLocalRange] = useState<[number | null, number | null]>([
    isNumber(column?.filterValue?.gte) ? column?.filterValue?.gte : null,
    isNumber(column?.filterValue?.lte) ? column?.filterValue?.lte : null,
  ]);

  const isDisabled = !isNumber(localRange[0]) && !isNumber(localRange[1]);

  const onSubmit = () => {
    column.setFilter({ gte: localRange[0], lte: localRange[1] });
    closeMe();
  };

  const onClear = () => {
    column.setFilter(null);
    closeMe();
  };

  return (
    <Modal
      opened={true}
      onClose={closeMe}
      size="lg"
      padding={32}
      title={`Filter by '${data.column.Header}'`}
    >
      <form onSubmit={suppressPropagation(onSubmit)}>
        <Group align="center" sx={{ width: '100%' }} mt={30}>
          <NumberInput
            data-autofocus
            hideControls
            placeholder={placeholder[0] || 'From'}
            value={localRange[0]}
            onChange={(value) => setLocalRange((curr) => [value, curr[1]])}
            sx={{
              flex: 1,
            }}
          />

          <Box
            mx={6}
            sx={{
              flexGrow: 0,
            }}
          >
            -
          </Box>

          <NumberInput
            hideControls
            placeholder={placeholder[1] || 'To'}
            value={localRange[1]}
            onChange={(value) => setLocalRange((curr) => [curr[0], value])}
            sx={{
              flex: 1,
            }}
          />
        </Group>

        <Group position="right" mt={50}>
          <Button
            variant="subtle"
            color="blue_gray"
            size="sm"
            onClick={closeMe}
          >
            Cancel
          </Button>

          <Button
            variant="outline"
            color="blue_gray"
            size="sm"
            onClick={onClear}
          >
            Clear Filters
          </Button>

          <Button size="sm" type="submit" disabled={isDisabled}>
            Apply Filters
          </Button>
        </Group>
      </form>
    </Modal>
  );
}

export default NumberFilter;
