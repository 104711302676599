import { ActionIcon } from '@mantine/core';
import { isNumber } from 'lodash/fp';
import React, { FC } from 'react';
import { FilterProps, UseFiltersColumnProps } from 'react-table';

import { useOpenModal } from '@portals/redux';
import { suppressPropagation } from '@portals/utils';

import { ReactComponent as ActiveFilterIcon } from '../../../../assets/active-filter.svg';
import { ReactComponent as FilterIcon } from '../../../../assets/filter.svg';

type NumberFilterProps = {
  column: FilterProps<any>['column'] & UseFiltersColumnProps<any>;
  placeholder?: [string, string];
};

const NumberFilter: FC<NumberFilterProps> = ({ column }) => {
  const { filterValue } = column;

  const openModal = useOpenModal();

  const onToggleFilterModal = () => openModal('NumberFilter', { column });

  return (
    <ActionIcon
      onClick={suppressPropagation(onToggleFilterModal)}
      size="xs"
      className="column-filter-toggle"
    >
      {isNumber(filterValue?.gte) || isNumber(filterValue?.lte) ? (
        <ActiveFilterIcon />
      ) : (
        <FilterIcon />
      )}
    </ActionIcon>
  );
};

export default NumberFilter;
