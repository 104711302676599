import { MantineProviderProps } from '@mantine/core';

import { breadcrumbsStyle } from './breadcrumbs';
import { buttonsStyle } from './button';
import {
  checkboxStyle,
  inputWrapperStyle,
  numberInputStyle,
  passwordStyle,
  textareaStyle,
  textInputStyle,
  colorInputStyle,
  radioGroupStyle,
  switchStyle,
} from './inputs';
import { menuStyles } from './menu';
import { modalStyles } from './modal';
import { notificationStyle } from './notification';
import { selectStyle } from './select';
import { stepperStyle } from './stepper';
import { tabsStyle } from './tabs';

const STYLES: MantineProviderProps['styles'] = {
  Stepper: stepperStyle,
  TextInput: textInputStyle,
  Textarea: textareaStyle,
  JsonInput: textareaStyle,
  NumberInput: numberInputStyle,
  InputWrapper: inputWrapperStyle,
  Checkbox: checkboxStyle,
  Select: selectStyle,
  MultiSelect: selectStyle,
  PasswordInput: passwordStyle,
  ColorInput: colorInputStyle,
  RadioGroup: radioGroupStyle,
  Radio: radioGroupStyle,
  Modal: modalStyles,
  Menu: menuStyles,
  Switch: switchStyle,
  Tabs: tabsStyle,
  Button: buttonsStyle,
  Notification: notificationStyle,
  Breadcrumbs: breadcrumbsStyle,
};

export default STYLES;
