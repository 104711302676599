import { Box, createStyles } from '@mantine/core';
import { getOr } from 'lodash/fp';
import React, { FC } from 'react';
import { connect } from 'react-redux';

import { useAppConfig } from '@portals/framework/context';
import { FeaturesType } from '@portals/types';
import { WithData } from '@portals/ui';

import Authenticated from '../components/Authenticated';
import GlobalSubscription from '../components/GlobalSubscription';
import { Navbar } from '../components/layout/navbar/Navbar';
import { Sidebar } from '../components/layout/sidebar';
import Main from '../components/Main';
import Onboarded from '../components/Onboarded';
import QuickNavProvider from '../components/QuickNav';

type DashboardConnectedProps = { config: FeaturesType };

const Dashboard: FC<DashboardConnectedProps> = ({ children, config }) => {
  const { classes, cx } = useStyles();
  const { preloadData, extraLayout = {}, tenantType } = useAppConfig();

  const {
    dashboardDataLayout: DataLayout,
    globalLayout,
    options = {},
  } = extraLayout;

  const content = ({ isFetched }) => (
    <WithData types={preloadData} isFetched={isFetched}>
      <Onboarded>
        {options.isMobile ? null : <Sidebar />}

        <Main style={{ position: 'relative', zIndex: 1 }}>
          <Navbar />
          <div className={cx(classes.content, 'page-content')}>{children}</div>

          {globalLayout ? globalLayout(config) : null}

          <GlobalSubscription tenantType={tenantType} />
        </Main>
      </Onboarded>
    </WithData>
  );

  return (
    <Authenticated>
      <QuickNavProvider>
        <Box className={cx(classes.wrapper, 'page-wrapper')}>
          {DataLayout ? (
            <DataLayout>{({ isFetched }) => content({ isFetched })}</DataLayout>
          ) : (
            content({ isFetched: true })
          )}
        </Box>
      </QuickNavProvider>
    </Authenticated>
  );
};

const useStyles = createStyles(() => ({
  wrapper: {
    height: '100vh',
    overflow: 'hidden',
  },
  content: {
    flex: 1,
    overflow: 'auto',
    position: 'relative',
  },
}));

const mapStateToProps = (state) => ({
  config: getOr({}, 'data.config.server.features', state),
});

export default connect<DashboardConnectedProps>(mapStateToProps)(Dashboard);
