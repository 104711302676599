import { StepperStylesNames, StepperStylesParams } from '@mantine/core';

import { ComponentStyleOverrideFn } from './common-types';

export const stepperStyle: ComponentStyleOverrideFn<
  StepperStylesNames,
  StepperStylesParams
> = (theme) => ({
  separator: {
    backgroundColor: theme.colors.blue_gray[3],
  },
  separatorActive: {
    backgroundColor: theme.colors.primary[4],
  },
  stepIcon: {
    border: `1px solid ${theme.colors.blue_gray[3]}`,
    backgroundColor: theme.colors.blue_gray[0],
    color: theme.colors.blue_gray[8],
    fontSize: theme.fontSizes.sm,
    fontWeight: 400,
  },
  stepLabel: {
    fontSize: theme.fontSizes.sm,
    fontWeight: 400,
    color: theme.colors.blue_gray[3],
  },
  //Step control modified when step is in progress
  stepProgress: {
    '.mantine-Stepper-stepLabel': {
      color: theme.other.primaryColor,
    },

    '.mantine-Stepper-stepIcon': {
      border: `1px solid ${theme.other.primaryColor}`,
      backgroundColor: theme.white,
      color: theme.other.primaryColor,
    },
  },
  stepCompleted: {
    '.mantine-Stepper-stepLabel': {
      color: theme.colors.blue_gray[8],
    },
  },
});
