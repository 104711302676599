import amplitude from 'amplitude-js';
import { endsWith } from 'lodash/fp';
import { useSelector } from 'react-redux';

import { getCurrentUser, getTenant } from '@portals/redux';
import { AuthType, UserType } from '@portals/types';

if (process.env['NX_AMPLITUDE_API_KEY']) {
  amplitude.getInstance().init(process.env['NX_AMPLITUDE_API_KEY']);
} else {
  console.warn('Amplitude API key not found');
}

let userId: null | string = null;

export const getCustomEventProperties = (
  user: UserType,
  tenant: AuthType['tenant']
) => {
  const screen_resolution = window?.screen
    ? `${window.screen.width}x${window.screen.height}`
    : 'unknown';

  return {
    screen_resolution,
    user: user.email,
    user_role: endsWith('@xyte.io', user.email) ? 'internal' : 'external',
    tenant: tenant.display_name || tenant.name,
  };
};

const logEvent = (
  eventName: string,
  eventProperties: Record<
    'screen_resolution' | 'user' | 'user_role' | string,
    any
  > = {}
) => {
  if (process.env['NODE_ENV'] === 'development') {
    console.log(`Amplitude event: ${eventName}`, eventProperties);
  } else {
    if (userId !== eventProperties['user']) {
      userId = eventProperties['user'];

      amplitude.getInstance().setUserId(userId);
    }

    amplitude.getInstance().logEvent(eventName, eventProperties);
  }
};

export const useLogEvent = () => {
  const user = useSelector(getCurrentUser);
  const tenant = useSelector(getTenant);

  return (eventName: string, eventProperties: Record<string, any> = {}) => {
    logEvent(eventName, {
      ...getCustomEventProperties(user, tenant),
      ...eventProperties,
    });
  };
};

export { logEvent };
