import { createStyles, ScrollArea } from '@mantine/core';
import { AnimateSharedLayout, motion } from 'framer-motion';
import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import styled from 'styled-components';

import { TabType } from '@portals/types';
import { getStyledThemeColor } from '@portals/utils';

interface TabsProps<
  TabContentComponentProps extends object = Record<string, unknown>,
  VisibleFnExtraParams extends object = Record<string, unknown>
> {
  tabs: Array<TabType<TabContentComponentProps, VisibleFnExtraParams>>;
  selected: TabType<TabContentComponentProps, VisibleFnExtraParams>;
  onSelect: (
    tab: TabType<TabContentComponentProps, VisibleFnExtraParams>
  ) => void;
  tabContentComponentProps?: TabContentComponentProps;
}

const useStyles = createStyles(() => ({
  scrollArea: {
    border: '1px solid #dee2e6',
    borderBottom: 'none',
  },
}));

export function Tabs<
  TabContentComponentProps extends object = Record<string, unknown>,
  VisibleFnExtraParams extends object = Record<string, unknown>
>({
  tabs,
  selected,
  onSelect,
  tabContentComponentProps,
}: TabsProps<TabContentComponentProps, VisibleFnExtraParams>) {
  const { classes } = useStyles();
  const { Component } = selected;

  return (
    <Container className="tab">
      <AnimateSharedLayout>
        <ScrollArea type="auto" className={classes.scrollArea}>
          <Nav tabs>
            {tabs.map((tab) => {
              return (
                <NavItem key={tab.title}>
                  {selected.name === tab.name ? (
                    <SelectedTab layoutId="selected-tab" />
                  ) : null}

                  <NavLink onClick={() => onSelect(tab)}>
                    {tab.title}
                    {tab.badge && <span className="ml-1">{tab.badge}</span>}
                  </NavLink>
                </NavItem>
              );
            })}
          </Nav>
        </ScrollArea>
      </AnimateSharedLayout>

      <div className="py-3 h-100 tab-wrapper">
        <Component {...tabContentComponentProps} />
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr;
  height: 100%;

  .nav-tabs {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    padding: 0;
    background-color: ${getStyledThemeColor('gray150')};
    height: 50px;
    width: 100%;
    margin: 0;

    .nav-item {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;

      &:first-of-type {
        a {
          padding-left: 20px !important;
        }
      }

      a {
        z-index: 1;
        padding: 0 15px !important;
      }
    }
  }
`;

const SelectedTab = styled(motion.div)`
  position: absolute;
  top: 0;
  background-color: ${getStyledThemeColor('gray300')};
  width: 100%;
  height: 100%;
  z-index: 0;
`;
