import React, { createContext, PropsWithChildren, useContext } from 'react';

import { TableInstanceType } from '@portals/types/Table';

export const TableInstanceContext =
  createContext<TableInstanceType<any>>(undefined);

type TableInstanceProps<TData extends object> = PropsWithChildren<{
  instance: TableInstanceType<TData>;
}>;

export function TableInstanceProvider<TData extends object>({
  instance,
  children,
}: TableInstanceProps<TData>) {
  return (
    <TableInstanceContext.Provider
      value={
        instance as any
        // `as any` because there's no easy way to make the provider accept the generic types
      }
    >
      {children}
    </TableInstanceContext.Provider>
  );
}

export const useTableInstance = <TData extends object>() =>
  useContext(TableInstanceContext) as TableInstanceType<TData>;
