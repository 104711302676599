import {
  ActionId,
  ActionImpl,
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarProvider,
  KBarResults,
  KBarSearch,
  useMatches,
  useRegisterActions,
} from 'kbar';
import {
  castArray,
  first,
  forEach,
  getOr,
  isFunction,
  isString,
  size,
} from 'lodash/fp';
import React, { ReactNode, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { useUserIsAdmin } from '@portals/entities';
import { useHubspotConversations } from '@portals/framework';
import { useAppConfig } from '@portals/framework/context';
import { useTranslation } from '@portals/i18n';
import {
  CloudChange,
  CloudPlus,
  Flag,
  Link,
  Profile2User,
  User,
  MessageQuestion,
  PasswordCheck,
  SecurityUser,
  DocumentText,
  Logout1,
} from '@portals/icons/linear';
import {
  getAuth,
  useFeatures,
  useOpenModal,
  useSetRoute,
  useUsers,
} from '@portals/redux';
import { signOut } from '@portals/redux/actions/auth';
import { ComponentRendererType, RouteType, TenantType } from '@portals/types';
import { useTheme } from '@portals/ui';
import { getStyledThemeColor } from '@portals/utils';

const QuickNavProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => (
  <KBarProvider options={{ disableScrollbarManagement: true }}>
    <QuickNav />

    {children}
  </KBarProvider>
);

interface QuickNavItem {
  id: string;
  name: string;
  Icon: ComponentRendererType;
  perform: () => void;
}

const QuickNav = () => {
  const { routes } = useAppConfig();
  const t = useTranslation();
  const setRoute = useSetRoute();
  const features = useFeatures();
  const openModal = useOpenModal();
  const { id, tenant } = useSelector(getAuth) || {};
  const isAdmin = useUserIsAdmin(id);
  const dispatch = useDispatch();
  const hubspotConversations = useHubspotConversations();

  const users = useUsers();
  const controlledTenants = size(
    getOr([], [id, 'controlled', tenant.type], users)
  );

  const actions = useMemo(() => {
    const defaultActions: Array<QuickNavItem> = [
      {
        id: 'users',
        name: 'Users',
        Icon: User,
        perform: () => setRoute('/settings/users'),
      },
      {
        id: 'groups',
        name: 'Groups',
        Icon: Profile2User,
        perform: () => setRoute('/settings/groups'),
      },
      {
        id: 'integrations',
        name: 'Integrations',
        Icon: Link,
        perform: () => setRoute('/settings/integrations'),
      },
      {
        id: 'help',
        Icon: MessageQuestion,
        name: 'Help',
        perform: () =>
          window.open(
            tenant.type === TenantType.Partner
              ? 'https://dev.xyte.io/'
              : 'https://docs.xyte.io/',
            '_blank'
          ),
      },
      {
        id: 'change_password',
        Icon: PasswordCheck,
        name: 'Change Password',
        perform: () => openModal('ChangePassword'),
      },
      {
        id: 'tos',
        Icon: DocumentText,
        name: 'Terms of service',
        perform: () =>
          window.open('https://xyte.io/terms-of-service', '_blank'),
      },
      {
        id: 'privacy',
        Icon: SecurityUser,
        name: 'Privacy',
        perform: () => window.open('https://xyte.io/privacy-policy', '_blank'),
      },
      {
        id: 'sign_out',
        Icon: Logout1,
        name: 'Sign out',
        perform: () => {
          hubspotConversations.removeWidget();
          dispatch(signOut());
        },
      },
    ];

    if (controlledTenants > 1) {
      defaultActions.push({
        id: 'switch_tenant',
        name: 'Switch Tenant',
        Icon: CloudChange,
        perform: () => openModal('SwitchTenant'),
      });
    }

    if (isAdmin && tenant.type === TenantType.Partner) {
      defaultActions.push({
        id: 'create_tenant',
        name: 'Create New Tenant',
        Icon: CloudPlus,
        perform: () => openModal('CreatePartner'),
      });
    }

    if (isAdmin) {
      defaultActions.push({
        id: 'featureFlags',
        name: 'Feature flags',
        Icon: Flag,
        perform: () => openModal('FeatureFlags'),
      });
    }

    forEach(
      ({ childRoutes }) =>
        forEach((route: RouteType) => {
          if (isFunction(route.isVisibleInSidebar)) {
            const isVisible = route.isVisibleInSidebar(features);

            if (!isVisible) return;
          }

          defaultActions.push({
            id: route.id,
            name: route.id ? t(route.id) : '',
            Icon: route.icon,
            perform: () =>
              route.href
                ? window.open(route.href, '_blank')
                : setRoute(first(castArray(route.path))),
          });
        }, childRoutes),
      routes.dashboard
    );

    return defaultActions;
  }, [
    controlledTenants,
    dispatch,
    features,
    hubspotConversations,
    isAdmin,
    openModal,
    routes.dashboard,
    setRoute,
    t,
    tenant.type,
  ]);

  useRegisterActions(actions, [actions]);

  return (
    <KBarPortal>
      <StyledKBarPositioner>
        <StyledKBarAnimator>
          <StyledKBarSearch />

          <Results />
        </StyledKBarAnimator>
      </StyledKBarPositioner>
    </KBarPortal>
  );
};

const Results = () => {
  const { results, rootActionId } = useMatches();

  return (
    <KBarResults
      maxHeight={500}
      items={results}
      onRender={({ item, active }) =>
        isString(item) ? (
          <>{item}</>
        ) : (
          <ResultItem
            action={item}
            active={active}
            currentRootActionId={rootActionId}
          />
        )
      }
    />
  );
};

interface ResultItemProps {
  action: ActionImpl;
  active: boolean;
  currentRootActionId: ActionId;
}

const ResultItem = React.forwardRef(
  (
    { action, active, currentRootActionId }: ResultItemProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { Icon } = action as ActionImpl & { Icon: React.ComponentType<any> };
    const { color } = useTheme();

    return (
      <ItemContainer ref={ref} isActive={active}>
        <div className="item-content" style={{ color: color.gray600 }}>
          {Icon ? <Icon size={18} className="align-middle mr-3" /> : null}

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>{action.name}</div>
          </div>
        </div>
      </ItemContainer>
    );
  }
);

const StyledKBarPositioner = styled(KBarPositioner)`
  z-index: 999;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.3);
`;

const StyledKBarAnimator = styled(KBarAnimator)`
  width: 600px;
  background: ${getStyledThemeColor('white')};
  color: ${getStyledThemeColor('dark')};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.05),
    0 0 1px rgba(0, 0, 0, 0.05);
  border: 1px solid ${getStyledThemeColor('gray300')};
`;

const StyledKBarSearch = styled(KBarSearch)`
  padding: 12px 16px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  border: none;
  background: ${getStyledThemeColor('white')};
  color: ${getStyledThemeColor('dark')};
`;

const ItemContainer = styled.div<{ isActive: boolean }>`
  padding: 12px 16px;
  background: ${({ isActive, theme }) =>
    isActive ? theme.color.gray150 : theme.color.white};,
  borderLeft: 2px solid ${({ isActive, theme }) =>
    isActive ? theme.color.primary : 'transparent'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: background 0.15s ease-in-out;

  .item-content {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 14px;
  }
`;

export default QuickNavProvider;
