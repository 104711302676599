import { Checkbox } from '@mantine/core';
import React from 'react';

interface ColumnOptionProps {
  option: any;
}

const ColumnOption = ({ option }: ColumnOptionProps) => {
  const { toggleHidden, isAction } = option;
  const { checked } = option.getToggleHiddenProps();

  if (isAction) return null;

  return (
    <Checkbox
      checked={checked}
      onChange={() => toggleHidden(checked)}
      label={option.Header}
      styles={{
        inner: {
          cursor: 'pointer',
        },
        input: {
          cursor: 'pointer',
        },
        label: {
          width: '100%',
          cursor: 'pointer',
        },
      }}
    />
  );
};

export default ColumnOption;
