import { MantineThemeOverride } from '@mantine/core';

import { COLORS } from './theme-colors';
import { THEME_OTHER } from './theme-other';

export const THEME: MantineThemeOverride = {
  primaryColor: 'blue_accent',
  primaryShade: 4,
  other: THEME_OTHER,
  fontFamily:
    'Nunito, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif',
  colors: COLORS,
  white: '#FFFFFF',
  black: '#000000',
  spacing: {
    xs: 8,
  },
  lineHeight: 'normal',
  headings: {
    fontFamily:
      'Nunito, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif',
    sizes: {
      h1: { fontSize: 36, lineHeight: 1.36 },
      h2: { fontSize: 32, lineHeight: 1.375 },
      h3: { fontSize: 28, lineHeight: 1.357 },
      h4: { fontSize: 24, lineHeight: 1.375 },
      h5: { fontSize: 20, lineHeight: 1.35 },
      h6: { fontSize: 18, lineHeight: 1.39 },
    },
  },
};
