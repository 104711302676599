import { ComponentType, ReactNode } from 'react';
import { Layout, Layouts } from 'react-grid-layout';

import { CanViewFn } from '@portals/framework';
import { CurrencyCode } from '@portals/framework/constants';
import { useFeatures } from '@portals/redux';
import { FieldType } from '@portals/ui/AutoFormik';
import { AccessLevelEnum } from '@portals/utils';

import { StateType } from './redux';

export type ComponentRendererType<
  TProps extends object = Record<string, unknown>
> = ComponentType<TProps>;

export type CanAccessRouteFn = (params: {
  canView: CanViewFn;
  features: StateType['data']['config']['server']['features'];
}) => boolean;

export type TabVisibleFn<ExtraParams extends object = Record<string, unknown>> =
  (
    params: {
      features?: StateType['data']['config']['server']['features'];
      canView?: CanViewFn;
      isAdmin?: boolean;
    } & ExtraParams
  ) => boolean;

export interface RoutesMap {
  dashboard: CategoryRouteItem[];
  auth?: RoutesType;
  shop?: RoutesType;
  onboarding?: RoutesType;
}

export interface CategoryRouteItem {
  id: string;
  headerId: string;
  childRoutes: RoutesType;
  canAccessRoute?: CanAccessRouteFn;
}

export type RouteType = {
  headerId?: string;
  href?: string;
  path:
    | ((features: ReturnType<typeof useFeatures>) => Array<string>)
    | Array<string>
    | string;
  id?: string;
  component?: ComponentRendererType;
  icon?: ComponentRendererType;
  badgeComponent?: ComponentRendererType;
  badgeColor?: string;
  badgeText?: string;
  isVisibleInSidebar?: (features: ReturnType<typeof useFeatures>) => boolean;
  canAccessRoute?: CanAccessRouteFn;
  children?: Array<RouteType>;
  open?: boolean;
  containsHome?: boolean;
  hasBorder?: boolean;
};

export enum TenantType {
  Organization = 'organization',
  Partner = 'partner',
}

export type PartnerConfigType = {
  logo?: string;
  tagline?: string;
  window_title?: string;
  login_header?: string;
  name?: string;
  hero?: string;
  favicon?: string;
  hero_gradient_primary?: string;
  hero_gradient_secondary?: string;
  tagline_color?: string;
  signup?: boolean;
  powered_by_xyte_label?: boolean;
};

export type PortalConfigType = {
  api_key: string;
  display_name: string;
  id: string;
  lab: boolean;
  name: string;
  partner: string;
  tagline?: string;
  tagline_color?: string;
  favicon?: string;
  logo?: string;
  login_header?: string;
  hero?: string;
  window_title?: string;
  hero_gradient_primary?: string;
  hero_gradient_secondary?: string;
  integrations?: Record<string, boolean>;
  dashboard?: string;
  pricing?: {
    sample_licenses: number;
  };
  admin_access: boolean;
  background?: string;
  domain?: string;
  candidate_domain?: string;
  email_settings?: {
    created?: boolean;
    color?: string;
    logo?: string;
    markdown?: string;
    primary?: string;
    secondary?: string;
    tertiary?: string;
    site_url?: string;
  };
  files?: boolean;
  files_management?: boolean;
  incidents?: boolean;
  licenses?: boolean;
  licenses_management?: boolean;
  partner_only?: boolean;
  reports?: boolean;
  self_signup_admin?: boolean;
  signup?: boolean;
  tickets?: boolean;
  uc_cloud?: boolean;
  uc_3rd_party?: boolean;
  users?: boolean;
  whitelist_devices?: boolean;
  external?: {
    intercom?: string;
  };
  powered_by_xyte_label?: boolean;
  asset_management: boolean;
  control_center: boolean;
  digital_products: boolean;
  support: boolean;
  managed_service_providers: boolean;
};

export type RoutesType = Array<RouteType>;
export type ModalsType = Record<string, any>;
export type PreloadDataType = Record<string, unknown> | undefined;
export type ExtraLayoutType = {
  globalLayout?: (config: PartnerConfigType | FeaturesType) => JSX.Element;
  navbarLayout?: () => JSX.Element;
  dashboardLayout?: any;
  shopLayout?: any;
  authLayout?: any;
  onboardingLayout?: any;
  sidebarLayout?: {
    brandRenderer: () => JSX.Element;
  };
  dashboardDataLayout?: ComponentType<{
    children: ({ isFetched }: { isFetched: boolean }) => JSX.Element;
  }>;
  options?: {
    isMobile?: boolean;
  };
};

export type AppProps = {
  readonly routes: RoutesMap;
  readonly modals: ModalsType;
  readonly tenantType: TenantType;
  readonly preloadData?: PreloadDataType;
  readonly extraLayout?: ExtraLayoutType;
};

export type TelemetryType = {
  id: string;
  created_at: string;
  timestamp: string;
  device_timestamp: string;
  common: JSON;
};

export type OrganizationIncidentType = {
  device_id: string;
  status: string;
  id: string;
  priority: number;
  title: string;
  description: string;
  issue: string;
  created_at: string;
  closed_at: string;
  ttc: string;
  space_id: string;
  urls: Record<string, string>;
  updated_at: string;
  access_level: AccessLevelEnum;

  // MSPs fields
  device_model: string;
  device_name: string;
  org_display_name: string;
  partner_display_name: string;
  space_name: string;
};

export type AccessType = Record<string, Record<UuidType, AccessLevelType>>;

export type SpaceType = {
  id: number;
  space_type: string;
  name: string;
  config: {
    events: {
      tree: {
        incidents: Record<string, boolean>;
      };
      "'incidents.email'": { email: string };
    };
    general: {
      location: {
        location: {
          lat: number;
          lng: number;
        };
        region_name: string;
        description: string;
      };
      priority_factor: number;
      inherit_location: boolean;
      inherit_priority_factor: boolean;
    };
  };
  path: Array<number>;
  created_at: string;
  template: string;
  parent_id: number | null;
  organization_id: string;
  calendar: Record<string, any>;
  tree_path_name: string;
  state?: {
    maintenance?: boolean;
    snoozed?: boolean;
    common: Record<string, any>;
    devices: number;
    incidents: Record<
      'low' | 'high' | 'total' | 'critical' | 'moderate' | 'planning',
      number
    >;
  };
  access: Record<string, any>;
  access_level: AccessLevelEnum;
};

export type UsageCountSpaceType = {
  id: number;
  name: string;
  devices: number;
};

export type UsageCountType = {
  '0': UsageCountSpaceType[];
  '5-10': UsageCountSpaceType[];
  '6-10': UsageCountSpaceType[];
  '10+': UsageCountSpaceType[];
};

export type DeviceType = {
  parent: null | string;
  id: string;
  name: string;
  space_id: string;
  state: {
    status: 'online' | 'offline' | 'unavailable' | 'error';
  };
  snoozed_until?: string;
  config_version: number;
  status: string;
  type: string;
  model_settings: {
    visibility: {
      claimable: boolean;
    };
  };
  partner: {
    sn: null | any;
    mac: null | any;
    vendor: string;
    model: string;
    type_id: string;
  };
  details: null | any;
  firmware: {
    version: null | any;
    url: null | any;
  };
  config: {
    other: {
      sample: number;
    };
    network: {
      ip: string;
      port: number;
    };
    version: number;
    last_updated: string;
  };
  organization?: {
    id: string;
    name: string;
  };
  config_schema: null | any;
  driver: null | any;
  incidents: Array<number>;
  last_seen: string;
  access_key: string;
  custom_info: Record<string, any>;
  custom_info_version: number;
  access_level: AccessLevelEnum;
  claimed?: boolean;
  device_model_id?: UuidType;
  model?: string;
  created_at?: string;
  partner_note?: string;
};

export type IntegrationExtraType = {
  item_fields: Array<{ name: string; label: string; choices: Array<string> }>;
  required_fields: Array<{
    name: string;
    label: string;
    choices: Array<string>;
  }>;
} & Record<string, unknown>;

interface ZoomRoomType {
  id: string;
  location_id: string;
  name: string;
  room_id: string;
  status: string;
}

export interface IntegrationsExtraZoomType {
  name: 'zoom';
  state: string;
  updated_at: string;
  import: {
    devices: number;
    spaces: number;
    state: string;
  };
  rooms: Array<ZoomRoomType>;
  unclaimed: Array<ZoomRoomType>;
}

export interface IntegrationExtraQSysType {
  cores: Record<
    number,
    {
      id: number;
      name: string;
      site: {
        id: number;
        name: string;
      };
      model: string;
      serial: string;
      status: {
        code: number;
        details: string;
        message: string;
      };
      uptime: number;
      firmware: string;
      accessMode: string;
      redundancy: any | null;
      accessLevel: number;
      modelNumber: string;
    }
  >;
  import: {
    devices: number;
    message: string;
    running: boolean;
    spaces: number;
    state: string;
  };
}

export interface IntegrationExtraBrightSignType {
  error: string;
  networks: string[];
  devices: Array<{
    id: number;
    name: string;
    serial: string;
  }>;
}

export enum IntegrationState {
  Active = 'active',
  Disabled = 'disabled',
  Error = 'error',
  Unauthenticated = 'unauthenticated',
}

export interface IntegrationType<ExtraType = IntegrationExtraType> {
  name: string;
  state: IntegrationState;
  updated_at: string;
  params: Record<string, any>;
  extra: ExtraType;
  error: null | any;
}

export type FeaturesType = {
  files: boolean;
  users: boolean;
  domain: string;
  signup: boolean;
  tagline?: string;
  favicon?: string;
  logo?: string;
  login_header?: string;
  hero?: string;
  hero_gradient_primary?: string;
  hero_gradient_secondary?: string;
  pricing: {
    sample_licenses: number;
  };
  reports: boolean;
  tickets: boolean;
  licenses: boolean;
  uc_cloud: boolean;
  uc_3rd_party: boolean;
  incidents: boolean;
  background: {
    url: string;
  };
  admin_access: boolean;
  integrations: {
    email: boolean;
    slack: boolean;
    msteams: boolean;
    web_hook: boolean;
    cisco_teams: true;
  };
  customer_management: boolean;
  partner_only: boolean;
  window_title: string;
  files_management: boolean;
  self_signup_admin: boolean;
  whitelist_devices: boolean;
  licenses_management: boolean;
  dev: boolean;
  crestron: boolean;
  products: boolean;
  schedule: boolean;
  subscriptions: Record<string, SubscriptionType>;
  warranties?: boolean;
  support_dashboard?: boolean;
  experts?: boolean;
  ansa?: boolean;
  finance?: boolean;
  control_center: boolean;
  asset_management: boolean;
  digital_products: boolean;
  support: boolean;
  managed_service_providers: boolean;
  powered_by_xyte_label?: boolean;
  home?: boolean;
  dashboard?: boolean;
  partners?: boolean;
  support_overview: boolean;
};

export type NotificationsType = {
  email: string;
  notify_referral?: boolean;
  notify_ticket?: boolean;
};

export type SubscriptionType = {
  id: string;
  name: string;
  status: string;
  payment_interval: string;
  amount_in_scu: number;
  currency: string;
  product: {
    id: string;
    name: string;
    image_url: string;
    category: string;
  };
};

export type NotificationType = {
  id: number;
  title: string;
  message: string;
  link?: string;
};

export interface DataOrganizationType {
  name: string;
  email: string;
  users: number;
  devices: number;
  referred_by?: string;
  created_at: string;
  partner_name: string;
  partner_display_name: string;
  id: string;
  display_name: string;
  api_key: string;
  lab: boolean;
  tagline: string;
  type: RefereeType;
}

export interface ConfigOrganizationType {
  name: string;
  email: string;
  users: number;
  devices: number;
  referred_by?: string;
  created_at: string;
  partner_name: string;
  partner_display_name: string;
  id: string;
  display_name: string;
  api_key: string;
  lab: boolean;
  tagline: string;
  type: RefereeType;
}

export interface ConfigPartnerType {
  id: string;
  name: string;
  display_name: string;
  hardware_key: string;
  device_count: number;
  shortcode: string;
  onboarding_step: string | 'complete';
  pricing_plan: {
    name: string;
    started_at: string;
    features: {
      powered_by_xyte_label_toggle: number;
      registered_devices_limit: number;
    };
  };
}

export type SystemIssuesType = {
  id: number;
  title: string;
  description: string;
  link?: string;
  entity: { type: string; id: string };
};

export interface UserPermissions {
  models: AccessLevelEnum;
  customers: AccessLevelEnum;
  support: AccessLevelEnum;
  white_labeling: AccessLevelEnum;
  user_management: AccessLevelEnum;
  account_management: AccessLevelEnum;
  finance: AccessLevelEnum;
}

export type UserType = {
  id: string;
  name: string;
  organization?: string;
  local: boolean;
  is_group: boolean;
  partner: string;
  email: string;
  sign_in_count: 47;
  last_sign_in_at: string;
  last_seen_at: string;
  last_ip: string;
  global_admin: boolean;
  org_admin: boolean;
  partner_admin: boolean;
  access?: Array<UuidType>;
  users?: Record<UuidType, boolean>;
  controlled: {
    organization: Record<string, string | null>;
    partner: Record<string, string | null>;
  };
  settings: Record<string, Layouts>;
  shared_settings?: Record<string, Layouts>;
  seats_limit?: number;
  confirmed_at?: string;
  permissions: UserPermissions;
};

export enum RefereeType {
  Organization = 'organization',
}

export type ReferralCodeType = {
  id: string;
  referral_code: string;
  title: string;
  registered?: Array<{ name: string }>;
  group_id?: string;
  organization_type?: RefereeType;
};

export interface RuleError {
  id: string;
  name: string;
  description: string;
  created_at: string;
}

export interface RuleType {
  id: string;
  name: string;
  active: boolean;
  device_model: string;
  description: string;
  priority: string;
  created_at: string;
  definition: any;
  errors: RuleError[];
}

export type DashboardLayout = {
  list: Layout[];
  layouts: Layouts;
};

export type SupportedCommandType = {
  id: UuidType;
  name: string;
  device_id: string;
  status: string;
  friendly_name: string;
  description: string;
  with_file: null | boolean;
  file_type?: string;
  custom_fields: null | Array<FieldType>;
  active: boolean;
  created_at: string;
  updated_at: string;
};

export enum AuthMethodType {
  NanoId = 'nano_id',
  MacSN = 'mac_sn',
  PubKey = 'pub_key',
  x509 = 'x509',
}

export enum CommunicationProtocolType {
  HTTP = 'http',
  MQTT = 'mqtt',
  VPRO = 'vPro',
}

export type DeviceModelType = {
  id: UuidType;
  partner: {
    id: string;
    name: string;
    display_name: string;
  };
  model: string;
  user_settings: {
    commands?: Array<any>;
    file_dumps?: boolean;
    visibility?: {
      claimable?: boolean;
      syncpro_uc?: boolean;
      uc?: boolean;
    };
  };
  config_schema: null | Record<string, any>;
  automatically_generated: boolean;
  allow_tickets: boolean;
  supported_commands: Array<SupportedCommandType>;
  driver: string;
  driver_name: string;
  active: boolean;
  mobile: boolean;
  type: string;
  devices_count?: number;
  auth_method: AuthMethodType;
  with_reboot: boolean;
  has_registered_devices: boolean;
  has_device_telemetries: boolean;
  communication_protocol: string;
  ttl_in_minutes?: number;
};

export type TicketType = {
  id: string;
  status: string;
  title: string;
  description: string;
  device_id: string;
  partner: string;
  organization: string;
  org_seen: boolean;
  partner_seen: boolean;
  created_at: string;
  updated_at: string;
  device?: {
    name: string;
    model: string;
    sn: null | any;
    model_icon?: string;
  };
  chat?: ChatMessageType[];
  space?: {
    name: string;
    path: string;
  };
  org_id?: string;
};

export interface ChatMessageType {
  created_at: string;
  id: number;
  owner: string;
  owner_type: TenantType | 'system';
  system: boolean;
  text: string;
  user: string;
}

export interface OrganizationFileType {
  id: string;
  name: string;
  url: string;
  desc: string;
  type: string;
  version: string;
  device_model: string | null;
  device_model_name: string | null;
  space: string | null;
  space_name: string;
  partner: boolean;
}

export interface PartnerFileType {
  id: string;
  name: string;
  url: string;
  desc: string;
  type: string;
  file_type: string;
  version: string;
  created_at: string;
  device_model_id: string;
  checksum: string;
  signature: string;
  notes: string;
  public_notes: string;
}

export type BuyerInfo = {
  full_name: string;
  email: string;
  phone: string;
  billing_address: string;
  billing_address2: string;
  city: string;
  country: string;
  state: string;
  zip: string;
};

export type LicenseType = {
  id: string;
  state: string;
  family: null | any;
  amount: number;
  transferable: boolean;
  expires_at: null | string;
  activated_at: null | string;
  created_at: string;
  duration: null | any;
  partner: string;
  product: string;
  license_type: string;
  device_id: number;
  device_model_id?: string;
  model: string;
  org_license: boolean;
  buyer_info?: BuyerInfo;
  assigned?: boolean;
  claimed?: boolean;
  notes?: string;
  data?: string;
  signature?: string;
  access_level: AccessLevelEnum;
  params?: {
    sn: string;
  };
  organization: {
    id: string;
    name: string;
  };
};

interface DeviceInfoType {
  claimed_devices: number;
  online_devices: number;
}

export type StatsType = {
  space_id: number;
  usage: {
    occupied: number;
    in_call: number;
    presenting: number;
  };
  unconfigured: number;
  incident_counts: Record<string, number>;
  stats_history: Array<{ timestamp: string; data: Record<string, any> }>;
  incidents: OrganizationIncidentType[];
  spaces_with_incidents: Array<number>;
  claimed_devices: number;
  online_devices: number;
  device_info: Record<string, DeviceInfoType>;
};

export type TabType<
  ComponentProps extends object = Record<string, unknown>,
  VisibleFnExtraParams extends object = Record<string, unknown>
> = {
  title: string;
  id?: string;
  name?: string;
  Component?: ComponentRendererType<ComponentProps>;
  props?: Record<string, any>;
  tabs?: TabType<ComponentProps, VisibleFnExtraParams>[];
  visible?: TabVisibleFn<VisibleFnExtraParams>;
  disabled?: (...params: any[]) => boolean;
  badge?: ReactNode;
  Counter?: ComponentRendererType<any>;
  counterProps?: (device: DeviceType) => {
    spaceId?: number;
    deviceId?: string;
    className?: string;
    inverted?: boolean;
    withTooltip?: boolean;
  };
  errors?: number;
  state?: string;
};

export type ProductType = {
  category: string;
  id: number;
  disabled?: boolean;
  owner: string;
  title: string;
  img: string;
  comingSoon?: boolean;
};

// TODO: Replace all usages
export type SetRoute = (path: string) => void;

export type ModelProductType = {
  device_model_id: string;
  id: string;
  active: boolean;
  name: string;
  description: string;
  subtitle: string;
  price: number | null;
  one_time_price: number;
  payment_interval: PeriodEnum;
  license_family: string;
  category?: string;
  transferable: boolean;
  plan_id?: string;
  type?: string;
  partner_name?: string;
  with_shipping: boolean;
  package: boolean;
  detailed_description: string | null;
  color?: string;
  badge?: string | null;
  image_url?: string | null;
  image?: string | null;
  license_data: string;
  terms: string | null;
  product_type: 'digital' | 'physical';
  payment_type: 'one-time' | 'recurring';
};

export type ModelType = {
  id: string;
};

export type MethodType = 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE';

export enum PeriodEnum {
  Monthly = 'monthly',
  Yearly = 'yearly',
  OneTime = 'one_time',
}

export type UuidType = string;
export type AccessLevelType = 0 | 1 | 2 | 3;

export type PackageType = {
  packageType: 'base' | 'pro' | 'premium';
  popularityType?: string;
  name: string;
  description: string;
  image: string;
  price: string;
  period: PeriodEnum;
  details: Array<{ category: string; features: Array<string> }>;
};

export enum CartNameEnum {
  DeviceProducts = 'device_products',
}

export type ExpertType = {
  id: string;
  name: string;
  image: string;
  description: string;
  email?: string;
  phone?: string;
  locations?: string;
  expertise?: string;
};

export type SpaceHistory = Record<
  UuidType,
  Array<
    [
      {
        common: any;
        devices: number;
        incidents: {
          low?: number;
          high?: number;
          total?: number;
          critical?: number;
          moderate?: number;
          planning?: number;
        };
      },
      any
    ]
  >
>;

export interface StateDumpType {
  id: string;
  filename: string;
  device_id: string;
  created_at: string;
  access_count: number;
  access_token: string;
  mime_type: string;
}

export enum WidgetTypeEnum {
  LineChart = 'line_chart',
  PieChart = 'pie_chart',
  MetricWidget = 'metric_widget',
  GaugeWidget = 'gauge_widget',
  StatusWidget = 'status_widget',
}

export type WidgetType = {
  id?: string;
  name: string;
  config: {
    id: WidgetTypeEnum;
    width: number;
    fields: Record<string, string>;
    layout?: Layout;
  };
};

export type ValidationErrorType = {
  data: string;
  error: string;
  field: string;
  path: Array<string>;
};

export type HardwareKeyType = {
  friendly_name: string;
  id: string;
  expected_device_count: number;
  used_devices_count: number;
};

export interface TestDeviceType {
  access_token: string;
  created_at: string;
  device_model_id: string;
  firmware: string;
  id: string;
  mac: null | string;
  nano_id: string;
  sn: null | string;
  telemetry_count?: boolean;
  test: boolean;
  note: null | string;
  hub_url: null | string;
}

export interface TestDeviceWithTelemetryType extends TestDeviceType {
  telemetries: Array<TelemetryType>;
}

export interface LocationType {
  lat: number;
  lng: number;
}

export interface TenantGroup {
  id: string;
  name: string;
  partner_id: string | null;
  organization_id: string | null;
  permissions: UserPermissions;
  static_name: boolean;
  updated_at: string;
  created_at: string;
}

export type OrganizationInvoiceType = {
  id: string;
  status: string;
  invoice_file_url: string;
  amount: number;
  currency: string;
  created_at: string;
  note: string;
  tax: number;
  partner: {
    id: string;
    display_name: string;
  };
};

export type PartnerInvoiceType = {
  id: string;
  status: string;
  invoice_file_url: string;
  amount: number;
  currency: string;
  created_at: string;
  note: string;
  tax: number;
  buyer: {
    id: string;
    name: string;
  };
  items: Array<{ name: string; model?: string; quantity: number }>;
  billing_address: OrganizationAddressType;
  organization: {
    id: string;
    name: string;
  };
};

// Shipping

export enum ShipmentStatusType {
  Preparing = 'preparing_for_shipment',
  Shipped = 'shipped',
  Delivered = 'delivered',
}

// Shipping - Organizations

export interface OrganizationShipmentType {
  id: string;
  tracking_number: string;
  seller: { id: string; name: string };
  status: ShipmentStatusType;
}

export interface OrganizationShippedProductType {
  id: string;
  product: {
    id: string;
    name: string;
    serial_number: string;
  };
}

// Shipping - Partners

export interface PartnerUnshippedRequestType {
  organization_id: string;
  organization_name: string;
}

export interface PartnerUnshippedRequestProductType {
  id: string;
  name: string;
}

export interface PartnerUnshippedRequestByOrgType {
  id: string;
  address: {
    address_1: string;
    address_2?: string;
    city: string;
    state?: string;
    country: string;
    zip: string;
  };
  products: Array<PartnerUnshippedRequestProductType>;
}

export interface PartnerShipmentType {
  id: string;
  tracking_number: string;
  organization: { id: string; name: string };
  status: ShipmentStatusType;
}

export type PartnerInvoiceItemType = {
  name: string;
  amount: number;
};

export interface OrderPreviewItemType {
  id: string;
  name: string;
  quantity: number;
  selected_payment_interval: PeriodEnum;
  charges: {
    one_time?: {
      amount_in_scu: number;
    };
    monthly?: {
      charge_date: string;
      amount_in_scu: number;
    };
    yearly?: {
      charge_date: string;
      amount_in_scu: number;
    };
  };
}

export interface EstimatedTax {
  name: string;
  rate: number;
  amount: number;
}

export interface PreviewOneTimeChargeSummary {
  type: 'one_time';
  amount_in_scu: number;
}

export interface PreviewProrataChargeSummary {
  type: 'prorata';
  amount_in_scu: number;
  start_date: string;
  end_date: string;
}

export interface OrderPreviewType {
  items: Array<OrderPreviewItemType>;
  currency: CurrencyCode;
  summary: {
    today: {
      charges: Array<PreviewOneTimeChargeSummary | PreviewProrataChargeSummary>;
      subtotal: number;
      estimated_tax: Array<EstimatedTax>;
      estimated_total: number;
    };
    next_month?: {
      charge_date: string;
      subtotal: number;
      estimated_tax: Array<EstimatedTax>;
      estimated_total: number;
    };
    next_year?: {
      charge_date: string;
      subtotal: number;
      estimated_tax: Array<EstimatedTax>;
      estimated_total: number;
    };
  };
}

export interface PartnerIncidentType {
  device_id: string;
  id: string;
  priority: number;
  title: string;
  created_at: string;
  device_name: string;
  org_display_name: string;
  space_name: string;
  space_path: string;
  device_model_name: string;
  issue: string;
  org_id?: string;
  space_coordinates?: MapCoordinatesType;
  description?: string;
}

export interface MapCoordinatesType {
  lat: number;
  lng: number;
}

export interface SupportOverviewKpisType {
  customers_count: number;
  monitored_devices_count: number;
  affected_devices_count: number;
  purchase_orders_count: number | null;
}

export interface CustomerKpisType {
  root_img_src: string;
  spaces_count: number;
  rooms_count: number;
  monitored_devices_count: number;
  purchase_orders_count: number | null;
}

enum SeverityStatus {
  High = 'high',
  Medium = 'medium',
  Low = 'low',
}

export interface CustomerType {
  id: string;
  name: string;
  devices_count: number;
  incidents_count: number;
  tickets_count: number;
  severity_status: SeverityStatus;
  map_coordinates: MapCoordinatesType;
}

export interface CustomerWithControlledType extends CustomerType {
  controlled: boolean;
}

export interface PurchasedProductType {
  id: string;
  created_at: string;
  product: {
    id: string;
    name: string;
    image_url: string;
    category: string;
  };
}

// @todo: rename to PartnerStoreSettingsType
export interface StoreSettingsType {
  currencies: Array<CurrencyCode>;
  address_1: string | null;
  address_2?: string | null;
  city: string | null;
  state?: string | null;
  country: string | null;
  zip: string | null;
  default_currency: CurrencyCode;
}

export enum PaymentStatus {
  Succeeded = 'succeeded',
  Pending = 'pending',
  Failed = 'failed',
}

export interface OrganizationOrderItemProduct {
  id: string;
  name: string;
  category: string;
  subtitle: string;
  image_url: string;
  product_details?: Record<string, any>;
}

export interface OrganizationOrderType {
  id: string;
  status: string;
  currency: string;
  amount_in_scu: number;
  tax_in_scu: number;

  items: Array<{
    quantity: number;
    product: OrganizationOrderItemProduct;
  }>;

  shipping_address: {
    line_1: string;
    line_2?: string;
    city: string;
    country: string;
    state: string;
    zip: string;
    receiver_name?: string;
    receiver_tax_id?: string;
    phone_number?: string;
  };

  partner: {
    id: string;
    name: string;
  };

  payment: {
    id: string;
    status: PaymentStatus;
  };
}

export interface PartnerStoreListingPrice {
  currency: CurrencyCode;
  one_time_price_in_scu: number | null;
  monthly_price_in_scu: number | null;
  yearly_price_in_scu: number | null;
}

export interface PartnerStoreListing {
  id: string;
  product: {
    id: string;
    category: string;
    name: string;
    description: string;
    device_model_id: string;
    terms: string | null;
    product_type: string | null;
    transferable: boolean;
    image_url: string;
    partner_name: string;
  };
  prices: Array<PartnerStoreListingPrice>;
}

export interface OrganizationStoreListing {
  id: string;
  partner_name: string;
  updated_at: string;
  product: {
    id: string;
    name: string;
    category: string;
    subtitle: string;
    image_url: string;
    product_details: string | null;
    description: string;
    product_type: 'physical' | 'digital';
    payment_type: 'one-time' | 'recurring';
    device_model_id?: string;
  };
  prices: Array<{
    currency: CurrencyCode;
    pricing_options: Array<{
      amount: number;
      type: PeriodEnum;
    }>;
  }>;
}

export interface PartnerCreateStoreListing {
  product_id: string;
  prices: Array<PartnerStoreListingPrice>;
}

export interface OrganizationAddressType {
  id: string;
  line_1: string;
  line_2: string | null;
  phone_number: string | null;
  city: string;
  state: string | null;
  country: string;
  zip: string | null;
  display_name: string;
  receiver_name: null;
  receiver_tax_id: null;
}

export enum CardBrand {
  amex = 'amex',
  cartes_bancaires = 'cartes_bancaires',
  diners = 'diners',
  discover = 'discover',
  jcb = 'jcb',
  mastercard = 'mastercard',
  visa = 'visa',
  unionpay = 'unionpay',
  unknown = 'unknown',
}

export enum PaymentMethodEnum {
  CreditCard = 'credit_card',
  Lab = 'lab',
  ACHTransfer = 'ach_transfer',
  PurchaseOrder = 'purchase_order',
}

export interface CreditCard {
  id: string;
  brand: CardBrand;
  last_4_digits: string;
  exp_month: number;
  exp_year: number;
}

export interface PartnerConnection {
  id: string;
  name: string;
  display_name: string;
  relationship_type: string;
}

export interface AuditLogParamsType {
  id: string;
  model: string;
  name: string;
  type: string;
  display_name: string;
}

export interface AuditLogType {
  id: string;
  full_message: string;
  created_at: string;
  user?: {
    id: string;
    name: string;
    email?: string;
  };
  template: string;
  params: Array<AuditLogParamsType>;
}

export interface PurchasedProductType {
  id: string;
  created_at: string;
  product: {
    id: string;
    name: string;
    image_url: string;
    category: string;
  };
}

export interface OrganizationShopType {
  products: Array<OrganizationStoreListing>;
  defaultCurrency: CurrencyCode;
}

export interface TaxGroupType {
  id: string;
  name: string;
  icon_name: string;
  tax_code: string;
  products_count: number;
}

export interface TaxGroupProductType {
  id: string;
  name: string;
  image_url: string;
}
