import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import Cropper from 'react-cropper';
import { ImageType } from 'react-images-uploading';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import 'rc-slider/assets/index.css';

import { useUploadFileToS3 } from '@portals/api';
import { PendingOverlay } from '@portals/ui';
import { getStyledThemeColor, hexToRgba } from '@portals/utils';

import ImageInput from '../../components/ImageInput';
import ImageCropperActions from './ImageCropperActions';

interface UploadImageProps {
  closeMe: () => void;
  data: {
    title?: string;
    aspectRatio?: number;
    onUpload: (imageUrl: string) => void;
    onError: (error: string) => void;
    image?: ImageType;
    onSaveChanges: (canvas: HTMLCanvasElement) => void;
  };
}

const UploadImage: FC<UploadImageProps> = ({ closeMe, data }) => {
  const { mutateAsync: uploadFile, isLoading } = useUploadFileToS3();

  const cropperRef = useRef<Cropper>();
  const {
    title = 'Upload image',
    aspectRatio = 1,
    image,
    onUpload,
    onError,
  } = data;

  const [imageToCrop, setImageToCrop] = useState<ImageType>(image);
  const [cropConfig, setCropConfig] = useState({ straighten: 0, scale: 1 });

  const onInitialized = useCallback((ref: Cropper) => {
    cropperRef.current = ref;
  }, []);

  const onReady = useCallback(() => {
    cropperRef.current.zoomTo(cropConfig.scale);
    cropperRef.current.rotateTo(cropConfig.straighten);
  }, [cropConfig.straighten, cropConfig.scale]);

  const onConfirm = useCallback(async () => {
    const canvas = cropperRef.current.getCroppedCanvas();

    try {
      canvas.toBlob(async (blob) => {
        const fileUrl = await uploadFile({
          blob,
          originalFileName: image?.file.name,
        });

        onUpload(fileUrl);

        closeMe();
      }, image?.file.type);
    } catch (err) {
      onError(err);
    }
  }, [
    closeMe,
    image?.file.name,
    image?.file.type,
    onError,
    onUpload,
    uploadFile,
  ]);

  const onSelectImage = useCallback((images: Array<ImageType>) => {
    setImageToCrop(images[0]);
  }, []);

  useEffect(
    function updateScale() {
      if (cropperRef.current) {
        cropperRef.current.scale(cropConfig.scale);
      }
    },
    [cropConfig.scale]
  );

  useEffect(
    function updateStraighten() {
      if (cropperRef.current) {
        cropperRef.current.rotateTo(cropConfig.straighten);
      }
    },
    [cropConfig.straighten]
  );

  return (
    <StyledModal isOpen toggle={closeMe} centered>
      <StyledPendingOverlay isVisible={isLoading} />

      <ModalHeader toggle={closeMe}>{title}</ModalHeader>

      <StyledModalBody>
        {imageToCrop ? (
          <>
            <CropWrapper>
              <Cropper
                src={imageToCrop.dataURL}
                guides
                dragMode="move"
                cropBoxMovable={false}
                cropBoxResizable={false}
                zoomOnWheel={false}
                style={{ width: '100%', height: 240 }}
                aspectRatio={aspectRatio}
                minCropBoxHeight={240}
                onInitialized={onInitialized}
                ready={onReady}
              />
            </CropWrapper>

            <ImageCropperActions
              cropConfig={cropConfig}
              setCropConfig={setCropConfig}
            />
          </>
        ) : (
          <ImageInputWrapper>
            <ImageInput onChange={onSelectImage} label={title} />
          </ImageInputWrapper>
        )}
      </StyledModalBody>

      <ModalFooter>
        <Button color="light" onClick={closeMe} disabled={isLoading}>
          Cancel
        </Button>

        <Button
          color="primary"
          onClick={onConfirm}
          disabled={!imageToCrop || isLoading}
        >
          Save
        </Button>
      </ModalFooter>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  position: relative;
`;

const StyledModalBody = styled(ModalBody)`
  padding: 0 !important;
`;

const CropWrapper = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${getStyledThemeColor('gray600')};
`;

const StyledPendingOverlay = styled(PendingOverlay)`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => hexToRgba(theme.color.white, 30)};
`;

const ImageInputWrapper = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default UploadImage;
