import { set } from 'lodash/fp';
import React, { FC } from 'react';
import { connect } from 'react-redux';

import { Meta, useWhiteLabelConfig } from '@portals/framework';
import {
  FeaturesType,
  PartnerConfigType,
  StateType,
  TenantType,
} from '@portals/types';
import { portalFavicon, portalWindowTitle } from '@portals/utils';

type FieldOpsMetaProps = {
  config: PartnerConfigType | FeaturesType;
};

type FieldOpsMetaConnectedProps = {
  loggedIn: boolean;
  partnerName: string;
};

export const FieldOpsMeta: FC<
  FieldOpsMetaProps & FieldOpsMetaConnectedProps
> = ({ loggedIn, partnerName, config = {} }) => {
  const adjustedConfig: Partial<PartnerConfigType> = useWhiteLabelConfig(
    TenantType.Organization,
    loggedIn,
    set(
      'name',
      loggedIn ? partnerName : (config as PartnerConfigType).name,
      config
    )
  );

  return (
    <Meta
      title={portalWindowTitle(adjustedConfig)}
      meta={[
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, maximum-scale=1',
        },
      ]}
      links={[
        {
          href: portalFavicon(adjustedConfig),
          rel: 'icon',
          sizes: '192x192',
        },
      ]}
    />
  );
};

const mapStateToProps = (state: StateType) => ({
  loggedIn: !!state.ui.auth,
  partnerName: state.data?.config?.organization?.partner_display_name,
});

export default connect<FieldOpsMetaConnectedProps>(mapStateToProps)(
  FieldOpsMeta
);
