import { Group, Badge, BadgeProps } from '@mantine/core';
import React, { FC, ReactNode } from 'react';

interface BadgeCellProps extends BadgeProps<'div'> {
  label: ReactNode;
}

export const BadgeCell: FC<BadgeCellProps> = ({ label, ...badgeProps }) => (
  <Group>
    <Badge
      radius="sm"
      size="lg"
      sx={(theme) => ({
        textTransform: 'capitalize',
        fontWeight: 400,
        fontSize: theme.fontSizes.xs,
        color: theme.colors.gray[9],
      })}
      {...badgeProps}
    >
      {label}
    </Badge>
  </Group>
);
