import { Divider, Group, Stack } from '@mantine/core';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useGoogleSSOButton } from '@portals/framework';
import { signInWithGoogle } from '@portals/redux/actions/auth';

import { CredentialResponse } from '../../types/google-auth';

const ALLOWED_HOSTS = ['.xyte.io', '.on-xyte.com', '.avocor.com'];

const SSO = () => {
  const dispatch = useDispatch();
  const setGoogleToken = useCallback(
    ({ credential }: CredentialResponse) => {
      dispatch(signInWithGoogle(credential));
    },
    [dispatch]
  );
  const googleBtnRef = useGoogleSSOButton(setGoogleToken);

  return (
    <Stack mb="md">
      <Divider label="OR" labelPosition="center" mb={10} />

      <Group position="center" align="center">
        <div ref={googleBtnRef} style={{ height: 44 }} />
      </Group>
    </Stack>
  );
};

const SSOWrapper = () => {
  const isAllowed =
    process.env.NODE_ENV === 'development' ||
    ALLOWED_HOSTS.some((host) => window.location.host.endsWith(host));

  return isAllowed ? <SSO /> : null;
};

export default SSOWrapper;
