import {
  filter,
  flow,
  get,
  isEmpty,
  keyBy,
  keys,
  reduce,
  uniq,
  values,
} from 'lodash/fp';
import { useEffect, useMemo } from 'react';
import { Selector, useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';

import { getUsers as getUsersAction } from '@portals/redux/actions/users';
import { getAuth } from '@portals/redux/selectors/ui';
import {
  AuthType,
  DeviceModelType,
  DeviceType,
  OrganizationIncidentType,
  LicenseType,
  StateType,
  SubscriptionType,
  UserType,
  UuidType,
} from '@portals/types';

import {
  getAccess,
  getConfig,
  getCurrentUser,
  getCurrentUserPermissions,
  getDeviceModel,
  getDeviceModels,
  getDevices,
  getFeatures,
  getFileById,
  getFiles,
  getIncidents,
  getIncidentsByDeviceId,
  getIntegrations,
  getLicenses,
  getOrganizationConfig,
  getOrganizations,
  getOrgFiles,
  getRuleById,
  getRules,
  getSpaces,
  getStateDumps,
  getSubscription,
  getSubscriptions,
  getTelemetries,
  getUserConfig,
  getUsers,
  getWelcomeDetails,
} from '../selectors/data';
import { useActionDispatch } from './api';
import { DataState, useDataWithState } from './ui';

/* USERS */

export const useGroups = () => {
  const users = useSelector(getUsers);

  const localGroups = useMemo(
    () =>
      flow([
        values,
        filter((group: UserType) => group.is_group && group.local),
      ])(users),
    [users]
  );

  const fetchUsers = useActionDispatch(getUsersAction);

  useEffectOnce(() => {
    if (isEmpty(localGroups)) {
      fetchUsers();
    }
  });

  const [, dataState] = useDataWithState({
    actionsTypes: 'getUsers',
    data: users,
  });

  const isLoading = useMemo(() => dataState === DataState.Pending, [dataState]);

  // Check if the groups contain any users we don't have cached locally and get them
  useEffect(() => {
    if (!localGroups || isLoading) {
      return;
    }

    const allUuids: Array<UuidType> = flow([
      reduce((list, group: UserType) => [...list, ...keys(group.users)], []),
      uniq,
    ])(localGroups);

    const missing: Array<UuidType> = filter(
      (uuid: UuidType) => !users[uuid],
      allUuids
    );

    if (missing && missing.length > 0) {
      fetchUsers(missing);
    }
  }, [localGroups, isLoading, users, fetchUsers]);

  return { data: localGroups, isLoading };
};

export const useUsers = (): Record<string, UserType | never> =>
  useSelector(getUsers);

export const useAuth = (): AuthType => useSelector(getAuth);

/* FEATURES */

export const useFeatures = () => useSelector(getFeatures);

/* DEVICES */

export const useDevices = (): Record<string, DeviceType> | undefined =>
  useSelector(getDevices);

export const useDeviceModels = (): Record<string, DeviceModelType> =>
  useSelector(getDeviceModels);

export const useClaimableDevices = (): Record<string, DeviceModelType> => {
  const deviceModels = useDeviceModels();
  const claimableDevices = values(deviceModels).filter((device) => {
    // Automatically generated models are created by edges and need to be manually approved
    // before being available to everyone
    if (device.automatically_generated) {
      return false;
    }

    // User can set their model to be only auto-claimed by edges
    return device.user_settings?.visibility?.claimable !== false;
  });

  return keyBy('id', claimableDevices);
};

export const useDeviceModel = (deviceId: string): DeviceModelType => {
  const selector = useMemo<Selector<StateType, DeviceModelType>>(
    () => getDeviceModel(deviceId),
    [deviceId]
  );

  return useSelector(selector);
};

/* LICENSES */

export const useLicenses = (): Record<string, LicenseType> | undefined =>
  useSelector(getLicenses);

/* FILES */

export const useFiles = (): StateType['data']['file_infos'] | undefined =>
  useSelector(getFiles);

export const useOrgFiles = (): StateType['data']['file_infos'] | undefined =>
  useSelector(getOrgFiles);

export const useFileById = (
  fileId: string
): StateType['data']['file_infos'][string] | undefined =>
  useSelector(getFileById(fileId));

/* SUBSCRIPTIONS */

export const useSubscriptions = ():
  | Record<string, SubscriptionType>
  | undefined => useSelector(getSubscriptions);

export const useSubscription = (subscriptionId): SubscriptionType | undefined =>
  useSelector(getSubscription(subscriptionId));

/* CONFIG */

export const useConfig = (): StateType['data']['config'] =>
  useSelector(getConfig);

export const useUserConfig = (): StateType['data']['config']['user'] =>
  useSelector(getUserConfig);

export const useOrganizationConfig =
  (): StateType['data']['config']['organization'] =>
    useSelector(getOrganizationConfig);

/* INCIDENTS */

export const useIncidents = (): Record<string, OrganizationIncidentType> =>
  useSelector(getIncidents);

export const useIncidentsByDeviceId = (
  deviceId: string
): Array<OrganizationIncidentType> | undefined =>
  useSelector(getIncidentsByDeviceId(deviceId));

/* SPACES */

export const useSpaces = () => useSelector(getSpaces);

export const useSpace = (spaceId: number) => {
  const spaces = useSpaces();

  return spaces[spaceId];
};

/* ACCESS */

export const useAccess = () => useSelector(getAccess);

/* CURRENT USER */

export const useCurrentUser = () => useSelector(getCurrentUser);

export const useCurrentUserPermissions = () =>
  useSelector(getCurrentUserPermissions);

/* INTEGRATIONS */

export const useIntegrations = () => useSelector(getIntegrations);
export const useIntegrationByName = <
  IntegrationName extends keyof StateType['data']['integrations']
>(
  integrationName: IntegrationName
) => {
  const integrations = useSelector(getIntegrations);

  return integrations[integrationName];
};

/* WELCOME DATA */

export const useWelcomeDetails = () => useSelector(getWelcomeDetails);

/* ORGANIZATIONS */

export const useOrganizations = () => useSelector(getOrganizations);

export const useStateDumps = () => useSelector(getStateDumps);

/* TELEMETRIES */
export const useTelemetries = (deviceId) => {
  const telemetries = useSelector(getTelemetries);

  return get([deviceId, 'telemetries'], telemetries) || [];
};

/* RULES */
export const useRules = () => useSelector(getRules);
export const useRuleById = (ruleId: string) => useSelector(getRuleById(ruleId));
