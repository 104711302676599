import { NotificationsProvider } from '@mantine/notifications';
import React, { FC } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import { Router } from 'react-router-dom';

import history from '@portals/browser-history';
import { ThemeProvider as MantineThemeProvider } from '@portals/core';
import { Localization } from '@portals/i18n';
import { store } from '@portals/redux';
import { AppProps } from '@portals/types';
import { ThemeProvider } from '@portals/ui';

import DevTools from './components/DevTools';
import EnvName from './components/EnvName';
import VersionMonitor from './components/version-monitor/VersionMonitor';
import { AppConfigProvider } from './context';
import { HubspotConversationsProvider } from './external/HubspotConversationsProvider';
import Routes from './routes/Routes';
import { ScrollStateProvider } from './ScrollStateContext';
import QueryClientWrapper from './wrappers/QueryClientWrapper';

const App: FC<AppProps> = (props) => (
  <Router history={history}>
    <ThemeProvider>
      <MantineThemeProvider>
        <ScrollStateProvider>
          <AppConfigProvider value={props}>
            <EnvName />

            <Provider store={store}>
              <NotificationsProvider
                position="top-right"
                style={{ top: 80, right: 0 }}
              >
                {process.env.NX_ENV === 'production' ? (
                  <VersionMonitor />
                ) : null}
                {process.env.NX_ENV === 'production' ? null : <DevTools />}

                <QueryClientWrapper>
                  <HubspotConversationsProvider>
                    <Localization>
                      <DndProvider backend={HTML5Backend}>
                        <Routes modals={props.modals} />
                        <ReduxToastr
                          timeOut={5000}
                          newestOnTop={true}
                          position="top-right"
                          transitionIn="fadeIn"
                          transitionOut="fadeOut"
                          progressBar
                          closeOnToastrClick
                          preventDuplicates
                        />
                      </DndProvider>
                    </Localization>
                  </HubspotConversationsProvider>
                </QueryClientWrapper>
              </NotificationsProvider>
            </Provider>
          </AppConfigProvider>
        </ScrollStateProvider>
      </MantineThemeProvider>
    </ThemeProvider>
  </Router>
);

export default App;
