import { API } from '@portals/redux';
import {
  deleteData,
  updateNormalizedData,
  replaceNormalizedData,
} from '@portals/redux/actions/data';
import { INCIDENT_SCHEMA } from '@portals/redux/schemas';
import { mergeActions } from '@portals/utils';

export const getIncidents = () => ({
  type: API,
  payload: {
    url: 'ui/organization/incidents',
    normalize: [INCIDENT_SCHEMA],
    success: replaceNormalizedData('incidents'),
  },
});

export const createIncident = (deviceId, data, extraActions) => ({
  type: API,
  payload: {
    method: 'post',
    url: `ui/organization/devices/${deviceId}/incidents`,
    data,
    toastr: 'Incident created',
    name: 'createIncident',
    normalize: INCIDENT_SCHEMA,
    withError: true,
    success: [updateNormalizedData('incidents'), extraActions],
  },
});

export const closeIncident = (incident, closedReason, extraActions) => ({
  type: API,
  payload: {
    method: 'delete',
    url: `ui/organization/devices/${incident.device_id}/incidents/${incident.id}`,
    toastr: 'Incident closed',
    name: 'closeIncident',
    data: { closed_reason: closedReason },
    success: mergeActions(deleteData('incidents', incident.id), extraActions),
  },
});
