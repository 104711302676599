import Slider from 'rc-slider';
import React, { Dispatch, FC, SetStateAction } from 'react';
import RotateCcw from 'react-feather/dist/icons/rotate-ccw';
import styled from 'styled-components';

import { useTheme } from '@portals/ui';
import { getStyledThemeColor } from '@portals/utils';

interface ImageCropperActionsProps {
  cropConfig: { scale: number; straighten: number };
  setCropConfig: Dispatch<
    SetStateAction<{ scale: number; straighten: number }>
  >;
}

const ImageCropperActions: FC<ImageCropperActionsProps> = ({
  cropConfig,
  setCropConfig,
}) => {
  const { color } = useTheme();

  return (
    <Container>
      <ActionWrapper>
        <div className="action-header">
          <div className="d-flex align-items-center">
            Zoom
            {cropConfig.scale !== 1 ? (
              <div
                className="revert-action"
                onClick={() =>
                  setCropConfig((curr) => ({
                    ...curr,
                    scale: 1,
                  }))
                }
              >
                <RotateCcw className="ml-2" />
              </div>
            ) : null}
          </div>

          <div>{cropConfig.scale}</div>
        </div>

        <div className="slider-wrapper">
          <Slider
            value={cropConfig.scale}
            onChange={(value: number) =>
              setCropConfig((curr) => ({
                ...curr,
                scale: value,
              }))
            }
            min={0.1}
            step={0.01}
            max={4}
            handleStyle={{ backgroundColor: color.primary }}
          />
        </div>
      </ActionWrapper>

      <ActionWrapper>
        <div className="action-header">
          <div className="d-flex align-items-center">
            Straighten
            {cropConfig.straighten !== 0 ? (
              <div
                className="revert-action"
                onClick={() =>
                  setCropConfig((curr) => ({
                    ...curr,
                    straighten: 0,
                  }))
                }
              >
                <RotateCcw className="ml-2" />
              </div>
            ) : null}
          </div>
          <div>{cropConfig.straighten}</div>
        </div>

        <div className="slider-wrapper">
          <Slider
            value={cropConfig.straighten}
            onChange={(value: number) =>
              setCropConfig((curr) => ({
                ...curr,
                straighten: value,
              }))
            }
            min={-180}
            max={180}
            step={1}
            handleStyle={{ backgroundColor: color.primary }}
          />
        </div>
      </ActionWrapper>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${getStyledThemeColor('dark')};
  height: 100px;
  width: 100%;
  padding: 0 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 50px;
  align-items: center;
`;

const ActionWrapper = styled.div`
  .action-header {
    color: ${getStyledThemeColor('white')};
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .revert-action {
      cursor: pointer;

      svg {
        height: 17px;
        width: 17px;
      }
    }
  }
`;

export default ImageCropperActions;
