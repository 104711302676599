import { Group, Menu, Text } from '@mantine/core';
import { getOr, size } from 'lodash/fp';
import { noop } from 'lodash/fp';
import React from 'react';

import { ArrowDown } from '@portals/icons/linear';
import { useAuth, useUsers } from '@portals/redux';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { TenantSwitch } from '../../../../../libs/framework/src/lib/components/layout/sidebar/TenantMenu/TenantSwitch';

const NavbarLayout = () => {
  const users = useUsers();

  const { id, tenant } = useAuth();
  const controlledTenants = size(
    getOr([], [id, 'controlled', tenant.type], users)
  );

  return (
    <Group
      pl="md"
      grow
      position="left"
      sx={{
        flex: 1,
      }}
    >
      {controlledTenants > 1 ? (
        <Menu
          control={
            <Group>
              <Text size="lg" weight="600">
                {tenant.name}
              </Text>

              <ArrowDown style={{ width: 17, height: 17 }} />
            </Group>
          }
          styles={{
            body: {
              width: 300,
            },
          }}
        >
          <TenantSwitch toggleTenantSwitch={noop} toggleTenantMenu={noop} />
        </Menu>
      ) : (
        <Text size="lg">{tenant.display_name}</Text>
      )}
    </Group>
  );
};

export default NavbarLayout;
