import {
  CheckboxStylesNames,
  CheckboxStylesParams,
  ColorInputStylesNames,
  InputStylesParams,
  InputWrapperStylesNames,
  InputWrapperStylesParams,
  NumberInputStylesNames,
  NumberInputStylesParams,
  PasswordInputStylesNames,
  PasswordInputStylesParams,
  RadioGroupStylesNames,
  RadioStylesParams,
  SwitchStylesNames,
  SwitchStylesParams,
  TextInputStylesNames,
} from '@mantine/core';
import { CSSObject, MantineTheme } from '@mantine/styles';

import { ComponentStyleOverrideFn } from './common-types';

const getCommonInputStyle: (
  theme: MantineTheme
) => Record<string, CSSObject> = (theme) => ({
  root: {
    label: {
      marginBottom: theme.spacing.xs,
      color: theme.colors.gray[7],
      fontWeight: 400,
    },

    input: {
      '&::placeholder': {
        fontWeight: 400,
        fontSize: theme.fontSizes.sm,
      },
    },
  },
});

export const textInputStyle: ComponentStyleOverrideFn<
  TextInputStylesNames,
  InputStylesParams
> = (theme) => ({
  ...getCommonInputStyle(theme),
});

export const colorInputStyle: ComponentStyleOverrideFn<
  ColorInputStylesNames,
  ColorInputStylesNames
> = (theme) => ({
  ...getCommonInputStyle(theme),
});

export const numberInputStyle: ComponentStyleOverrideFn<
  NumberInputStylesNames,
  NumberInputStylesParams
> = (theme) => ({
  ...getCommonInputStyle(theme),
});

export const inputWrapperStyle: ComponentStyleOverrideFn<
  InputWrapperStylesNames,
  InputWrapperStylesParams
> = (theme) => ({
  ...getCommonInputStyle(theme),
});

export const textareaStyle: ComponentStyleOverrideFn<
  TextInputStylesNames,
  InputStylesParams
> = (theme) => ({
  ...getCommonInputStyle(theme),
});

export const passwordStyle: ComponentStyleOverrideFn<
  PasswordInputStylesNames,
  PasswordInputStylesParams
> = (theme) => ({
  ...getCommonInputStyle(theme),
});

export const switchStyle: ComponentStyleOverrideFn<
  SwitchStylesNames,
  SwitchStylesParams
> = (theme) => ({
  root: {
    label: {
      marginBottom: 0,
      color: theme.colors.gray[7],
      fontWeight: 400,
      fontSize: theme.fontSizes.sm,
    },
    input: {
      backgroundColor: theme.colors.blue_gray[1],

      '&:before': {
        width: '16px !important',
        height: '16px !important',
        border: 'none',
      },

      '&:checked': {
        '&:before': {
          transform: 'translateX(18px) !important',
        },
      },

      '&:disabled': {
        backgroundColor: theme.colors.blue_gray[1],

        '&:before': {
          backgroundColor: theme.colors.gray[4],
        },
      },
    },
    description: {
      fontSize: theme.fontSizes.sm,
    },
  },
});

export const radioGroupStyle: ComponentStyleOverrideFn<
  RadioGroupStylesNames,
  RadioStylesParams
> = (theme) => ({
  label: {
    marginBottom: theme.spacing.xs,
    color: theme.colors.gray[7],
    fontWeight: 400,
    fontSize: theme.fontSizes.sm,
  },
  radioWrapper: {
    label: {
      marginBottom: 0,
      color: theme.colors.gray[7],
      fontWeight: 400,
    },
  },
});

export const checkboxStyle: ComponentStyleOverrideFn<
  CheckboxStylesNames,
  CheckboxStylesParams
> = (theme) => ({
  root: {
    label: {
      marginBottom: 0,
      color: theme.colors.gray[7],
      fontWeight: 400,
    },
  },
});
