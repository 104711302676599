// @ts-nocheck
// TODO: Fix ReactS3Uploader types
import React, { useState } from 'react';
import { connect } from 'react-redux';
import ReactS3Uploader from 'react-s3-uploader';
import { Progress } from 'reactstrap';

import { getHeaders } from '@portals/redux/actions/auth';

const Uploader = ({ auth, onUploaded }) => {
  const [progress, setProgress] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const uploading = progress && progress !== 100;

  const clear = (file, next) => {
    setError(null);
    setSuccess(null);
    next(file);
  };

  const onError = (msg: string, file, response) =>
    setError(response.statusText);
  const onFinish = (params) => {
    setSuccess(true);
    onUploaded(process.env.NX_STATIC_FILES + params.path);
  };

  return (
    <div className="mb-3">
      <ReactS3Uploader
        signingUrl="ui/file_upload"
        signingUrlMethod="POST"
        accept="*"
        s3path="/uploads/"
        preprocess={clear}
        onSignedUrl={() => null}
        onProgress={setProgress}
        onError={onError}
        onFinish={onFinish}
        signingUrlHeaders={getHeaders(auth)}
        contentDisposition="auto"
        scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/gi, '')}
        server={process.env.NX_SERVER_URL}
        autoUpload={true}
      />

      {uploading && (
        <Progress className="mt-2" animated value={progress}>
          {progress}%
        </Progress>
      )}

      {error && (
        <p className="text-danger">
          Error uploading file:
          {error}
        </p>
      )}

      {success && (
        <p className="text-primary">File uploaded to remote storage</p>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.ui.auth,
});

export default connect(mapStateToProps)(Uploader);
