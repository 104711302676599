import React from 'react';

import { SmartTableProps } from '@portals/types/Table';

import { TableInstanceProvider } from '../context';
import { useSmartTable } from './hooks';
import SmartTable from './SmartTable';

export function SmartTableWrapper<TData extends object = any>({
  name,
  additionalActions,
  selectedItemsActions,
  noDataIndication,
  noExport,
  noHeader,
  noFilters,
  noColumnsSelection,
  defaultSorted,
  defaultFilters,
  columns,
  data,
  expandRow,
  noSort,
  onSelected,
  rowStyle,
  rowMenu,
  isUrlSyncEnabled,
  isCompact,
  expanded,
  pageSize,
  menuProps = {},
}: SmartTableProps<TData>) {
  const tableInstance = useSmartTable<TData>({
    defaultSortBy: defaultSorted,
    defaultFilters,
    data,
    expandRow,
    rowStyle,
    columns,
    onSelected,
    isUrlSyncEnabled,
    name,
    noColumnsSelection,
    expanded,
    pageSize,
  });

  return (
    <TableInstanceProvider instance={tableInstance}>
      <SmartTable<TData>
        name={name}
        rowStyle={rowStyle}
        expandRow={expandRow}
        onSelected={onSelected}
        additionalActions={additionalActions}
        selectedItemsActions={selectedItemsActions}
        noHeader={noHeader}
        noFilters={noFilters}
        noSort={noSort}
        noDataIndication={noDataIndication}
        noColumnsSelection={noColumnsSelection}
        noExport={noExport}
        rowMenu={rowMenu}
        isCompact={isCompact}
        withGlobalSearch
        menuProps={menuProps}
      />
    </TableInstanceProvider>
  );
}

export default SmartTableWrapper;
