import { Box, createStyles } from '@mantine/core';
import React from 'react';

import { TableCell } from '@portals/types';

interface TCellProps<TData extends object> {
  cell: TableCell<TData>;
}

function TCell<TData extends object>({ cell }: TCellProps<TData>) {
  const { cx, classes } = useStyles();
  const { isEditable } = cell.column;
  const cellProps = cell.getCellProps();

  return (
    <Box
      id={`cell-${cellProps.key}`}
      className={cx('tcell', classes.container, {
        editable: isEditable,
      })}
      {...cellProps}
    >
      <Box className={cx('tcell-wrapper', classes.wrapper)}>
        {cell.render('Cell')}
      </Box>
    </Box>
  );
}

function StickyTCell<TData extends object>({ cell }: TCellProps<TData>) {
  const { cx, classes } = useStyles();
  const { isSticky, isEditable } = cell.column;
  const cellProps = cell.getCellProps();

  return (
    <Box
      id={`cell-${cellProps.key}`}
      className={cx('tcell', classes.container, {
        sticky: isSticky,
        editable: isEditable,
      })}
      {...cellProps}
    >
      <Box className={cx('tcell-wrapper', classes.wrapper)}>
        {cell.render('Cell')}
      </Box>
    </Box>
  );
}

function TCellWrapper<TData extends object>({ cell }: TCellProps<TData>) {
  if (cell.column.isSticky) {
    return <StickyTCell<TData> cell={cell} />;
  }

  return <TCell<TData> cell={cell} />;
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    display: 'grid',
    color: theme.colors.gray[7],
    fontWeight: 400,
    padding: `0px ${theme.spacing.md}px`,

    '&.with-shadow': {
      boxShadow: `8px 0px 9px ${theme.fn.rgba(theme.black, 0.07)}`,
    },

    '&.sticky': {
      height: '100%',
      position: 'sticky',
      left: 0,
      zIndex: 2,
      backgroundColor: theme.colors.gray[0],
      clipPath: 'inset(0px -15px 0px 0px)',
    },

    '&.editable': {
      padding: 0,

      '.tcell-wrapper': {
        height: '100%',
      },
    },
  },
  wrapper: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

export default TCellWrapper;
