import React, { FC } from 'react';
import AlertCircle from 'react-feather/dist/icons/alert-circle';
import { connect } from 'react-redux';

import { StateType } from '@portals/types';

import { ReactComponent as BellIcon } from '../../../assets/img/icons/notification.svg';
import NavbarDropdownItem from '../../NavbarDropdownItem';
import { NavbarDropdown } from './NavbarDropdown';

type NotificationsConnectedProps = {
  notifications: StateType['ui']['notifications'];
};

const Notifications: FC<NotificationsConnectedProps> = ({ notifications }) => {
  const count = notifications.length;

  return (
    <NavbarDropdown header="System notifications" icon={BellIcon} count={count}>
      {notifications.map(({ id, title, message, link }) => (
        <NavbarDropdownItem
          key={id}
          title={title}
          icon={<AlertCircle size={18} className="text-danger" />}
          message={message}
          link={link}
          spacing
        />
      ))}
    </NavbarDropdown>
  );
};

const mapStateToProps = (state: StateType) => ({
  notifications: state.ui.notifications,
});

export default connect<NotificationsConnectedProps>(mapStateToProps)(
  Notifications
);
