import { FormikProps } from 'formik';
import React, { FC, ReactElement, ReactNode } from 'react';
import { ModalBody, ModalFooter } from 'reactstrap';

import FormikOnChange from '../FormikOnChange';
import Actions from './Actions';
import Body from './Body';
import { AutoFormikProps } from './types';

type WrapProps = {
  children: ReactNode;
  Component?:
    | (({ children }: { children: ReactNode }) => ReactNode)
    | typeof ModalBody;
};

const Wrap: FC<WrapProps> | ((props: WrapProps) => ReactElement) = ({
  Component,
  children,
}) => (Component ? <Component>{children}</Component> : children);

const formBuilder =
  (builderProps: AutoFormikProps) => (formikProps: FormikProps<any>) => {
    return (
      <form onSubmit={formikProps.handleSubmit} autoComplete="off">
        <FormikOnChange onChange={builderProps.onChange} />

        <Wrap Component={builderProps.bodyWrap || ModalBody}>
          <Body {...builderProps} {...formikProps} />
        </Wrap>

        <Wrap Component={builderProps.actionWrap || ModalFooter}>
          {!builderProps.readOnly ? (
            <Actions {...builderProps} {...formikProps} />
          ) : null}
        </Wrap>
      </form>
    );
  };

export default formBuilder;
