import {
  ActionIcon,
  Box,
  createStyles,
  Stack,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { get, getOr, isEmpty, keys, map, size, toLower } from 'lodash/fp';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useAppConfig } from '@portals/framework/context';
import { ArrowRight1 } from '@portals/icons/linear';
import { useAuth, useConfig, useUsers } from '@portals/redux';
import { switchTenant } from '@portals/redux/actions/auth';
import { SearchInput, VerticalScrollBar } from '@portals/ui';

import { ReactComponent as SelectedIcon } from '../../../../assets/img/icons/tick-square.svg';

interface TenantSwitchProps {
  toggleTenantMenu: () => void;
  toggleTenantSwitch: () => void;
  isStandalone?: boolean;
}

export const TenantSwitch: FC<TenantSwitchProps> = ({
  toggleTenantSwitch,
  toggleTenantMenu,
  isStandalone = false,
}) => {
  const { classes, cx } = useStyles({ isStandalone });

  const [searchValue, setSearchValue] = useState('');
  const [searchInputPlaceholder, setSearchInputPlaceholder] =
    useState('Switch Tenant');

  const dispatch = useDispatch();
  const config = useConfig();
  const { tenantType } = useAppConfig();
  const users = useUsers();
  const currentUser = useAuth();

  const currentTenant = get(tenantType, config);

  const controlled = getOr(
    [],
    [currentUser?.id, 'controlled', tenantType],
    users
  );

  const options = useMemo(() => {
    const groups = map(
      (tenantId) => ({
        value: tenantId,
        label: controlled[tenantId],
      }),
      keys(controlled)
    );

    if (!isEmpty(searchValue)) {
      const lowerCaseSearchValue = toLower(searchValue);

      return groups.filter(({ label }) =>
        toLower(label).includes(lowerCaseSearchValue)
      );
    }

    return groups;
  }, [controlled, searchValue]);

  const changeTenant = useCallback(
    (newTenantId) => {
      toggleTenantMenu();

      if (newTenantId === currentTenant?.id) {
        return;
      }

      dispatch(
        switchTenant(
          { id: newTenantId, name: controlled[newTenantId] },
          tenantType,
          true
        )
      );
    },
    [controlled, currentTenant?.id, dispatch, tenantType, toggleTenantMenu]
  );

  const onSearchInputFocusChanged = (isFocused: boolean) => {
    if (isFocused) {
      setSearchInputPlaceholder('Switch tenant');
    } else {
      setSearchInputPlaceholder('Find tenant...');
    }
  };

  if (!currentTenant || !users || size(controlled) < 2) {
    return null;
  }

  return (
    <Stack
      pr="xs"
      spacing={0}
      className={cx('tenant-switch-container', classes.container)}
    >
      <Box p="md" className={cx('tenant-switch-header', classes.header)}>
        {isStandalone ? null : (
          <ActionIcon
            onClick={toggleTenantSwitch}
            className={cx(classes.toggle, 'tenant-menu-toggle')}
          >
            <ArrowRight1 />
          </ActionIcon>
        )}

        <SearchInput
          value={searchValue}
          className={classes.input}
          placeholder={
            isStandalone ? 'Search Tenant...' : searchInputPlaceholder
          }
          withRemove
          onChange={setSearchValue}
          onInputFocusChanged={onSearchInputFocusChanged}
          autoFocus
        />
      </Box>

      <VerticalScrollBar
        renderView={(props) => <Stack {...props} spacing="xs" p="xs" />}
      >
        {options.map(({ label, value }) => (
          <UnstyledButton
            key={value}
            onClick={() => changeTenant(value)}
            className={cx(classes.tenant, {
              'current-tenant': value === currentTenant?.id,
            })}
          >
            <Text
              size="sm"
              sx={(theme) => ({
                color: theme.colors.blue_gray[9],
              })}
            >
              {label}
            </Text>

            {value === currentTenant?.id && <SelectedIcon />}
          </UnstyledButton>
        ))}
      </VerticalScrollBar>
    </Stack>
  );
};

const useStyles = createStyles((theme, params: { isStandalone: boolean }) => ({
  container: {
    height: 390,
    width: 300,
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',
  },
  header: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: params.isStandalone ? '1fr' : '18px 1fr',
    gridColumnGap: theme.spacing.md,
  },
  input: {
    fontSize: theme.fontSizes.sm,

    input: {
      paddingLeft: 0,

      '::placeholder': {
        fontWeight: 400,
      },
    },
  },
  toggle: {
    transform: 'rotate(180deg)',
    color: theme.colors.blue_gray[2],
    transition: 'color 0.1s ease-in-out',
    width: 18,
    height: 18,
    svg: {
      width: 18,
      height: 18,
    },

    '&:hover': {
      color: theme.colors.blue_accent[4],
    },
  },
  tenant: {
    padding: theme.spacing.sm,
    flexShrink: 0,
    transition: 'all 0.1s ease-in-out',
    cursor: 'pointer',
    display: 'grid',
    alignItems: 'center',
    borderRadius: theme.radius.md,
    gridTemplateColumns: '1fr',
    backgroundColor: theme.white,

    '&:hover': {
      backgroundColor: theme.colors.gray[0],
    },

    '&.current-tenant': {
      gridTemplateColumns: '1fr max-content',
      backgroundColor: `${theme.colors.blue[0]} !important`,
    },
  },
}));
