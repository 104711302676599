import { Modal, Stack } from '@mantine/core';
import { noop } from 'lodash/fp';
import React, { FC } from 'react';

import { TenantSwitch } from '../components/layout/sidebar/TenantMenu/TenantSwitch';

import type { ModalProps } from '../components/Modals';

const SwitchTenant: FC<ModalProps> = ({ closeMe }) => {
  return (
    <Modal
      opened={true}
      onClose={closeMe}
      padding={0}
      title="Switch Tenant"
      styles={(theme) => ({
        modal: {
          borderRadius: theme.radius.md,
          width: 340,
        },
        header: {
          borderBottom: `1px solid ${theme.colors.gray[3]}`,
          margin: 0,
          padding: theme.spacing.md,
        },
        title: {
          fontSize: theme.fontSizes.xl,
        },
        root: {
          '.tenant-switch-container': {
            width: '100%',

            '.tenant-switch-header': {
              height: 55,
            },

            '.tenant-switch-list': {
              padding: 0,
            },
          },
        },
      })}
    >
      <Stack>
        <TenantSwitch
          toggleTenantSwitch={noop}
          toggleTenantMenu={closeMe}
          isStandalone
        />
      </Stack>
    </Modal>
  );
};

export default SwitchTenant;
