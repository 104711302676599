import {
  NumberInput,
  Group,
  ActionIcon,
  NumberInputHandlers,
} from '@mantine/core';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Plus, Minus } from 'react-feather';
import styled from 'styled-components';

import { getStyledThemeColor } from '@portals/utils';

const NumberSelector = ({ quantity, setQuantity }) => {
  const handlers = useRef<NumberInputHandlers>();

  return (
    <Container style={{ width: '120px' }} className="number-selector-container">
      <Group spacing={2}>
        <ActionIcon
          size={20}
          variant="default"
          className="input-group-prepend"
          onClick={() => handlers.current.decrement()}
        >
          <Minus size={15} />
        </ActionIcon>

        <NumberInput
          hideControls
          value={quantity}
          onChange={setQuantity}
          handlersRef={handlers}
          min={0}
          step={1}
          styles={{ input: { width: 54, textAlign: 'center' } }}
        />

        <ActionIcon
          size={20}
          variant="default"
          className="input-group-append"
          onClick={() => handlers.current.increment()}
        >
          <Plus size={15} />
        </ActionIcon>
      </Group>
    </Container>
  );
};

const Container = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input {
    border: 1px solid ${getStyledThemeColor('gray200')};
    border-radius: 4px !important;
    margin: 0 10px;
  }

  .input-group-prepend,
  .input-group-append {
    display: flex;
    align-items: center;
    color: ${getStyledThemeColor('gray600')};
    transition: color 0.1s ease-in-out;

    &:hover,
    &:active {
      color: ${getStyledThemeColor('primary')};
    }

    .number-selector-icon {
      transition: transform 0.15s ease-in-out;

      line {
        stroke-width: 2.5px;
      }
    }

    &:active {
      .number-selector-icon {
        transform: scale(0.8);
        transform-origin: center;
      }
    }
  }
`;

NumberSelector.propTypes = {
  quantity: PropTypes.number.isRequired,
  setQuantity: PropTypes.func.isRequired,
};

export default NumberSelector;
