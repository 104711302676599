import { Box, Button, Tooltip } from '@mantine/core';
import { keys, map, noop, size } from 'lodash/fp';
import React, { FC, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { useUserCanManage } from '@portals/entities';
import { AddSquare, Trash } from '@portals/icons/linear';
import {
  addGroupMember,
  removeGroupMember,
} from '@portals/redux/actions/groups';
import { AuthType, TenantType, UserType, UuidType } from '@portals/types';
import { TableColumn } from '@portals/types/Table';
import { BadgeCell, RowMenuItems, SmartTable } from '@portals/ui/Table';

import ModalButton from '../ModalButton';
import AddGroupMember from './AddGroupMember';
import EditGroupSelectedActions from './EditGroupSelectedActions';
import GroupMember from './GroupMember';

interface EditGroupProps {
  tableName: string;
  group: UserType;
  users: Record<UuidType, UserType>;
  currentUser: UserType | AuthType;
  tenantType: TenantType;
  removeGroupMember: (groupId: UuidType, memberId: UuidType) => void;
  isCompact?: boolean;
  addGroupMember: (
    groupId: UuidType,
    memberId: UuidType,
    admin: boolean
  ) => void;
}

const EditGroup: FC<EditGroupProps> = ({
  currentUser,
  group,
  users,
  tenantType,
  removeGroupMember,
  addGroupMember,
  tableName,
}) => {
  const groupUsers = group.users;
  const [showAdd, setShowAdd] = useState(false);
  const canManage =
    useUserCanManage(currentUser.id) || group.users[currentUser.id];

  const showPermissionsButton =
    tenantType === TenantType.Partner && group.name !== 'administrators';

  const columns = useMemo(() => {
    const columnsList: Array<TableColumn> = [
      {
        dataField: 'user',
        text: 'User',
        formatter: (_, { uuid }) =>
          users[uuid] ? <GroupMember user={users[uuid]} /> : 'Unknown',
      },
      {
        dataField: 'type',
        text: 'Type',
        formatter: (_, { uuid }) => (
          <BadgeCell
            label={groupUsers[uuid] ? 'Admin' : 'User'}
            color={groupUsers[uuid] ? 'indigo' : 'cyan'}
          />
        ),
      },
    ];

    return columnsList;
  }, [users, groupUsers]);

  const tableData = useMemo(
    () => map((uuid) => ({ id: uuid, uuid }), keys(groupUsers)),
    [groupUsers]
  );

  return (
    <Container canManage={canManage}>
      {canManage ? (
        <Box
          py="md"
          sx={{
            width: '100%',
          }}
        >
          {showAdd ? (
            <AddGroupMember
              group={group}
              users={users}
              tenantType={tenantType}
              addGroupMember={addGroupMember}
              onCancel={() => setShowAdd(false)}
            />
          ) : (
            <Actions>
              <Tooltip
                label="Reached group's max capacity, contact support@xyte.io for adding seats"
                disabled={
                  group.seats_limit === 0 ||
                  size(group.users) < group.seats_limit
                }
              >
                <Button
                  variant="outline"
                  size="xs"
                  onClick={() => setShowAdd(true)}
                  leftIcon={<AddSquare />}
                  disabled={
                    group.seats_limit !== 0 &&
                    size(group.users) >= group.seats_limit
                  }
                  sx={{
                    svg: {
                      width: 16,
                      height: 16,
                    },
                  }}
                >
                  Add Users
                </Button>
              </Tooltip>
              {showPermissionsButton && (
                <ModalButton
                  modalName="ManagedPermissions"
                  label="Managed Permissions"
                  variant="outline"
                  data={group}
                />
              )}
            </Actions>
          )}
        </Box>
      ) : null}

      <SmartTable<{ uuid: string }>
        name={tableName}
        keyField="id"
        noColumnsSelection
        noDataIndication={{ title: 'No users' }}
        data={tableData}
        columns={columns}
        isCompact
        onSelected={canManage ? noop : undefined}
        additionalActions={() => null}
        selectedItemsActions={
          canManage ? (
            <EditGroupSelectedActions
              users={tableData}
              onRemove={removeGroupMember}
              groupId={group.id}
            />
          ) : null
        }
        rowMenu={({ row }) =>
          canManage ? (
            <RowMenuItems
              items={[
                {
                  id: 'removeMember',
                  onClick: () => removeGroupMember(group.id, row.original.uuid),
                  Icon: Trash,
                  label: 'Remove',
                  color: 'red',
                },
              ]}
            />
          ) : null
        }
      />
    </Container>
  );
};

const Container = styled.div<{ canManage: boolean }>`
  display: grid;
  grid-template-rows: ${({ canManage }) =>
    canManage ? 'max-content 1fr' : '1fr'};
  height: 100%;
`;

const Actions = styled.div`
  display: flex;
  gap: 10px;
`;

export default connect(null, { removeGroupMember, addGroupMember })(EditGroup);
