import { useFormikContext } from 'formik';
import noop from 'lodash/noop';
import { FC, useEffect, useRef } from 'react';

type FormikOnChangeProps = {
  onChange: (values: any) => void;
};

const FormikOnChange: FC<FormikOnChangeProps> = ({ onChange = noop }) => {
  const { values } = useFormikContext();
  const onChangeRef = useRef(onChange);

  useEffect(
    function updateOnChangeReference() {
      onChangeRef.current = onChange;
    },
    [onChange]
  );

  useEffect(
    function useCallbackOnValuesChange() {
      onChangeRef.current(values);
    },
    [values]
  );

  return null;
};

export default FormikOnChange;
