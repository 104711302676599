import { ActionIcon } from '@mantine/core';
import React, { FC } from 'react';
import { FilterProps, UseFiltersColumnProps } from 'react-table';

import { useOpenModal } from '@portals/redux';
import { suppressPropagation } from '@portals/utils';

import { ReactComponent as ActiveFilterIcon } from '../../../../assets/active-filter.svg';
import { ReactComponent as FilterIcon } from '../../../../assets/filter.svg';

type SelectFilterProps = {
  column: FilterProps<any>['column'] & UseFiltersColumnProps<any>;
  placeholder?: string;
  options?: Record<string, string>;
};

const SelectFilter: FC<SelectFilterProps> = ({
  column,
  placeholder = '',
  options = {},
}) => {
  const { filterValue } = column;

  const openModal = useOpenModal();

  const onToggleFilterModal = () =>
    openModal('SelectFilter', { column, placeholder, options });

  return (
    <ActionIcon
      onClick={suppressPropagation(onToggleFilterModal)}
      size="xs"
      className="column-filter-toggle"
    >
      {filterValue ? <ActiveFilterIcon /> : <FilterIcon />}
    </ActionIcon>
  );
};

export default SelectFilter;
