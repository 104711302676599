import React, { useState } from 'react';
import { Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import styled from 'styled-components';

import { camelToPretty } from '@portals/utils';

const ObjectFieldTemplate = ({ schema, properties }) => {
  const [selected, setSelected] = useState(0);
  const schemaProperties = schema.properties;
  const currProperty = properties[selected];
  const allObject = Object.keys(schemaProperties).every(
    (key) => typeof schemaProperties[key] === 'object'
  );

  if (!allObject) {
    return (
      <div>{properties.filter((prop) => prop).map((prop) => prop.content)}</div>
    );
  }

  return (
    <StyledRow>
      <StyledCol sm={4}>
        <ListGroup>
          {properties.map(({ name }, index) => (
            <ListGroupItem
              tag="a"
              key={name}
              onClick={() => setSelected(index)}
              action
              active={index === selected}
            >
              {camelToPretty(name)}
            </ListGroupItem>
          ))}
        </ListGroup>
      </StyledCol>

      <StyledCol>
        <div className="property-wrapper">{currProperty.content}</div>
      </StyledCol>
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  padding: 0;
  margin: 0;
  gap: 20px;

  .list-group {
    border-right: 1px solid #e9ecef;
    height: 100%;

    .list-group-item {
      border-left: none;
      border-right: none;
      border-radius: 0;

      &:last-child {
        border-bottom: none;
      }

      &:first-child {
        border-top: none;
      }
    }
  }
`;

const StyledCol = styled(Col)`
  padding: 0;
  margin: 0;
`;

export default ObjectFieldTemplate;
