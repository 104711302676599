import { ModalStylesNames, ModalStylesParams } from '@mantine/core';
import { CSSObject } from '@mantine/styles';

import { ComponentStyleOverrideFn } from './common-types';

export const modalStyles: ComponentStyleOverrideFn<
  ModalStylesNames,
  ModalStylesParams
> = (theme, params) => {
  const commonStyles: Partial<Record<ModalStylesNames, CSSObject>> = {
    title: {
      fontSize: 24,
    },
    body: {
      fontSize: theme.fontSizes.sm,
    },
    close: {
      svg: {
        width: 18,
        height: 18,
      },
    },
  };

  if (params.size === 'lg') {
    return {
      ...commonStyles,
      modal: {
        width: 565,
      },
    };
  }

  return commonStyles;
};
