import { getCustomEventProperties, logEvent } from '@portals/analytics';
import { getCurrentUser, getTenant } from '@portals/redux';

import { LOG_ANALYTICS_EVENT } from '../constants';

const analytics =
  ({ getState }) =>
  (next) =>
  (action) => {
    if (action.type === LOG_ANALYTICS_EVENT) {
      const { eventName, eventProperties = {} } = action.payload;
      const state = getState();
      const user = getCurrentUser(state);
      const tenant = getTenant(state);

      logEvent(eventName, {
        ...getCustomEventProperties(user, tenant),
        ...eventProperties,
      });
    }

    return next(action);
  };

export default analytics;
