import { Group, Stack, Text, Title } from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';
import { get } from 'lodash/fp';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { useAppConfig } from '@portals/framework/context';
import { changePasswordToken } from '@portals/redux/actions/auth';
import { setRoute } from '@portals/redux/actions/routing';
import { FADE_IN_OUT } from '@portals/ui';
import AutoFormik, { FieldTypeEnum } from '@portals/ui/AutoFormik';

import { FormWrapper } from './common';

const FIELDS = [
  {
    name: 'password',
    title: 'New Password',
    type: FieldTypeEnum.Password,
    required: true,
    placeholder: 'Hard to guess password',
    validations: Yup.string().min(6),
  },
  {
    name: 'confirm',
    title: 'Confirm Password',
    type: FieldTypeEnum.Password,
    required: true,
    placeholder: 'The same password again',
    validations: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match'
    ),
  },
];

const ChangePassword = ({ match, error, inProgress, changePasswordToken }) => {
  const { tenantType } = useAppConfig();

  const handleSubmit = ({ password }) => {
    changePasswordToken(match.params.token, password, tenantType);
  };

  return (
    <FormWrapper id="change-password">
      <Stack>
        <Title order={1} mb="lg">
          Change Password
        </Title>

        <AutoFormik
          fields={FIELDS}
          inProgress={inProgress}
          handleSubmit={handleSubmit}
          buttonsProps={{
            size: 'sm',
            style: {
              width: '100%',
              marginTop: '20px',
            },
          }}
        />

        <AnimatePresence>
          {error ? (
            <motion.p {...FADE_IN_OUT} className="text-danger text-center">
              {error}
            </motion.p>
          ) : null}
        </AnimatePresence>

        <Text color="dimmed" size="sm" align="center">
          <Group spacing={6}>
            Return to
            <Link to="/auth/sign-in">Sign-In</Link>
          </Group>
        </Text>
      </Stack>
    </FormWrapper>
  );
};

const mapStateToProps = (state) => ({
  error: state.errors['changePasswordToken'],
  inProgress: get('ui.network.changePasswordToken', state),
});

export default connect(mapStateToProps, { changePasswordToken, setRoute })(
  ChangePassword
);
