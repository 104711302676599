import { lazy } from 'react';
import Camera from 'react-feather/dist/icons/camera';
import HomeIcon from 'react-feather/dist/icons/home';
import Navigation from 'react-feather/dist/icons/navigation';

import { RoutesMap, RoutesType } from '@portals/types';

const dashboardChildRoutes: RoutesType = [
  {
    path: '/',
    id: 'sidebar.dashboard',
    icon: HomeIcon,
    component: lazy(() => import('./pages/dashboard')),
  },
];

const spacesChildRoutes: RoutesType = [
  {
    path: '/spaces',
    id: 'sidebar.spaces',
    icon: Navigation,
    component: lazy(() => import('./pages/spaces')),
  },
];

const scannerChildRoutes: RoutesType = [
  {
    path: '/spaces/:id/scanner',
    id: 'sidebar.scanner',
    icon: Camera,
    component: lazy(() => import('./pages/scanner')),
  },
];

// Dashboard specific routes
export const routes: RoutesMap = {
  dashboard: [
    {
      id: 'dashboard',
      headerId: '',
      childRoutes: dashboardChildRoutes,
    },
    {
      id: 'spaces',
      headerId: '',
      childRoutes: spacesChildRoutes,
    },
    {
      id: 'scanner',
      headerId: '',
      childRoutes: scannerChildRoutes,
    },
  ],
};
