import { API } from '@portals/redux';
import { updateData } from '@portals/redux/actions/data';

export const getConfig = () => ({
  type: API,
  payload: {
    url: 'ui/organization/configuration',
    success: updateData('config'),
    name: 'getConfig',
  },
});

export const updateConfig = (data, toastr = null, debounce = false) => ({
  type: API,
  payload: {
    url: 'ui/organization/configuration',
    method: 'PUT',
    data,
    name: 'updateConfig',
    success: updateData('config'),
    toastr,
  },
  meta: {
    debounce,
  },
});

export const updateNotifications = (notifications) => ({
  type: API,
  payload: {
    url: 'ui/organization/configuration/notifications',
    method: 'POST',
    data: notifications,
    name: 'updateNotifications',
    success: updateData('config'),
    toastr: 'Notifications update',
  },
});

export const sendReport = (email) => ({
  type: API,
  payload: {
    url: 'ui/organization/configuration/send_report',
    method: 'POST',
    data: email,
    toastr: 'Daily report sent',
  },
});
