import { Group } from '@mantine/core';
import { DatePicker, DatePickerProps } from '@mantine/dates';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { ArrowRight } from 'react-feather';

import { DateRangeType } from './types';

interface DateInputGroupProps {
  dateRange: DateRangeType;
  setDateRange: Dispatch<SetStateAction<DateRangeType>>;
}

const DateInputGroup: FC<DateInputGroupProps> = ({
  dateRange,
  setDateRange,
}) => (
  <Group align="center">
    <DateInput
      value={dateRange[0]}
      onChange={(fromDate) => setDateRange(([, toDate]) => [fromDate, toDate])}
    />

    <ArrowRight style={{ width: 15, height: 15 }} />

    <DateInput
      value={dateRange[1]}
      onChange={(toDate) => setDateRange(([fromDate]) => [fromDate, toDate])}
    />
  </Group>
);

const DateInput = (props: DatePickerProps) => (
  <DatePicker
    withinPortal={false}
    initiallyOpened
    size="md"
    styles={(theme) => ({
      root: {
        '.mantine-Popper-arrow': {
          display: 'none',
        },
      },
      freeInput: {
        width: 120,
        fontSize: theme.fontSizes.sm,
      },
      dropdown: {
        display: 'none',
      },
    })}
    clearable={false}
    allowFreeInput
    placeholder="MM/DD/YYYY"
    inputFormat="MM/DD/YYYY"
    labelFormat="MM/DD/YYYY"
    {...props}
  />
);

export default DateInputGroup;
