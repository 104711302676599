import { get } from 'lodash/fp';

import { setValue } from '../actions/data';
import { startNetwork, endNetwork } from '../actions/ui';
import { DEVICE_API } from '../constants';

const buildUrl = (serverUrl, uri) => `${serverUrl}/${uri}`;

const handleArrayOfSuccess = (array, dispatch, data) => {
  array.forEach((item) => {
    const action = typeof item === 'function' ? item(data) : item;
    dispatch(action);
  });
};

const api =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (action.type !== DEVICE_API) {
      return next(action);
    }

    const {
      url, // URL to send to
      success, // Action(s) to perform on success
      name, // Name for internal tracking (spinners)
      data, // Data for post/patch/etc
      method, // Method (GET/POST/etc)
      accessToken, // Pass access token directly
      error, // Optional error handler
    } = action.payload;

    const handleSuccess = (data) => {
      if (name) {
        dispatch(setValue('tracking', name)({ status: 'success', data }));
      }

      if (success) {
        handleArrayOfSuccess(
          Array.isArray(success) ? success : [success],
          dispatch,
          data
        );
      }

      dispatch(endNetwork(name));
    };

    const dispatchErrorHandler = (data) => {
      if (name) {
        dispatch(setValue('tracking', name)({ status: 'error', data }));
      }

      if (error) {
        dispatch(error(data));
      }

      dispatch(endNetwork(name));
    };

    const handleError = (err) => {
      let msg;

      // Did we get a Response object?
      if (err instanceof Response) {
        msg = err.statusText;
      } else {
        msg = err;
      }

      console.warn('Error from API: ', msg);

      if (name) {
        if (err instanceof Response) {
          err.json().then(dispatchErrorHandler);
        } else {
          dispatchErrorHandler(msg);
        }
      }
    };

    let serverUrl;

    if (action.meta?.provisionServer) {
      serverUrl = process.env.NX_DEVICE_HUB_URL;
    } else {
      serverUrl = get('data.dev.auth.hub_url', getState());

      if (!serverUrl) {
        throw new Error('Hub URL not set');
      }
    }

    // Set default headers and options
    const options: Omit<RequestInit, 'headers'> & { headers: Headers } = {
      headers: new Headers({ 'content-type': 'application/json' }),
    };

    const accessKey =
      accessToken || get('data.dev.auth.access_key', getState());

    if (accessKey) {
      options.headers.append('Authorization', accessKey);
    }

    if (method) {
      options.method = method;
    }

    // Turn into POST (or other) request if data was passed
    if (data) {
      options.method = options.method || 'post';
      options.body = JSON.stringify(data);
    }

    if (name) {
      dispatch(setValue('tracking', name)({ status: 'pending', data: {} }));
    }

    dispatch(startNetwork(name));

    fetch(buildUrl(serverUrl, url), options)
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then(handleSuccess)
      .catch(handleError);
  };

export default api;
