import moment from 'moment';

import { DatePresetType } from './types';

export const RANGE_PRESETS: Array<DatePresetType> = [
  {
    id: 'lastWeek',
    label: 'Last 7 Days',
    generateRange: () => [
      moment().subtract(7, 'days').toDate(),
      moment().toDate(),
    ],
  },
  {
    id: 'lastTwoWeeks',
    label: 'Last 14 Days',
    generateRange: () => [
      moment().subtract(14, 'days').toDate(),
      moment().toDate(),
    ],
  },
  {
    id: 'lastMonth',
    label: 'Last 30 Days',
    generateRange: () => [
      moment().subtract(30, 'days').toDate(),
      moment().toDate(),
    ],
  },
  {
    id: 'lastThreeMonths',
    label: 'Last 3 Months',
    generateRange: () => [
      moment().subtract(3, 'months').toDate(),
      moment().toDate(),
    ],
  },
  {
    id: 'lastYear',
    label: 'Last 12 Months',
    generateRange: () => [
      moment().subtract(12, 'months').toDate(),
      moment().toDate(),
    ],
  },
  {
    id: 'monthToDate',
    label: 'Month to Date',
    generateRange: () => [
      moment().startOf('month').toDate(),
      moment().toDate(),
    ],
  },
  {
    id: 'quarterToDate',
    label: 'Quarter to Date',
    generateRange: () => [
      moment().startOf('quarter').toDate(),
      moment().toDate(),
    ],
  },
  {
    id: 'all',
    label: 'All Time',
    generateRange: () => [null, null],
  },
];
