import classnames from 'classnames';
import React, { FC, ReactNode } from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

import { getStyledThemeColor } from '@portals/utils';

import { useTheme } from '../../ThemeProvider';

export const InfoTable = styled.div.attrs(
  ({ className }: { className?: string }) => ({
    className: classnames('info-table', className),
  })
)`
  margin-bottom: 0;
  color: ${getStyledThemeColor('dark')} !important;
  border: 1px solid ${getStyledThemeColor('gray300')};
  display: grid;
  height: fit-content;

  .info-table-row {
    display: grid;
    grid-template-columns: 30% 1fr;

    &:not(:last-of-type) {
      border-bottom: 1px solid ${getStyledThemeColor('gray300')};
    }

    .info-table-row-label {
      padding: 10px 20px;
      background-color: ${getStyledThemeColor('gray150')};
      border-right: 1px solid ${getStyledThemeColor('gray300')};
      align-items: center;
      display: grid;
    }

    .info-table-row-value {
      padding: 10px 20px;
      color: ${getStyledThemeColor('gray600')};
      align-items: center;
      display: grid;
    }

    &.header-row {
      width: 100%;
      grid-template-columns: 1fr 0;

      .info-table-row-label {
        justify-content: center;
        width: 100%;
        font-weight: bold;
        border-right: none;
      }
    }

    &.main-row {
      .info-table-row-label,
      .info-table-row-value {
        background-color: ${getStyledThemeColor('gray200')};
        color: ${getStyledThemeColor('dark')};
        font-weight: normal;
      }
    }
  }
`;

type InfoTableRowProps = {
  label: ReactNode;
  value: ReactNode;
  className?: string;
  isHeader?: boolean;
  isLoading?: boolean;
};

export const InfoTableRow: FC<InfoTableRowProps> = ({
  label,
  value,
  className,
  isHeader,
  isLoading,
}) => {
  const theme = useTheme();

  return (
    <div
      className={classnames('info-table-row', className, {
        'header-row': isHeader,
      })}
    >
      <div className="info-table-row-label">{label}</div>

      {!isHeader && (
        <div className="info-table-row-value">
          {isLoading ? (
            <ContentLoader
              speed={2}
              width="100%"
              height={20}
              backgroundColor={theme.color.gray200}
              foregroundColor={theme.color.gray100}
            >
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="20" />
            </ContentLoader>
          ) : (
            value
          )}
        </div>
      )}
    </div>
  );
};

export const InfoTableHeader = styled.div`
  padding: 10px 10px;
  border-bottom: 1px solid ${getStyledThemeColor('gray300')};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: ${getStyledThemeColor('gray150')};
`;

export default {
  Table: InfoTable as typeof InfoTable,
  Row: InfoTableRow as typeof InfoTableRow,
  Header: InfoTableHeader as typeof InfoTableHeader,
};
