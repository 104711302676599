import { isUndefined } from 'lodash/fp';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { store } from '@portals/redux';

import PendingOverlay from './PendingOverlay';

// Prefetch data for faster navigation:
// This function should run only once after a delay and call 'delayed list' actions
// Only if the data was not yet fetched by some other component
const loadDelayed = (list = {}) => {
  const { data } = store.getState();

  Object.keys(list).forEach((dataType) => {
    if (isUndefined(data[dataType])) {
      store.dispatch(list[dataType]());
    }
  });
};

const WithData = ({ children, pending, types, dispatch, data, isFetched }) => {
  const { immediate, delayed } = types;

  useEffect(() => {
    // Don't load data if its already loaded
    if (!pending) {
      return;
    }

    Object.values(immediate).map((action: () => Record<string, any>) =>
      dispatch(action())
    );
  }, [immediate, dispatch, pending]);

  // Prefetch data for faster navigation
  useEffect(() => {
    setTimeout(() => loadDelayed(delayed), 3000);
  }, [delayed]);

  if (pending || isFetched === false) {
    return <StyledPendingOverlay isVisible />;
  }

  if (typeof children === 'function') {
    // Pass all requested data as props to child function
    const childProps = Object.keys(immediate).reduce((acc, key) => {
      acc[key] = data[key];
      return acc;
    }, {});

    return children(childProps);
  }

  return children;
};

const StyledPendingOverlay = styled(PendingOverlay)`
  width: 100%;
  height: 100%;
  position: fixed;
`;

const mapStateToProps = (state, ownProps) => ({
  pending: Object.keys(ownProps.types.immediate).some(
    (type) => state.data[type] === undefined
  ),
  data: state.data,
});

export default connect(mapStateToProps)(WithData);
