import { ActionIcon } from '@mantine/core';
import React, { FC } from 'react';
import { FilterProps, UseFiltersColumnProps } from 'react-table';

import { useOpenModal } from '@portals/redux';
import { suppressPropagation } from '@portals/utils';

import { ReactComponent as ActiveFilterIcon } from '../../../../assets/active-filter.svg';
import { ReactComponent as FilterIcon } from '../../../../assets/filter.svg';

type TextFilterProps = {
  column: FilterProps<any>['column'] & UseFiltersColumnProps<any>;
  placeholder?: string;
};

const TextFilter: FC<TextFilterProps> = ({ column }) => {
  const { filterValue } = column;

  const openModal = useOpenModal();

  const onToggleFilterModal = () => openModal('TextFilter', { column });

  return (
    <ActionIcon
      onClick={suppressPropagation(onToggleFilterModal)}
      size="xs"
      className="column-filter-toggle"
    >
      {filterValue ? <ActiveFilterIcon /> : <FilterIcon />}
    </ActionIcon>
  );
};

export default TextFilter;
