import { forEach, keys } from 'lodash/fp';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getAuth } from '@portals/redux';
import { getHeaders } from '@portals/redux/actions/auth';
import { MethodType, StateType } from '@portals/types';

import { OptionsType } from '../types';

export type ApiRequest = {
  url: string;
  method?: MethodType;
  data?: any;
  headers?: any;
};

export const buildUrl = (uri: string): string =>
  process.env.NX_SERVER_URL + uri;

export const getRequestOptions = (
  { method, data, headers, url }: Partial<ApiRequest>,
  auth?: StateType['ui']['auth']
) => {
  // Set default headers and options
  const options: OptionsType = {
    headers: new Headers({ 'content-type': 'application/json' }),
    cache: 'no-store',
  };

  if (headers) {
    forEach(
      (header) => options.headers.append(header, headers[header]),
      keys(headers)
    );
  }

  if (auth) {
    const autHeaders = getHeaders(auth);

    forEach(
      // @ts-ignore
      (header) => options.headers.append(header, autHeaders[header]),
      keys(autHeaders)
    );
  }

  if (method) {
    options.method = method;
  }

  // Turn into POST (or other) request if data was passed
  if (data) {
    options.method = options.method || 'post';
    options.body = JSON.stringify(data);
  }

  return { options, url: buildUrl(url as string) };
};

export const useRequestOptions = (requestOptions: ApiRequest) => {
  const auth = useSelector(getAuth);

  return useMemo(
    () => getRequestOptions(requestOptions, auth),
    [requestOptions, auth]
  );
};

export const fetchApiRequest = async (url: string, options?: OptionsType) => {
  const response = await fetch(url, options);

  if (!response?.ok) {
    if (response?.status === 401) {
      const error = await response?.json();

      // eslint-disable-next-line no-throw-literal
      throw { type: 'NOT_AUTHORIZED', data: error };
    }

    throw await response.json();
  }

  if (response?.status === 204) {
    return;
  }

  if (response.text) {
    const responseText = await response.text();

    return responseText ? JSON.parse(responseText) : {};
  }
};
