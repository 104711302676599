import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { fetchApiRequest, useApiQuery, useRequestOptions } from '@portals/api';
import { getConfig } from '@portals/redux';
import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';
import { PortalConfigType, TenantType } from '@portals/types';

const API_URL = 'ui/partner/portal_config';
const QUERY_KEY = 'portal_config';

export function usePortalConfig() {
  return useApiQuery<PortalConfigType>(API_URL, QUERY_KEY);
}

export function usePortalDomain() {
  const { data: portalConfig } = usePortalConfig();
  const config = useSelector(getConfig);

  const partnerName =
    config?.[TenantType.Partner]?.name ||
    config?.[TenantType.Organization]?.partner_name;

  if (partnerName === 'xyte') {
    return 'app.xyte.io';
  }

  const customDomain = portalConfig?.domain;

  return customDomain || `${partnerName}.on-xyte.com`;
}

export function usePortalProtocolAndDomain() {
  const domain = usePortalDomain();
  const { isLoading } = usePortalConfig();

  return {
    isLoading,
    protocolAndDomain: isLoading
      ? null
      : `${window.location.protocol}//${domain}`,
  };
}

/*
  MUTATIONS
* */
interface MutationFnParams {
  updatedConfig: Partial<PortalConfigType>;
  withSuccessNotification?: boolean;
}

export function useUpdatePortalConfig() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { url, options } = useRequestOptions({
    url: API_URL,
    method: 'PUT',
  });

  return useMutation({
    mutationFn: ({
      updatedConfig,
    }: MutationFnParams): Promise<PortalConfigType> => {
      const queryData = queryClient.getQueryData<PortalConfigType>(QUERY_KEY);

      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({ ...queryData, ...updatedConfig }),
      });
    },
    onSuccess: (response: PortalConfigType, { withSuccessNotification }) => {
      queryClient.setQueryData(QUERY_KEY, response);

      if (withSuccessNotification) {
        dispatch(toastrSuccess('Portal configuration updated successfully'));
      }
    },
    onError: ({ error }: any) => dispatch(toastrError(error)),
  });
}
