import { Button } from '@mantine/core';
import { keys, forEach } from 'lodash/fp';
import React, { FC } from 'react';

import { CloseSquare } from '@portals/icons/linear';
import { useTableInstance } from '@portals/ui/Table';

interface EditGroupSelectedActionsProps {
  onRemove: (groupId: string, userId: string) => void;
  groupId: string;
  users: Array<{ uuid: string }>;
}

const EditGroupSelectedActions: FC<EditGroupSelectedActionsProps> = ({
  onRemove,
  groupId,
  users,
}) => {
  const { state, toggleAllRowsSelected } = useTableInstance();
  const { selectedRowIds } = state;

  const onRemoveSelected = () => {
    forEach((rowIndex) => {
      const userId = users[rowIndex]?.uuid;

      if (userId) {
        onRemove(groupId, userId);
      }
    }, keys(selectedRowIds));

    toggleAllRowsSelected(false);
  };

  return (
    <Button
      variant="subtle"
      color="red"
      size="xs"
      px={5}
      styles={{
        leftIcon: {
          width: 16,
          height: 16,
        },
      }}
      onClick={onRemoveSelected}
      leftIcon={<CloseSquare />}
    >
      Remove
    </Button>
  );
};

export default EditGroupSelectedActions;
