import React, { useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';

import { useOrgIncidentsChannel } from '@portals/framework';
import { useActionDispatch, useOrganizationConfig } from '@portals/redux';
import { getIncidents as getIncidentsAction } from '@portals/shared-organizations/redux/actions/incidents';
import { TenantType } from '@portals/types';

const OrganizationGlobalSubscription = () => {
  const config = useOrganizationConfig();
  const getIncidents = useActionDispatch(getIncidentsAction);
  const queryClient = useQueryClient();

  const messageHandler = useCallback(() => {
    getIncidents();
    queryClient.invalidateQueries('stats');
  }, [getIncidents, queryClient]);

  const subscriptionParams = useMemo(
    () => ({ org_id: config?.id }),
    [config?.id]
  );

  useOrgIncidentsChannel({
    subscriptionParams,
    messageHandler,
  });

  return null;
};

const GlobalSubscription = ({ tenantType }: { tenantType: TenantType }) => {
  switch (tenantType) {
    case TenantType.Organization:
      return <OrganizationGlobalSubscription />;
    default:
      return null;
  }
};

export default GlobalSubscription;
