import { createStyles, Divider, Group, Pagination, Text } from '@mantine/core';
import { ceil, min } from 'lodash/fp';
import React, { FC, useMemo } from 'react';

import { ROW_HEIGHT, useTableInstance } from '@portals/ui/Table';

interface FooterProps {
  isCompact?: boolean;
  totalCount: number;
}

const Footer: FC<FooterProps> = ({ isCompact, totalCount }) => {
  const { state, gotoPage } = useTableInstance();
  const { classes, cx } = useTableFooterStyles({ isCompact });

  const label = useMemo(() => {
    const firstRowIndex = state.pageIndex * state.pageSize;
    const lastRowIndex = firstRowIndex + state.pageSize;

    return `${firstRowIndex + 1} - ${min([
      lastRowIndex,
      totalCount,
    ])} of ${totalCount} items`;
  }, [totalCount, state.pageIndex, state.pageSize]);

  return (
    <Group
      position="right"
      align="center"
      className={cx('table-footer-container', classes.container)}
    >
      {totalCount > state.pageSize ? (
        <>
          <Pagination
            size="sm"
            withEdges
            total={ceil(totalCount / state.pageSize)}
            page={state.pageIndex + 1}
            onChange={(pageIndex) => gotoPage(pageIndex - 1)}
          />

          <Group>
            <Divider
              sx={(theme) => ({
                height: 24,
                borderColor: theme.colors.gray[3],
              })}
              orientation="vertical"
            />
          </Group>
        </>
      ) : null}

      <Text
        pr="md"
        size="xs"
        sx={(theme) => ({
          color: theme.colors.gray[4],
        })}
      >
        {label}
      </Text>
    </Group>
  );
};

export const useTableFooterStyles = createStyles<any, { isCompact: boolean }>(
  (theme, params) => {
    const common = {
      backgroundColor: theme.white,
      borderTop: 'none',
      height: ROW_HEIGHT,
    };

    return params.isCompact
      ? {
          container: {
            ...common,
            border: 'none',
            borderBottom: `1px solid ${theme.colors.gray[2]}`,
            padding: 0,
          },
        }
      : {
          container: {
            ...common,
            border: `1px solid ${theme.colors.gray[2]}`,
            padding: `0px ${theme.spacing.md}`,
          },
        };
  }
);

export default Footer;
