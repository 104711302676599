import { Box, Menu } from '@mantine/core';
import React, { FC, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useKey } from 'react-use';

import { getTenant } from '@portals/redux';
import { TenantType } from '@portals/types';
import { useOnClickOutside } from '@portals/utils';

import { useShouldDisplayTenantMenu } from './hooks';
import {
  OrganizationMenuContent,
  PartnerMenuContent,
} from './TenantMenuContent';
import { OrganizationUser, PartnerUser } from './TenantUser';

export const TenantMenu: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const currentTenantType = useSelector(getTenant)?.type;

  const MenuContentWrapper =
    currentTenantType === TenantType.Organization
      ? OrganizationMenuContent
      : PartnerMenuContent;

  const contentRef = useRef<HTMLDivElement>(null);

  const onEscape = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  useOnClickOutside(contentRef, () => setIsOpen(false), isOpen);
  useKey('Escape', onEscape, { event: 'keydown' }, [isOpen]);

  const shouldDisplayTenantMenu = useShouldDisplayTenantMenu();

  if (!shouldDisplayTenantMenu) {
    return (
      <Box sx={{ height: 76 }}>
        {currentTenantType === TenantType.Organization ? (
          <OrganizationUser isActive={isOpen} isDisabled />
        ) : (
          <PartnerUser isActive={isOpen} isDisabled />
        )}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        position: 'relative',
        height: 76,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Menu
        sx={{
          width: '100%',
          position: 'absolute',
          top: 0,
        }}
        gutter={0}
        opened={isOpen}
        closeOnItemClick={false}
        control={
          <Box sx={{ height: 76 }} onClick={() => setIsOpen((curr) => !curr)}>
            {currentTenantType === TenantType.Organization ? (
              <OrganizationUser isActive={isOpen} />
            ) : (
              <PartnerUser isActive={isOpen} />
            )}
          </Box>
        }
        position="right"
        placement="start"
        styles={(theme) => ({
          body: {
            width: 300,
            boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.08)',
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
          },
          item: {
            borderRadius: theme.radius.md,
            transition: 'background-color 0.1s ease-in-out',
          },
          itemBody: {
            height: 44,
            padding: '0 20px',
          },
        })}
      >
        <MenuContentWrapper ref={contentRef} onClose={() => setIsOpen(false)} />
      </Menu>
    </Box>
  );
};

export default TenantMenu;
