import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  Select,
  SelectItem,
  Stack,
} from '@mantine/core';
import { capitalize, find, map, values } from 'lodash/fp';
import React, { FC, useEffect, useState } from 'react';

import {
  ModalProps,
  useTenantGroups,
  useUpdateTenantGroupPermissions,
} from '@portals/framework';
import { TenantGroup, UserType } from '@portals/types';
import { InfoTable } from '@portals/ui/Table';
import { AccessLevelEnum } from '@portals/utils';

const OPTIONS: SelectItem[] = [
  { value: String(AccessLevelEnum.None), label: 'Restricted' },
  { value: String(AccessLevelEnum.View), label: 'View Only' },
  { value: String(AccessLevelEnum.Edit), label: 'Edit' },
];

type PermissionKeys = keyof TenantGroup['permissions'];

type AvailableAccessLevel =
  | AccessLevelEnum.None
  | AccessLevelEnum.View
  | AccessLevelEnum.Edit;

const PERMISSION_ROWS: Record<
  PermissionKeys,
  { id: PermissionKeys; label: string }
> = {
  models: {
    id: 'models',
    label: 'Models',
  },
  customers: {
    id: 'customers',
    label: 'Customers',
  },
  support: {
    id: 'support',
    label: 'Support Center',
  },
  white_labeling: {
    id: 'white_labeling',
    label: 'White Labeling',
  },
  user_management: {
    id: 'user_management',
    label: 'User Management',
  },
  account_management: {
    id: 'account_management',
    label: 'Account Management',
  },
  finance: {
    id: 'finance',
    label: 'Finance',
  },
};

const ManagedPermissions: FC<ModalProps<UserType>> = ({
  closeMe,
  data: group,
}) => {
  const { data: tenantGroups, isLoading: isLoadingGroups } = useTenantGroups();
  const { mutateAsync, isLoading: isUpdatingPermissions } =
    useUpdateTenantGroupPermissions(group.id);

  const [localPermissionValues, setLocalPermissionValues] =
    useState<TenantGroup['permissions']>();

  useEffect(
    function waitForDataAndUpdateState() {
      if (!tenantGroups) return;

      const currentGroup = find({ id: group.id }, tenantGroups);

      if (currentGroup) {
        setLocalPermissionValues(currentGroup.permissions);
      }
    },
    [group.id, tenantGroups]
  );

  const toggleAllValue = calcToggleAllFieldValue(localPermissionValues);

  const handleToggleAll = (value: string) => {
    const valueAsNumber = Number(value);

    if (!localPermissionValues) return;

    const newPermissionValues = {} as TenantGroup['permissions'];
    for (const key in localPermissionValues) {
      newPermissionValues[key as PermissionKeys] = valueAsNumber;
    }

    setLocalPermissionValues(newPermissionValues);
  };

  const onChangeHandler = (fieldName: PermissionKeys, value: string) => {
    if (!localPermissionValues) return;

    const newState = {
      ...localPermissionValues,
      [fieldName]: Number(value),
    };

    setLocalPermissionValues(newState);
  };

  const onSubmit = async () => {
    if (!localPermissionValues) return;

    try {
      await mutateAsync(localPermissionValues);
      closeMe();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      title={`Managed Permissions (${capitalize(group.name)})`}
    >
      <LoadingOverlay visible={isLoadingGroups} />
      <Stack>
        <Group position="right" pr="lg">
          <Select
            withinPortal={false}
            placeholder="Select..."
            label="Toggle All"
            data={OPTIONS}
            value={String(toggleAllValue)}
            onChange={handleToggleAll}
          />
        </Group>
        <InfoTable.Table>
          {localPermissionValues &&
            map((permission) => {
              if (
                group.name !== 'support' &&
                permission.label === PERMISSION_ROWS.support.label
              )
                return null;

              return (
                <InfoTable.Row
                  key={permission.id}
                  label={permission.label}
                  value={
                    <Group position="right">
                      <Select
                        withinPortal={false}
                        data={OPTIONS}
                        value={String(localPermissionValues[permission.id])}
                        disabled={
                          group.name === 'support' &&
                          permission.label === PERMISSION_ROWS.support.label
                        }
                        onChange={(value) =>
                          onChangeHandler(permission.id, value as string)
                        }
                      />
                    </Group>
                  }
                />
              );
            }, PERMISSION_ROWS)}
        </InfoTable.Table>
      </Stack>

      <Group mt="xl" position="right">
        <Button variant="default" onClick={closeMe}>
          Cancel
        </Button>
        <Button
          loading={isUpdatingPermissions}
          disabled={!localPermissionValues}
          onClick={onSubmit}
        >
          Save
        </Button>
      </Group>
    </Modal>
  );
};

function calcToggleAllFieldValue(
  permissions: TenantGroup['permissions'] | undefined
): AvailableAccessLevel | null {
  const permissionValues = values(permissions);

  // all permissions have the same value
  if (permissionValues.every((item) => item === permissionValues[0])) {
    return permissionValues[0] as AvailableAccessLevel;
  } else {
    return null;
  }
}

export default ManagedPermissions;
