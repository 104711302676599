import { createStyles, Group } from '@mantine/core';
import React, { FC } from 'react';

import { XyteLogo } from '@portals/framework';
import {
  useActionDispatch,
  useFeatures,
  useIsSidebarOpen,
} from '@portals/redux';
import { toggleSidebar as toggleSidebarAction } from '@portals/redux/actions/sidebarActions';

import { SidebarToggleButton } from './SidebarToggleButton';

const useStyles = createStyles((theme) => ({
  footer: {
    padding: theme.spacing.md,
    borderTop: `2px solid ${theme.colors.blue_gray[0]}`,
    color: theme.colors.blue_gray[2],
  },
}));

export const SidebarFooter: FC = () => {
  const { classes } = useStyles();
  const features = useFeatures();
  const isSidebarOpen = useIsSidebarOpen();
  const toggleSidebar = useActionDispatch(toggleSidebarAction);

  const showXyteCredits =
    features.powered_by_xyte_label !== false && isSidebarOpen;

  return (
    <Group
      align="center"
      position={isSidebarOpen ? 'apart' : 'center'}
      className={classes.footer}
    >
      {showXyteCredits && (
        <Group align="center" spacing="xs">
          <span>Powered by</span>
          <XyteLogo height={13} />
        </Group>
      )}
      <SidebarToggleButton
        isOpened={isSidebarOpen}
        toggleSidebar={toggleSidebar}
      />
    </Group>
  );
};
