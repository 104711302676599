import { Avatar, createStyles, Group, Paper, Stack, Text } from '@mantine/core';
import React, { FC } from 'react';

export interface KpiWidgetType {
  title: string;
  avatarColor: string;
  counter: number;
  icon: React.ReactNode;
}

interface KPIWidgetProps {
  kpiWidget: KpiWidgetType;
}

const KpiWidget: FC<KPIWidgetProps> = ({ kpiWidget }) => {
  const { classes } = useStyles(kpiWidget?.avatarColor);

  return (
    <Paper radius="md" className={classes.container}>
      <Group spacing="md" noWrap className={classes.contentContainer}>
        <Avatar
          radius="xl"
          className={classes.avatar}
          styles={(theme) => ({
            placeholder: {
              backgroundColor: theme.colors[kpiWidget?.avatarColor][0],
            },
          })}
        >
          {kpiWidget.icon}
        </Avatar>
        <Stack spacing={0}>
          <Text className={classes.counter}>{kpiWidget.counter}</Text>
          <Text className={classes.title}>{kpiWidget.title}</Text>
        </Stack>
      </Group>
    </Paper>
  );
};

const useStyles = createStyles((theme, avatarColor: string) => ({
  container: {
    padding: theme.spacing.xl,
    border: `1px solid ${theme.colors.gray[2]}`,

    [theme.fn.largerThan('xl')]: {
      padding: `33px ${theme.spacing.xl}px`,
    },
  },
  contentContainer: {
    [theme.fn.largerThan('xl')]: {
      gap: theme.spacing.xl,
    },
  },
  counter: {
    fontSize: 32,
    lineHeight: '39px',
    fontWeight: 600,
    color: theme.colors.blue_gray[9],

    [theme.fn.largerThan('xl')]: {
      fontSize: 42,
      lineHeight: '48px',
    },
  },
  avatar: {
    width: 40,
    height: 40,
    minWidth: 40,

    svg: {
      color: theme.colors[avatarColor][3],
      width: 20,
      height: 20,
    },

    [theme.fn.largerThan('xl')]: {
      width: 59,
      height: 59,

      svg: {
        width: 24,
        height: 24,
      },
    },
  },
  title: {
    color: theme.colors.blue_gray[4],
    fontSize: theme.fontSizes.sm,

    [theme.fn.largerThan('xl')]: {
      fontSize: theme.fontSizes.md,
    },
  },
}));

export default KpiWidget;
