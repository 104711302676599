import React, { FC } from 'react';
import styled from 'styled-components';

import { useTheme } from '@portals/ui';

type SortCaretProps = {
  direction?: 'asc' | 'desc';
};

const SortCaret: FC<SortCaretProps> = ({ direction }) => {
  const { color } = useTheme();

  return (
    <Svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      className={direction}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.70716 0.293C6.51964 0.105529 6.26533 0.000213623 6.00016 0.000213623C5.735 0.000213623 5.48069 0.105529 5.29316 0.293L2.29316 3.293C2.111 3.4816 2.01021 3.7342 2.01249 3.9964C2.01477 4.2586 2.11994 4.50941 2.30534 4.69482C2.49075 4.88023 2.74156 4.9854 3.00376 4.98767C3.26596 4.98995 3.51856 4.88916 3.70716 4.707L6.00016 2.414L8.29316 4.707C8.48177 4.88916 8.73437 4.98995 8.99656 4.98767C9.25876 4.9854 9.50957 4.88023 9.69498 4.69482C9.88039 4.50941 9.98556 4.2586 9.98784 3.9964C9.99012 3.7342 9.88932 3.4816 9.70716 3.293L6.70716 0.293Z"
        fill={direction === 'asc' ? color.dark : color.gray400}
      />
      <path
        d="M8.29308 7.29299L6.00008 9.58599L3.70708 7.29299C3.61483 7.19748 3.50449 7.1213 3.38249 7.06889C3.26048 7.01648 3.12926 6.98889 2.99648 6.98774C2.8637 6.98659 2.73202 7.01189 2.60913 7.06217C2.48623 7.11245 2.37458 7.1867 2.28069 7.28059C2.18679 7.37449 2.11254 7.48614 2.06226 7.60904C2.01198 7.73193 1.98668 7.86361 1.98783 7.99639C1.98898 8.12917 2.01657 8.26039 2.06898 8.38239C2.12139 8.5044 2.19757 8.61474 2.29308 8.70699L5.29308 11.707C5.48061 11.8945 5.73492 11.9998 6.00008 11.9998C6.26525 11.9998 6.51955 11.8945 6.70708 11.707L9.70708 8.70699C9.80259 8.61474 9.87877 8.5044 9.93118 8.38239C9.98359 8.26039 10.0112 8.12917 10.0123 7.99639C10.0135 7.86361 9.98818 7.73193 9.9379 7.60904C9.88762 7.48614 9.81337 7.37449 9.71948 7.28059C9.62558 7.1867 9.51393 7.11245 9.39104 7.06217C9.26814 7.01189 9.13646 6.98659 9.00368 6.98774C8.8709 6.98889 8.73968 7.01648 8.61768 7.06889C8.49567 7.1213 8.38533 7.19748 8.29308 7.29299Z"
        fill={direction === 'desc' ? color.dark : color.gray400}
      />
    </Svg>
  );
};

const Svg = styled.svg`
  transform: translateY(-1px);

  path {
    transition: fill 0.15s ease-in-out;
  }
`;

export default SortCaret;
