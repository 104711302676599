import { noop } from 'lodash/fp';
import React, { useCallback } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const DeleteReferralCode = ({ data, closeMe }) => {
  const { title, onDelete = noop } = data;

  const handleDelete = useCallback(() => {
    closeMe();
    onDelete();
  }, [closeMe, onDelete]);

  return (
    <Modal
      isOpen={true}
      toggle={closeMe}
      className="modal-colored modal-danger"
      centered
    >
      <ModalHeader toggle={closeMe}>Delete Referral Code</ModalHeader>

      <ModalBody className="text-center m-3">
        <p className="mb-0">
          {title
            ? `Are you sure you want to delete "${data.title}"?`
            : 'Are you sure you want to delete this referral code?'}
        </p>
      </ModalBody>

      <ModalFooter>
        <Button color="danger" onClick={handleDelete}>
          Delete
        </Button>
        <Button color="light" onClick={closeMe}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteReferralCode;
