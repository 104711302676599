import { Box, Stack, Text } from '@mantine/core';
import { map } from 'lodash/fp';
import React, { Dispatch, FC, SetStateAction } from 'react';

import { RANGE_PRESETS } from './constants';
import { DateRangeType } from './types';

interface DateRangePresetsProps {
  onChange: Dispatch<SetStateAction<DateRangeType>>;
}

const DateRangePresets: FC<DateRangePresetsProps> = ({ onChange }) => (
  <Stack
    spacing={0}
    px="xs"
    py="lg"
    sx={(theme) => ({
      height: '100%',
      borderRight: `1px solid ${theme.colors.gray[3]}`,
    })}
  >
    {map(
      (preset) => (
        <Box
          px="lg"
          key={preset.id}
          onClick={() => onChange(preset.generateRange())}
          sx={(theme) => ({
            height: 35,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            borderRadius: theme.radius.sm,

            '&:hover': {
              backgroundColor: theme.fn.rgba(theme.colors.blue[0], 0.3),
              color: theme.colors[theme.primaryColor][4],
            },
          })}
        >
          <Text size="sm">{preset.label}</Text>
        </Box>
      ),
      RANGE_PRESETS
    )}
  </Stack>
);

export default DateRangePresets;
