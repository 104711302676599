import { useMemo } from 'react';

import {
  FeaturesType,
  PartnerConfigType,
  PortalConfigType,
  TenantType,
} from '@portals/types';

export const useWhiteLabelConfig = (
  tenantType: TenantType,
  isLoggedIn: boolean,
  config: PartnerConfigType | PortalConfigType | FeaturesType
): PartnerConfigType =>
  useMemo(() => {
    if (!isLoggedIn) return config as PartnerConfigType;

    let currentConfig;

    if (tenantType === TenantType.Organization) {
      currentConfig = config as FeaturesType;
    } else {
      currentConfig = config as PortalConfigType;
    }

    return {
      tagline: currentConfig?.tagline,
      tagline_color: currentConfig?.tagline_color,
      logo: currentConfig.logo,
      hero: currentConfig.hero,
      hero_gradient_primary: currentConfig?.hero_gradient_primary,
      hero_gradient_secondary: currentConfig?.hero_gradient_secondary,
      window_title: currentConfig?.window_title,
      name: currentConfig?.name,
      favicon: currentConfig.favicon,
    };
  }, [config, isLoggedIn, tenantType]);
