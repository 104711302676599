import { Button, Group, Stack, Text } from '@mantine/core';
import moment from 'moment';
import React, { FC } from 'react';

const Message: FC = () => {
  const notificationTime = moment().calendar(null, {
    lastWeek: '[Last] dddd LT',
    lastDay: '[Yesterday] LT',
    sameDay: '[Today] LT',
    sameElse: 'DD/MM/YYYY LT',
  });

  return (
    <>
      <Stack spacing="xl" className="notification-content">
        <Stack spacing="xs">
          <Text
            size="sm"
            color="blue_gray"
            sx={{
              whiteSpace: 'pre-line',
            }}
          >
            A new version was released. {'\n'}Please click here to reload.
          </Text>
          <Text
            size="xs"
            sx={(theme) => ({
              color: theme.colors.blue_gray[3],
            })}
          >
            {notificationTime}
          </Text>
        </Stack>
      </Stack>

      <Group grow>
        <Button onClick={() => window.location.reload()}>Reload</Button>
      </Group>
    </>
  );
};

export default Message;
