import { InputWrapper } from '@mantine/core';
import { isNumber } from 'lodash/fp';
import React, { FC, useState } from 'react';
import Check from 'react-feather/dist/icons/check';
import { connect } from 'react-redux';
import ReactS3Uploader, { ReactS3UploaderProps } from 'react-s3-uploader';
import { Progress } from 'reactstrap';
import styled from 'styled-components';

import { getHeaders } from '@portals/redux/actions/auth';
import { StateType } from '@portals/types';
import { getStyledThemeColor, hexToRgba } from '@portals/utils';

import InfoTable from '../../Table/InfoTable';
import { AutoFormikProps, FieldType } from '../types';

type FileFieldProps = {
  value: string;
  error?: string;
  auth: StateType['ui']['auth'];
  field: FieldType;
  setFieldValue: (fieldName: string, path: string) => void;
  horizontal?: AutoFormikProps['inputProps']['horizontal'];
  type: string;
};

const FileField: FC<FileFieldProps> = ({
  value,
  auth,
  field,
  setFieldValue,
  type,
}) => {
  const { title } = field;
  const [progress, setProgress] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const uploading = isNumber(progress) && progress !== 100;

  const clear = (file, next) => {
    setError(null);
    setSuccess(null);
    next(file);
  };

  const handleFinish = (response) => {
    setSuccess(true);
    setFieldValue(field.name, process.env.NX_STATIC_FILES + response.path);
  };

  const handleError: ReactS3UploaderProps['onError'] = (msg) => setError(msg);

  return (
    <InputWrapper
      label={title}
      error={error ? `Error uploading file: ${error}` : null}
    >
      <S3UploaderWrapper>
        <ReactS3Uploader
          id="s3-file-uploader"
          className="s3-file-uploader"
          signingUrl="ui/file_upload"
          signingUrlMethod="POST"
          accept={type === 'image' ? 'image/*' : '*'}
          s3path="/uploads/"
          preprocess={clear}
          onSignedUrl={() => null}
          onProgress={setProgress}
          onError={handleError}
          onFinish={handleFinish}
          // @ts-ignore (library's types do not match its real API)
          signingUrlHeaders={getHeaders(auth)}
          contentDisposition="auto"
          scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/gi, '')}
          server={process.env.NX_SERVER_URL}
          autoUpload={true}
        />
      </S3UploaderWrapper>

      {uploading ? (
        <Progress className="mt-2" animated value={progress}>
          {progress}%
        </Progress>
      ) : null}

      {!!value && type !== 'image' ? (
        <UrlWrapper className="mb-2">
          <InfoTable.Table>
            <InfoTable.Row
              label="File URL"
              value={
                <a href={value} target="_blank" rel="noopener noreferrer">
                  {value}
                </a>
              }
            />
          </InfoTable.Table>
        </UrlWrapper>
      ) : null}

      {value && type === 'image' ? (
        <div className="mb-2">
          <img
            src={value}
            alt="Uploaded logo"
            style={{
              maxWidth: '400px',
              maxHeight: '200px',
            }}
          />
        </div>
      ) : null}

      {success ? (
        <p className="text-primary d-flex align-items-center">
          <Check size={17} className="mr-2" />
          File uploaded to remote storage
        </p>
      ) : null}
    </InputWrapper>
  );
};

const S3UploaderWrapper = styled.div`
  input {
    &.s3-file-uploader {
      position: relative;
      margin-bottom: 10px;
      align-items: flex-start;
      display: flex;

      ::file-selector-button {
        background-color: ${({ theme }) => hexToRgba(theme.color.info, 90)};
        border: 1px solid ${getStyledThemeColor('gray300')};
        color: ${getStyledThemeColor('white')};
        border-radius: 4px;
        padding: 5px 10px;
        cursor: pointer;
        transition: background-color 0.15s ease-in-out;
        margin-right: 10px;

        &:hover {
          background-color: ${getStyledThemeColor('info')};
        }
      }
    }
  }
`;

const UrlWrapper = styled.div`
  .info-table {
    .info-table-row {
      grid-template-columns: 100px 1fr;
    }
  }
`;

const mapStateToProps = (state) => ({
  auth: state.ui.auth,
});

export default connect(mapStateToProps)(FileField);
