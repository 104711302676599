import { Textarea } from '@mantine/core';
import { isString } from 'lodash/fp';
import React, { FC } from 'react';

import { FieldRendererProps } from '../types';

const TextareaField: FC<FieldRendererProps> = ({
  field,
  value,
  handleBlur,
  setFieldValue,
  required,
  readOnly,
  disabled,
  error,
}) => {
  const { name, title, placeholder, inputProps } = field;
  const { startAdornment, endAdornment, ...restInputProps } = inputProps || {};

  return (
    <Textarea
      icon={startAdornment}
      rightSection={endAdornment}
      label={title}
      name={name}
      error={error}
      required={required}
      placeholder={placeholder || (isString(title) ? title : '')}
      value={value}
      disabled={disabled || readOnly}
      onChange={(event) => setFieldValue(field.name, event.target.value)}
      onBlur={handleBlur}
      {...restInputProps}
    />
  );
};

export default TextareaField;
