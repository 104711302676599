import React, { FC } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: false,
    },
  },
});

const QueryClientWrapper: FC<{ children }> = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    {children}

    {process.env.NODE_ENV === 'development' ? (
      <ReactQueryDevtools
        initialIsOpen={false}
        toggleButtonProps={{
          style: {
            left: 80,
          },
        }}
      />
    ) : null}
  </QueryClientProvider>
);

export default QueryClientWrapper;
