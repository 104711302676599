import { updateData } from '@portals/redux/actions/data';
import { reloadPage, setRoute } from '@portals/redux/actions/routing';
import { TenantType } from '@portals/types';

import {
  API,
  SIGN_IN_FAILED,
  SIGNED_IN,
  SIGNED_OUT,
  SWITCH_TENANT,
  UPDATE_AUTH_TENANT_NAME,
} from '../constants';

export const signedIn = (payload) => ({
  type: SIGNED_IN,
  payload,
});

export const signInFail = (error) => ({
  type: SIGN_IN_FAILED,
  payload: error,
});

export const signIn = (email, password, tenantType) => ({
  type: API,
  payload: {
    url: 'auth/sign_in',
    data: { email, password, [tenantType]: true },
    success: signedIn,
    error: signInFail,
    name: 'signIn',
  },
  meta: { log: false },
});

export const signInWithGoogle = (token: string) => ({
  type: API,
  payload: {
    url: 'auth/google/login',
    data: { token },
    success: signedIn,
    error: signInFail,
    name: 'signIn',
  },
  meta: { log: false },
});

export const signedOut = () => ({
  type: SIGNED_OUT,
});

export const signOut = () => ({
  type: API,
  payload: {
    url: 'auth/sign_out',
    method: 'delete',
    success: signedOut,
    error: signedOut,
  },
});

export const updateAuthTenantName = (tenantName: string) => ({
  type: UPDATE_AUTH_TENANT_NAME,
  payload: { tenantName },
});

export const switchTenant = (tenant, type, navigateToRoot = false) => [
  {
    type: SWITCH_TENANT,
    payload: { ...tenant, type },
  },
  ...(navigateToRoot ? [setRoute('/', true)] : []),
  reloadPage(),
];

export const getHeaders = ({ client, token, uid, expiry, tenant }) => ({
  client,
  'access-token': token,
  uid,
  'token-type': 'Bearer',
  expiry,
  tenant: tenant && tenant.id,
  'tenant-type': tenant && tenant.type,
});

export const createOrg = (
  org: string,
  name: string,
  email: string,
  password: string,
  referral: string | undefined,
  afterAuthPath?: string
) => ({
  type: API,
  payload: {
    url: 'ui/organization/organizations',
    data: { org, name, email, password, referral },
    success: [signedIn, setRoute(afterAuthPath || '/')],
    withError: true,
    name: 'createOrg',
  },
  meta: { log: false },
});

export const forgotPassword = (email, tenant) => ({
  type: API,
  payload: {
    url: 'ui/users/forgot_password',
    data: { email, tenant },
    success: setRoute('/auth/sign-in'),
    withError: true,
    name: 'resetPasswordLink',
    toastr: 'Password reset link sent in email',
  },
});

export const changePasswordToken = (token, password, tenant) => ({
  type: API,
  payload: {
    url: 'ui/users/reset_password_token',
    data: { token, password, tenant },
    success: [signedIn, setRoute('/')],
    withError: true,
    name: 'changePasswordToken',
    toastr: 'New password set',
  },
  meta: { log: false },
});

export const changePassword = (data, extraActions) => ({
  type: API,
  payload: {
    url: 'ui/users/change_password',
    data,
    success: [signedIn, extraActions],
    withError: true,
    name: 'changePassword',
    toastr: 'New password set',
  },
  meta: { log: false },
});

export const getUserDetails = (token: string, tenant: TenantType) => ({
  type: API,
  payload: {
    url: `ui/users/get_user_details/${tenant}/${token}`,
    success: updateData('welcome_details'),
    name: 'getUserDetails',
    withError: true,
  },
  meta: { log: false },
});

export type SetUserDetailsType = {
  token: string;
  password: string;
  name: string;
  tenant: TenantType;
};

export const setUserDetails = (data: SetUserDetailsType) => ({
  type: API,
  payload: {
    url: 'ui/users/set_user_details',
    data,
    success: [signedIn, setRoute('/')],
    withError: true,
    name: 'setUserDetails',
    toastr: 'User details set',
  },
  meta: { log: false },
});
