import { TabsStylesNames, TabsStylesParams } from '@mantine/core';

import { ComponentStyleOverrideFn } from './common-types';

export const tabsStyle: ComponentStyleOverrideFn<
  TabsStylesNames,
  TabsStylesParams
> = (theme) => ({
  root: {
    backgroundColor: theme.white,
  },
  body: {
    padding: 0,
  },
  tabControl: {
    height: '35px',
    borderBottom: `3px solid ${theme.colors.gray[2]} !important`,
  },
  tabActive: {
    '.mantine-Tabs-tabLabel': {
      color: theme.other.primaryColor,
    },
    borderBottom: `3px solid ${theme.other.primaryColor} !important`,
  },
  tabInner: {
    lineHeight: 'normal',
  },
  tabLabel: {
    fontWeight: 600,
    color: theme.colors.gray[6],
  },
});
